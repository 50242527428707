import React, { useState, useEffect } from "react";
import AccordionItem from "./components/AccordianItem";
import companyLogo from "../../images/cm-logo-white.png";
import locationIcon from "../../images/footer/location.png";
import phoneIcon from "../../images/footer/phone.png";
import mainIcon from "../../images/footer/mail.png";
import youtubeIcon from "../../images/footer/youtube.png";
import twitterIcon from "../../images/footer/twitter.png";
import linkedinIcon from "../../images/footer/linkedin.png";
import instagramIcon from "../../images/footer/instagram.png";
import facebookIcon from "../../images/footer/facebook.png";
import { ExploreColleges, FooterLinks } from "./Json/FooterJson";
import { Link } from "react-router-dom";
import config from "../../util/config";

const GlobalFooter = () => {
  // Links with URLs for each section

  const [openSection, setOpenSection] = useState<number | null>(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setOpenSection(0);
    } else {
      setOpenSection(null);
    }
  }, [isMobile]);

  const toggleSection = (index: number) => {
    if (!isMobile) return;

    setOpenSection((prev) => (prev === index ? null : index));
  };
  return (
    <footer className="bg-[#173CBA] text-white">
      <div className="container flex flex-col gap-4 p-6 max-w-screen-2xl">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {FooterLinks.map((column, index) => (
            <div key={index}>
              <div
                className="flex justify-between items-center w-full md:cursor-pointer"
                onClick={() => toggleSection(index)}
              >
                <h3 className="text-lg font-semibold">{column.title}</h3>
                {isMobile && (
                  <button
                    className="text-xl font-bold"
                    aria-expanded={openSection === index}
                  >
                    {openSection === index ? "-" : "+"}
                  </button>
                )}
              </div>

              {/* Accordion Content - Open by default on Desktop */}
              <ul
                className={`space-y-2 overflow-hidden transition-all duration-300 ${
                  openSection === index || !isMobile
                    ? "max-h-auto opacity-100 py-2"
                    : "max-h-0 opacity-0"
                }`}
              >
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="list-none">
                    <a
                      href={link.url}
                      className="text-xs text-white transition-opacity duration-200 opacity-70 hover:opacity-100"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div>
          <AccordionItem
            title="Explore University Applications for 2025"
            links={ExploreColleges}
          />
        </div>

        <div className="flex flex-col justify-between p-4 md:items-center md:flex-row xs:items-baseline">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={companyLogo} alt="Logo" className="w-auto h-10" />
          </div>

          <div className="flex items-center space-x-4 text-right">
            <h2 className="text-md">Connect with us</h2>

            <div className="flex mt-2 space-x-4 md:mt-0">
              <a
                href="https://www.youtube.com/@college.mentor"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={youtubeIcon}
                  alt="youtube icon"
                  className="shrink-0 aspect-[1.43] fill-white w-[23px]"
                />
              </a>
              <a
                href="https://x.com/college_mentor_"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={twitterIcon}
                  alt="twitter icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/mycollegementor"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={linkedinIcon}
                  alt="linkedin icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a
                href="https://www.facebook.com/people/My-College-Mentor/61570539353128/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={facebookIcon}
                  alt="facebook icon"
                  className="w-4 shrink-0 aspect-square"
                />
              </a>
              <a
                href="https://www.instagram.com/college.mentor_insta"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src={instagramIcon}
                  alt="instagram icon"
                  className="w-4 shrink-0 aspect-square fill-white"
                />
              </a>
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-sm">{config.mobileNumber}</p>{" "}
          </div>

          <div className="flex items-center">
            <p className="text-sm">admin@collegementor.com</p>{" "}
          </div>
        </div>

        <div className="flex flex-col items-center justify-between pt-4 border-t border-gray-500 sm:flex-row">
          <div className="mb-4 space-x-4 sm:mb-0">
            <Link to="/about-us" className="hover:underline">
              About Us
            </Link>
            <a href="/contact-us" className="hover:underline">
              Contact Us
            </a>
            <Link to="/privacy-policy" className="hover:underline inline-block">
              Privacy Policy
            </Link>
            <Link to="/careers-dd" className="hover:underline">
              Careers
            </Link>
            <Link to="/terms" className="hover:underline">
              Terms & Conditions
            </Link>
            <Link to="/sitemap.xml" className="hover:underline">
              Sitemap
            </Link>
          </div>

          <div className="text-center sm:text-right">
            <p className="text-center sm:text-right">
              <span className="block sm:inline">All Rights Reserved. </span>{" "}
              <span className="block sm:inline">
                © {new Date().toLocaleString("en-IN", { year: "numeric" })}{" "}
                Dreams and Degrees Edutech Private Limited
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
