import React from "react";

interface AboutNEETSectionProps {
  title: string;
  description: string;
  imageUrl?: string;
}

const AboutNEETSection: React.FC<AboutNEETSectionProps> = ({
  title,
  description,
  imageUrl,
}) => {
  return (
    <div className="section-container py-10 max-sm:py-5 bg-[#FFF9E5]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-10">
          <div
            className={`w-full ${
              imageUrl && imageUrl !== "#" ? "lg:w-1/2" : "lg:w-full"
            }`}
          >
            <div className="flex flex-col gap-3">
              <h2 className="text-3xl font-normal text-blue">{title}</h2>
              <div
                className="text-black text-base flex flex-col gap-3 examDetailsSec"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>

          {imageUrl && imageUrl !== "#" && (
            <div className="w-full lg:w-1/2">
              <img
                src={imageUrl}
                alt={title}
                className="mb-4 w-full max-h-60 object-cover rounded-md"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutNEETSection;
