import React, { useEffect } from "react";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "404 Not Found";
    // Simulate a real 404 status
    window.history.replaceState({}, "Not Found", window.location.pathname);
  }, []);
  return (
    <div className="not-found-container">
      <div className="not-found-header">
        <h1>404 NOT FOUND</h1>
      </div>

      <div className="not-found-main">
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
