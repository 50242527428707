import React, { useState } from "react";

interface FAQItemProps {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  isOpen,
  onClick,
}) => {
  return (
    <div className="flex flex-col mt-5 w-full leading-7 gap-5 max-md:w-full">
      <button
        className="flex flex-row text-[14px] gap-3.5 items-center text-left w-full max-md:w-full"
        onClick={onClick}
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M11 18L11 6"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        )}
        <span className="font-semibold  ">{question}</span>
      </button>
      {isOpen && (
        <div className="flex-auto leading-6 font-normal max-md:w-full pl-4">
          {answer}
        </div>
      )}
    </div>
  );
};

const CareerDiscoveryFAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqItems = [
    {
      question: "Why do students need career counseling?",
      answer:
        "Career counseling helps students understand their strengths, interests, and options, guiding them in making informed decisions about their future.",
    },
    {
      question: "What is career counseling?",
      answer:
        "Career counseling is a process where trained professionals assist individuals in exploring career options and making choices based on their skills and aspirations.",
    },
    {
      question: "What types of career counseling are available?",
      answer:
        "There are various types of career counseling, including individual counseling, group workshops, and online assessments to suit different needs.",
    },
    {
      question: "How does career counseling benefit students?",
      answer:
        "It provides clarity on career paths, helps set achievable goals, enhances job search strategies, and increases confidence in decision-making.",
    },
    {
      question: "What is the Ikigai Career Assessment Test?",
      answer:
        "The Ikigai Career Assessment Test is a tool designed to help individuals find their passion and purpose in their career by evaluating their interests and values.",
    },
    {
      question: "Who developed the Ikigai Career Assessment Test?",
      answer:
        "The Ikigai concept originates from Japanese philosophy, but various career coaches and organizations have adapted it into assessment tools for career counseling.",
    },
    {
      question: "How does the assessment work?",
      answer:
        "Participants answer questions regarding their passions, skills, and what they value in a career. The results help identify potential career paths that align with their Ikigai.",
    },
    {
      question: "What can I expect from the counseling sessions?",
      answer:
        "Counseling sessions typically include discussions about your goals, assessments to identify strengths, and guidance on creating a personalized action plan.",
    },
  ];

  return (
    <div className="flex flex-col max-md:px-8 px-20 max-md:w-full text-sm font-semibold py-10 max-md:py-5 text-black w-full  bg-[#FFF]">
      <h2 className="text-[40px] max-md:text-xl font-bold text-black leading-[55px]">
        Knowledge Hub (FAQ'S)
      </h2>
      {faqItems.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={openIndex === index}
          onClick={() => setOpenIndex(openIndex === index ? null : index)}
        />
      ))}
    </div>
  );
};

export default CareerDiscoveryFAQ;
