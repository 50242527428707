import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper"; // ✅ Fix Swiper Import
import { Navigation } from "swiper/modules"; // ✅ Import only Navigation
import "swiper/css";
import "swiper/css/navigation";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";
import NeetUpdateSliderBg from "../../images/exams/updatesSlider.webp";
import NeetUpdateMobile from "../../images/exams/NeetUpdateMobile.webp";
import { EpRight } from "../study-abroad-landing/AboutEducationMentorsGlobally";

interface NeetUpdate {
  imageUrl: string;
  title: string;
  heading: string;
  subheading: string;
  description: string;
  additionalInfo: string;
  order: string;
}

const NeetUpdatesSlider: React.FC = () => {
  const { examName } = useParams<{ examName: string }>();
  const [updates, setUpdates] = useState<NeetUpdate[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Refs for custom navigation buttons
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const swiperRef = useRef<SwiperType | null>(null); // ✅ Store Swiper instance

  useEffect(() => {
    const fetchUpdates = async () => {
      if (!examName) {
        setError("Exam name is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${config.apiUrl}/api/exam-details/${examName}/examdetails-updates`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch updates");
        }

        const data = await response.json();
        const sortedUpdates = (Object.values(data) as NeetUpdate[]).sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        );

        setUpdates(sortedUpdates);
      } catch (err) {
        console.error("Error fetching updates:", err);
        setError("Unable to load updates.");
      } finally {
        setLoading(false);
      }
    };

    fetchUpdates();
  }, [examName]);

  // **Fix navigation assignment after Swiper initializes**
  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      const navigationParams = swiperRef.current.params.navigation;

      if (navigationParams && typeof navigationParams !== "boolean") {
        navigationParams.prevEl = prevRef.current;
        navigationParams.nextEl = nextRef.current;
        swiperRef.current.navigation.init();
        swiperRef.current.navigation.update();
      }
    }
  }, [updates]); // Run only when `updates` are set

  if (loading) return <Loader />;
  if (error || updates.length === 0) return null;

  const formatExamName = (examName: string): string => {
    return examName
      .split("-")
      .map((word) =>
        word.length < 5
          ? word.toUpperCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  };

  return (
    <div
      className="hidden sm:block w-full mx-auto relative z-20 rounded-[80px] max-w-96"
      style={{
        backgroundSize: "100%",
        backgroundImage: `url(${NeetUpdateSliderBg})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="h-[630px] sm:h-[718px] w-full relative flex flex-col justify-between"
        style={{
          backgroundImage: `url(${NeetUpdateMobile})`,
          backgroundSize: "100%",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Swiper
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={1}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          className="overflow-hidden w-full"
        >
          {updates.map((update, index) => (
            <SwiperSlide key={index} className="rounded-lg p-8 h-full">
              <div className="flex flex-col mt-8 h-full overflow-hidden">
                <h2 className="text-lg font-bold text-blue line-clamp-2">
                  {formatExamName(examName || "")}{" "}
                  <span className="font-normal">Updates</span>
                </h2>
                <div className="min-h-[290px] sm:min-h-[350px]"></div>
                <div className="h-40 overflow-x-hidden overflow-y-auto">
                  <p className="text-sm text-gray-800 mt-4">
                    {update.description}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="flex justify-center items-center gap-8 mb-8">
          <button
            ref={prevRef}
            className="w-12 h-12 flex items-center justify-center bg-blue text-white rounded-full shadow-md"
          >
            <EpRight className="rotate-180" />
          </button>
          <span className="text-gray-600 text-lg">
            {`1 / ${updates.length}`}
          </span>
          <button
            ref={nextRef}
            className="w-12 h-12 flex items-center justify-center bg-blue text-white rounded-full shadow-md"
          >
            <EpRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NeetUpdatesSlider;
