import React from "react";
import { Link } from "react-router-dom";

const courses1 = [
  { title: "Diploma", category: "diploma" },
  { title: "B.E / B.TECH", category: "bachelors" },
  { title: "M.E/ M.TECH", category: "masters" },
  { title: "BBA", category: "bachelors" },
  { title: "MBA", category: "masters" },
  { title: "B.PHARM", category: "bachelors" },
  { title: "M.PHARM", category: "masters" },
  { title: "MBBS", category: "bachelors" },
  { title: "MD", category: "masters" },
  { title: "MS", category: "masters" },
  { title: "BDS", category: "bachelors" },
  { title: "MDS", category: "masters" },
  { title: "B.ARCH", category: "bachelors" },
  { title: "M.ARCH", category: "masters" },
  { title: "B.DES", category: "bachelors" },
  { title: "M.DES", category: "masters" },
  { title: "BA", category: "bachelors" },
  { title: "MA", category: "masters" },
  { title: "BSC", category: "bachelors" },
  { title: "MSC", category: "bachelors" },
  { title: "B.COM", category: "bachelors" },
  { title: "M.COM", category: "masters" },
  { title: "BCA", category: "bachelors" },
  { title: "MCA", category: "masters" },
  { title: "LLB", category: "bachelors" },
  { title: "LLM", category: "masters" },
];

const DreamCourses = ({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) => {
  return (
    <div className="flex flex-col text-sm font-bold text-black">
      <div className="flex flex-col justify-center items-center px-20 py-5 w-full bg-[#F7F7F7] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full items-center max-md:max-w-full">
          <div className="flex justify-center items-center relative">
            <h3 className="text-black text-3xl font-extrabold">
              Dream Courses
            </h3>
            <div
              className="absolute -right-24 top-1/2 -translate-y-1/2 h-16 w-16"
              ref={animationCheckPointRef}
            ></div>
          </div>

          <div className="flex flex-wrap gap-4 justify-center font-normal items-center mt-7 leading-8 text-center max-md:mr-2.5 max-md:max-w-full">
            {courses1
              .slice(0, window.innerWidth <= 768 ? 9 : courses1.length)
              .map((course, index) => (
                <Link
                  key={index}
                  to={`/courses/list-of-courses-in-india?sort-by=popularity&degreeCategory=${course.category}`}
                  className="gap-2.5 self-stretch px-5 hover:text-[#17bc] py-0.5 my-auto whitespace-nowrap bg-white border border-solid border-[#747474] min-h-[36px] rounded-[50px]"
                >
                  {course.title}
                </Link>
              ))}
          </div>

          <Link
            to={"/courses/list-of--courses-in-india"}
            className="flex gap-2 justify-center  items-center self-center button-styles arrow-button white-color map-mentor mt-8 ml-[-30px] text-base text-white bg-blue-800 rounded-[50px] max-md:px-5"
          >
            <span className="self-stretch my-auto">View all courses</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
              className="arrow-icon"
            >
              <path
                d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                fill="currentColor"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DreamCourses;
