import React from "react";
import TuitionFeeStructure from "../admissions/TuitionFeeStructure";
import KnowledgeHubFAQ from "../KnowledgeHubFAQ";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import OnlineCourses from "../admissions/OnlineCourses";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface AdmissionSectionProps {
  title: string;
  description: string;
  orderIndex?: string;
}

interface FAQItem {
  title: string;
  description?: string;
  orderIndex?: string;
}

interface CollegeProps {
  image: string;
  name: string;
  location: string;
  title: string;
}

interface AdmissionsTabProps {
  admissionsDetails: {
    [key: string]:
      | AdmissionSectionProps
      | FAQItem[]
      | CollegeProps[]
      | { title: string; array: FAQItem[] }
      | undefined;
    similarColleges?: CollegeProps[];
    faqs?: {
      title: string;
      array: FAQItem[];
    };
  };
}

const AdmissionSection: React.FC<AdmissionSectionProps> = ({
  title,
  description,
}) => {
  return (
    <section className="admission-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const AdmissionsTab: React.FC<AdmissionsTabProps> = ({ admissionsDetails }) => {
  const similarColleges = admissionsDetails.similarColleges || [];
  const admissionsFAQs = admissionsDetails.faqs as
    | { title: string; array: FAQItem[] }
    | undefined;

  const keys = Object.keys(admissionsDetails)
    .filter((key) => key.startsWith("key_") && admissionsDetails[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (admissionsDetails[a] as AdmissionSectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (admissionsDetails[b] as AdmissionSectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              <div className="flex flex-col gap-5">
                {keys.map((key) => {
                  const section = admissionsDetails[
                    key
                  ] as AdmissionSectionProps;

                  if (!section?.title || !section?.description) return null;

                  if (key === "key_5") {
                    return (
                      <TuitionFeeStructure
                        key={key}
                        title={section.title}
                        description={section.description}
                      />
                    );
                  }

                  if (key === "key_6") {
                    return (
                      <OnlineCourses
                        key={key}
                        title={section.title}
                        description={section.description}
                      />
                    );
                  }

                  return (
                    <AdmissionSection
                      key={key}
                      title={section.title}
                      description={section.description}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full lg:w-1/4">
              <div className="flex flex-col gap-10">
                <ApplicationForms />
                <CoursesList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        {admissionsFAQs?.array?.length ? (
          <KnowledgeHubFAQ
            title={admissionsFAQs.title || "Knowledge Hub (FAQs)"}
            faqs={admissionsFAQs.array.map((item: FAQItem) => ({
              question: item.title,
              answer: item.description || "Information not available.",
            }))}
          />
        ) : null}

        {similarColleges.length > 0 && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </>
  );
};

export default AdmissionsTab;
