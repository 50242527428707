import React, { useState } from "react";

const faqs = [
  {
    question: "What Does College Mentor Do?",
    answer:
      "College Mentor helps students connect with mentors to receive guidance on academics, career paths, and college life.",
  },
  {
    question: "Where are your mentors located?",
    answer:
      "Our mentors are from various locations worldwide, covering multiple time zones for better accessibility.",
  },
  {
    question:
      "Are Language Skills an Issue When Working with a College Mentor?",
    answer:
      "No, we have mentors proficient in various languages to assist students from different backgrounds.",
  },
  {
    question:
      "How Often Will I Be Charged? How Are Payments and Invoices Handled?",
    answer:
      "Payments are processed monthly, and invoices are sent to your registered email.",
  },
  {
    question: "What Methods of Payment Do You Accept?",
    answer: "We accept credit cards, PayPal, and other online payment options.",
  },
  {
    question: "I Want to Request a Refund?",
    answer:
      "Refunds can be requested through the support portal within 7 days of payment.",
  },
  {
    question: "When to Open a Refund Request?",
    answer:
      "Refund requests should be opened if you have billing issues or are unsatisfied with the service.",
  },
  {
    question: "What Are Your Mentor Requirements and Commitments?",
    answer:
      "Mentors are required to have experience in their field and dedicate a few hours per week to mentoring.",
  },
  {
    question: "What is the Purpose of Screening College Mentors?",
    answer:
      "The screening ensures that only qualified and experienced mentors are available for students.",
  },
  {
    question: "What Does Your Screening Process Include?",
    answer:
      "Our screening process includes background verification, experience checks, and an interview.",
  },
];

export const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="bg-[#FFF9E5] py-14 max-md:py-6 px-6">
      <div className="container">
        <h2 className="text-[40px] font-bold text-[#383838] mb-12 min-max-xss:text-2xl min-max-xs:text-3xl max-md:mb-6">
          Common Questions (FAQS)
        </h2>
        <div className="xl:w-[1029px] py-8 md:px-14 bg-white max-md:w-full max-md:px-4 max-md:py-3 ">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border-b border-[#D6D2D2] py-4 cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex justify-between items-center max-md:gap-2 ">
                <h3 className="text-lg font-medium text-black max-md:text-sm min-max-xs:max-w-[268px] min-max-xss:w-[206px]">
                  {faq.question}
                </h3>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  className="transition-transform duration-300"
                >
                  <circle cx="15" cy="15" r="15" fill="#173CBA" />
                  {openIndex === index ? (
                    <path
                      d="M6.6673 15H23.334"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  ) : (
                    <>
                      <path
                        d="M14.9999 6.66699L14.9999 23.3337"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6.6673 15H23.334"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </>
                  )}
                </svg>
              </div>
              {openIndex === index && (
                <p className="mt-2 text-gray-600 max-sm:text-sm">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
