import React, { useEffect } from "react";
import StudyUniversityBanner from "../../components/study-abroad-university/StudyUniversityBanner";
import RecruitDiverse from "../../components/study-abroad-university/RecruitDiverse";
import BrandAwareness from "../../components/study-abroad-university/BrandAwareness";
import EngageActiveStudents from "../../components/study-abroad-university/EngageActiveStudents";
import TrafficEnrollments from "../../components/study-abroad-university/TrafficEnrollments";
import ConvertConfidence from "../../components/study-abroad-university/ConvertConfidence";
import UnrivaledConversionRates from "../../components/study-abroad-university/UnrivaledConversionRates";
import InternationalConsulting from "../../components/study-abroad-university/InternationalConsulting";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";

const StudyAbroadUniversity: React.FC = () => {
  const data = {
    bannerSection: {
      title: "Transformative Solutions For Higher Education",
      description:
        "Enrollment Marketing for <strong>K-12, & Higher Ed</strong> Less Guesswork. More Enrollments.",
      paragraphText:
        "<strong class='text-blue'>College mentor</strong> an online education agency. We provide you with admission-ready applications.",
      buttonText: "Connect to Marketing Head",
    },
    RecruitDiverseStudents: {
      title: "Recruit Diverse Students",
      description:
        "Access a global talent pool with our strategic network. Achieve diversity with our extensive reach.",
    },
    brandAwareness: {
      title: "Build Brand Awareness",
      description:
        "Influence students and families with a standout College Mentor profile. Use targeted digital advertising and automated emails to stay top-of-mind.",
    },
    engageActiveStudents: {
      title: "Engage Active Students",
      description:
        "Identify and engage high-intent students at every stage of their journey. Drive traffic to your website and increase inquiries and applications.",
    },
    trafficEnrollments: {
      title: "Convert Traffic into Enrollments",
      description:
        "Turn website visits into enrolled students with our unique tools and programs. Leverage Qualified Inquiries and Direct Admissions Programs to connect with best-fit students.",
    },
    ConvertConfidence: {
      title: "Reach and Convert with Confidence",
      description: "Brand-Building Reach: Trusted by students and families.",
    },
    UnrivaledConversionRates: {
      title: "Unrivaled Conversion Rates",
      descriptionOne:
        "15x more likely to apply and 24x more likely to enroll. High-Intent Audience: Efficiently target active searchers",
      descriptionTwo:
        "BOOK A CALL to explore our enrollment marketing solutions.",
      buttonText: "Connect to Marketing Head",
    },
    AdmissionsConsulting: {
      title: "International Admissions Consulting",
      description:
        "International Admissions Consulting Helping students apply to colleges and universities in different countries, offering expertise in application processes, requirements, and strategic planning.",
      items: [
        {
          checkBoxText: "Get access to manage your College profile",
        },
        {
          checkBoxText:
            "Connect with users who are actively Researching their Dream Universities",
        },
        {
          checkBoxText:
            "College Mentor has the most comprehensive data Students looking for Admissions",
        },
        {
          checkBoxText:
            "Upgrade to premium Make your profile stand out and take advantage of our enrollment marketing solutions.",
        },
      ],
      buttonText: "Connect to Marketing Head",
    },
  };
  useEffect(() => {
    document.title =
      "Increase admissions with targeted marketing of college mentor";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Connect to Indian students through College Mentor & increase your university application percentage, convert them into admissions & create brand awareness."
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            Increase admissions with targeted marketing of college mentor
          </title>
          <meta
            name="description"
            content="Connect to Indian students through College Mentor & increase your university application percentage, convert them into admissions & create brand awareness."
          />
          <meta
            name="keywords"
            content="Through college mentors connect to students, Increase your application percentage, Reach the students through College Mentor, Effective marketing to reach the students/ audience, Creating Brand awareness, Indian students to world universities, Admissions and applications, University Admissions."
          />
          <link
            rel="canonical"
            href={`${config.apiUrl}/study-abroad-university`}
          />

          <meta
            property="og:title"
            content="Increase admissions with targeted marketing of college mentor"
          />
          <meta
            property="og:description"
            content="Connect to Indian students through College Mentor & increase your university application percentage, convert them into admissions & create brand awareness."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Increase admissions with targeted marketing of college mentor"
          />
          <meta
            name="twitter:description"
            content="Connect to Indian students through College Mentor & increase your university application percentage, convert them into admissions & create brand awareness."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="flex flex-col lg:gap-1.5">
        <StudyUniversityBanner data={data.bannerSection} />
        <RecruitDiverse data={data.RecruitDiverseStudents} />
        <BrandAwareness data={data.brandAwareness} />
        <EngageActiveStudents data={data.engageActiveStudents} />
        <TrafficEnrollments data={data.trafficEnrollments} />
        <ConvertConfidence data={data.ConvertConfidence} />
        <UnrivaledConversionRates data={data.UnrivaledConversionRates} />
        <InternationalConsulting data={data.AdmissionsConsulting} />
      </div>
    </>
  );
};

export default StudyAbroadUniversity;
