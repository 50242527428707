import React from "react";
import AboutNEETSection from "./Overview/AboutNEETSection";
import CoursesSection from "./Overview/CoursesSection";
import HighlightsSection from "./Overview/HighlightsSection";
import ImportantDatesSection from "./Overview/ImportantDatesSection";
import ExamUpdates from "./ExamUpdates";
import OverviewTabRightSide from "./OverviewTabRightSide";
import NeetUpdatesSlider from "./NeetUpdatesSlider";

interface Section {
  title: string;
  description: string;
  imageUrl?: string;
  order: string;
}

interface OverviewTabProps {
  data: { [key: string]: Section };
}

const OverviewTab: React.FC<OverviewTabProps> = ({ data }) => {
  if (!data || typeof data !== "object") {
    console.error("Invalid data provided to OverviewTab component");
    return <div>No data available</div>;
  }

  const sortedSections = Object.keys(data)
    .map((key) => ({ key, ...data[key] }))
    .sort((a, b) => parseInt(a.order) - parseInt(b.order));

  return (
    <div className="flex flex-col lg:flex-row mx-auto gap-4">
      <div className="w-full flex flex-col gap-4">
        {sortedSections.map((section) => {
          if (section.key === "key_0") {
            return (
              <div key={section.key}>
                <AboutNEETSection
                  title={section.title}
                  description={section.description}
                  imageUrl={section.imageUrl}
                />
                <ExamUpdates />
              </div>
            );
          }
          return null;
        })}
        <section className="section-container py-10 bg-[#FFF9E5] max-sm:py-6">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row gap-8">
              <div className="w-full lg:w-[60%] flex flex-col gap-6 max-sm:gap-3">
                {sortedSections.map((section) => {
                  if (section.key !== "key_0") {
                    return (
                      <CoursesSection
                        key={section.key}
                        title={section.title}
                        description={section.description}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="w-full lg:w-[40%] flex flex-col gap-8">
                <OverviewTabRightSide />
                <NeetUpdatesSlider />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OverviewTab;
