import React from "react";

const Banner = () => {
  return (
    <div>
      <div className="px-20 min-max-md:px-14 min-max-md:py-10 py-16 max-sm:py-8 w-full max-md:px-5 max-md:max-w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED]">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-3/5 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto w-full max-md:mt-3 max-md:max-w-full">
              <div className="flex flex-col w-full text-[#173CBA] max-md:max-w-full">
                <h1 className="text-4xl font-bold leading-none max-md:max-w-full">
                  Refer and Earn
                </h1>
                <div className="flex flex-col mt-3 w-full max-md:max-w-full">
                  <p className="text-xl font-light leading-9 max-md:max-w-full">
                    <span className="">Get up to</span>{" "}
                    <span className="font-semibold">₹150,000</span>
                    <span className="">! Earn </span>
                    <span className="font-semibold">₹5,000</span>
                    <span className="">
                      {" "}
                      for each friend who successfully enrolls. Spread the word!{" "}
                    </span>
                  </p>
                  <span className="mt-10 text-xl">
                    Spread Education and Impact lives with College Mentor
                  </span>
                  <h2 className="mt-14 text-2xl max-md:mt-10 max-md:max-w-full">
                    Study Together. Stay Together!
                  </h2>
                </div>
              </div>
              <button className="flex gap-1 justify-center items-center self-start px-3 py-3 mt-10 bg-[#0c9] rounded min-h-[42px]">
                <span className="self-stretch my-auto text-base font-semibold leading-none text-white">
                  Start Earning
                </span>
                <div className="flex flex-col justify-center self-stretch px-1 py-2.5 my-auto w-[19px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                  >
                    <path
                      d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-2/5 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="/images/referral-marketing.png"
              alt="Refer and Earn illustration"
              className="object-contain grow w-full aspect-[1.21] max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
