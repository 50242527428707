import "./Footer.scss";

import React, { useEffect, useState } from "react";

const Footer = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine the background image based on screen size
  const backgroundImageStyle = {
    backgroundImage: `url(${
      isSmallScreen
        ? "/images/college-predictor/footer/sm-bg.png"
        : "/images/college-predictor/footer/bg.png"
    })`,
    backgroundSize: `${isSmallScreen ? "cover" : "contain"}`,
    backgroundRepeat: "no-repeat",
    margin: 0,
    padding: 0,
  };
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [choice, setChoice] = useState("option1");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log({ email, phone, choice });
  };
  return (
    <div className="container flex flex-col justify-center my-12 lg:my-7">
      <div
        className="w-full h-[90vh] md:h-[80vh] md:object-contain lg:h-[90vh] "
        style={backgroundImageStyle}
      >
        <div className="flex flex-col">
          <div className="h-[25vh] md:h-[40vh] lg:h-[68vh] relative"></div>
          <div className="flex flex-col justify-center">
            <div className="head">
              College Mentor <br />
              Dreams to Degrees
            </div>
            <div className="form-container flex flex-col lg:flex-row justify-center">
              <div className="text-[#383838] text-center font-sans text-base font-bold leading-[27px]">
                Get started Today
              </div>
              <form
                className="flex flex-col lg:flex-row gap-4 lg:gap-0"
                onSubmit={handleSubmit}
              >
                <label
                  className="text-[#383838] font-sans text-base font-normal leading-[27px] bg-white p-2 mr-2"
                  style={{ gap: "5px" }}
                >
                  Mail ID:
                  <input
                    type="email"
                    placeholder="Youremail@Id.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label
                  className="text-[#383838] font-sans text-base font-normal leading-[27px] bg-white p-2 mr-2"
                  style={{ gap: "5px" }}
                >
                  Mobile Number:
                  <input
                    type="tel"
                    placeholder="1234567890"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </label>
                <select
                  className="p-2 mr-2"
                  value={choice}
                  onChange={(e) => setChoice(e.target.value)}
                >
                  <option value="option1">Choice of study</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
                <button className="bg-[#0C9] p-2" type="submit">
                  Submit
                </button>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>{" "}
      <div className="relative text-white text-center font-sans text-[12px] md:text-2xl  font-normal leading-[27px] -translate-y-2">
        <span className="bg-[#173CBA] md:px-6 lg:px-20 py-1 rounded-lg whitespace-nowrap ">
          {" "}
          Will guide you every step of your Educational and Career journey
        </span>
      </div>
    </div>
  );
};

export default Footer;
