import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import BlogsHeader from "./BlogsHeader";
import { HeaderAPIResponse } from "../data/UtilsData";
import axios from "axios";
import config from "../../../util/config";
import { BiLogoMessenger } from "react-icons/bi";
import BlogsMegaMenu from "./BlogsMegaMenu";
import BlogFooter from "./BlogFooter";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { fetchLandingData } from "../../../store/thunks/blogThunk";
import ScrollToTop from "../../../components/ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GlobalFooter from "../../../components/global/GlobalFooter";
const headerLogo = "../src/college-mentor-logo.png";
const BlogsLayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [headerResponse, setHeaderResponse] = useState<any>([]);
  const getHeaderData = async () => {
    await axios
      .get(config.apiUrl + "/api/blogs/allsections")
      .then((response: { data: any }) => {
        // console.log("response", response);
        // const allSectionData = response.data;
        // console.log("allSectionData", allSectionData);
        setHeaderResponse(response.data);
        // setSectionWiseData(allSectionData?.sections[0]);
      })
      .catch((error: any) => console.log("error", error));
  };
  useEffect(() => {
    getHeaderData();
    dispatch(fetchLandingData());
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>Blog main - College Mentor</title>
          <meta
            name="description"
            content="Default description for the blog."
          />
          <meta name="keywords" content="Education, Blogs, College Mentor" />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content="Blog Details" />
          <meta property="og:description" content="Content" />
          <meta property="og:image" content={headerLogo} />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        {/* <BlogsHeader
        headerResponse={headerResponse?.categories}
        sectionsList={headerResponse?.sectionsList}
      /> */}
        <BlogsMegaMenu
          categories={headerResponse?.categories}
          sectionsList={headerResponse?.sectionsList}
        />
        <Outlet />
        <ScrollToTop />
        {/* <BlogFooter sectionsList={headerResponse?.sectionsList} /> */}
        <GlobalFooter />
      </HelmetProvider>
    </>
  );
};

export default BlogsLayout;
