import React, { useEffect } from "react";
import Banner from "./components/Banner";
import HowItWorks from "./components/HowItWorks";
import TermsAndConditions from "./components/TermsAndConditions";
import WhatOurReferences from "./components/WhatOurReferences";
import ReferralBenefits from "./components/ReferralBenefits";
import TopEarners from "./components/TopEarners";
import FAQ from "./components/FAQ";
import ReferralSupport from "./components/ReferralSupport";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";

const ReferAndEarn = () => {
  useEffect(() => {
    document.title = "Refer & Earn with College Mentor | Earn Up to ₹1,50,000";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Help your friends achieve their dreams with College Mentor! Refer to them and earn exciting rewards while they enjoy exclusive admission benefits."
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>Refer & Earn with College Mentor | Earn Up to ₹1,50,000</title>
          <meta
            name="description"
            content="Help your friends achieve their dreams with College Mentor! Refer to them and earn exciting rewards while they enjoy exclusive admission benefits."
          />
          <meta
            name="keywords"
            content="refer and earn program, refer a friend and earn money, referral program for students, earn cashback for referrals, study abroad referral benefits, college mentor referral program, up to ₹1,50,000 referral rewards, referral rewards India and abroad, cashback for referrals, easy referral program education."
          />
          <link rel="canonical" href={`${config.apiUrl}/refer-and-earn`} />

          <meta
            property="og:title"
            content="Refer & Earn with College Mentor | Earn Up to ₹1,50,000"
          />
          <meta
            property="og:description"
            content="Help your friends achieve their dreams with College Mentor! Refer to them and earn exciting rewards while they enjoy exclusive admission benefits."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Refer & Earn with College Mentor | Earn Up to ₹1,50,000"
          />
          <meta
            name="twitter:description"
            content="Help your friends achieve their dreams with College Mentor! Refer to them and earn exciting rewards while they enjoy exclusive admission benefits."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner />
        <HowItWorks />
        <ReferralBenefits />
        <TermsAndConditions />
        <TopEarners />
        <WhatOurReferences />
        <FAQ />
        <ReferralSupport />
      </div>
    </>
  );
};

export default ReferAndEarn;
