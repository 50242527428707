import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useMediaQuery } from "react-responsive";

interface ScholarshipItemProps {
  image?: string;
  lookingFor?: string;
  typeName?: string;
}

const ScholarshipItem: React.FC<{ item: ScholarshipItemProps }> = ({
  item,
}) => (
  <a href="/scholarships/list-of-scholarships-in-india">
    <div className="flex items-center mr-8 bg-white pr-6 rounded-3xl w-[305px] relative inline-block flex-shrink-0">
      {item.image && (
        <div className="flex justify-center w-[66px] h-[66px] rounded-full">
          <img
            src={item.image}
            alt="User looking for"
            className="mr-2 object-contain"
          />
        </div>
      )}
      <span className="ml-2 text-[#383838] font-['Roboto'] text-[14px] font-bold leading-normal">
        {item.lookingFor || item.typeName}
      </span>
      <div className="absolute right-0 pr-4">
        <svg
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538407 1.31946 0.538407 0.928933 0.928932C0.538408 1.31946 0.538408 1.95262 0.928933 2.34315L6.58579 8L0.928932 13.6569C0.538407 14.0474 0.538407 14.6805 0.928932 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM7 9L8 9L8 7L7 7L7 9Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  </a>
);
const rightScholarships = {
  userLookingFor: [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/lookingFor.png",
      lookingFor: "I'm a Future UnderGraduate",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/lookingFor-1.png",
      lookingFor: "I'm Ready for PostGraduate",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/lookingFor-2.png",
      lookingFor: "I'm looking for diploma",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/lookingFor-3.png",
      lookingFor: "I'm Starting Out Foundation",
    },
  ],
  types: [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/types.png",
      typeName: "Central Schemes",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/ugc.png",
      typeName: "UGC",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/types.png",
      typeName: "State Schemes",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/scholarship/landing/private.png",
      typeName: "Private and deemed",
    },
  ],
};
const RightScholarship: React.FC = () => {
  // const rightScholarships = useSelector((state: RootState) => state.scholarship.rightScholarships);
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  const displayedUserLookingFor = isLargeScreen
    ? rightScholarships.userLookingFor.slice(0, 4)
    : rightScholarships.userLookingFor.slice(0, 2);
  const displayedTypes = isLargeScreen
    ? rightScholarships.types.slice(0, 4)
    : rightScholarships.types.slice(0, 2);

  if (!rightScholarships) {
    return null;
  }
  return (
    <div className="py-10 bg-[#F7F7F7]  max-sm:py-6">
      <div className="container">
        <h2 className="text-[#383838] font-['Roboto'] text-[36px] font-medium leading-[50px]">
          Discover the Right Scholarship for YOU
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-12 w-full overflow-hidden pl-2 gap-2 md:gap-0">
          {displayedUserLookingFor.map(
            (item: ScholarshipItemProps, index: number) => (
              <ScholarshipItem key={index} item={item} />
            )
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-12 w-full overflow-hidden pl-2 gap-2 md:gap-0">
          {displayedTypes.map((item: ScholarshipItemProps, index: number) => (
            <ScholarshipItem key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightScholarship;
