import Styles from "./Card.module.scss";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "./opacity.scss";
// Define the types for the props
interface BlogItem {
  imageUrl: string;
  description: string;
  title: string;
  slug?: string;
  name: string;
}

interface CardProps {
  content: BlogItem[]; // Array of BlogItem objects
  keyindex: number; // Key index for the card
  title: string; // Title of the card
}

const Card: React.FC<CardProps> = ({ content, keyindex, title }) => {
  const [show, setShown] = useState(false);
  const [keykey, setKeykey] = useState(0);

  console.log(content);
  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });

  return (
    <div className="">
      {/* {keykey} */}
      <animated.div
        onClick={() => setKeykey(keyindex)}
        className={Styles.card}
        style={props3}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        <div className="flex justify-center text-xl font-bold text-[#373737] pt-2">
          {title}
        </div>
        {content.map((item, index) => (
          <div key={index} className={Styles.cardItem}>
            <div className={Styles.image}>
              <img
                src={item.imageUrl}
                alt={`${item.title}`}
                style={{ borderRadius: "5%" }}
              />
            </div>
            <div className={Styles.content}>
              <div
                className="line-clamp-2"
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginTop: "0.5rem",
                }}
              >
                {item.title}
              </div>{" "}
              <p className="line-clamp-2">{item.description}</p>
              <a
                href={`/blogs/${item.slug}/${item.name}`}
                style={{ textDecoration: "none", color: "#113CC0" }}
              >
                Read more
              </a>
            </div>
          </div>
        ))}
      </animated.div>
    </div>
  );
};

export default Card;
