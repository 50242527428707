import React, { useState } from "react";
import careerDetailsBannerImg from "../../images/career/details/career-details-banner-img.png";
import type { SVGProps } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Modal from "../Modal";
import QuizWidget, { QuizQuestion } from "../courses/QuizWidget";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

export function MingcuteWhatsappFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M12 2C6.477 2 2 6.477 2 12c0 1.89.525 3.66 1.438 5.168L2.546 20.2A1.01 1.01 0 0 0 3.8 21.454l3.032-.892A9.96 9.96 0 0 0 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2M9.738 14.263c2.023 2.022 3.954 2.289 4.636 2.314c1.037.038 2.047-.754 2.44-1.673a.7.7 0 0 0-.088-.703c-.548-.7-1.289-1.203-2.013-1.703a.71.71 0 0 0-.973.158l-.6.915a.23.23 0 0 1-.305.076c-.407-.233-1-.629-1.426-1.055s-.798-.992-1.007-1.373a.23.23 0 0 1 .067-.291l.924-.686a.71.71 0 0 0 .12-.94c-.448-.656-.97-1.49-1.727-2.043a.7.7 0 0 0-.684-.075c-.92.394-1.716 1.404-1.678 2.443c.025.682.292 2.613 2.314 4.636"
        ></path>
      </g>
    </svg>
  );
}
const quizQuestions: QuizQuestion[] = [
  {
    question: "How many hours do you study daily?",
    options: [
      { answer: "Less than 2 hours" },
      { answer: "2-4 hours" },
      { answer: "4-6 hours" },
      { answer: "More than 6 hours" },
    ],
  },
  {
    question: "What is your preferred method of learning?",
    options: [
      { answer: "Self-study" },
      { answer: "Group study" },
      { answer: "Online courses or videos" },
      { answer: "Coaching or tuitions" },
    ],
  },
  {
    question: "How do you usually approach solving problems in your studies?",
    options: [
      { answer: "Practice with multiple exercises" },
      { answer: "Focus on understanding concepts deeply" },
      { answer: "Review and revise notes regularly" },
      { answer: "Depend on last-minute preparation" },
    ],
  },
  {
    question: "How do you rate your time management skills?",
    options: [
      { answer: "Very effective" },
      { answer: "Moderately effective" },
      { answer: "Needs improvement" },
      { answer: "I often procrastinate" },
    ],
  },
  {
    question: "How confident are you about your preparation?",
    options: [
      { answer: "Very confident" },
      { answer: "Somewhat confident" },
      { answer: "Not very confident" },
      { answer: "I feel underprepared" },
    ],
  },
  {
    question: "How frequently do you take mock tests?",
    options: [
      { answer: "Once a week or more" },
      { answer: "Once every two weeks" },
      { answer: "Once a month" },
      { answer: "Rarely or never" },
    ],
  },
  {
    question: "What do you usually do when you don’t understand a topic?",
    options: [
      { answer: "Ask my teacher or mentor" },
      { answer: "Search online for explanations" },
      { answer: "Skip and move to the next topic" },
      { answer: "Spend extra time figuring it out myself" },
    ],
  },
  {
    question: "How do you prefer to revise your syllabus?",
    options: [
      { answer: "Re-read notes and textbooks" },
      { answer: "Solve previous years' questions" },
      { answer: "Take quizzes or tests" },
      { answer: "Use flashcards or summaries" },
    ],
  },
  {
    question: "How do you feel about competitive exams?",
    options: [
      { answer: "Excited and motivated" },
      { answer: "Challenged but nervous" },
      { answer: "Indifferent or neutral" },
      { answer: "Overwhelmed and anxious" },
    ],
  },
  {
    question: "How often do you focus on weak areas during preparation?",
    options: [
      { answer: "Always" },
      { answer: "Often" },
      { answer: "Sometimes" },
      { answer: "Rarely or never" },
    ],
  },
];

interface CareerDetailsBannerProps {
  title: string;
  progressTitle: string;
  progressDescription: string;
  progressButtonText: string;
  matchPercentage: number;
  description: string;
  bannerImage: string; // Add description prop
}

const CareerDetailsBanner: React.FC<CareerDetailsBannerProps> = ({
  title,
  progressTitle,
  progressDescription,
  progressButtonText,
  matchPercentage,
  description,
  bannerImage,
}) => {
  const handleDownload = () => {
    const brochureUrl =
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
    const anchor = document.createElement("a");
    anchor.href = brochureUrl;
    anchor.target = "_blank";
    anchor.download = "brochure.pdf";
    anchor.click();
  };
  const { careername } = useParams<{ careername: string }>();

  const handleShareOnWhatsApp = () => {
    const text =
      "Check out this brochure: https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleTakeCareerTest = () => {
    window.location.href = "https://example.com/career-test";
  };
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleMapPopup = () => {
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      setIsPopupVisible(!isPopupVisible);
    }
  };

  const cleanedTitle = title.replace(/\?/g, ""); //to remove "?" from title

  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "Careers",
      path: "/careers",
    },
  ];

  if (cleanedTitle) {
    breadcrumbLinks.push({
      name: cleanedTitle,
      path: `/careers-details/${cleanedTitle}`,
    });
  }

  return (
    <div className="flex flex-col md:flex-row bg-[#061958] text-white">
      <div className="md:container mx-auto min-max-xs:container min-max-xss:px-2 ">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-5 lg:gap-14 max-lg:pt-2">
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col gap-2">
              <div className="relative   text-[#0c9] font-open-sans text-sm">
                <Breadcrumbs links={breadcrumbLinks} />
              </div>
              <h1 className="text-4xl lg:text-5xl text-white font-bold">
                {title}
              </h1>
            </div>
            <div
              className="bg-white text-black p-6 rounded-3xl shadow-md w-full mt-8 border-2 border-green"
              style={{
                boxShadow: "0px 5px 16px 0px #080F340F",
              }}
            >
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2.5">
                  <span className="text-base font-medium blue-text dark:text-white">
                    {matchPercentage}% Match?
                  </span>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="blue-bg h-2.5 rounded-full"
                      style={{
                        width: `${matchPercentage}%`,
                        transition: "width 0.68s linear",
                        transitionDelay: "0.5s",
                      }}
                    ></div>
                  </div>
                </div>
                <span className="mt-2.5 text-zinc-800 max-md:max-w-full font-medium">
                  Would you make a good{" "}
                  {careername
                    ?.toString()
                    .toLowerCase()
                    .split("-")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  ?
                </span>
                <p className="text-sm font-medium text-zinc-800 max-md:max-w-full">
                  Take our career test and find your match with over 250
                  careers.
                </p>
                <div className="flex gap-5">
                  <button
                    onClick={handleMapPopup}
                    className="hover:bg-blue text-white py-3.5 px-9 rounded-full flex items-center gap-1.5 bg-green hover:rounded-none transition-all duration-300 ease-in-out text-sm font-semibold"
                  >
                    Take the free quiz test
                  </button>
                  <p className="items-center my-auto text-[#6F6F6F]">
                    Learn more about the career test
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <img
              loading="lazy"
              src={bannerImage}
              alt="careerdetailbanner"
              className="w-full"
            />
          </div>
        </div>
      </div>
      <MapMyCareerPopup
        isOpen={isPopupVisible}
        onClose={() => {
          setIsPopupVisible(false);
        }}
      />
    </div>
  );
};

export default CareerDetailsBanner;

const MapMyCareerPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget questions={quizQuestions} destinationLink="" />{" "}
    </Modal>
  );
};
