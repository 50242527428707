import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import LeadGenCtaBtn from "../../../../features/lead-generation/LeadGenCtaBtn";

const CollegeCard = ({ image, url }: { image: string; url: string }) => (
  <div
    className="flex flex-col gap-2 p-4 rounded-lg my-2"
    style={{ boxShadow: "0px 0px 4px #ccc" }}
  >
    <img src={image} className="w-full rounded-md lg:min-h-[346px]" alt={url} />
    <div className="flex gap-4 items-center justify-between p-2 bg-blue rounded-md text-white">
      <span className="truncate text-xs font-semibold">{url}</span>
      <LeadGenCtaBtn
        pageName="college-landing"
        ctaName="featured-college-apply"
        additionalInfo={url}
        className="shrink-0 text-sm font-bold py-1 px-2 bg-green rounded-md"
      >
        Apply Now
      </LeadGenCtaBtn>
    </div>
  </div>
);
interface College {
  image: string;
  url: string;
  collegeLogo: string;
}
interface FeaturedCollegesProps {
  featuredColleges: College[];
  title: string;
}
const FeaturedColleges: React.FC<FeaturedCollegesProps> = ({
  featuredColleges,
  title,
}) => {
  if (!featuredColleges || featuredColleges.length <= 0) return null;

  const cardContent = featuredColleges?.map((college) => (
    <CollegeCard {...college} />
  ));
  return (
    <>
      <div className="bg-[#EEF2FF] text-black relative z-10">
        <div className="container py-5 max-sm:py-5">
          <h1 className="text-4xl lg:text-5xl font-bold text-center">
            {title}
          </h1>
          <div className="flex justify-center mt-2">
            <CustomSwiper
              className="!px-2"
              items={cardContent}
              navigation={"bottom"}
              autoplay={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedColleges;
