import React, { useEffect, useState } from "react";
import MentorBanner from "./components/MentorBanner";
import FeaturedMentors from "./components/FeaturedMentors";
import { MentorMenteeMatch } from "./components/MentorMenteeMatch";
import { FAQSection } from "./components/FAQSection";
import { NoteSection } from "./components/NoteSection";
import { PricingPlans } from "./components/PricingPlans";
import { MentorGrid } from "./components/MentorGrid";
import { SpeakToMentors } from "./components/SpeakToMentors";
import MentorNetwork from "./components/MentorNetwork";
import ExploreMore from "./components/ExploreMore";
import config from "../../util/config";
import Loader from "../../components/Loader/Loader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import StudentAlumni from "./components/StudentAlumini";

import Mentorship from "./components/Mentorship";
import MentorCards from "./components/MentorCards";

export interface Country {
  countryId: number;
  countryName: string;
}

export interface MentorSocialLinks {
  mentorId: number;
  instagram: string;
  twitter: string;
  linkedin: string;
  facebook: string;
  telegram: string;
}

export interface Mentor {
  active: string;
  respondtime: string;
  videoUrl: any;
  id: number;
  name: string;
  role: string;
  experiance: string;
  worksOn: string | null;
  imageUrl: string;
  location: string | null;
  country: Country;
  mentorsSocialLinks: MentorSocialLinks;
  languages: string[];
  featured: boolean;
}

export const Mentors: React.FC = () => {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setMentors(responseData.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();

    document.title = "College Mentor | Find Your Perfect Mentor for Success";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "College Mentor connects students with experts in academics, careers, scholarships, and emotional wellbeing. Our mentorship is designed to guide all aspects of student life from finding a right college, scholarship, students loans guidance and many more"
      );
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>College Mentor | Find Your Perfect Mentor for Success</title>
          <meta
            name="description"
            content="College Mentor connects students with experts in academics, careers, scholarships, and emotional wellbeing. Our mentorship is designed to guide all aspects of student life from finding a right college, scholarship, students loans guidance and many more"
          />
          <meta
            name="keywords"
            content="College mentor platform, Career guidance for students, Academic mentor, Scholarship mentor, health mentor, Admission mentor, Connect with a mentor, Personalized student mentorship, Mentor program for college students, Academic companion, Find a career mentor, Scholarship guidance, Mentor onboarding process, Best mentor programs for students."
          />
          <link rel="canonical" href={`${config.apiUrl}/mentors`} />

          <meta
            property="og:title"
            content="College Mentor | Find Your Perfect Mentor for Success"
          />
          <meta
            property="og:description"
            content="College Mentor connects students with experts in academics, careers, scholarships, and emotional wellbeing. Our mentorship is designed to guide all aspects of student life from finding a right college, scholarship, students loans guidance and many more"
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Find Your Perfect Mentor for Success"
          />
          <meta
            name="twitter:description"
            content="College Mentor connects students with experts in academics, careers, scholarships, and emotional wellbeing. Our mentorship is designed to guide all aspects of student life from finding a right college, scholarship, students loans guidance and many more"
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        {!loading ? (
          <div className="mentors-section">
            <MentorBanner />
            <FeaturedMentors mentors={mentors} />
            <MentorMenteeMatch />
            {/* <MentorNetwork /> */}
            <SpeakToMentors />
            <MentorCards mentors={mentors} />
            <Mentorship />
            <PricingPlans />
            <NoteSection />
            <StudentAlumni />
            <ExploreMore />
            <FAQSection />
            {/* <MentorGrid /> */}
          </div>
        ) : (
          <>
            <div className="container">
              <Loader />
            </div>
          </>
        )}
      </div>
    </>
  );
};
