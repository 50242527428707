import React from "react";

interface CoursesSectionProps {
  title: string;
  description: string;
}

const CoursesSection: React.FC<CoursesSectionProps> = ({
  title,
  description,
}) => {
  return (
    <div className="flex flex-col lg:flex-row gap-10">
      <div className="flex flex-col gap-5">
        <h2 className="text-3xl font-normal text-blue">{title}</h2>
        <div className="flex flex-row lg:gap-14 max-lg:flex-col max-lg:gap-10 ">
          <div
            className="text-black text-base examDetailsTable flex flex-col gap-4 "
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

export default CoursesSection;
