import { FC, ReactNode, useState } from "react";

interface IProps {
  questionsAndAnswers: { question: string; answer: ReactNode }[];
}

const FAQV2: FC<IProps> = ({ questionsAndAnswers }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="flex flex-col gap-4">
      {questionsAndAnswers.map((item, index) => (
        <Accordion
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={openIndex === index}
          onClick={() => setOpenIndex(openIndex === index ? null : index)}
        />
      ))}
    </div>
  );
};

interface IAccordion {
  question: string;
  answer: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const Accordion: FC<IAccordion> = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="text-sm flex flex-col gap-1">
      <div onClick={onClick} className="flex gap-4 items-center cursor-pointer">
        {isOpen ? <MinusIcon /> : <PlusIcon />}
        <span className="font-semibold max-sm:w-[310px]">{question}</span>
      </div>
      {isOpen && (
        <div className="pl-10 relative">
          {answer}
          <div className="h-full absolute top-0 left-2.5 flex flex-col gap-2 py-2 overflow-hidden">
            <div className="shrink-0 h-[3px] w-[3px] rounded-full bg-black"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQV2;

const MinusIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="23" height="23" rx="3" fill="#173CBA" />
    <path d="M5 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const PlusIcon = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="23" height="23" rx="3" fill="#173CBA" />
    <path d="M5 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M11 18L11 6"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
