import { useEffect, useState } from "react";
import ComparisonEditor from "./components/ComparisonEditor";
import axios from "axios";
import ComparisonDetailsTable from "./components/ComparisonDetailsTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import BookSession from "./components/BookSession";
import { fetchLandingData } from "./collegeCompareThunk";
import FAQ from "./components/FAQ";
import SuccessStories from "./components/SuccessStories";
import CompareScholarship from "./components/CompareScholarship";
import FrequentComparison from "./components/FrequentComparison";
import CompareBanner from "./components/CompareBanner";
import { HighlightProvider } from "./components/HighlightContext";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

import { fetchAndAddCollegeData, fetchSearchData } from "./collegeCompareThunk";
import config from "../../util/config";
interface college {
  [key: string]: any;
}

const CollegeCompare = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { ids } = location.state || { ids: [] };

  console.log(ids);
  useEffect(() => {
    dispatch(fetchLandingData());
  }, []);
  useEffect(() => {
    if (ids && ids.length > 0) {
      ids.forEach((id: number) => {
        dispatch(fetchAndAddCollegeData(id));
      });
    }

    document.title =
      "College Mentor | compare colleges & university rankings features";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Compare colleges & universities with College Mentor. Evaluate rankings, fees, labs, infrastructure & admissions to find your perfect match for higher education."
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | compare colleges & university rankings features
          </title>
          <meta
            name="description"
            content="Compare colleges & universities with College Mentor. Evaluate rankings, fees, labs, infrastructure & admissions to find your perfect match for higher education."
          />
          <meta
            name="keywords"
            content="college comparison, university comparison, compare university rankings, fees comparison, labs facilities, ranks, alumni success, ranking comparison, NIRF rankings, college infrastructure, best colleges and universities, eligibility comparison, admission criteria."
          />
          <link rel="canonical" href={`${config.apiUrl}/college-compare`} />

          <meta
            property="og:title"
            content="College Mentor | Compare colleges & university rankings features"
          />
          <meta
            property="og:description"
            content="Compare colleges & universities with College Mentor. Evaluate rankings, fees, labs, infrastructure & admissions to find your perfect match for higher education."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | compare colleges & university rankings features"
          />
          <meta
            name="twitter:description"
            content="Compare colleges & universities with College Mentor. Evaluate rankings, fees, labs, infrastructure & admissions to find your perfect match for higher education."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="font-inter flex flex-col gap-4 lg:gap-6">
        <HighlightProvider>
          <CompareBanner />
          <section className="py-12 bg-[#F5F8FF] text-black">
            <div className="container">
              <ComparisonEditor />

              <ComparisonDetailsTable />
            </div>
          </section>
          <FrequentComparison />
          <CompareScholarship />
          <SuccessStories />
          <FAQ />
          <BookSession />
        </HighlightProvider>
      </div>
    </>
  );
};
export default CollegeCompare;
