import React from "react";

export const ChildrenFuture: React.FC = () => {
  return (
    <div className="px-20 pt-5  w-full bg-[#FFF9E5] max-md:px-8 max-lg:max-w-full my-1">
      <div className="flex justify-between max-lg:flex-col">
        <div className="flex flex-col w-5/12 max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col self-stretch my-auto text-base leading-7 text-black max-lg:mt-2 max-lg:max-w-full">
            <h3 className="text-[32px] font-light leading-10 max-lg:max-w-full">
              Concerned About{" "}
              <span className="font-medium text-black">
                Your Child's Future?{" "}
              </span>
              Discover the Right Path Together!
            </h3>
            <p className="mt-4 max-lg:max-w-full">
              Is your child having difficulty selecting the right subjects?
              Unsure about their future career direction? You're not alone -
              many parents share this concern.
            </p>
            <p className="mt-4 max-lg:max-w-full">
              Our IKIGAI discovery program is here to help. Using a proven
              methodology, we assist your child in identifying their passions,
              strengths, and potential career paths. Let us guide them toward a
              fulfilling future!
            </p>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-lg:w-full mr-10">
          <img
            loading="lazy"
            src="/images/career-discovery/children-future.webp"
            alt="Parent and Child Planning Future"
            className="object-contain grow w-full aspect-[1.23] max-md:mt-10 max-lg:max-w-full min-max-md:h-80 "
          />
        </div>
      </div>
    </div>
  );
};
