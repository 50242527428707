import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import NavigationTabs from "../../components/exam-details/NavigationTabs";
import config from "../../util/config";
import "./exam-details-styles.scss";
import Banner from "../../components/exam-details/Banner";
import KnowledgeHub from "./KnowledgeHub";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
export interface Tab {
  name: string;
  title: string;
}

interface Action {
  icon: string;
  text: string;
  link: string;
}

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerData {
  examId: number;
  examTitle: string;
  examAbout: string;
  examBanner: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  buttons: Button[];
}

const ExamDetails: React.FC = () => {
  const { examName } = useParams<{ examName: string }>();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [bannerData, setBannerData] = useState<BannerData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        // Fetch the banner data
        const bannerResponse = await fetch(
          `${config.apiUrl}/api/exam-details/${examName}`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!bannerResponse.ok) {
          throw new Error("Failed to fetch exam details");
        }

        const bannerData = await bannerResponse.json();
        setBannerData({
          examId: bannerData.examId,
          examTitle: bannerData.examTitle,
          examAbout: bannerData.examAbout,
          examBanner: bannerData.examBanner,
          metaTitle: bannerData.metaTitle,
          metaDescription: bannerData.metaDescription,
          metaKeywords: bannerData.metaKeywords,
          buttons: [
            { text: "Apply Now", link: "#apply", type: "primary" },
            {
              text: "Talk to Mentor",
              link: "/mentors",
              type: "secondary",
            },
          ],
        });
        console.log(bannerData.examTitle, "teststtst");

        // Fetch the tab data
        const tabsResponse = await fetch(
          `${config.apiUrl}/api/exam-details/tabnames`,
          {
            method: "GET",
            headers: { Accept: "*/*" },
          }
        );

        if (!tabsResponse.ok) {
          throw new Error("Failed to fetch tab names");
        }

        const tabsData = await tabsResponse.json();
        setTabs(tabsData);
      } catch (err) {
        console.error("Error fetching exam details:", err);
        setError("Unable to load exam details.");
      } finally {
        setLoading(false);
      }
    };

    fetchExamDetails();
  }, [examName]);

  if (loading) {
    return <Loader />;
  }

  if (error || !bannerData || tabs.length === 0) {
    return (
      <div className="container mx-auto text-center py-10">
        <p className="text-red-500 font-bold">
          {error || "No data available."}
        </p>
      </div>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>{bannerData.metaTitle}</title>
          <meta name="description" content={bannerData.metaDescription} />
          <meta name="keywords" content={bannerData.metaKeywords} />
          <link
            rel="canonical"
            href={`${config.apiUrl}/exam-details/${examName}`}
          />
          <meta property="og:title" content={bannerData.metaTitle} />
          <meta
            property="og:description"
            content={bannerData.metaDescription}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={bannerData.metaTitle} />
          <meta
            name="twitter:description"
            content={bannerData.metaDescription}
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner data={bannerData} />
        <NavigationTabs tabs={tabs} examName={examName || ""} />
        <KnowledgeHub />
      </div>
    </>
  );
};

export default ExamDetails;
