import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";
import OverviewTab from "../../components/exam-details/OverviewTab";
import EligibilityTab from "./EligibilityTab";
import ApplicationTab from "./ApplicationTab";
import ExamSyllabusTab from "./ExamSyllabusTab";
import ExamPatternTab from "./ExamPatternTab";
import AdmitCardTab from "./AdmitCardTab";
import AnswerKeyAnalysisTab from "./AnswerKeyAnalysisTab";
import ResultAndCutoffTab from "./ResultAndCutoffTab";
import CounselingTab from "./CounselingTab";
import { EpRight } from "../college-details/components/NavigationTabs";

interface Tab {
  name: string;
  title: string;
}

interface NavigationTabsProps {
  tabs: Tab[];
  examName: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs, examName }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.name || "");
  const [tabContent, setTabContent] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateTabFromHash = () => {
      const hashTab = window.location.hash.replace("#", "");
      if (hashTab && tabs.some((tab) => tab.name === hashTab)) {
        setActiveTab(hashTab);
      } else if (tabs.length > 0) {
        const defaultTab =
          tabs.find((tab) => tab.name === "overview") || tabs[0];
        setActiveTab(defaultTab.name);
      }
    };

    updateTabFromHash();
    window.addEventListener("hashchange", updateTabFromHash);
    return () => {
      window.removeEventListener("hashchange", updateTabFromHash);
    };
  }, [tabs]);

  useEffect(() => {
    if (activeTab && examName) {
      fetchTabContent(activeTab);
    }
  }, [activeTab, examName]);
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY >= 560);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const checkScrollable = () => {
      const tabContainer = tabContainerRef.current;
      if (tabContainer) {
        setCanScrollLeft(tabContainer.scrollLeft > 0);
        setCanScrollRight(
          tabContainer.scrollWidth >
            tabContainer.scrollLeft + tabContainer.clientWidth
        );
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    tabContainerRef.current?.addEventListener("scroll", checkScrollable);

    return () => {
      window.removeEventListener("resize", checkScrollable);
      tabContainerRef.current?.removeEventListener("scroll", checkScrollable);
    };
  }, [tabs]);

  const fetchTabContent = async (tabName: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/api/exam-details/${examName}/${tabName}`,
        { method: "GET", headers: { Accept: "*/*" } }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch content for ${tabName}`);
      }

      const data = await response.json();
      setTabContent(data || null);
    } catch (error) {
      console.error(`Error fetching content for ${tabName}:`, error);
      setTabContent(null);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollByAmount = (direction: "left" | "right") => {
    const tabContainer = tabContainerRef.current;
    if (tabContainer) {
      const scrollAmount = tabContainer.clientWidth / 2;
      tabContainer.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const handleTabChange = (tabName: string) => {
    if (tabName !== activeTab) {
      setActiveTab(tabName);
      window.location.hash = `#${tabName}`;

      tabsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (!tabContent) {
      return (
        <p className="text-center text-red-500 py-10">
          Data Not Available for this tab.
        </p>
      );
    }

    switch (activeTab) {
      case "overview":
        return <OverviewTab data={tabContent} />;
      case "eligibility-criteria":
        return <EligibilityTab data={tabContent} />;
      case "application":
        return <ApplicationTab data={tabContent} />;
      case "exam-syllabus":
        return <ExamSyllabusTab data={tabContent} />;
      case "exam-pattern":
        return <ExamPatternTab data={tabContent} />;
      case "admit-card":
        return <AdmitCardTab data={tabContent} />;
      case "answer-key-analysis":
        return <AnswerKeyAnalysisTab data={tabContent} />;
      case "result-n-cut-off":
        return <ResultAndCutoffTab data={tabContent} />;
      case "counseling":
        return <CounselingTab data={tabContent} />;
      default:
        return (
          <p className="text-center text-red-500 py-10">
            Data Not Available for this tab.
          </p>
        );
    }
  };

  return (
    <div>
      <div
        className={`transition-opacity duration-500 ${
          isSticky
            ? "fixed top-0 left-0 w-full opacity-100 z-50 shadow-lg"
            : "opacity-100"
        }`}
      >
        <ul
          className="flex items-center justify-start space-x-4 px-4 py-2 overflow-x-auto"
          style={{
            background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
          }}
        >
          {canScrollLeft && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white p-2 hidden sm:block"
              onClick={() => handleScrollByAmount("left")}
            >
              <EpRight className="rotate-180" />
            </button>
          )}

          <div
            ref={tabContainerRef}
            className="overflow-x-scroll sm:overflow-x-auto w-full mx-auto mobile-scroll "
          >
            <ul className="list-none flex gap-4 items-center text-center w-full mx-auto justify-center">
              {tabs.map((tab) => (
                <li
                  key={tab.name}
                  className={`text-base py-1.5 px-3.5 font-semibold rounded-sm cursor-pointer whitespace-nowrap ${
                    activeTab === tab.name
                      ? "bg-green text-white"
                      : "bg-white text-black hover:bg-green hover:text-white"
                  }`}
                  onClick={() => handleTabChange(tab.name)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </div>

          {canScrollRight && (
            <button
              className="bg-[#00CC99] border border-white rounded text-white p-2 hidden sm:block"
              onClick={() => handleScrollByAmount("right")}
            >
              <EpRight />
            </button>
          )}
        </ul>
      </div>

      <div className="w-full" ref={tabsRef}>
        {renderContent()}
      </div>
    </div>
  );
};

export default NavigationTabs;
