import { useParams } from "react-router-dom";
import quizData from "./data.json";
import { useEffect, useState } from "react";
import bgArrows from "./images/quiz-bg-arrows.svg";
import congratsImage from "./images/congrats-text.png";
import QuizBreadcrumb from "./QuizBreadCrumb";
const Quiz = () => {
  const { topic } = useParams();
  const topicData = quizData.find((quizTopic) => quizTopic.topic == topic);
  const numberOfQuestions = topicData?.questions.length || 0;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(180);
  const [selectedOptions, setSelectedOptions] = useState<(number | null)[]>(
    Array(numberOfQuestions).fill(null)
  );
  const [ended, setEnded] = useState(false);

  const endQuiz = () => setEnded(true);

  useEffect(() => {
    let interval = null;
    if (!ended)
      interval = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [ended]);

  const question =
    (topicData?.questions &&
      topicData?.questions?.length > 0 &&
      topicData?.questions?.[currentQuestionIndex]?.question) ||
    "Question not available";

  const options =
    (topicData?.questions &&
      topicData?.questions?.length > 0 &&
      topicData?.questions?.[currentQuestionIndex]?.options) ||
    [];

  const currentQuestionSelectedOption = selectedOptions[currentQuestionIndex];

  const numberOfAnswered = selectedOptions.reduce((prev: number, current) => {
    if (current != null) return prev + 1;
    else return prev;
  }, 0);

  const numberOfCurrentAnswers = selectedOptions.reduce(
    (prev: number, current) => {
      if (current == topicData?.questions?.[currentQuestionIndex]?.answer)
        return prev + 1;
      else return prev;
    },
    0
  );

  const numberOfWrongAnswers = numberOfAnswered - numberOfCurrentAnswers;

  const goToNextQuestion = () => {
    setCurrentQuestionIndex((prev) => {
      if (prev + 1 < numberOfQuestions) return prev + 1;
      else return prev;
    });
  };

  const goToPrevQuestion = () => {
    setCurrentQuestionIndex((prev) => {
      if (prev - 1 >= 0) return prev - 1;
      else return prev;
    });
  };

  const selectAnOption = (questionIndex: number, optionIndex: number) => {
    setSelectedOptions((prev) => {
      const selectedOptions = [...prev];
      if (questionIndex < numberOfQuestions)
        selectedOptions[questionIndex] = optionIndex;
      return selectedOptions;
    });
  };

  if (!topicData) return <div>Quiz not found</div>;

  console.log("render");

  return (
    <>
      <div className="p-4 bg-[#F7F7F7]">
        <QuizBreadcrumb title={topic || ""} />
      </div>

      <div className="py-12 bg-[#F7F7F7] text-black text-base font-roboto flex justify-center">
        {!ended ? (
          // interactive quiz card
          <div className="w-full md:max-w-4/5 lg:max-w-[50vw] bg-white divide-y-2 divide-grey-3 shadow-lg">
            <div className="p-4 flex items-center justify-between">
              <div className="px-4 py-1 bg-blue text-white rounded">
                {topic}
              </div>
              <div className="flex gap-4">
                <div className="p-1 bg-green text-white flex gap-2 items-center">
                  <span>Question</span>
                  <span className="px-1 bg-white text-black">{`${
                    currentQuestionIndex + 1
                  } / ${numberOfQuestions}`}</span>
                </div>
                <div className="p-1 bg-green text-white flex gap-2 items-center">
                  <span>Time</span>
                  <span className="px-1 bg-white text-black">
                    {formatTime(timeRemaining)}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="p-4"
              style={{
                background: `url('${bgArrows}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <h1 className="text-2xl font-bold">{question}</h1>
              <div className="grid grid-cols-2 gap-4 mt-4">
                {options.map((option, index) => (
                  <button
                    className={`p-2 border text-start rounded ${
                      currentQuestionSelectedOption == index
                        ? "bg-blue-light border-blue"
                        : "bg-yellow-light border-yellow"
                    }`}
                    onClick={() => selectAnOption(currentQuestionIndex, index)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <div className="p-4 flex justify-between">
              <button
                className="px-4 py-1 bg-blue text-white disabled:opacity-40 rounded"
                onClick={goToPrevQuestion}
                disabled={currentQuestionIndex <= 0}
              >
                Previous
              </button>
              <button
                className="px-4 py-1 bg-blue text-white disabled:opacity-40 rounded"
                onClick={goToNextQuestion}
                disabled={currentQuestionIndex >= numberOfQuestions - 1}
              >
                Next
              </button>
              <button
                className="px-4 py-1 bg-green text-white rounded"
                onClick={endQuiz}
              >
                Finish
              </button>
            </div>
          </div>
        ) : (
          // results card
          <div
            className="w-full md:max-w-4/5 lg:max-w-[50vw] bg-white divide-y-2 divide-grey-3 shadow-lg"
            style={{
              background: `url('${bgArrows}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="p-4 flex justify-center">
              <img src={congratsImage} />
            </div>
            <div className="p-4 flex justify-center divide-x-2 divide-grey-3">
              <div className="p-4 flex flex-col items-center">
                <span className="text-xl font-bold">{`${numberOfAnswered}/${numberOfQuestions}`}</span>
                <span>Attempted</span>
              </div>
              <div className="p-4 flex flex-col items-center">
                <span className="text-xl font-bold">{`${formatTime(
                  180 - timeRemaining
                )} Min`}</span>
                <span>Time taken</span>
              </div>
              <div className="p-4 flex flex-col items-center">
                <span className="text-xl font-bold">
                  {numberOfCurrentAnswers}
                </span>
                <span>Correct</span>
              </div>
              <div className="p-4 flex flex-col items-center">
                <span className="text-xl font-bold">
                  {numberOfWrongAnswers}
                </span>
                <span>Wrong</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Quiz;

const formatTime = (duration: number) => {
  const seconds = (duration % 60).toString().padStart(2, "0");
  const minutes = Math.floor(duration / 60)
    .toString()
    .padStart(2, "0");
  return `${minutes}:${seconds}`;
};
