import React, { FC, useState, useEffect, useRef } from "react";
import "./CollegeBanner.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import config from "../../../../util/config";
import PopupInfo from "../../../../components/popupInfo/popup";

interface College {
  name: string;
  categoryName: string;
  title: string;
  isDataAvailable: boolean;
}

const CollegeBanner = () => {
  const [imageIndex, setImageIndex] = useState(() =>
    parseInt(localStorage.getItem("bannerIndex") || "0")
  );
  const firstImageRef = useRef<HTMLImageElement | null>(null);
  const secondImageRef = useRef<HTMLImageElement | null>(null);
  const thirdImageRef = useRef<HTMLImageElement | null>(null);
  const fourthImageRef = useRef<HTMLImageElement | null>(null);
  const fifthImageRef = useRef<HTMLImageElement | null>(null);
  const sixthImageRef = useRef<HTMLImageElement | null>(null);
  const mapButtonRef = useRef<HTMLButtonElement | null>(null);

  const imageSets = [
    [
      "/images/College-landing/Banner/banner-1-1.webp",
      "/images/College-landing/Banner/banner-1-2.webp",
      "/images/College-landing/Banner/banner-1-3.webp",
      "/images/College-landing/Banner/banner-1-4.webp",
      "/images/College-landing/Banner/banner-1-5.webp",
    ],
    [
      "/images/College-landing/Banner/banner-2-1.webp",
      "/images/College-landing/Banner/banner-2-2.webp",
      "/images/College-landing/Banner/banner-2-3.webp",
      "/images/College-landing/Banner/banner-2-4.webp",
      "/images/College-landing/Banner/banner-2-5.webp",
    ],
    [
      "/images/College-landing/Banner/banner-3-1.webp",
      "/images/College-landing/Banner/banner-3-2.webp",
      "/images/College-landing/Banner/banner-3-3.webp",
      "/images/College-landing/Banner/banner-3-4.webp",
      "/images/College-landing/Banner/banner-3-5.webp",
    ],
  ];
  const selectedImageSet = imageSets[imageIndex];

  useEffect(() => {
    localStorage.setItem("bannerIndex", ((imageIndex + 1) % 3).toString());
  }, []);

  useEffect(() => {
    if (firstImageRef.current)
      firstImageRef.current.classList.add("animate-translate");
    if (thirdImageRef.current)
      thirdImageRef.current.classList.add("animate-translate");
    if (fifthImageRef.current)
      fifthImageRef.current.classList.add("animate-translate");

    const timer = setTimeout(() => {
      if (secondImageRef.current)
        secondImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
      if (fourthImageRef.current)
        fourthImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
      if (sixthImageRef.current)
        sixthImageRef.current.classList.add(
          "animate-translate-2",
          "animate-translate-delay-1"
        );
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="top-hidden flex w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] sm:bg-gradient-to-b sm:from-[#FFFFFF] sm:to-[#CDEDFF]">
      <div className="container">
        <div className=" flex lg:flex-row flex-col mt-12 max-sm:mt-4 lg:mt-40 max-lg:mb-40">
          <div className="lg:w-[40%] w-full max-sm:gap-1">
            <div>
              <div className="text-[#383838] font-roboto text-light text-3xl lg:text-[40px]  max-lg:text-center">
                College Mentor Help to <span className="font-bold">Build</span>{" "}
                <br />
                <div className="flex  justify-center font-bold xl:pr-10">
                  <span className=" px-1 py-1 bg-[#173CBA] text-[#ffffff] text-center justify-center items-center">
                    Your
                  </span>
                  <span className="px-1 py-1 bg-green text-[#ffffff]">
                    {" "}
                    Dream
                  </span>
                </div>
              </div>
            </div>
            <div className="flex text-[#747474] mt-4 text-base justify-center text-center px-0 md:px-20 lg:px-0">
              Our mission is to help students make informed decisions about
              choosing their dream college
            </div>
            <div className="mt-5">
              <div className="w-full lg:w-[468px] flex gap-5 relative mb-60 max-sm:mb-56 md:mb-72 lg:mb-32 text-[--primary-color] mt-5 lg:mt-10 px-0 md:px-20 lg:px-0">
                <SearchBox />
              </div>
            </div>
          </div>
          <div className="lg:w-[50%] translate-y-4 md:-translate-y-32 lg:translate-y-[0px] translate-x-20 md:translate-x-48 lg:translate-x-40">
            <div
              className="absolute flex gap-2 w-[110%]"
              style={{ transform: "rotate(-23deg)" }}
            >
              <div>
                <img
                  ref={firstImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] m-2 rounded-lg"
                  src={selectedImageSet[0]}
                  alt="college-banner-images"
                />
                <img
                  ref={secondImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] m-2 rounded-lg"
                  src={selectedImageSet[1]}
                  alt="college-banner-images"
                />
              </div>
              <div>
                <img
                  ref={thirdImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[340px] m-2 rounded-lg"
                  src={selectedImageSet[2]}
                  alt="college-banner-images"
                />
                <img
                  ref={fourthImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[240px] m-2 rounded-lg"
                  src={selectedImageSet[3]}
                  alt="college-banner-images"
                />
              </div>
              <div className="translate-y-12 lg:translate-y-32">
                <img
                  ref={fifthImageRef}
                  className="w-[120px] h-[140px] md:w-[160px] md:h-[185px] lg:w-[255px] lg:h-[290px] translate-y-8 md:translate-y-12 lg:translate-y-32 m-2 rounded-lg"
                  src={selectedImageSet[4]}
                  alt="college-banner-images"
                />
                <a href="/college-mentor-scholarship">
                  <div
                    ref={sixthImageRef}
                    className="w-[120px] h-[70px] md:w-[160px] md:h-[90px]  lg:w-[222px] lg:h-[111px] translate-y-8 md:translate-y-12 lg:translate-y-32 m-2 rounded-lg bg-blue"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeBanner;

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [collegesList, setCollegesList] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<College[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCollegeUrl, setSelectedCollegeUrl] = useState<string>("");
  const [filteredColleges, setFilteredColleges] = useState<string[]>([]);

  useEffect(() => {
    axios({
      url: config.apiUrl + "/indexes/colleges/search",
      method: "POST",
      headers: {
        Authorization: `Bearer ` + config.searchApiKey,
        "Content-Type": "application/json",
      },
      data: {
        q: searchTerm || " ",
      },
    })
      .then((response) => {
        const fetchedColleges = response.data.hits.map((college: College) => ({
          name: college.name,
          categoryName: college.categoryName,
          title: college.title,
          isDataAvailable: college.isDataAvailable,
        }));

        setCollegesList(fetchedColleges);

        if (searchTerm.trim()) {
          setSuggestions(fetchedColleges);
        } else {
          setSuggestions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching exams data:", error);
      });
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = collegesList.filter((suggestion: any) =>
        suggestion?.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSuggestionClick = (suggestion: College) => {
    setSearchTerm(suggestion.title);
    if (suggestion.isDataAvailable) {
      setSelectedCollegeUrl(`/college-details/${suggestion.name}`);
      window.location.href = `/college-details/${suggestion.name}`;
    } else {
      setShowPopup(true);
      setSelectedCollegeUrl("");
    }
    setSuggestions([]);
  };

  const handleClear = () => {
    setSearchTerm("");
    setIsDropdownOpen(false);
  };
  const handleSearchCollege = () => {
    if (selectedCollegeUrl) {
      window.location.href = selectedCollegeUrl;
    }
  };

  return (
    <form className="bg-white pl-3 py-1 pr-1 md:pl-4 md:pr-2 md:py-2 rounded-full flex flex-row w-full items-center justify-between">
      <div className="flex flex-row gap-2 items-center w-full">
        <div className="flex flex-col w-5 h-5">
          <svg
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 md:w-5 md:h-5"
          >
            <path
              d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
              fill="#113CC0"
            />
          </svg>
        </div>
        <div className="relative w-full">
          <input
            type="text"
            id="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search College"
            className="block w-full px-2 pr-6 text-xs md:text-base text-[#A3A2AC] outline-none"
          />
          {searchTerm.length > 0 && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-1 top-1/2 transform text-xl -translate-y-1/2 text-blue hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faClose} size="sm" />
            </button>
          )}

          {suggestions.length > 0 && (
            <div
              className={`absolute -left-3.5 w-full py-6 top-[38px] z-50 ${
                suggestions.length > 0
                  ? "animate__animated animate__fadeIn bg-white searchSuggestion"
                  : ""
              }`}
            >
              <hr className="border-gray-200 py-2" />
              <div className="min-h-[200px] max-h-[300px] overflow-y-auto scrollbar-custom">
                <ul>
                  {suggestions.map((college, index) => (
                    <div key={index}>
                      <li
                        className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                        onClick={() => handleSuggestionClick(college)}
                      >
                        <span>{college.title}</span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleSearchCollege}
        className="arw-button-college bg-[--primary-color] text-white py-1 px-3 rounded-full"
        style={{
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
      >
        <div className="flex items-center whitespace-nowrap">
          <span className="hidden md:block">Search College</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="currentColor"
            className="arrow-icon m-2"
          >
            <path
              d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>
      {showPopup && (
        <PopupInfo isOpen={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </form>
  );
};
