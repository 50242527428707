import React, { useEffect, useState } from "react";
import { Accordion, Option } from "./Accordion";
import { useSearchParams } from "react-router-dom";
import { useFiltersAndTags } from "../CollegeIntermediate";

export interface FilterCategory {
  categoryName: string;
  categoryKey: string;
  options: Option[];
}

interface DesktopFiltersProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: any;
  fetchData: () => void;
}

const DesktopFilters: React.FC<DesktopFiltersProps> = ({
  filters,
  setSelectedVal,
  setFilters,
  fetchData,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateUrlParams = (key: string, values: string | string[]) => {
    const urlParams = new URLSearchParams(window.location.search);

    if (Array.isArray(values) && values.length > 0) {
      urlParams.set(key, values.join(","));
    } else if (typeof values === "string" && values.length > 0) {
      urlParams.set(key, values);
    } else {
      urlParams.delete(key);
    }

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const { selectedVal, setFiltersData } = useFiltersAndTags(fetchData);

  const handleFilterChange = (value: string, filter: FilterCategory) => {
    setSelectedVal((prevSelected: any) => {
      const updatedSelectedVal = { ...prevSelected };

      let currentValues: string[] = Array.isArray(
        updatedSelectedVal[filter.categoryKey]
      )
        ? updatedSelectedVal[filter.categoryKey]
        : updatedSelectedVal[filter.categoryKey]
        ? updatedSelectedVal[filter.categoryKey].split(",")
        : [];

      let newValues: string[];

      if (currentValues.includes(value)) {
        newValues = currentValues.filter((v) => v !== value);
      } else {
        newValues = [...currentValues, value];
      }

      if (newValues.length > 0) {
        updatedSelectedVal[filter.categoryKey] = newValues;
      } else {
        delete updatedSelectedVal[filter.categoryKey];
      }

      updateUrlParams(filter.categoryKey, newValues);

      setFiltersData((prevFilters: any) =>
        prevFilters.map((f: any) =>
          f.categoryKey === filter.categoryKey
            ? {
                ...f,
                options: f.options.map((option: any) => ({
                  ...option,
                  isSelected: newValues.includes(option.key),
                })),
              }
            : f
        )
      );

      return updatedSelectedVal;
    });
  };

  useEffect(() => {
    if (Object.keys(selectedVal).length === 0) return;

    const timeout = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(timeout);
  }, [selectedVal]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const filtersFromUrl: Record<string, string[]> = {};

    params.forEach((value, key) => {
      filtersFromUrl[key] = value.split(",");
    });

    setSelectedVal(filtersFromUrl);

    setFilters((prevFilters: any[]) =>
      prevFilters.map((f) => ({
        ...f,
        options: f.options.map((option: { key: string }) => ({
          ...option,
          isSelected:
            filtersFromUrl[f.categoryKey]?.includes(option.key) || false,
        })),
      }))
    );

    fetchData();
  }, [searchParams, setSelectedVal, setFilters]);

  return (
    <>
      {filters.map((filter, index) => (
        <Accordion
          key={index}
          filters={{
            label: filter.categoryName,
            options: filter.options,
          }}
          isOpen={false}
          onFilterChange={(value: string) => handleFilterChange(value, filter)}
          title={filter.categoryName}
        />
      ))}
    </>
  );
};

export default DesktopFilters;
