import React, { useState, useEffect } from "react";
import "./Universities.scss";

export default function Universities() {
  const imageLinks = [
    {
      id: 1484,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Bennett.png",
      redirectPath: "/college-details/bennett-university-greater-noida",
    },
    {
      id: 1485,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/bml-logo.png",
      redirectPath: "/college-details/bml-munjal-university-gurgaon",
    },
    {
      id: 1486,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Gitam-University.png",
      redirectPath: "/college-details/gitam-gandhi-institute-of-technology-and-management",
    },
    // {
    //   id: 1487,
    //   imageUrl:
    //     "https://cdncollegementor.blob.core.windows.net/partnered-university/chandigarh-university.png",
    //   redirectPath: "/college-details/candhigardh-university",
    // },
    {
      id: 1488,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/amrita-logo.png",
      redirectPath: "/college-details/amrita-university",
    },
    {
      id: 1489,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/KIIT-University.webp",
      redirectPath: "/college-details/kiit-university-kalinga-institute-of-industrial-technology-bhubaneswar",
    },
    {
      id: 1490,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/kl-university-logo.png",
      redirectPath: "/college-details/kl-university-guntur-koneru-lakshmaiah-education-foundation-guntur",
    },
    // {
    //   id: 1491,
    //   imageUrl:
    //     "https://cdncollegementor.blob.core.windows.net/partnered-university/LPU-logo.png",
    //   redirectPath: "/college-details/lovely-professional-university",
    // },
    {
      id: 1492,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/manav-rachna.png",
      redirectPath: "/college-details/mriirs-faridabad-manav-rachna-international-institute-of-research-and-studies-faridabad",
    },
    {
      id: 1493,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/satya-bhama.png",
      redirectPath: "/college-details/sathyabama-university-sathyabama-institute-of-science-and-technology-chennai",
    },
    {
      id: 1494,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/saveetha-university.png",
      redirectPath: "/college-details/simats-saveetha-institute-of-medical-and-technical-sciences-chennai",
    },
    {
      id: 1495,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/UPES-logo.png",
      redirectPath: "/college-details/upes-university-of-petroleum-and-energy-studies",
    },
    {
      id: 1496,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Woxsen-logo.png",
      redirectPath: "/college-details/woxsen-university-hyderabad",
    },
    {
      id: 1497,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/aditya-univ-logo.jpg",
      redirectPath: "/college-details/aditya-university-kakinada",
    },
    {
      id: 1484,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Bennett.png",
      redirectPath: "/college-details/bennett-university-greater-noida",
    },
    {
      id: 1485,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/bml-logo.png",
      redirectPath: "/college-details/bml-munjal-university-gurgaon",
    },
    {
      id: 1486,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Gitam-University.png",
      redirectPath: "/college-details/gitam-gandhi-institute-of-technology-and-management",
    },
    // {
    //   id: 1487,
    //   imageUrl:
    //     "https://cdncollegementor.blob.core.windows.net/partnered-university/chandigarh-university.png",
    //   redirectPath: "/college-details/candhigardh-university",
    // },
    {
      id: 1488,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/amrita-logo.png",
      redirectPath: "/college-details/amrita-university",
    },
    {
      id: 1489,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/KIIT-University.webp",
      redirectPath: "/college-details/kiit-university-kalinga-institute-of-industrial-technology-bhubaneswar",
    },
    {
      id: 1490,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/kl-university-logo.png",
      redirectPath: "/college-details/kl-university-guntur-koneru-lakshmaiah-education-foundation-guntur",
    },
    // {
    //   id: 1491,
    //   imageUrl:
    //     "https://cdncollegementor.blob.core.windows.net/partnered-university/LPU-logo.png",
    //   redirectPath: "/college-details/lovely-professional-university",
    // },
    {
      id: 1492,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/manav-rachna.png",
      redirectPath: "/college-details/mriirs-faridabad-manav-rachna-international-institute-of-research-and-studies-faridabad",
    },
    {
      id: 1493,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/satya-bhama.png",
      redirectPath: "/college-details/sathyabama-university-sathyabama-institute-of-science-and-technology-chennai",
    },
    {
      id: 1494,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/saveetha-university.png",
      redirectPath: "/college-details/simats-saveetha-institute-of-medical-and-technical-sciences-chennai",
    },
    {
      id: 1495,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/UPES-logo.png",
      redirectPath: "/college-details/upes-university-of-petroleum-and-energy-studies",
    },
    {
      id: 1496,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/Woxsen-logo.png",
      redirectPath: "/college-details/woxsen-university-hyderabad",
    },
    {
      id: 1497,
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/partnered-university/aditya-univ-logo.jpg",
      redirectPath: "/college-details/aditya-university-kakinada",
    },
  ];

  const repeatedImageLinks = Array.from({ length: 5 }, () => imageLinks).flat(); // Repeat 5 times

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % repeatedImageLinks.length
      );
    }, 5000); // Change the interval duration as needed (e.g., every 5 seconds)

    return () => clearInterval(interval);
  }, [repeatedImageLinks]);

  return (
    <div className="universities-section">
      <div className="flex flex-col justify-center">
        <div className="self-center text-xl font-medium text-[#667085]">
          Trusted by esteemed universities
        </div>
        <div className="flex gap-5 justify-between px-5 mt-4 max-md:flex-wrap">
          <div className="carousel">
            <div className="slider">
              <div
                className="slidetrack"
                style={{
                  transform: `translateX(-${currentImageIndex * 100}%)`,
                }}
              >
                {repeatedImageLinks.map((item, index) => (
                  <div key={index} className="slide">
                    <a href={item.redirectPath}>
                      <img
                        loading="lazy"
                        src={item.imageUrl}
                        alt="university"
                        className="h-full w-full object-contain"
                        width={255}
                        height={96}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
