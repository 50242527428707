import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { ImageCard } from "../../../../components/image-card/ImageCard";
import "./CollegesByDestination.scss";

const imagesData = [
  { image: "/images/clg-by-destination/hyderabad.webp", city: "Hyderabad" },
  { image: "/images/clg-by-destination/pune.webp", city: "Pune" },
  { image: "/images/clg-by-destination/chennai.webp", city: "Chennai" },
  { image: "/images/clg-by-destination/coimbatore.webp", city: "Coimbatore" },
  {
    image: "/images/clg-by-destination/bhubhaneshwar.webp",
    city: "Bhubhaneshwar",
  },
  { image: "/images/clg-by-destination/jaipur.webp", city: "Jaipur" },
  { image: "/images/clg-by-destination/varanasi.webp", city: "Varanasi" },
  { image: "/images/clg-by-destination/agra.webp", city: "Agra" },
  { image: "/images/clg-by-destination/kolkata.webp", city: "Kolkata" },
  { image: "/images/clg-by-destination/ahmedabad.webp", city: "Ahmedabad" },
  { image: "/images/clg-by-destination/delhi.webp", city: "Delhi" },
  { image: "/images/clg-by-destination/mumbai.webp", city: "Mumbai" },
];

export const CollegesByDestination = ({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) => {
  const [slidesPerView, setSlidesPerView] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 510) {
        setSlidesPerView(2); // Small screens
      } else if (width > 510 && width <= 768) {
        setSlidesPerView(3); // Medium screens
      } else if (width > 768 && width <= 1024) {
        setSlidesPerView(4); // Large-medium screens
      } else {
        setSlidesPerView(6); // Larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="clg-by-destination-main py-5 md:py-16 bg-[#F4F7FB]">
      <div className="flex flex-col items-center justify-center gap-4 md:gap-8">
        <div className="flex justify-center items-center relative">
          <h3 className="text-black text-3xl font-extrabold">
            Colleges by Destination
          </h3>
          <div
            className="absolute -right-24 top-1/2 -translate-y-1/2 h-16 w-16 max-md:hidden"
            ref={animationCheckPointRef}
          ></div>
        </div>
        {/* slider section starts */}
        <div className="swiper-section w-[1360px] mx-auto max-md:w-full min-max-md:w-full min-max-lg:w-full">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={7}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {imagesData.map((item, index) => (
              <SwiperSlide key={index}>
                <ImageCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="flex items-center justify-center -mt-24">
            <a
              href="/list-of-colleges-in-india"
              className="flex gap-2 z-10 justify-center py-[14px] px-[34px] text-base font-semibold text-white button-styles rounded-full arrow-button white-color map-mentor"
            >
              <span>View all</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="currentColor"
                className="arrow-icon"
              >
                <path
                  d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </div>
        {/* slider section ends */}
      </div>
    </div>
  );
};
