import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

interface ProgramCard {
  image: string;
  title: string;
  subtitle: string;
}

const programs: ProgramCard[] = [
  {
    image: "/images/career-discovery/program1.webp",
    title: "8 - 9 Class",
    subtitle: "Ikigai\nDiscovery Program",
  },
  {
    image: "/images/career-discovery/program2.webp",
    title: "10 - 12 Class",
    subtitle: "Discover Board,\nStream & Subject",
  },
  {
    image: "/images/career-discovery/program3.webp",
    title: "12 Class",
    subtitle: "Discover Career,\nCourse, College\nExam, Scholarships",
  },
  {
    image: "/images/career-discovery/program4.webp",
    title: "College Graduates",
    subtitle: "Masters or Job's",
  },
];

const svgs = ["#FFD540", "#00CC99", "#FF66CC", "#173CBA"];

export const IkigaiDiscoveryProgram: React.FC = () => {
  return (
    <div className="flex flex-row max-md:flex-col px-[80px] justify-between max-md:gap-5 items-center self-center py-10 max-md:mt-6 max-md:w-full container max-md:px-7 max-md:py-5">
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        slidesPerView={1}
        loop={true}
        spaceBetween={20}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
            allowTouchMove: true,
            autoplay: true,
            loop: true,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            allowTouchMove: true,
            autoplay: true,
            loop: true,
          },
          1280: {
            slidesPerView: programs.length,
            spaceBetween: 40,
            allowTouchMove: false,
            autoplay: false,
            loop: false,
          },
        }}
        className="flex max-md:block gap-5 px-[100px] max-md:px-7 py-10 container"
      >
        {programs.map((program, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col self-stretch my-auto relative w-[277px] pt-[6px] rounded-none max-md:w-full min-max-md:mr-6">
              <div className="w-full flex justify-center absolute  top-0 left-0">
                {index % 2 !== 0 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="277"
                    height="142"
                    viewBox="0 0 277 142"
                    fill="none"
                    className=" w-full h-auto max-md:w-[277px] "
                  >
                    <path
                      d="M134.448 55.1689L0 142V0H277V142L134.448 55.1689Z"
                      fill={svgs[index]}
                    />
                  </svg>
                )}
              </div>

              <div className="flex relative z-10 mx-auto flex-col w-[265px] h-[265px] items-center px-7 pt-40 pb-9 text-center text-white rounded-none aspect-[1.022] shadow-[0px_0px_10px_rgba(0,0,0,0.15)] max-md:px-5 max-md:pt-24">
                <img
                  loading="lazy"
                  src={program.image}
                  alt={program.title}
                  className="object-cover absolute inset-0 size-full"
                />
                <h2 className="relative text-xl font-bold leading-loose max-md:mt-14">
                  {program.title}
                </h2>
                <p className="relative text-base font-semibold leading-5 whitespace-pre-line max-md:mb-8">
                  {program.subtitle}
                </p>
              </div>
              {/* {index % 2 === 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="277"
                  height="142"
                  viewBox="0 0 277 142"
                  fill="none"
                  className="absolute bottom-[17.5%] left-0 w-full h-auto max-md:w-[277px] flex max-md:mx-auto"
                >
                  <path
                    d="M134.448 86.8311L0 0V142H277V0L134.448 86.8311Z"
                    fill={svgs[index]}
                  />
                </svg>
              )} */}
              {index % 2 === 0 && (
                <div className="w-full flex justify-center absolute bottom-[17.5%] left-0 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="277"
                    height="142"
                    viewBox="0 0 277 142"
                    fill="none"
                    className="w-full h-auto max-md:w-[277px]"
                  >
                    <path
                      d="M134.448 86.8311L0 0V142H277V0L134.448 86.8311Z"
                      fill={svgs[index]}
                    />
                  </svg>
                </div>
              )}

              <button
                className="flex gap-1.5 self-center px-2.5 py-1.5 mt-7 max-w-full text-base font-bold text-white bg-[#0c9] rounded-md w-[120px]"
                aria-label={`Apply Now for ${program.title}`}
              >
                <span className="grow">Apply Now</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  className="my-auto"
                >
                  <path
                    d="M12.1619 6.53033C12.4548 6.23744 12.4548 5.76256 12.1619 5.46967L7.38891 0.696698C7.09602 0.403805 6.62115 0.403805 6.32825 0.696698C6.03536 0.989592 6.03536 1.46447 6.32825 1.75736L10.5709 6L6.32825 10.2426C6.03536 10.5355 6.03536 11.0104 6.32825 11.3033C6.62115 11.5962 7.09602 11.5962 7.38891 11.3033L12.1619 6.53033ZM0.894714 6.75L11.6316 6.75L11.6316 5.25L0.894714 5.25L0.894714 6.75Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
