import React, { useState, useRef } from "react";
import Slider from "react-slick";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import jeeMainsRank from "../../../../images/rank-predictor/jee-mains-rank.webp";
import jeeAdvanced from "../../../../images/rank-predictor/jee-advanced.webp";
import "./RankPredictor.scss";
import { Link } from "react-router-dom";

const RankPredictorSlider = [
  {
    image: jeeAdvanced,
    title: "Predict My College",
    link: "/college-predictor",
  },
  {
    image: jeeMainsRank,
    title: "Predict My Rank",
    link: "",
  },
  {
    image: jeeMainsRank,
    title: "Compare Colleges",
    link: "/college-compare",
  },
  {
    image: jeeAdvanced,
    title: "Application to Admission",
    link: "/applications-admissions",
  },
  {
    image: jeeMainsRank,
    title: "Education for All",
    link: "/education-for-all",
  },

  // Additional cards...
];

const menuItems = [
  { title: "College Predictor", link: "/college-predictor" },
  { title: "Compare Colleges", link: "/college-compare" },
  { title: "College Rankings", link: "/rankings" },
  { title: "Application to Admission", link: "/applications-admissions" },
  { title: "Education for All", link: "/education-for-all" },
];

export const RankPredictor = ({
  animationCheckPointRef,
}: {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
}) => {
  const [isSliding, setIsSliding] = useState(false);
  const sliderRef = useRef<Slider>(null);

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} custom-class-prev ${
          isSliding ? "disabled" : ""
        }`}
        style={{ ...style }}
        onClick={!isSliding ? onClick : undefined}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
    );
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} custom-class-next ${
          isSliding ? "disabled" : ""
        }`}
        style={{ ...style }}
        onClick={!isSliding ? onClick : undefined}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    centerMode: false,
    cssEase: "ease-in-out",
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: () => setIsSliding(true),
    afterChange: () => setTimeout(() => setIsSliding(false), 10),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="rank-predictor-section flex py-5 md:py-14">
      <div className="container-section container mx-auto flex flex-col gap-16">
        <div className="flex flex-col items-start justify-center gap-4 w-full lg:w-1/2 min-max-md:w-full">
          <div className="flex justify-center items-center">
            <h3 className="text-black text-3xl font-extrabold">
              Start Exploring Tools
            </h3>
            <div
              className="translate-x-12 h-16 w-16"
              ref={animationCheckPointRef}
            ></div>
          </div>
          <p className="text-left text-[#6B7280] max-w-[600px] text-base">
            We'll assist you in predicting colleges and rankings, comparing
            institutions, and managing your college applications from submission
            to admission.
          </p>
          <div>
            {menuItems.map((item, index) => (
              <div
                key={index}
                className="flex gap-2 flex-col items-center mt-2 text-[18px] text-[#747474] cursor-pointer"
              >
                <Link
                  to={item.link}
                  className="flex items-center self-stretch my-auto h-8 w-full group"
                >
                  <h3>{item.title}</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    className="my-auto ml-4 transform transition-transform group-hover:translate-x-1"
                  >
                    <path
                      d="M13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.34315 0.928932C6.95262 0.538408 6.31946 0.538408 5.92893 0.928932C5.53841 1.31946 5.53841 1.95262 5.92893 2.34315L11.5858 8L5.92893 13.6569C5.53841 14.0474 5.53841 14.6805 5.92893 15.0711C6.31946 15.4616 6.95262 15.4616 7.34315 15.0711L13.7071 8.70711ZM0 9H13V7H0V9Z"
                      fill="#00C798"
                    />
                  </svg>
                </Link>
                {index !== menuItems.length - 1 && (
                  <div className="min-h-0 border border-[#6B7280] w-[247px]" />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="w-full lg:w-1/2 relative flex flex-col max-md:hidden min-max-md:hidden">
          <Slider {...settings} ref={sliderRef}>
            {RankPredictorSlider.map((item, index) => (
              <div
                key={index}
                className={`rankSlider flex flex-col p-8 font-semibold bg-white rounded-2xl`}
              >
                <div className="flex flex-col w-full h-full">
                  <img
                    src={item.image}
                    alt={`${item.title}-icon`}
                    className="transition-all duration-900 transform"
                  />
                  <div className="py-10 transition-all duration-900 transform">
                    <p className="text-lg leading-8 font-semibold text-center text-black line-clamp-2 min-h-4">
                      {item.title}
                    </p>
                  </div>
                  <div className="flex justify-center w-full gap-2 transition-all duration-900 transform">
                    <Link
                      to={item.link}
                      className="flex gap-2 justify-center px-5 py-3.5 text-base text-white bg-[#113CC0] font-semibold rounded-full whitespace-nowrap"
                    >
                      Learn more
                      <ArrowForwardIcon className="shrink-0 my-auto w-2 h-2 aspect-square fill-white" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <img
        loading="lazy"
        src="/images/common/shape001.png"
        alt="rank-predictor-shape001"
        className="shrink-0 self-start w-11 aspect-[0.35] min-max-lg:hidden"
      />
    </div>
  );
};
