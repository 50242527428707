import Icons1 from "../../images/navbar/megamenu/icon-1.svg";
import Icons2 from "../../images/navbar/megamenu/icon-2.svg";
import Icons3 from "../../images/navbar/megamenu/icon-3.svg";
import Icons4 from "../../images/navbar/megamenu/icon-4.svg";
import Icons5 from "../../images/navbar/megamenu/icon-5.svg";
import Icons6 from "../../images/navbar/megamenu/icon-6.svg";
import Icons7 from "../../images/navbar/megamenu/icon-7.svg";
import Icons8 from "../../images/navbar/megamenu/icon-8.svg";
import Icons9 from "../../images/navbar/megamenu/icon-9.svg";
import Icons10 from "../../images/navbar/megamenu/icon-10.svg";
import Icons11 from "../../images/navbar/megamenu/icon-11.svg";
import Icons12 from "../../images/navbar/megamenu/icon-12.svg";
import Icons13 from "../../images/navbar/megamenu/icon-13.svg";
import Icons16 from "../../images/navbar/megamenu/icon-16.svg";
export const serviceItems = [
  // { text: "College Mentor Services", icon: Icons1, url: "#" },
  { text: "Scholarships", icon: Icons2, url: "/scholarships" },
  // { text: "Campus Tour 2025", icon: Icons3, url: "#" },
  {
    text: "Regulatory Professional Councils",
    icon: Icons4,
    url: "/regulatory-professional-councils",
  },
  {
    text: "Application to Admission",
    icon: Icons5,
    url: "/applications-admissions",
  },
  { text: "Education Loans", icon: Icons6, url: "/education-loan-details" },
  { text: "Education for All", icon: Icons7, url: "/education-for-all" },
  {
    text: "College Mentor Scholarships",
    icon: Icons8,
    url: "/college-mentor-scholarship",
  },
  { text: "Study Abroad Mentoring", icon: Icons9, url: "/study-abroad" },
  {
    text: "Ikigai Discovery Test",
    icon: Icons10,
    url: "/ikigai-career-discovery",
  },
  { text: "Mentoring 360", icon: Icons11, url: "/mentors" },
  {
    text: "Write a Review Earn Up to Rs.300",
    icon: Icons12,
    url: "/refer-and-earn",
  },
  { text: "Exam & Admissions Alerts", icon: Icons13, url: "/exams" },
  { text: "About Us", icon: Icons16, url: "/about-us" },
];
