import React from "react";
import KnowledgeHubFAQ from "../rankings/KnowledgeHubFAQ";
import AwardsAccreditations from "../rankings/AwardsAccreditations";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import SidebarAdd from "../sidebar/SidebarAdd";

interface AccreditationItem {
  description: string;
  orderIndex: string;
  title: string;
}

interface RankingSectionProps {
  title: string;
  description: string;
  array?: AccreditationItem[];
  orderIndex?: string;
}

interface CollegeProps {
  image: string;
  name: string;
  location: string;
  title: string;
}

interface FAQItem {
  description: string;
  orderIndex: string;
  title: string;
}

interface RankingsTabProps {
  rankings: {
    [key: string]: RankingSectionProps | CollegeProps[] | undefined;
  };
}

const RankingSection: React.FC<RankingSectionProps> = ({
  title,
  description,
}) => {
  return (
    <section className="ranking-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const RankingsTab: React.FC<RankingsTabProps> = ({ rankings }) => {
  const similarColleges = rankings.similarColleges as CollegeProps[];
  const keys = Object.keys(rankings)
    .filter((key) => key.startsWith("key_") && rankings[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (rankings[a] as RankingSectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (rankings[b] as RankingSectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  const rankingsFAQs = rankings.faqs as RankingSectionProps | undefined;

  return (
    <>
      <div className="py-10 bg-[#f4f4f4] max-sm:py-6">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              <div className="flex flex-col gap-5">
                {keys.map((key) => {
                  const section = rankings[key] as RankingSectionProps;

                  if (key === "key_5" && section.array?.length) {
                    return (
                      <AwardsAccreditations
                        key={key}
                        title={section.title}
                        description={section.description}
                        array={section.array}
                      />
                    );
                  }

                  return (
                    <RankingSection
                      key={key}
                      title={section.title}
                      description={section.description}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full lg:w-1/4">
              <SidebarAdd />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        {rankingsFAQs?.array?.length ? (
          <KnowledgeHubFAQ
            title={rankingsFAQs.title || "Knowledge Hub (FAQs)"}
            faqs={rankingsFAQs.array.map((item: FAQItem) => ({
              question: item.title,
              answer: item.description || "Information not available.",
            }))}
          />
        ) : null}
        {similarColleges && (
          <SimilarEngineeringColleges colleges={similarColleges} />
        )}
      </div>
    </>
  );
};

export default RankingsTab;
