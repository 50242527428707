import React, { useEffect, useRef, useState } from "react";

const DreamstoReality = () => {
  const head = [
    "India's No. #1 Scholarship & Loan Search Engine",
    "Seamless searching",
    "Excel in Your Studies",
  ];
  const para = [
    "India's No. #1 Scholarship & Loan Search Engine for Indian students",
    "Information about all types of Scholarships and Loans at your fingertip with powerful searching technique.",
    "Advance your career with higher education. Contact us to learn more.",
  ];

  const icons = [
    "/images/scholarship/landing/medal.svg",
    "/images/scholarship/landing/cap.svg",
    "/images/scholarship/landing/note.svg",
  ];
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPlayed, setVideoPlayed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current && !videoPlayed) {
        const rect = videoRef.current.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (isVisible) {
          videoRef.current.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [videoPlayed]);

  return (
    <div className="bg-[#E6F9F5]">
      <div className="container py-12 max-sm:py-8">
        <div className="flex flex-col lg:flex-row max-sm:gap-2">
          <div className="w-full lg:w-1/2">
            <div>
              <div className="video-trigger">
                {/* {!videoPlayed ? (
                  <video
                    ref={videoRef}
                    src="/images/scholarship/landing/video.mp4"
                    onEnded={() => {
                      setVideoPlayed(true); 

                    }}
                  />
                ) : ( */}
                <img
                  src="https://cdncollegementor.blob.core.windows.net/scholarship/landing/dreams.png"
                  alt="Dreams"
                />
                {/* )} */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:ml-32 lg:my-auto">
            <div className="lg:pl-7 flex text-[#383838] font-roboto text-3xl font-medium leading-[42px]">
              We Transform
              <br />
              your Dreams into Reality!
            </div>
            <div className="flex flex-col gap-4 mt-2">
              {head.map((heading, index) => (
                <div key={index} className="flex flex-row gap-1">
                  <img
                    src={icons[index]}
                    alt={`Icon ${index + 1}`}
                    className="mr-2"
                  />
                  <div className="flex flex-col gap-1">
                    <div className="">
                      <div className="text-[#383838] font-roboto text-sm font-medium">
                        {heading}
                      </div>
                    </div>
                    <div className="text-[#383838] font-roboto text-xs font-normal ">
                      {para[index]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamstoReality;
