import { FC, useState } from "react";
import AnimatedTabSlider from "../../../../components/AnimatedTabSlider";
import collegeIcon from "../images/find-perfect-college/college-icon.svg";
import examIcon from "../images/find-perfect-college/exam-icon.svg";
import courseIcon from "../images/find-perfect-college/course-icon.svg";
import rankingIcon from "../images/find-perfect-college/ranking-icon.svg";
import leftArrowIcon from "../images/find-perfect-college/left-arrow.svg";
import rightArrowIcon from "../images/find-perfect-college/right-arrow.svg";
import SearchBarWithSuggestion from "./SearchBarWithSuggestion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import TabSelectorV1 from "../../../../components/TabSelectorV1";

const cardsList: {
  title: (stream: string) => string;
  key: "college" | "specialization" | "exam" | "resource";
  colorDark: string;
  colorLight: string;
  icon: string;
}[] = [
  {
    title: (stream: string) => `Top ${stream} Colleges`,
    key: "college",
    colorDark: "#F49FB6",
    colorLight: "#FFEFFF",
    icon: collegeIcon,
  },
  {
    title: (stream: string) => `${stream} Exams`,
    key: "exam",
    colorDark: "#F4D2D2",
    colorLight: "#FFFAFA",
    icon: rankingIcon,
  },
  {
    title: (stream: string) => `${stream} Specializations`,
    key: "specialization",
    colorDark: "#A6E0DE",
    colorLight: "#ECFFFF",
    icon: examIcon,
  },
  {
    title: (stream: string) => `${stream} Courses`,
    key: "resource",
    colorDark: "#FFE5D9",
    colorLight: "#FFFFFF",
    icon: courseIcon,
  },
];

const indianColleges = [
  "Indian Institute of Technology Bombay",
  "Indian Institute of Technology Delhi",
  "Indian Institute of Technology Madras",
  "Indian Institute of Technology Kanpur",
  "Indian Institute of Technology Kharagpur",
  "Indian Institute of Science",
  "Indian Institute of Technology Roorkee",
  "Indian Institute of Technology Guwahati",
  "National Institute of Technology Tiruchirappalli",
  "Birla Institute of Technology and Science, Pilani",
  "All India Institute of Medical Sciences, New Delhi",
  "Jawaharlal Nehru University",
  "Banaras Hindu University",
  "Anna University",
  "University of Delhi",
  "National Institute of Technology Surathkal",
  "Manipal Academy of Higher Education",
  "Vellore Institute of Technology",
  "Savitribai Phule Pune University",
  "Amrita Vishwa Vidyapeetham",
];

const data = [
  {
    data: {
      exam: [
        { name: "VITEEE", link: "/exam-details/viteee" },
        { name: "AP EAPCET", link: "/exam-details/AP-EAPCET" },
        { name: "KIITEE", link: "/exam-details/kiitee" },
        { name: "BITSAT", link: "/exam-details/BITSAT" },
        { name: "COMEDK", link: "/exam-details/comedk-uget" },
        { name: "JEE Mains", link: "/exam-details/jee-main" },
      ],
      resource: [
        {
          name: "Mechanical Engineering",
          link: "/course-details/mechanical-engineering",
        },
        {
          name: "Artificial Intelligence & Machine Learning",
          link: "/course-details/artificial-intelligence-machine-learning",
        },
        {
          name: "Robotics Engineering",
          link: "/course-details/robotics-engineering",
        },
        {
          name: "Chemical Engineering",
          link: "/course-details/chemical-engineering",
        },
        {
          name: "Petroleum Engineering",
          link: "/course-details/petroleum-engineering",
        },
        {
          name: "Electronics and Communications Engineering",
          link: "/course-details/electronics-and-communications-engineering",
        },
        {
          name: "Automotive Engineering",
          link: "/course-details/automotive-engineering",
        },
        {
          name: "Aeronautical Engineering",
          link: "/course-details/aeronautical-engineering",
        },
        {
          name: "Marine Engineering",
          link: "/course-details/marine-engineering",
        },
        {
          name: "Automation and Robotics Engineering",
          link: "/course-details/automation-and-robotics-engineering",
        },
        {
          name: "Agricultural and Irrigation Engineering",
          link: "/course-details/agricultural-and-irrigation-engineering",
        },
        {
          name: "Computer Science Engineering",
          link: "/course-details/computer-science-engineering",
        },
        {
          name: "Biotechnology Engineering",
          link: "/course-details/biotechnology-engineering",
        },
        {
          name: "Electrical and Electronics Engineering",
          link: "/course-details/electrical-and-electronics-engineering",
        },
        {
          name: "Aircraft Manufacturing and Maintenance Engineering",
          link: "/course-details/aircraft-manufacturing-and-maintenance-engineering",
        },
      ],
      college: [
        {
          name: "IIT Madras",
          link: "/college-details/iit-madras-indian-institute-of-technology-madras",
        },
        {
          name: "IIT Kanpur",
          link: "/college-details/iit-kanpur-indian-institute-of-technology-kanpur",
        },
        {
          name: "IIT Delhi",
          link: "/college-details/ndiit-delhi-new-delhi-institute-of-information-technology-and-management-delhi",
        },
        {
          name: "IIT Bombay",
          link: "/college-details/iit-bombay-indian-institute-of-technology-bombay",
        },
        {
          name: "NIT Warangal",
          link: "/college-details/nit-warangal-national-institute-of-technology-warangal",
        },
        {
          name: "NIT Trichy",
          link: "/college-details/nit-trichy-national-institute-of-technology-tiruchirappalli",
        },
        {
          name: "IIIT Hyderabad",
          link: "/college-details/iiit-hyderabad-international-institute-of-information-technology-hyderabad",
        },
        {
          name: "IISC Bangalore",
          link: "/college-details/iisc-bangalore-indian-institute-of-science-bangalore",
        },
        {
          name: "Amrita Vishwa Vidyapeetham",
          link: "/college-details/amrita-university",
        },
        {
          name: "Birla Institute of Technology & Science",
          link: "/college-details/bits-pilani-birla-institute-of-technology-and-science-pilani",
        },
        {
          name: "GITAM",
          link: "/college-details/gitam-gandhi-institute-of-technology-and-management",
        },
        {
          name: "Graphic Era",
          link: "/college-details/graphic-era-university",
        },
        {
          name: "HIT",
          link: "/college-details/hits-chennai-hindustan-institute-of-technology-and-science-chennai",
        },
        {
          name: "Aditya University Kakinada",
          link: "/college-details/aditya-university-kakinada",
        },
        {
          name: "ICFAI Foundation for Higher Education",
          link: "/college-details/dr.-m.g.r.-educational-and-research-institute-chennai",
        },
        {
          name: "Jain University",
          link: "/college-details/jain-deemed-to-be-university-bangalore-jain-deemed-to-be-university-bangalore",
        },
        {
          name: "Kalasalingam Academy of Research and Education",
          link: "/college-details/kare-kalasalingam-academy-of-research-and-education",
        },
        {
          name: "KIIT University",
          link: "/college-details/kiit-university-kalinga-institute-of-industrial-technology-bhubaneswar",
        },
        {
          name: "VIT Vellore",
          link: "/college-details/vit-vellore-vellore-institute-of-technology-vellore",
        },
      ],
      specialization: [
        { name: "Nuclear Engineer", link: "/career-details/nuclear-engineer" },
        { name: "Web Developer", link: "/career-details/web-developer" },
        { name: "Cloud Developer", link: "/career-details/cloud-developer" },
        {
          name: "Full Stack Developer",
          link: "/career-details/full-stack-developer",
        },
        { name: "DevOps Engineer", link: "/career-details/devops-engineer" },
        {
          name: "Blockchain Developer",
          link: "/career-details/blockchain-developer",
        },
        { name: "Ethical Hacker", link: "/career-details/ethical-hacker" },
        {
          name: "Biomedical Engineer",
          link: "/career-details/biomedical-engineer",
        },
        {
          name: "Software Engineer",
          link: "/career-details/software-engineer",
        },
        {
          name: "Electrical Engineer",
          link: "/career-details/electrical-engineer",
        },
        { name: "Civil Engineer", link: "/career-details/civil-engineer" },
        {
          name: "Aerospace Engineer",
          link: "/career-details/aerospace-engineer",
        },
        {
          name: "Automobile Engineer",
          link: "/career-details/automobile-engineer",
        },
        {
          name: "Computer Hardware Engineer",
          link: "/career-details/computer-hardware-engineer",
        },
        {
          name: "Software Developer",
          link: "/career-details/software-developer",
        },
        {
          name: "Aeronautical Engineer",
          link: "/career-details/aeronautical-engineer",
        },
        { name: "Process Engineer", link: "/career-details/process-engineer" },
        {
          name: "Automation Test Engineer",
          link: "/career-details/automation-test-engineer",
        },
        { name: "Sound Engineer", link: "/career-details/sound-engineer" },
      ],
    },
    stream: "Engineering",
  },
  {
    data: {
      exam: [
        { name: "MAT", link: "/exam-details/mat" },
        { name: "GRE", link: "/exam-details/gre" },
      ],
      resource: [
        {
          name: "Financial Management",
          link: "/course-details/financial-management",
        },
        {
          name: "Operations Management",
          link: "/course-details/operations-management",
        },
        {
          name: "International Business",
          link: "/course-details/international-business",
        },
        { name: "Entrepreneurship", link: "/course-details/entrepreneurship" },
        { name: "Hotel Management", link: "/course-details/hotel-management" },
        { name: "Pathology", link: "/course-details/pathology" },
        { name: "Ayurveda", link: "/course-details/ayurveda" },
        { name: "Cardiology", link: "/course-details/cardiology" },
        { name: "Dermatology", link: "/course-details/dermatology" },
        {
          name: "Travel and Tourism",
          link: "/course-details/travel-and-tourism",
        },
        { name: "Neurology", link: "/course-details/neurology" },
        { name: "Pharmacy", link: "/course-details/pharmacy" },
        { name: "Nursing", link: "/course-details/nursing" },
        { name: "Horticulture", link: "/course-details/horticulture" },
        { name: "Archaeology", link: "/course-details/archaeology" },
      ],
      college: [
        {
          name: "Bennet University",
          link: "/college-details/bennett-university-greater-noida",
        },
        {
          name: "Woxsen University",
          link: "/college-details/woxsen-university-hyderabad",
        },
        {
          name: "ICFAI Foundation for Higher Education",
          link: "/college-details/dr.-m.g.r.-educational-and-research-institute-chennai",
        },
        {
          name: "Jain University",
          link: "/college-details/jain-deemed-to-be-university-bangalore-jain-deemed-to-be-university-bangalore",
        },
        {
          name: "Manav Rachna International Institute of Research and Studies",
          link: "/college-details/mriirs-faridabad-manav-rachna-international-institute-of-research-and-studies-faridabad",
        },
        {
          name: "Galgotias University",
          link: "/college-details/galgotias-university-galgotias-university-greater-noida",
        },
        {
          name: "Chandigarh University, Punjab",
          link: "/college-details/chandigarh-university-punjab",
        },
        {
          name: "BML Munjal University",
          link: "/college-details/bml-munjal-university-gurgaon",
        },
        {
          name: "UPES",
          link: "/college-details/upes-university-of-petroleum-and-energy-studies",
        },
      ],
      specialization: [
        {
          name: "Marketing Manager",
          link: "/career-details/marketing-manager",
        },
        { name: "Project Manager", link: "/career-details/project-manager" },
        { name: "HR Manager", link: "/career-details/hr-manager" },
        { name: "Business Analyst", link: "/career-details/business-analyst" },
        {
          name: "Investment Banker",
          link: "/career-details/investment-banker",
        },
        { name: "Sales Manager", link: "/career-details/sales-manager" },
        {
          name: "Supply Chain Manager",
          link: "/career-details/supply-chain-manager",
        },
        { name: "Marine Manager", link: "/career-details/marine-manager" },
        {
          name: "Cryptocurrency Trader",
          link: "/career-details/cryptocurrency-trader",
        },
      ],
    },
    stream: "Management",
  },
  {
    data: {
      exam: [{ name: "NEET UG", link: "/exam-details/neet" }],
      resource: [
        { name: "Pharmacist", link: "/career-details/pharmacist" },
        { name: "Dentist", link: "/career-details/dentist" },
        { name: "Surgeon", link: "/career-details/surgeon" },
        { name: "Psychiatrist", link: "/career-details/psychiatrist" },
        { name: "Paediatrician", link: "/career-details/paediatrician" },
        { name: "Radiologist", link: "/career-details/radiologist" },
        {
          name: "Occupational Therapist",
          link: "/career-details/occupational-therapist",
        },
        { name: "Veterinarian", link: "/career-details/veterinarian" },
        {
          name: "Child Psychologist",
          link: "/career-details/child-psychologist",
        },
        { name: "Psychologist", link: "/career-details/psychologist" },
      ],
      college: [
        {
          name: "Chettinad Academy of Research and Education (CARE)",
          link: "/college-details/care-chettinad-academy-of-research-and-education",
        },
        {
          name: "Dr. M.G.R. Educational and Research Institute",
          link: "/college-details/dr.-m.g.r.-educational-and-research-institute-chennai",
        },
        {
          name: "JSS Academy of Higher Education & Research",
          link: "/college-details/jss-academy-of-higher-education-and-research-mysuru",
        },
        {
          name: "Sri Siddhartha Academy of Higher Education",
          link: "/college-details/sri-siddartha-academy-of-higher-education-tumkur",
        },
        {
          name: "Saveetha Institute of Medical and Technical Sciences",
          link: "/college-details/simats-saveetha-institute-of-medical-and-technical-sciences-chennai",
        },
      ],
      specialization: [
        { name: "Pathology", link: "/career-details/pathology" },
        { name: "Ayurveda", link: "/career-details/ayurveda" },
        { name: "Cardiology", link: "/career-details/cardiology" },
        { name: "Dermatology", link: "/career-details/dermatology" },
        { name: "Neurology", link: "/career-details/neurology" },
        { name: "Pharmacy", link: "/career-details/pharmacy" },
        { name: "Nursing", link: "/career-details/nursing" },
      ],
    },
    stream: "Medical",
  },
  {
    data: {
      exam: [
        { name: "SLAT Exam", link: "/exam-details/slat-exam" },
        { name: "CLAT Exam", link: "/exam-details/clat-exam" },
        { name: "AILET Exam", link: "/exam-details/ailet-exam" },
        { name: "LSAT", link: "/exam-details/lsat" },
      ],
      specialization: [
        { name: "Corporate Lawyer", link: "/career-details/corporate-lawyer" },
        { name: "Criminal Lawyer", link: "/career-details/criminal-lawyer" },
        { name: "Judge", link: "/career-details/judge" },
        { name: "Legal Advisor", link: "/career-details/legal-advisor" },
      ],
      college: [
        {
          name: "ICFAI Foundation for Higher Education",
          link: "/college-details/dr.-m.g.r.-educational-and-research-institute-chennai",
        },
        {
          name: "UPES",
          link: "/college-details/upes-university-of-petroleum-and-energy-studies",
        },
        {
          name: "KIIT University",
          link: "/college-details/kiit-university-kalinga-institute-of-industrial-technology-bhubaneswar",
        },
        {
          name: "Bennett University",
          link: "/college-details/bennett-university-greater-noida",
        },
      ],
      resource: [],
    },
    stream: "Law",
  },
  {
    data: {
      college: [
        {
          name: "S.R.M Institute of Science and Technology",
          link: "/college-details/srm-university-chennai-srm-institute-of-science-and-technology-chennai",
        },
        {
          name: "Hindustan Institute of Technology and Science",
          link: "/college-details/hits-chennai-hindustan-institute-of-technology-and-science-chennai",
        },
        {
          name: "Manipal Academy of Higher Education",
          link: "/college-details/manipal-academy-of-higher-education-mahe-manipal-academy-of-higher-education-manipal",
        },
        {
          name: "Sathyabama Institute of Science and Technology",
          link: "/college-details/sathyabama-university-sathyabama-institute-of-science-and-technology-chennai",
        },
      ],
      exam: [
        { name: "NATA", link: "/exam-details/nata" },
        { name: "AP EAPCET", link: "/exam-details/AP-EAPCET" },
      ],
      specialization: [],
      resource: [],
    },
    stream: "Architecture & Planning",
  },
  {
    data: {
      college: [
        {
          name: "Parul University",
          link: "/college-details/parul-university-vadodara",
        },
      ],
      exam: [{ name: "AP EAPCET", link: "/exam-details/AP-EAPCET" }],
      specialization: [],
      resource: [
        { name: "Horticulture", link: "/course-details/horticulture" },
        {
          name: "Agricultural and Irrigation Engineering",
          link: "/course-details/agricultural-and-irrigation-engineering",
        },
      ],
    },
    stream: "Agriculture",
  },
  {
    data: {
      college: [
        {
          name: "Chinmaya Vishwavidyapeeth",
          link: "/college-details/chinmaya-vishwavidyapeeth-ernakulam",
        },
        {
          name: "Manav Rachna International Institute of Research and Studies",
          link: "/college-details/mriirs-faridabad-manav-rachna-international-institute-of-research-and-studies-faridabad",
        },
        {
          name: "ICFAI Foundation for Higher Education",
          link: "/college-details/dr.-m.g.r.-educational-and-research-institute-chennai",
        },
        {
          name: "Parul University",
          link: "/college-details/parul-university-vadodara",
        },
        {
          name: "BML Munjal University",
          link: "/college-details/bml-munjal-university-gurgaon",
        },
      ],
      exam: [
        { name: "CUET UG", link: "/exam-details/cuet-ug" },
        { name: "CUET PG", link: "/exam-details/cuet-pg" },
        { name: "IIT JAM", link: "/exam-details/iit-jam" },
        { name: "UGC NET", link: "/exam-details/ugc-net" },
      ],
      specialization: [
        { name: "Graphic Designer", link: "/career-details/graphic-designer" },
        { name: "Journalist", link: "/career-details/journalist" },
        { name: "Fashion Designer", link: "/career-details/fashion-designer" },
        { name: "Art Director", link: "/career-details/art-director" },
        { name: "Cinematographer", link: "/career-details/cinematographer" },
        {
          name: "Social Media Influencer",
          link: "/career-details/social-media-influencer",
        },
        { name: "Accountant", link: "/career-details/accountant" },
      ],
      resource: [{ name: "Archaeology", link: "/course-details/archaeology" }],
    },
    stream: "Liberal Arts",
  },
  {
    data: {
      college: [
        {
          name: "Manipal Academy of Higher Education",
          link: "/college-details/manipal-academy-of-higher-education-mahe-manipal-academy-of-higher-education-manipal",
        },
        {
          name: "Chandigarh University, Punjab",
          link: "/college-details/chandigarh-university-punjab",
        },
        {
          name: "Jain University",
          link: "/college-details/jain-deemed-to-be-university-bangalore-jain-deemed-to-be-university-bangalore",
        },
        {
          name: "UPES",
          link: "/college-details/upes-university-of-petroleum-and-energy-studies",
        },
      ],
      exam: [{ name: "NCHM JEE", link: "/exam-details/nchm-jee" }],
      specialization: [
        { name: "Chef", link: "/career-details/chef" },
        { name: "Pilot", link: "/career-details/pilot" },
        { name: "Hotel Manager", link: "/career-details/hotel-manager" },
      ],
      resource: [
        { name: "Hotel Management", link: "/course-details/hotel-management" },
        {
          name: "Travel and Tourism",
          link: "/course-details/travel-and-tourism",
        },
      ],
    },
    stream: "Hospitality",
  },
];

const indianCollegesWithLinks = indianColleges.map((college) => ({
  name: college,
  link: "/college-details",
}));

const isLargeScreen = window.innerWidth > 1280;

const FindPerfectCollege = () => {
  // const data = useSelector(
  //   (state: RootState) => state.collegeLanding.perfectCollege
  // );

  const [backgroundColor, setBackgroundColor] = useState(
    cardsList[0].colorDark
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const changeSelectedTabIndex = (index: number) => setSelectedTabIndex(index);

  if (!data || data.length <= 0) return;

  return (
    <div
      style={{
        background: isLargeScreen
          ? `linear-gradient(to bottom, transparent 60%, ${backgroundColor} 40%)`
          : "transparent",
      }}
    >
      <div className="container text-black py-12 max-sm:py-8">
        <div className="flex flex-col lg:flex-row lg:items-end gap-6 pb-12 max-sm:pb-4">
          <div className="flex-[2_2_0%]">
            <h3 className="text-3xl lg:text-5xl font-bold">
              Find the Perfect College For You
            </h3>
            <p className="text-base text-grey">
              Explore the leading College Profiles, Rankings & Accreditations.
            </p>
          </div>
          <div className="flex-1">
            <SearchBarWithSuggestion data={indianCollegesWithLinks} />
          </div>
        </div>
        <TabSelectorV1
          tabList={data.map((item) => item.stream)}
          activeTab={selectedTabIndex}
          changeActiveTab={changeSelectedTabIndex}
        />
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
          {cardsList.map((card) => {
            let title = card.title(data[selectedTabIndex].stream);
            return (
              <Card
                title={title}
                list={data[selectedTabIndex].data[card.key]}
                colorDark={card.colorDark}
                colorLight={card.colorLight}
                icon={card.icon}
                handleHover={(color) => setBackgroundColor(color)}
              />
            );
          })}
        </div>
        <Link
          to="/list-of-colleges-in-india"
          className="block w-fit mt-6 mx-auto bg-green text-white text-lg font-bold px-6 py-2 rounded-full"
        >
          View all
        </Link>
      </div>
    </div>
  );
};

export default FindPerfectCollege;

interface CardProps {
  title: string;
  list: { name: string; link?: string }[];
  colorDark: string;
  colorLight: string;
  icon: string;
  handleHover: (color: string) => void;
}

const Card: FC<CardProps> = ({
  title,
  list,
  colorDark,
  colorLight,
  icon,
  handleHover,
}) => {
  const SIZE = 8;
  const totalCount = list?.length;
  const totalPages = Math.ceil(totalCount / SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const start = currentPage * SIZE;
  const listItemsToShow = list?.slice(start, start + SIZE);

  return (
    <div
      className="w-full p-4 flex flex-col gap-4"
      style={{ backgroundColor: colorDark }}
      onMouseEnter={() => handleHover(colorDark)}
    >
      <h5 className="text-xl font-bold">{title}</h5>
      <div className="flex-1">
        <div style={{ backgroundColor: colorLight }}>
          {listItemsToShow?.map((item) =>
            item.link ? (
              <Link
                to={item.link}
                className="p-2 flex items-center gap-2"
                style={{ borderBottom: `2px solid ${colorDark}` }}
              >
                <img src={icon} alt="icon" />
                <span className="hover:text-blue">{item.name}</span>
              </Link>
            ) : (
              <div
                className="p-2 flex items-center gap-2"
                style={{ borderBottom: `2px solid ${colorDark}` }}
              >
                <img src={icon} alt="icon" />
                <a href={item.link} className="hover:text-blue">
                  {item.name}
                </a>
              </div>
            )
          )}
        </div>
      </div>
      <div className="flex gap-4 items-center justify-center text-grey">
        <button
          onClick={() =>
            setCurrentPage((prev) => (totalPages + prev - 1) % totalPages)
          }
        >
          <img src={leftArrowIcon} className="w-4" alt="leftArrowIcon" />
        </button>
        <span>{currentPage + 1}</span>
        <div className="w-24 h-1 bg-grey">
          <div
            className="h-full bg-blue"
            style={{
              width: ((currentPage + 1) / totalPages) * 100 + "%",
            }}
          ></div>
        </div>
        <span>{totalPages}</span>
        <button
          onClick={() => setCurrentPage((prev) => (prev + 1) % totalPages)}
        >
          <img src={rightArrowIcon} className="w-4" alt="rightArrowIcon" />
        </button>
      </div>
    </div>
  );
};
