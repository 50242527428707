import React from "react";
import profileImagesOne from "../../../../images/exams/dreamer/profileImg-1.png";
import profileImagesTwo from "../../../../images/exams/dreamer/profileImg-2.png";
import universityLogoOne from "../../../../images/exams/dreamer/amity-university.png";
import universityLogoTwo from "../../../../images/exams/dreamer/university-school.png";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "./DreamersToAchiever.scss";
import { Autoplay } from "swiper/modules";

import { Pagination } from "swiper/modules";
interface StudentInfoProps {
  paragraph: string;
  StudentInfomation: {
    name: string;
    designation: string;
    score: number;
    universityLogo: string;
    profileImage: string;
  }[];
  targetScrorer: string[];
}

const StudentInfoContent: StudentInfoProps[] = [
  {
    paragraph:
      "Tell us about yourself to get a personalised call with our GMAT expert.",
    StudentInfomation: [
      {
        name: "John Doe",
        designation: "Software Developer",
        score: 95,
        universityLogo: universityLogoOne,
        profileImage: profileImagesOne,
      },
      {
        name: "Jane Doe",
        designation: "Software Developer",
        score: 760,
        universityLogo: universityLogoOne,
        profileImage: profileImagesTwo,
      },
      {
        name: "Jane Doe",
        designation: "Software Developer",
        score: 760,
        universityLogo: universityLogoOne,
        profileImage: profileImagesTwo,
      },
    ],
    targetScrorer: ["720+", "680 - 750"],
  },
];

const StudentInfo: React.FC = () => {
  return (
    <div className="bg-black p-0 px-5 w-full">
      <div className="flex flex-col items-center gap-3">
        <Swiper
          slidesPerView={1}
          className="w-full StudentInfoProps"
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {StudentInfoContent.map((content, index) => (
            <>
              {content.StudentInfomation.map((student, index) => (
                <SwiperSlide key={index}>
                  <div className="flex flex-row items-start">
                    <div className="flex w-full relative">
                      <div className="md:absolute min-max-xss:w-[180px] max-md:w-[120%] min-max-sm:translate-x-9 md:w-[120%] lg:w-80 xl:w-64 2xl:w-60">
                        <img
                          src={student.profileImage}
                          alt="profile"
                          className="w-full h-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col min-max-xss:-translate-x-10  items-end justify-end gap-2 md:gap-3">
                      <div className="flex flex-col justify-end">
                        <p className="text-white text-base md:text-xl font-semibold leading-8 text-right">
                          I scored <br />
                          <strong className="text-green text-xl md:text-2xl">
                            {student.score}
                            <br /> on GMAT
                          </strong>
                        </p>
                      </div>
                      <div className="universityLogoDeamser">
                        <img
                          src={student.universityLogo}
                          alt="university"
                          className="w-full h-full"
                        />
                      </div>

                      <div className="flex flex-col text-right justify-end w-full relative">
                        <h3 className="text-grey-4 text-sm md:text-base font-semibold">
                          {student.name}
                        </h3>
                        <p className="text-grey-4 text-sm md:text-base font-semibold">
                          {student.designation}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </>
          ))}
        </Swiper>
        {StudentInfoContent.map((content, index) => (
          <div className="flex flex-col items-start justify-start gap-4">
            <div className="flex flex-col items-start justify-start gap-8">
              <p className="text-grey-3 text-base font-medium leading-5 text-center">
                {content.paragraph}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center gap-8 w-full">
              <div className="flex flex-col items-center bg-white rounded-3xl w-full p-2 md:p-3 gap-2">
                <h4 className="text-black text-lg font-medium leading-6 text-center">
                  What is your target score?
                </h4>
                <div className="flex flex-col items-center justify-center gap-2 w-full">
                  {content.targetScrorer.map((target, index) => (
                    <>
                      <button
                        className={`p-2 rounded-full text-white text-base w-full font-semibold ${
                          index === 0
                            ? "bg-green"
                            : index === 1
                            ? "bg-black"
                            : "bg-green-500"
                        }`}
                      >
                        {target}
                      </button>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentInfo;
