export const categories = [
  {
    title: "Architecture",
    icon: "🏛️",
    items: [
      {
        text: "Architecture Courses",
        url: "/courses/list-of-architecture-courses-in-india?sort-by=popularity",
      },
      { text: "Mentor Connect", url: "/mentors" },
      { text: "Architecture Exams", url: "/list-of-exams-in-india" },
      { text: "Scholarships", url: "/scholarships" },
      {
        text: "Architecture Predictor",
        url: "/college-predictor?predictor-tools=Architecture",
      },
      { text: "NIRF Rankings", url: "/rankings" },
      { text: "Architecture Colleges", url: "/list-of-colleges-in-india?" },
      { text: "News & Articles", url: "/blogs" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
  {
    title: "Engineering",
    icon: "⚙️",
    items: [
      {
        text: "Engineering Courses",
        url: "/courses/list-of-engineering-courses-in-india?sort-by=popularity",
      },
      { text: "Mentor Connect", url: "/mentors" },
      { text: "Engineering Exams", url: "/list-of-exams-in-india" },
      { text: "Scholarships", url: "/scholarships" },
      {
        text: "Engineering Rank Predictors",
        url: "/college-predictor?predictor-tools=Engineering",
      },
      { text: "NIRF Rankings", url: "/rankings" },
      // { text: "Engineering Deemed to be Universities", url: "#" },
      { text: "News & Articles", url: "/blogs" },
      // { text: "Engineering State Private Universities", url: "#" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      { text: "Engineering Colleges", url: "/list-of-colleges-in-india?" },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
  {
    title: "Medical",
    icon: "💙",
    items: [
      {
        text: "Medical Courses",
        url: "/courses/list-of-medicine-and-allied-sciences-courses-in-india?sort-by=popularity",
      },
      { text: "Mentor Connect", url: "/mentors" },
      // { text: "Medical Council", url: "#" },
      { text: "Scholarships", url: "/scholarships" },
      { text: "Medical Exams", url: "/list-of-exams-in-india" },
      { text: "NIRF Rankings", url: "/rankings" },
      {
        text: "Medical Rank Predictor",
        url: "/college-predictor?predictor-tools=Medicine+and+Allied+Sciences",
      },
      { text: "News & Articles", url: "/blogs" },
      // { text: "Medical Deemed to be Universities", url: "#" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      // { text: "Medical State Private Universities", url: "#" },
      { text: "Medical Colleges in India", url: "/list-of-colleges-in-india?" },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
  {
    title: "Management",
    icon: "📊",
    items: [
      {
        text: "Management Courses",
        url: "/courses/list-of-management-and-business-administration-courses-in-india?sort-by=popularity",
      },
      { text: "Mentor Connect", url: "/mentors" },
      // { text: "Management Council", url: "#" },
      { text: "Scholarships", url: "/scholarships" },
      { text: "Management Exams", url: "/list-of-exams-in-india" },
      { text: "NIRF Rankings", url: "/rankings" },
      {
        text: "Management Rank Predictor",
        url: "/college-predictor?predictor-tools=Management+and+Business+Administration",
      },
      { text: "News & Articles", url: "/blogs" },
      // { text: "Management Deemed to be Universities", url: "#" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      // { text: "Management State Private Universities", url: "#" },
      {
        text: "Management Colleges in India",
        url: "/list-of-colleges-in-india?",
      },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
  {
    title: "Law",
    icon: "⚖️",
    items: [
      {
        text: "Law Courses",
        url: "/courses/list-of-law-courses-in-india?sort-by=popularity",
      },
      { text: "Mentor Connect", url: "/mentors" },
      // { text: "Engineering Council", url: "#" },
      { text: "Scholarships", url: "/scholarships" },
      { text: "Law Exams", url: "/list-of-exams-in-india" },
      { text: "NIRF Rankings", url: "/rankings" },
      {
        text: "Law Rank Predictor",
        url: "/college-predictor?predictor-tools=Law",
      },
      { text: "News & Articles", url: "/blogs" },
      // { text: "Law Deemed to be Universities", url: "#" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      // { text: "Law State Private Universities", url: "#" },
      { text: "Law Colleges in India", url: "/list-of-colleges-in-india?" },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
  {
    title: "Liberal Arts",
    icon: "🎭",
    items: [
      // { text: "Liberal Arts Courses", url: "#" },
      { text: "Mentor Connect", url: "/mentors" },
      // { text: "Liberal Arts Council", url: "#" },
      { text: "Scholarships", url: "/scholarships" },
      // { text: "Liberal Arts Exams", url: "#" },
      { text: "NIRF Rankings", url: "/rankings" },
      // { text: "Liberal Arts Rank Predictor", url: "#" },
      { text: "News & Articles", url: "/blogs" },
      // { text: "Liberal Arts Deemed to be Universities", url: "#" },
      { text: "Admissions @ 8959591818", url: "/applications-admissions" },
      // { text: "Liberal Arts State Private Universities", url: "#" },
      // { text: "Liberal Arts Colleges in India", url: "#" },
      { text: "Compare Colleges", url: "/college-compare" },
    ],
  },
];
