import React from "react";

const Mentorship: React.FC = () => {
  return (
    <div className="bg-[#EEFFFF] pt-8 md:px-12 md:pb-14  max-md:pb-9">
      <div className="container flex flex-col lg:flex-row gap-8 mx-auto relative my-auto">
        <div className="w-full md:w-1/2 my-auto max-lg:w-full">
          <div className="lg:max-w-[456px] max-lg:w-full ">
            <h3 className="font-medium text-[40px] max-md:text-3xl leading-10 md:leading-[60px] max-md:text-center">
              Come for the Mentorship,
              <br /> Stay for the Friendships
            </h3>
            <h3 className="mt-5 font-medium text-[40px] max-md:text-3xl leading-14 max-md:text-center">
              Got a Burning Question?
            </h3>
            <h3 className="my-1 text-[#173CBA] text-[50px] max-md:text-4xl font-light max-md:text-center">
              We're here to help
            </h3>
            <p className="mt-2 font-sans leading-8 max-md:text-center">
              Curious about something? Connect with current students to get
              answers to all your questions about your dream colleges, courses,
              scholarships, placements, research & innovation.
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/2 relative min-max-xss:gap-[65px] flex flex-row gap-[93px] min-max-md:gap-[179px] max-md:[31px] items-center max-md:w-full max-md:h-auto min-max-md:w-full ">
          <div className="my-auto relative max-md:w-[130px] max-md:z-10 border-[#EEFFFF] border-1 hover:border-[#0c9]">
            <img
              src="/images/student-one.webp"
              className="max-md:w-[130px] rounded-full border-4 border-transparent hover:border-[#0c9] box-border transition-all"
              alt="Aspiring Student"
            />

            <button className="bg-[#173CBA] w-[205px] max-md:w-[140px] h-10 px-6 max-md:px-2 py-1 text-white text-sm font-semibold flex justify-center items-center rounded-[50px] absolute -bottom-5 left-1/2 transform -translate-x-1/2">
              Aspiring Student
            </button>
          </div>

          <div className="relative flex flex-col items-center gap-[179px] max-md:gap-[141px]">
            <div className="relative max-md:w-[130px]">
              <img
                src="/images/student-three.webp"
                alt="Student Buddy Mentor"
                className="max-md:w-[130px] rounded-full border-4 border-transparent hover:border-[#0c9] box-border transition-all"
              />
              <button className="bg-[#173CBA] w-[205px]  max-md:left-1/2 h-10 px-6 py-1 lg:left-52 min-max-md:left-1/2  text-white text-sm font-semibold flex justify-center items-center rounded-[50px] absolute -bottom-5  transform -translate-x-1/2">
                Student Buddy Mentor
              </button>
            </div>
            <div className="relative max-md:w-[130px]">
              <img
                src="/images/student-two.webp"
                alt="Admission Buddy Mentor"
                className="max-md:w-[130px] rounded-full border-4 border-transparent hover:border-[#0c9] box-border transition-all"
              />
              <button className="bg-[#173CBA]  max-md:left-1/2 w-56 h-10 px-6 min-max-md:left-1/2 py-1 text-white text-sm font-semibold flex justify-center items-center rounded-[50px] absolute -bottom-5 lg:left-52 transform -translate-x-1/2">
                Admission Buddy Mentor
              </button>
            </div>
          </div>

          <div className="absolute md:top-[42%] md:left-40 rounded-xl px-[10px] py-1 bg-white shadow-xl w-[465px] min-h-20 z-10 max-md:bottom-0 max-md:hidden">
            <p className="text-xs font-bold">
              Got a question? Seeking Advice from Current Students
            </p>
            <div className="flex flex-wrap gap-1 text-xs text-slate-200 mt-1">
              <span className="bg-[#987284] px-2 py-1 rounded-md">
                Majors & Minors
              </span>
              <span className="bg-[#BE93D0] px-2 py-1 rounded-md">
                Placements
              </span>
              <span className="bg-[#D4A373] px-2 py-1 rounded-md">
                Univ Clubs and Events
              </span>
              <span className="bg-[#D5AA9F] px-2 py-1 rounded-md">
                Campus Safety
              </span>
              <span className="bg-[#6B8F71] px-2 py-1 rounded-md">
                Course Fees & Scholarships
              </span>
              <span className="bg-[#D4AC0D] px-2 py-1 rounded-md">
                Admission Timelines
              </span>
              <span className="bg-[#E88E96] px-2 py-1 rounded-md">
                International Students
              </span>
            </div>
          </div>

          <div className="absolute left-48 max-md:left-28 top-[20%] max-md:top-[13%] flex gap-[88px] flex-col max-md:gap-[75px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71"
              height="116"
              viewBox="0 0 71 116"
              fill="none"
              className="top-[20%]"
            >
              <path
                d="M70.6233 0.97883L53.6461 4.41054L65.1066 17.3973L70.6233 0.97883ZM3.29829 115.023C6.86269 94.6151 23.3963 47.2813 61.5379 10.9956L59.4701 8.82203C20.7677 45.6413 3.9931 93.6085 0.343031 114.507L3.29829 115.023Z"
                fill="#00CC99"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71"
              height="115"
              viewBox="0 0 71 115"
              fill="none"
            >
              <path
                d="M70.7757 114.693L65.1218 98.3217L53.7704 111.404L70.7757 114.693ZM61.6172 104.763C41.9703 85.9523 13.0608 48.842 3.062 0.832188L0.12502 1.44386C10.2859 50.2318 39.617 87.8525 59.5425 106.93L61.6172 104.763Z"
                fill="#00CC99"
              />
            </svg>
          </div>
        </div>
        <div className="rounded-xl px-2 py-1 flex flex-wrap bg-white shadow-xl max-md:w-full z-10 max-md:bottom-0 md:hidden">
          <p className="text-xs font-bold">
            Got a question? Seeking Advice from Current Students
          </p>
          <div className="flex flex-wrap gap-1 text-xs text-slate-200 mt-1 max-md:gap-[2px]">
            <span className="bg-[#987284] px-2 max-md:px-1 py-1 rounded-md">
              Majors & Minors
            </span>
            <span className="bg-[#BE93D0] px-2  max-md:px-1 py-1 rounded-md">
              Placements
            </span>
            <span className="bg-[#D4A373] px-2 max-md:px-1 py-1 rounded-md">
              Univ Clubs and Events
            </span>
            <span className="bg-[#D5AA9F] px-2 max-md:px-1 py-1 rounded-md">
              Campus Safety
            </span>
            <span className="bg-[#6B8F71] px-2 max-md:px-1 py-1 rounded-md">
              Course Fees & Scholarships
            </span>
            <span className="bg-[#D4AC0D] px-2 max-md:px-1 py-1 rounded-md">
              Admission Timelines
            </span>
            <span className="bg-[#E88E96] px-2 max-md:px-1 py-1 rounded-md">
              International Students
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentorship;
