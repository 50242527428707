import React, { useEffect } from "react";

interface ContentMapingProps {
  title: string;
  description: string;
}

const ContentMaping: React.FC<ContentMapingProps> = ({
  title,
  description,
}) => {
  useEffect(() => {
    document.querySelectorAll("p.MsoNormal").forEach((p) => {
      const paragraph = p as HTMLParagraphElement;

      if (
        paragraph.innerHTML.trim() === "&nbsp;" ||
        paragraph.textContent?.trim() === ""
      ) {
        paragraph.style.display = "none";
      }
    });
  }, [description]);
  return (
    <section className="latest-updates-section mb-8">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>

      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

export default ContentMaping;
