import React from "react";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface SectionProps {
  title?: string;
  description?: string;
  orderIndex?: string;
}

interface CampusFacilitiesTabProps {
  facilitiesData: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const FacilitySection: React.FC<SectionProps> = ({ title, description }) => {
  if (!title || !description) return null;

  return (
    <section className="facility-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const CampusFacilitiesTab: React.FC<CampusFacilitiesTabProps> = ({
  facilitiesData,
}) => {
  const similarColleges = facilitiesData.similarColleges as College[];
  const keys = Object.keys(facilitiesData)
    .filter((key) => key.startsWith("key_") && facilitiesData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (facilitiesData[a] as SectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (facilitiesData[b] as SectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            {keys.map((key) => {
              const section = facilitiesData[key] as SectionProps;
              return (
                <FacilitySection
                  key={key}
                  title={section?.title}
                  description={section?.description}
                />
              );
            })}
          </div>
          <div className="w-full lg:w-3/12 hidden lg:block">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <div className="container mx-auto mt-8">
            <SimilarEngineeringColleges colleges={similarColleges} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampusFacilitiesTab;
