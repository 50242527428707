export const CollegeAndRandPredictorData = [
  {
    id: 1,
    img: "/images/explore-rank-img.jpg",
    title: "Engineering Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
    predictorTools: "Engineering",
  },
  {
    id: 2,
    img: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/course-stream-medicineandscience.png",
    title: "Medicine Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
    predictorTools: "Medicine+and+Allied+Sciences",
  },
  {
    id: 3,
    img: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/course-stream-law.png",
    title: "Law Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
    predictorTools: "Law",
  },
  {
    id: 4,
    img: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/course-stream-animationanddesing.png",
    title: "Design Colleges Predictor",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
    predictorTools: "Animation+and+Design",
  },
  {
    id: 5,
    img: "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/course-stream-masscommunicationandjournalisum.png",
    title: "Media and Mass Communication",
    description:
      "Find your best-fit college with our easy-to-use College Predictor",
    predictorTools: "Media+and+Mass+Communication+and+Journalism",
  },
];

export const ExamLandingMockResponse = {
  featuredExams: {
    stats: [
      {
        count: 200,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-stats-upcomingexam.png",
        id: 1,
        title: "Upcoming Exams",
      },
      {
        count: 100,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featurred-stats-ActiveExams.png",
        id: 2,
        title: "Active Exams",
      },
      {
        count: 150,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/featured-stats-completed-exams.png",
        id: 3,
        title: "Completed Exams",
      },
    ],
    mostRecentExam: [
      {
        onlineExam: "April2025",
        examTitle: "SRM JEE -2025",
        examDate: "21st, 22nd",
        examName: "SRM JEE -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/SRM-JEE-Image.png",
        description: "Phase -1 21st , 22nd April-2025",
        id: 618,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/SRM-LOGO.png",
        applicationOpen: "Jan 10 - Mar 31st -2025",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "BITSAT -2025",
        examDate: "21st, 22nd",
        examName: "BITSAT -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/BITSAT-IMAGE.png",
        description:
          "Register once, Attempt Twice, Last Date to Apply -11th April 2025",
        id: 619,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/bits-pilani-logo.png",
        applicationOpen: "Jan 10 - Mar 31st -2025",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "VIT EEE -2025",
        examDate: "21st, 22nd",
        examName: "VIT EEE -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/VIT-Image.png",
        description: "B.Tech Admissions?31st march 2024",
        id: 620,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/VIT-Logo.png",
        applicationOpen: "Jan 10 - Mar 31st -2025",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "Woxsen -2025",
        examDate: "21st, 22nd",
        examName: "Woxsen -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/Woxsen-image.png",
        description: "Phase -1?30th June ,2025",
        id: 621,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/Woxsen%20logo.png",
        applicationOpen: "Jan 10 - Mar 31st -2025",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "MET -2025",
        examDate: "April 18-19",
        examName: "MET -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/manipal-image.webp",
        description: "UG Admissions Phase Exam - April 18-19",
        id: 622,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/manpal-logo.webp",
        applicationOpen: "Till March 15",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "TG EAMCET -2025",
        examDate: "April 2025",
        examName: "TG EAMCET -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/TG-EAPCET-image.webp",
        description: "UG Admissions Phase Exam - April 2025",
        id: 623,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/TG-EAPCET-logo.webp",
        applicationOpen: "Feb 25 to April 4",
        applicationCost: 1500,
      },
      {
        onlineExam: "April2025",
        examTitle: "AEEE -2025",
        examDate: "April 2025",
        examName: "AEEE -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/amrita-image.webp",
        description: "UG Admissions Phase Exam - April 2025",
        id: 624,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/Amrita-logo.webp",
        applicationOpen: "May 10 to 14",
        applicationCost: 1500,
      },
      {
        onlineExam: "May2025",
        examTitle: "NEET -2025",
        examDate: "May 04",
        examName: "NEET -2025",
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/neet-image.webp",
        description: "UG Admissions Phase Exam - May 04",
        id: 625,
        iconUrl:
          "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/Featured-Exams/neet-logo.webp",
        applicationOpen: "Feb 7 to March 7",
        applicationCost: 1500,
      },
    ],
  },
  downloads: [
    {
      subTitle: "CAT Question & Answers",
      downloads: 15,
      description: "CAT Question",
      iconUrl: "string", // Replace with actual URL if needed
      id: 2,
      title: "CAT",
      downloadUrl: "",
    },
    {
      subTitle: "JEE Mains Question & Answers",
      downloads: 15,
      description: "JEE Mains Question",
      iconUrl: "string", // Replace with actual URL if needed
      id: 3,
      title: "JEE Mains",
      downloadUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/freeDownloads/JEE%20Mains%202025%2024%20Jan%20Shift%201%20Question%20Paper%20with%20Solutions2.pdf",
    },
    {
      subTitle: "JEE Advance Question & Answers",
      downloads: 12,
      description: "JEE Advance Question",
      iconUrl: "string", // Replace with actual URL if needed
      id: 4,
      title: "JEE Advance",
      downloadUrl: "",
    },
    {
      subTitle: "CLAT Question & Answers",
      downloads: 12,
      description: "CLAT Question",
      iconUrl: "string", // Replace with actual URL if needed
      id: 5,
      title: "CLAT",
      downloadUrl: "",
    },
    {
      subTitle: "NATA Question & Answers",
      downloads: 12,
      description: "NATA Question",
      id: 9,
      title: "NATA",
      downloadUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/freeDownloads/NATA_2024_Question_Paper_6th.pdf",
    },
    {
      subTitle: "NIFT Question & Answers",
      downloads: 15,
      description: "NIFT Question",
      id: 10,
      title: "NIFT",
      downloadUrl: "",
    },
  ],
  examBanner: [
    {
      examTitle: "NEET UG",
      examName: "neet",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/NEET.png",
      id: 602,
      downloadUrl: "",
    },
    {
      examTitle: "JEE Advanced",
      examName: "JEE Advanced",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/JEE%20Advanced.png",
      id: 607,
      downloadUrl: "",
    },
    {
      examTitle: "CLAT",
      examName: "CLAT",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/CLAT.png",
      id: 609,
      downloadUrl: "",
    },
    {
      examTitle: "CUET",
      examName: "CUET",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/CUET.png",
      id: 610,
      downloadUrl: "",
    },
    {
      examTitle: "BITS PILANI",
      examName: "BITS PILANI",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/BITS%20PILANI.png",
      id: 611,
      downloadUrl: "",
    },
    {
      examTitle: "NATA",
      examName: "NATA",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/NATA.png",
      id: 612,
      downloadUrl: "",
    },
    {
      examTitle: "NIFT",
      examName: "NIFT",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/NIFT.png",
      id: 613,
      downloadUrl: "",
    },
    {
      examTitle: "CAT",
      examName: "CAT",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/CAT.png",
      id: 614,
      downloadUrl: "",
    },
    {
      examTitle: "GATE",
      examName: "GATE",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/GATE.png",
      id: 615,
      downloadUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/freeDownloads/Gate%20Aeronaitcal%202024%20question%20paper.pdf",
    },
    {
      examTitle: "CA",
      examName: "CA",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/exams/exam_landing/CA.png",
      id: 616,
      downloadUrl: "",
    },
  ],
};

const collegeLandingMock = {
  worldClass: [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/SRM%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/SRMUH-%20Where%20Experiential%20Learning%20Is%20A%20Joy!.mp4",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/Aditya%20University%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/aditya.mp4",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/Gitam%20University%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/gitam.mp4",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/woxsen%20University%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/Woxsen%20University%20Campus%20Tour%20_%20Mega%20Facilities%20I%20Bloomberg%20Finance%20Lab%20_%20Sports%20Arena%20&%20More.mp4",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/KL%20University%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/KL.mp4",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass/Manav%20Rachna%20University%20copy.jpg",
      logo: "https://i.ibb.co/7vR7Vw7/webinar1.png",
      video:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/worldclass%20video/manav.mp4",
    },
  ],
  statewiseColleges: {
    country: {
      image:
        "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/india%20gate.png",
      publicUniversities: 22,
      name: "India",
      deemedUniversities: 10,
      statePrivateUniversities: 15,
      collegeData: [
        {
          image: "https://i.ibb.co/ZKBGZPB/Karnataka.png",
          name: "chandigarh-university",
        },
        {
          image: "https://i.ibb.co/ZKBGZPB/Karnataka.png",
          name: "srm-university",
        },
        {
          image: "https://i.ibb.co/zhm9pT8/tamilnadu.png",
          name: "anna-university",
        },
        {
          image: "https://i.ibb.co/ZKBGZPB/Karnataka.png",
          name: "srm-1-university",
          collegeUrl: "#",
        },
        {
          image: "https://i.ibb.co/ZKBGZPB/Karnataka.png",
          name: "srm-1-university-1",
          collegeUrl: "#",
        },
      ],
    },
    states: [
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/tamilnadu.png",
        publicUniversities: 22,
        name: "Tamil Nadu",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 7,
            name: "anna-university",
          },
          {
            image: "https://i.ibb.co/fkZ5PFq/Admission-1.png",
            collegeId: 11,
            name: "srm-1-university",
            collegeUrl: "#",
          },
          {
            image: "https://i.ibb.co/fkZ5PFq/Admission-1.png",
            collegeId: 12,
            name: "srm-1-university-1",
            collegeUrl: "#",
          },
        ],
      },
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/karnataka.png",
        publicUniversities: 22,
        name: "Karnataka",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 5,
            name: "srm-university",
          },
        ],
      },
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/telangana.png",
        publicUniversities: 22,
        name: "Telangana",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 4,
            name: "chandigarh-university",
          },
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 6,
            name: "jnt-university",
          },
        ],
      },
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/tamilnadu.png",
        publicUniversities: 22,
        name: "Tamil Nadu",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 7,
            name: "anna-university",
          },
          {
            image: "https://i.ibb.co/fkZ5PFq/Admission-1.png",
            collegeId: 11,
            name: "srm-1-university",
            collegeUrl: "#",
          },
          {
            image: "https://i.ibb.co/fkZ5PFq/Admission-1.png",
            collegeId: 12,
            name: "srm-1-university-1",
            collegeUrl: "#",
          },
        ],
      },
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/karnataka.png",
        publicUniversities: 22,
        name: "Karnataka",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 5,
            name: "srm-university",
          },
        ],
      },
      {
        image:
          "https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/statewise/telangana.png",
        publicUniversities: 22,
        name: "Telangana",
        deemedUniversities: 10,
        statePrivateUniversities: 15,
        collegeData: [
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 4,
            name: "chandigarh-university",
          },
          {
            image: "https://i.ibb.co/pWTk2CQ/india-college-image.png",
            collegeId: 6,
            name: "jnt-university",
          },
        ],
      },
    ],
  },
  dreamsHappen: [
    {
      college: "Woxsen University",
      bg: "#F49FB6",
      name: "Kavya M",
      description:
        "College Mentor played a pivotal role in securing admission in BA Psychology course at Woxen University",
      bendPath: "TEAM",
      title: "Making Dreams Happen",
      bannerPath:
        "https://cdncollegementor.blob.core.windows.net/colleges/colleges-landing/making-dreamhappen-kavya.webp",
    },
    {
      college: "VIT University",
      bg: "#F4D2D2",
      name: "Rishitha P",
      description: "I enrolled in the prestigious university",
      bendPath: "TEAM",
      title: "Making Dreams Happen",
      bannerPath:
        "https://cdncollegementor.blob.core.windows.net/colleges/colleges-landing/making-dreamhappen-rishita.webp",
    },
    {
      college: "Aditya University",
      bg: "#A6E0DE",
      name: "Sudheer",
      description: "I have secured admission to Aditya University",
      bendPath: "TEAM",
      title: "Making Dreams Happen",
      bannerPath:
        "https://mentee-mentor-dev.vercel.app/images/College-landing/dreams/banner2.png",
    },
  ],
  cardsDreams: [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/career_landing/Mentor-2.png",
      role: "Admission Mentor",
      name: "M. Raja Shekar1",
      id: 2,
      experience: "10+Year Exp",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/career_landing/Mentor-1.png",
      role: "Student Buddy Mentor",
      name: "M. Kavya",
      id: 14,
      experience: "1 + Year",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/career_landing/Mentor-1.png",
      role: "Admission Mentor",
      name: "K. Madahan",
      id: 17,
      experience: "5 years",
    },
  ],
  featuredColleges: [
    {
      collegeLogo: "https://i.ibb.co/8N146d5/feature-1.png",
      image: "https://i.ibb.co/8N146d5/feature-1.png",
      url: "www.snu.edu.in",
    },
    {
      collegeLogo: "https://i.ibb.co/bv8F6HN/Featured-2.png",
      image: "https://i.ibb.co/bv8F6HN/Featured-2.png",
      url: "www.jgu.edu.in",
    },
    {
      collegeLogo:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Amity_01.jpg",
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Amity_01.jpg",
      url: "www.amity.edu/bengaluru",
    },
    {
      collegeLogo:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Alliance_01.png",
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Alliance_01.png",
      url: "www.snu.edu.in",
    },
    {
      collegeLogo:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Bennett%20university_01.jpg",
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/Bennett%20university_01.jpg",
      url: "www.bennett.edu.in",
    },
    {
      collegeLogo:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/JAIN.jpg",
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/JAIN.jpg",
      url: "www.jainuniversity.ac.in",
    },
    {
      collegeLogo:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/UPES.jpg",
      image:
        "https://cdncollegementor.blob.core.windows.net/application-addmission/Top-Colleges/UPES.jpg",
      url: "www.upes.ac.in",
    },
  ],
};

export default collegeLandingMock;

export const AlsoIncludesData = [
  "All Test Series",
  "Prev. Year Paper",
  "Practice",
  "Pro Live Tests",
  "Unlimited Test Re-Attempts",
];

export const ExamFiltersData = {
  filters: [
    {
      categoryName: "DEGREES",
      categoryKey: "DEGREES",
      options: [
        {
          name: "B.E / B.Tech",
          key: "be_btech",
        },
        {
          name: "M.E / M.Tech",
          key: "me_mtech",
        },
        {
          name: "Diploma",
          key: "diploma",
        },
        {
          name: "MBA",
          key: "mba",
        },
        {
          name: "M.Sc",
          key: "msc",
        },
      ],
    },
    {
      categoryName: "MODES",
      categoryKey: "MODES",
      options: [
        {
          name: "Online",
          key: "online",
        },
        {
          name: "Offline",
          key: "offline",
        },
      ],
    },
    {
      categoryName: "EDUCATION LEVELS",
      categoryKey: "EDUCATION_LEVELS",
      options: [
        {
          name: "UG",
          key: "ug",
        },
        {
          name: "PG",
          key: "pg",
        },
        {
          name: "Diploma",
          key: "diploma",
        },
      ],
    },
    {
      categoryName: "EXAMINATION TYPES",
      categoryKey: "EXAMINATION_TYPES",
      options: [
        {
          name: "University Level Exam - UG",
          key: "university_level_exam_ug",
        },
        {
          name: "State Level Exam - UG",
          key: "state_level_exam_ug",
        },
        {
          name: "State Level Exam - Diploma",
          key: "state_level_exam_diploma",
        },
        {
          name: "National Level Exam - UG",
          key: "national_level_exam_ug",
        },
        {
          name: "University Level Exam - PG",
          key: "university_level_exam_pg",
        },
        {
          name: "State Level Exam - PG",
          key: "state_level_exam_pg",
        },
        {
          name: "National Level Exam - PG",
          key: "national_level_exam_pg",
        },
        {
          name: "National Level Exam - Diploma",
          key: "national_level_exam_diploma",
        },
      ],
    },
    {
      categoryName: "EXAM CATEGORIES",
      categoryKey: "EXAM_CATEGORIES",
      options: [
        {
          name: "College Entrance Exams",
          key: "college_entrance_exams",
        },
      ],
    },
    {
      categoryName: "SORT BY",
      categoryKey: "SORT_BY",
      options: [
        {
          name: "Popularity",
          key: "popularity",
        },
        {
          name: "Application Date",
          key: "application_date",
        },
        {
          name: "Exam Date",
          key: "exam_date",
        },
        {
          name: "Result Date",
          key: "result_date",
        },
        {
          name: "Upcoming Event",
          key: "upcoming_event",
        },
      ],
    },
    {
      categoryName: "LINGUISTIC MEDIUMS",
      categoryKey: "LINGUISTIC_MEDIUMS",
      options: [
        {
          name: "English",
          key: "english",
        },
        {
          name: "Hindi",
          key: "hindi",
        },
        {
          name: "Telugu",
          key: "telugu",
        },
        {
          name: "Urdu",
          key: "urdu",
        },
      ],
    },
  ],
  data: {
    totalCount: 625,
    exams: [
      {
        name: "JEE MAIN - Joint Entrance Examination (Main)",
        status: "Opened",
        mode: "Online",
        level: "National level exam",
        frequency: "2 Times/Year",
        application: {
          openDates: "05-12-2024 to 31-03-2025",
          cost: 1000,
        },
        examDates: "06-04-2025 to 10-04-2025 (Phase 1 Exam)",
      },
      {
        name: "TEST MAIN - Joint Entrance Examination (Main)",
        status: "Opened",
        mode: "Online",
        level: "National level exam",
        frequency: "2 Times/Year",
        application: {
          openDates: "05-12-2024 to 31-03-2025",
          cost: 1000,
        },
        examDates: "06-04-2025 to 10-04-2025 (Phase 1 Exam)",
      },
    ],
  },
};

export const FiltersData = {
  filters: [
    {
      categoryName: "SORT BY",
      categoryKey: "SORT_BY",
      options: [
        {
          name: "A to Z",
          key: "a_to_z",
        },
        {
          name: "Z to A",
          key: "z_to_a",
        },
        {
          name: "Featured Colleges",
          key: "featured_colleges",
        },
        {
          name: "Direct Admission",
          key: "direct_admission",
        },
      ],
    },
    {
      categoryName: "UNIVERSITIES",
      categoryKey: "UNIVERSITIES",
      options: [
        {
          name: "Central Universities",
          key: "central_universities",
        },
        {
          name: "Deemed Universities",
          key: "deemed_universities",
        },
        {
          name: "State Government Universities",
          key: "state_government_universities",
        },
        {
          name: "State Private Universities",
          key: "state_private_universities",
        },
        {
          name: "Autonomous Colleges",
          key: "autonomous_colleges",
        },
        {
          name: "Affiliated Colleges",
          key: "affiliated_colleges",
        },
      ],
    },
    {
      categoryName: "RANKING ACCREDITATIONS",
      categoryKey: "RANKING_ACCREDITATIONS",
      options: [
        {
          name: "NIRF Ranking",
          key: "nirf_ranking",
        },
        {
          name: "NAAC Grade",
          key: "naac_grade",
        },
        {
          name: "NBA Accreditation",
          key: "nba_accreditation",
        },
        {
          name: "Institutes of Eminence (IOE)",
          key: "institutes_of_eminence_ioe",
        },
        {
          name: "International Rankings",
          key: "international_rankings",
        },
        {
          name: "QS Ranking",
          key: "qs_ranking",
        },
        {
          name: "World Ranking",
          key: "world_ranking",
        },
        {
          name: "Asia Ranking",
          key: "asia_ranking",
        },
        {
          name: "Indian Colleges Ranking",
          key: "indian_colleges_ranking",
        },
        {
          name: "ARIIA Ranking",
          key: "ariia_ranking",
        },
        {
          name: "Times Higher Education Ranking",
          key: "times_higher_education_ranking",
        },
        {
          name: "Outlook Ranking",
          key: "outlook_ranking",
        },
        {
          name: "Career 360 Ranking",
          key: "career_360_ranking",
        },
      ],
    },
    {
      categoryName: "STATE/UT",
      categoryKey: "STATE_UT",
      options: [
        {
          name: "Andhra Pradesh",
          key: "andhra_pradesh",
        },
        {
          name: "Arunachal Pradesh",
          key: "arunachal_pradesh",
        },
        {
          name: "Assam",
          key: "assam",
        },
        {
          name: "Bihar",
          key: "bihar",
        },
        {
          name: "Chhattisgarh",
          key: "chhattisgarh",
        },
        {
          name: "Delhi",
          key: "delhi",
        },
        {
          name: "Goa",
          key: "goa",
        },
        {
          name: "Gujarat",
          key: "gujarat",
        },
      ],
    },
    {
      categoryName: "CITIES",
      categoryKey: "CITIES",
      options: [
        {
          name: "Mumbai",
          key: "mumbai",
        },
        {
          name: "Bengaluru",
          key: "bengaluru",
        },
        {
          name: "Hyderabad",
          key: "hyderabad",
        },
        {
          name: "Pune",
          key: "pune",
        },
        {
          name: "Chennai",
          key: "chennai",
        },
        {
          name: "Surat",
          key: "surat",
        },
      ],
    },
    {
      categoryName: "DEGREES",
      categoryKey: "DEGREES",
      options: [
        {
          name: "Diploma",
          key: "diploma",
        },
        {
          name: "BE/B-Tech",
          key: "be_btech",
        },
        {
          name: "ME/M-Tech",
          key: "me_mtech",
        },
        {
          name: "M.Tech Ph.D",
          key: "mtech_phd",
        },
        {
          name: "B-Arch",
          key: "b_arch",
        },
        {
          name: "M-Arch",
          key: "m_arch",
        },
        {
          name: "B-Tech + M-Tech (Integrated)",
          key: "btech_mtech_integrated",
        },
        {
          name: "B-Tech + MBA",
          key: "btech_mba",
        },
        {
          name: "BSc",
          key: "bsc",
        },
      ],
    },
    {
      categoryName: "ENTRANCE EXAMINATIONS",
      categoryKey: "ENTRANCE_EXAMINATIONS",
      options: [
        {
          name: "JEE Mains",
          key: "jee_mains",
        },
        {
          name: "JEE Advanced",
          key: "jee_advanced",
        },
        {
          name: "AP EAPCET",
          key: "ap_eapcet",
        },
        {
          name: "TS EAPCET",
          key: "ts_eapcet",
        },
        {
          name: "CUET",
          key: "cuet",
        },
      ],
    },
    {
      categoryName: "FEE RANGES",
      categoryKey: "FEE_RANGES",
      options: [
        {
          name: "\u003C 1 lac",
          key: "under_1_lac",
        },
        {
          name: "1 - 2 lac",
          key: "1_to_2_lac",
        },
        {
          name: "2 - 3 lac",
          key: "2_to_3_lac",
        },
        {
          name: "3 - 4 lac",
          key: "3_to_4_lac",
        },
        {
          name: "4 - 5 lac",
          key: "4_to_5_lac",
        },
      ],
    },
    {
      categoryName: "SCHOLARSHIPS",
      categoryKey: "SCHOLARSHIPS",
      options: [
        {
          name: "10%",
          key: "10_percent",
        },
        {
          name: "11 - 25%",
          key: "11_to_25_percent",
        },
        {
          name: "26 - 50%",
          key: "26_to_50_percent",
        },
        {
          name: "51 - 75%",
          key: "51_to_75_percent",
        },
        {
          name: "76 - 100%",
          key: "76_to_100_percent",
        },
      ],
    },
    {
      categoryName: "EDUCATION MODES",
      categoryKey: "EDUCATION_MODES",
      options: [
        {
          name: "Full time",
          key: "full_time",
        },
        {
          name: "Part-time",
          key: "part_time",
        },
        {
          name: "Online",
          key: "online",
        },
        {
          name: "Distance",
          key: "distance",
        },
        {
          name: "Hybrid",
          key: "hybrid",
        },
      ],
    },
    {
      categoryName: "GENDER DIVERSITY",
      categoryKey: "GENDER_DIVERSITY",
      options: [
        {
          name: "Co-Education",
          key: "co_education",
        },
        {
          name: "Girls",
          key: "girls",
        },
        {
          name: "Boys",
          key: "boys",
        },
      ],
    },
    {
      categoryName: "MINORITY AFFILIATIONS",
      categoryKey: "MINORITY_AFFILIATIONS",
      options: [
        {
          name: "Muslim",
          key: "muslim",
        },
        {
          name: "Christain",
          key: "christian",
        },
        {
          name: "Jain",
          key: "jain",
        },
        {
          name: "Sikh",
          key: "sikh",
        },
        {
          name: "Parsi",
          key: "parsi",
        },
      ],
    },
  ],
  data: [
    {
      college_ranking: "#NIRF 38 College in India",
      college_name: "SRM Institute of Science and Technology, Madras",
      location: "Chennai, Tamil Nadu",
      type: "Private",
      details: {
        courses: ["B-Tech", "M-Tech", "MBA", "B.S", "M.A", "PH.D"],
        exams: ["JEE Advanced", "2"],
      },
      actions: [
        "Admission & Eligibility",
        "Placement",
        "Facilities",
        "Compare",
        "Request Campus Visit",
        "Virtual Tour",
        "Apply Now",
      ],
      brochure: "Download link",
      additional_info: {
        accreditations: ["NAAC A++", "ABET", "NBA", "ICAR"],
        nirf: {
          overall: 32,
          university: 18,
          engineering: 28,
        },
        qs_ranking: {
          world: "1000 - 1200",
          asia: 162,
          india: 17,
        },
        times_higher_education: {
          world: "1201 - 1500",
          asia: "401 - 500",
        },
        shanghai_ranking: {
          world: "801 - 900",
          india: "9 - 12",
        },
      },
    },
    {
      college_ranking: "#NIRF 38 College in India",
      college_name: "SRM Institute of Science and Technology, Madras",
      location: "Chennai, Tamil Nadu",
      type: "Private",
      details: {
        courses: ["B-Tech", "M-Tech", "MBA", "B.S", "M.A", "PH.D"],
        exams: ["JEE Advanced", "+2"],
      },
      actions: [
        {
          name: "Admission & Eligibility",
          link: "https://example.com/admission-eligibility",
        },
        {
          name: "Placement",
          link: "https://example.com/placement",
        },
        {
          name: "Facilities",
          link: "https://example.com/facilities",
        },
        {
          name: "Compare",
          link: "https://example.com/compare",
        },
        {
          name: "Request Campus Visit",
          link: "https://example.com/request-campus-visit",
        },
        {
          name: "Virtual Tour",
          link: "https://example.com/virtual-tour",
        },
        {
          name: "Apply Now",
          link: "https://example.com/apply-now",
        },
      ],
      brochure: "Download link",
      placeholders_info: {
        accreditations: ["NAAC A++", "ABET", "NBA", "ICAR"],
        nirf: {
          overall: 32,
          university: 18,
          engineering: 28,
        },
        qs_ranking: {
          world: "1000 - 1200",
          asia: 162,
          india: 17,
        },
        times_higher_education: {
          world: "1201 - 1500",
          asia: "401 - 500",
        },
        shanghai_ranking: {
          world: "801 - 900",
          india: "9 - 12",
        },
      },
    },
  ],
};
