import React, { useEffect, useState } from "react";
import DesktopFilters, { FilterCategory } from "./DesktopFilters";
import MobileFilters from "./MobileFilters";

interface FiltersSectionProps {
  filters: FilterCategory[];
  setSelectedVal: (selected: any) => void;
  setFilters: (filters: FilterCategory[]) => void;
  mobileFilter: boolean;
  handleMobileFilterClose: () => void;
  fetchData: () => void;
}

const FiltersSection: React.FC<FiltersSectionProps> = ({
  filters,
  setSelectedVal,
  setFilters,
  mobileFilter,
  handleMobileFilterClose,
  fetchData,
}) => {
  const [isMediumDevice, setIsMediumDevice] = useState<boolean>(
    window.innerWidth >= 768
  );

  useEffect(() => {
    const handleResize = () => setIsMediumDevice(window.innerWidth >= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredFilters = filters.filter(
    (filter) => filter.categoryKey !== "sortBy"
  );

  return (
    <div>
      {!isMediumDevice && mobileFilter && (
        <MobileFilters
          filters={filteredFilters}
          setSelectedVal={setSelectedVal}
          setFilters={setFilters}
          handleMobileFilterClose={handleMobileFilterClose}
        />
      )}
      {isMediumDevice && (
        <DesktopFilters
          filters={filteredFilters}
          setSelectedVal={setSelectedVal}
          setFilters={setFilters}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default FiltersSection;
