import React, { useEffect, useState } from "react";
import "./Banner.scss";
import SearchBox from "./SearchBox";
import BannerLines from "../../../../images/exams/banner/bannerBgLines.svg";
import UserProfilesOne from "../../../../images/exams/banner/user-profiles-1.png";
import UserProfilesTwo from "../../../../images/exams/banner/user-profiles-2.png";

interface BannerProps {
  title: string;
  paragraph?: string;
  userProfile?: string[];
  reviewTest?: string;
}

interface LogosProps {
  data: {
    examBanner: Array<{
      examName: string;
      imageUrl: string;
      id: number;
    }>;
  };
}

const BannerContent: BannerProps[] = [
  {
    title:
      "Unlock Your Potential <br/><strong>One <span class='blueColor px-1'>Life</span><br/> One <span class='lightGreenColor px-1'>Dream</span><br/> Go <span class='lightPinkColor px-1'>Achieve</span></strong>",
    paragraph:
      "Stress free preparation for Exam. Accelerate your Success with College Mentor",
    userProfile: [
      UserProfilesOne,
      UserProfilesTwo,
      UserProfilesOne,
      UserProfilesTwo,
    ],
    reviewTest: "College Mentor Scholarship Test",
  },
];

const ExamBanner: React.FC<LogosProps> = ({ data }) => {
  const [shuffledLogos, setShuffledLogos] = useState(data?.examBanner || []);

  useEffect(() => {
    const shuffleArray = (array: typeof shuffledLogos) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    setShuffledLogos((prevLogos) => shuffleArray([...prevLogos]));
  }, [data]);

  return (
    <div className="flex bg-[#BAF4F2] py-12 md:py-12 max-sm:py-4">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between gap-5 max-sm:gap-1">
          {BannerContent.map((content) => (
            <div
              className="flex items-center w-full bannerSearchBox"
              key={content.title}
            >
              <div className="flex gap-5 flex-col">
                <h1
                  className="text-3xl lg:text-4xl 2xl:text-[64px] font-light leading-10 lg:leading-[47px] 2xl:leading-[80px] text-[--body-color] bannerStyles"
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />
                <p className="text-base lg:w-[374px] text-[#747474] text-left">
                  {content.paragraph}
                </p>
                <div className="flex gap-5 items-center">
                  <a href="/college-mentor-scholarship">
                    <p className="text-[#173CBA] text-sm font-semibold">
                      {content.reviewTest}
                    </p>
                  </a>
                  <div className="flex relative whitespace-nowrap text-left">
                    {content.userProfile?.map((profile, index) => (
                      <div
                        key={index}
                        className={`w-10 h-10 overflow-hidden -mr-3 inline-block relative hover:z-10 z-${
                          index + 1
                        }`}
                      >
                        <img
                          src={profile}
                          alt="user-profile"
                          className="w-full h-full rounded-full m-0 object-cover border-2 border-white"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex gap-5 items-center">
                  <SearchBox />
                </div>
              </div>
            </div>
          ))}
          <div className="w-full bannerSectionLogos">
            <div className="flex gap-[22px] max-sm:gap-[16px] justify-center items-center flex-wrap flex-col bg-no-repeat bg-center relative">
              <div className="BannerLines">
                <img src={BannerLines} alt="lines" className="w-full h-full" />
              </div>

              <div className="h-24 w-full heightSec"></div>
              <div className="relative z-10 flex gap-[22px] justify-center items-center flex-wrap flex-col bannerSection">
                <div className="flex flex-row items-center examBannerRows">
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[0]?.imageUrl}
                      alt={shuffledLogos[0]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[1]?.imageUrl}
                      alt={shuffledLogos[1]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center examBannerRows">
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[2]?.imageUrl}
                      alt={shuffledLogos[2]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[3]?.imageUrl}
                      alt={shuffledLogos[3]?.examName}
                      className="examsBannerImg"
                    />
                  </div>

                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[4]?.imageUrl}
                      alt={shuffledLogos[4]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center examBannerRows">
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[5]?.imageUrl}
                      alt={shuffledLogos[5]?.examName}
                      className="examsBannerImg"
                    />
                  </div>

                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[6]?.imageUrl}
                      alt={shuffledLogos[6]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[7]?.imageUrl}
                      alt={shuffledLogos[7]?.examName}
                      className="examsBannerImg cat-logo"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[8]?.imageUrl}
                      alt={shuffledLogos[8]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center lastExamBannerRows">
                  <div className="flex flex-col items-center justify-center bg-white overflow-hidden examBannerLogos gap-2">
                    <img
                      src={shuffledLogos[9]?.imageUrl}
                      alt={shuffledLogos[9]?.examName}
                      className="examsBannerImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamBanner;
