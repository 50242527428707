import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ClgLogo from "../../images/application/adminstrative.jpg";
import sathyabamaImg from "../../images/application/sathyabama-institute.png";
import type { SVGProps } from "react";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";
import { Link } from "react-router-dom";
import collageListBGImg from "../../images/application/collagelist-bg-img.jpeg";
import "./application-admission-styles.scss";
import FiltersSidebar from "./components/FiltersSidebar";
import adityaUiniversity from "../../images/application/adityaUiniversity.jpeg";
import UPESlogo from "../../images/application/UPES-logo.png";
import lovelyProfessional from "../../images/application/lovely-professional.png";
import WoxsenUniversity from "../../images/application/WoxsenUniversity.png";
import bennetUniversity from "../../images/application/bennetUniversity.png";
import chandigarhUniversity from "../../images/application/chandigarhUniversity.png";
import saveethaInstitute from "../../images/application/saveethaInstitute.png";
import rachanaUniversity from "../../images/application/rachanaUniversity.png";
export function MdiFilter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14 12v7.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-2.01-2.01a.99.99 0 0 1-.29-.83V12h-.03L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L14.03 12z"
      ></path>
    </svg>
  );
}
export function MdiTickCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8z"
      ></path>
    </svg>
  );
}
type FiltersType = Record<string, string[]>;

const universityData = {
  filters: {
    goal: ["Colleges", "Exams", "Coaching", "Study Abroad"],
    educationStream: [
      "Animation and Design",
      "Media Mass Communication and Journalism",
      "Arts Humanities and Social Sciences",
      "Sciences",
      "Commerce",
      "Computer Application and IT",
      "Pharmacy",
      "Education",
    ],
    level: [
      "School (Class 8-11)",
      "Class 12 (Targeting UG)",
      "Targeting PG Courses",
    ],
    degree: [
      "Diploma",
      "B.E /B.Tech",
      "B.Arch",
      "B.Plan",
      "Other Bachelors",
      "M.E /M.Tech",
      "M.Arch",
      "M.Plan",
      "M.S",
    ],
    targetYear: ["2024", "2025", "2026"],
    state: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Delhi",
    ],
  },
  universities: [
    {
      name: "Amrita Vishwa Vidyapeetham",
      logo: ClgLogo,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Exams, Colleges",
      details: [
        "**NAAC A++ Grade & NBA Accredited**",
        "**Ranked No.1** in The Impact Rankings 2024",
        "**Ranked No.4** in private universities by **ARIIA**",
        "Ranked among **1000 - 1200** in **QS World University Rankings**",
        "Esteemed Recruiters: **Arista, Ericsson, Amazon, Bosch, McKinsey & Company, Microsoft, Nissan**",
        "**Ranked among 2% of Top Researchers**",
        "**100+ MoUs** across multiple countries and International Student Exchange Programs",
        "**Scholarships and Awards** for Academic Excellence",
      ],
      targetYear: "2024, 2026, 2025",
      educationStream: "Sciences",
      state: "Tamil Nadu",
      level: "SchoolClass8-11, Class 12 (Targeting UG)",
      degree: "Diploma",
    },
    {
      name: "Aditya University",
      logo: adityaUiniversity,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Exams, Coaching",
      details: [
        "**NIRF A++ Grade** & NBA Accredited",
        "NIRF rank band 201 - 300",
        "Rank band 26 - 50 by **ARIIA**",
        "Nearly 10 students got placed with >30 lakhs Package",
        "**Ranked among 2% of Top Researchers**",
        "Esteemed Recruiters: Wallmart, Hexaware, DXC, Tech Mahindra, Yamaha, Jio, ICICI Bank",
        "Students also Internships at Google",
        "Upto 100% merit Scholarship for Academic Excellence",
      ],
      targetYear: "2024, 2026",
      educationStream: "Sciences",
      state: "Tamil Nadu",
      level: "Class12 (Targeting UG)",
      degree: "Diploma",
    },
    {
      name: "Sathyabama Institute of Science and Technology",
      logo: sathyabamaImg,
      goal: "Colleges, Coaching",
      accreditation: "NAAC A++ Grade & NBA Accredited",
      details: [
        "**NAAC 'A++'** grade",
        "Ranked 401 - 450 by the QS Asia University Rankings, 2024",
        "Ranked 51 among Universities in NIRF Rankings",
        "Esteemed Recruiters: **Oracle and Deloitte, Ernst & Young, Amazon, Accenture, Capgemini, Cognizant, HCL and IBM**",
        "Offers Merit based Scholarships for Students",
        "Multiple Industrial and Academic Collaborations",
        "> 500 Published Patents",
      ],
      targetYear: "2024, 2026, 2025",
      educationStream: "Engineering",
      state: "Andhra Pradesh",
      level: "Class12 (Targeting UG)",
      degree: "Diploma",
    },
    {
      name: "UPES University of Petroleum and Energy Studies",
      logo: UPESlogo,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Colleges, Exams, Coaching",
      details: [
        "**NAAC A** grade & NBA Accredition",
        "**Rank 1 - Academic Reputation** for Private University in India as per **QS World University Rankings, 2025**",
        "Rank - 46 in NIRF Rank, 2024",
        "THE World ranking ranked UPES as 7th in India",
        "Nearly 75 startups from students got Grants",
        "Esteemed Recruiters: **Design Direction, Elephant Design, Desmania, TATA Elexi, Titan, Samsung, and Philips.**",
        "70+ tie-ups with global Universities",
        "45 Faculty among the world's Top 2% of University",
      ],
      targetYear: "2024, 2026, 2025",
      educationStream: "Engineering, Colleges",
      state: "Andhra Pradesh",
      level: "Targeting PG Courses",
      degree: "Diploma",
    },
    {
      name: "Lovely Professional University",
      logo: lovelyProfessional,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      details: [
        "**A++ grade by NAAC & NBA** Accreditation",
        "THE World University Rankings - **Rank 9** in Indian Universities",
        "**Ranked 27** by NIRF 2024 Ranking",
        "Esteemed Recruiters: **Bosch, Business Today, Cognizant, Informatica, Infosys, Capgemini, KnowledgeReview and SHL**",
        "Overall 1700+ students got placed in dream packages",
        "Scholarships and Awards for Academic Excellence",
        "100+ start-ups incubated through LPU",
        "Offers International Credit Transfer Program",
      ],
      targetYear: "2026",
      educationStream: "Engineering",
      state: "Andhra Pradesh",
      level: "Targeting PG Courses",
      degree: "Diploma",
    },
    {
      name: "Woxsen University",
      logo: WoxsenUniversity,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Study Abroad, Colleges",
      details: [
        "**NAAC A++ Grade & NBA Accredited**",
        "Ranked **401 - 450** by the QS Asia University Rankings, 2024",
        "Ranked **51** among Universities in **NIRF Rankings**",
        "Esteemed Recruiters: **Oracle, Deloitte, Ernst & Young, Amazon, Accenture, Capgemini, Cognizant, HCL, IBM**",
        "Offers **Merit-based Scholarships** for Students",
        "**Multiple Industrial and Academic Collaborations**",
        "**500+ Published Patents**",
      ],
      targetYear: "2026",
      educationStream: "Pharmacy, Commerce",
      state: "Delhi, Andhra Pradesh",
      level: "School (Class 8-11)",
      degree: "B.E /B.Tech, Other Bachelors",
    },
    {
      name: "Bennet University",
      logo: bennetUniversity,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Himachal Pradesh, Coaching",
      details: [
        "**NAAC A+** grade",
        "Adobe, Amazon, Deloitte, Goldman Sachs, Google, Microsoft, TCS, and Walmart.",
        "In 2023, Bennett University was ranked 51st for BBA and 82nd for Engineering in India Today's rankings.",
        "In 2022, Bennett University was ranked 2nd in the Times Engineering Ranking.",
        "In 2022, Bennett University was ranked 3rd in the IIRF's ranking of the top BBA colleges in India.",
      ],
      targetYear: "2026",
      educationStream: "Pharmacy, Computer Application and IT",
      state: "Chhattisgarh",
      level: "School (Class 8-11)",
      degree: "M.Plan, M.S",
    },
    {
      name: "Chandigarh University",
      logo: chandigarhUniversity,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Study Abroad",
      details: [
        "**NAAC A+ Grade**",
        "it secured the **#32 rank in the 'Overall' category**",
        "Along with an impressive 120th overall rank in Asia, Chandigarh University clinches the #1 position among all the Private Universities in India at the QS Asia University Rankings 2025.",
        "Ranked #20 among The Best Universities In India By NIRF Rankings 2024",
        "Ranked #13 among The Best Institutions For Architecture & Planning In India By NIRF Rankings 2024",
        "It is ranked #691-700 in QS World University Rankings 2025.",
        "Amazon, Microsoft, Google, wipro, TCS, L&T, HCL",
        "**Ranked in the top 1.1% of universities in Asia.**",
      ],
      targetYear: "2026",
      educationStream: "Computer Application and IT",
      state: "Assam,Chhattisgarh",
      level: "School (Class 8-11)",
      degree: "B.Arch, B.E /B.Tech",
    },
    {
      name: "Saveetha Institute of Medical and Technical Sciences",
      logo: saveethaInstitute,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Study Abroad, Exams, Coaching",
      details: [
        "SIMATS Engineering is accredited for the UG Programs by the NBA",
        "**NAAC A++** Grade",
        "SIMATS Engineering has achieved the top rank and is awarded the prestigious **PLATINUM** by **QS I - GUAGE**.",
        "SIMATS Engineering was ranked in 'Band A' (6-25 rank) among private/self-financed colleges in the Atal Ranking of Institutions on Innovation Achievements (ARIIA) 2020.",
        "Cognizant, Accenture, Capgemini, TCS, Bosch, Virtusa, HCL, Amazon, Mahendra",
        "SIMATS is ranked 26 out of 1000 private and public universities",
        "identified the top 2% of the most-cited scientists",
      ],
      targetYear: "2024, 2026",
      educationStream: "Sciences, Animation and Design",
      state: "Delhi, Bihar, Assam",
      level: "School (Class 8-11)",
      degree: "B.Arch, B.E /B.Tech",
    },
    {
      name: "Manav Rachana University",
      logo: rachanaUniversity,
      accreditation: "NAAC A++ Grade & NBA Accredited",
      goal: "Study Abroad, Coaching",
      details: [
        "Naac Grade A++",
        "IMPACT RANKING: MRIIRS was ranked 92nd in the Universities category in the 2024 NIRF India Rankings.",
        "MRU was ranked 5th in Delhi NCR and 20th in North India in the 2024 THE WEEK rankings.",
        "MRU was ranked 79th in the Engineering (Private) category in the 2024 India Today rankings.",
        "WORLD UNIVERSITY RANKING: In the QS Asia University Rankings 2024, MRIIRS was ranked 258 in the Southern Asia category.",
        "**In the QS International Trade Rankings 2024, MRIIRS was ranked in the 151-175 band.**",
        "In the Online Learning Rankings 2024, MRIIRS was awarded gold status and received an outcomes score of 64.1.",
        "In the NIRF rankings, MRIIRS was ranked in the 101-150 band in India in 2024.",
      ],
      targetYear: "2026",
      educationStream: "Education",
      state: "Haryana, Gujarat",
      level: "Targeting PG Courses",
      degree: "Diploma, B.E /B.Tech",
    },
  ],
};

const ApplicationToAdmissionFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FiltersType>({
    goal: [],
    educationStream: [],
    targetYear: [],
    state: [],
    degree: [],
    level: [],
  });
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  useEffect(() => {
    const getFiltersFromURL = (): FiltersType => ({
      goal: searchParams.get("goal")?.split(",") || [],
      degree: searchParams.get("degree")?.split(",") || [],
      level: searchParams.get("level")?.split(",") || [],
      educationStream: searchParams.get("educationStream")?.split(",") || [],
      targetYear: searchParams.get("targetYear")?.split(",") || [],
      state: searchParams.get("state")?.split(",") || [],
    });
    setFilters(getFiltersFromURL());
  }, [searchParams]);

  const filteredUniversities = useMemo(() => {
    return universityData.universities.filter((university) => {
      // Convert comma-separated string into an array
      const universityGoals =
        university.goal?.split(",").map((g) => g.trim()) || [];
      const universityStreams =
        university.educationStream?.split(",").map((s) => s.trim()) || [];
      const universityTargetYears =
        university.targetYear?.split(",").map((y) => y.trim()) || [];
      const universityStates =
        university.state?.split(",").map((s) => s.trim()) || [];
      const universityDegrees =
        university.degree?.split(",").map((d) => d.trim()) || [];
      const universityLevels =
        university.level?.split(",").map((l) => l.trim()) || [];

      return (
        (!filters.goal.length || universityGoals.includes(filters.goal[0])) && // Single selection
        (!filters.educationStream.length ||
          universityStreams.includes(filters.educationStream[0])) && // Single selection
        (!filters.targetYear.length ||
          universityTargetYears.includes(filters.targetYear[0])) && // Single selection
        (!filters.state.length ||
          filters.state.some((state) => universityStates.includes(state))) && // Multi-selection
        (!filters.degree.length ||
          universityDegrees.includes(filters.degree[0])) && // Single selection
        (!filters.level.length || universityLevels.includes(filters.level[0])) // Single selection
      );
    });
  }, [filters]);

  const handleCheckboxChange = (category: keyof FiltersType, value: string) => {
    setFilters((prev) => {
      const isSingleSelection =
        category === "goal" ||
        category === "educationStream" ||
        category === "level" ||
        category === "degree" ||
        category === "targetYear";

      const updatedCategory = isSingleSelection
        ? [value]
        : prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value];

      const newParams = new URLSearchParams(searchParams);
      updatedCategory.length > 0
        ? newParams.set(category, updatedCategory.join(","))
        : newParams.delete(category);
      setSearchParams(newParams);

      return { ...prev, [category]: updatedCategory };
    });
  };
  const handleRemoveFilter = (category: keyof FiltersType, value: string) => {
    setFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [category]: prev[category].filter((item) => item !== value),
      };

      const newParams = new URLSearchParams(searchParams);
      if (updatedFilters[category].length > 0) {
        newParams.set(category, updatedFilters[category].join(","));
      } else {
        newParams.delete(category);
      }
      setSearchParams(newParams);

      return updatedFilters;
    });
  };
  const renderFormattedText = (text: string) => {
    const formattedText = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    return { __html: formattedText };
  };

  return (
    <div className="p-4">
      <div className="flex flex-col items-end md:hidden">
        <button
          className=" p-2 bg-blue text-white text-lg font-semibold rounded-md"
          onClick={() => setShowMobileFilter(true)}
        >
          <MdiFilter />
        </button>
      </div>
      <div className="flex gap-4 justify-start items-start">
        <div className="hidden md:block w-1/3 bg-blue p-4 text-white rounded-md">
          <FiltersSidebar
            filters={filters}
            setFilters={setFilters}
            handleCheckboxChange={handleCheckboxChange}
            universityData={universityData}
          />
        </div>

        <div className="w-full md:w-2/3">
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap gap-2">
              {Object.keys(filters).map((category) =>
                filters[category as keyof FiltersType].map((item) => (
                  <div
                    key={item}
                    className="bg-white text-black py-1.5 px-3 flex items-center space-x-2 shadow-lg border border-[#7C7C7C]"
                  >
                    <span>{item}</span>
                    <button
                      className=" text-black"
                      onClick={() =>
                        handleRemoveFilter(category as keyof FiltersType, item)
                      }
                    >
                      ✕
                    </button>
                  </div>
                ))
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {filteredUniversities.length > 0 ? (
                filteredUniversities.map((university, index) => (
                  <div
                    key={index}
                    className={`relative border border-[#7C7C7C] p-4 rounded-sm shadow-lg bg-white bg-cover bg-center bg-no-repeat bg-opacity-10 application-admission`}
                    style={{
                      backgroundImage: `url(${collageListBGImg})`,
                    }}
                  >
                    <div className="flex flex-col justify-between gap-5 h-full relative z-10">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <div className="w-32 h-auto overflow-hidden p-2 bg-white">
                            <img
                              src={university.logo}
                              alt={university.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="bg-blue p-2 w-full">
                            <h3 className="font-semibold text-white text-sm leading-5">
                              {university.name}
                            </h3>
                          </div>
                        </div>
                        <hr className="bg-green w-full h-[1px] border-transparent my-2" />

                        <div className="flex flex-col mt-2">
                          <ul className="list-none text-sm flex flex-col gap-3">
                            {university.details.map((detail, idx) => (
                              <li key={idx} className="flex items-start gap-2">
                                <span>
                                  <MdiTickCircle className="text-blue w-4 h-4" />
                                </span>
                                <span
                                  className="text-black text-sm"
                                  dangerouslySetInnerHTML={renderFormattedText(
                                    detail
                                  )}
                                ></span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="flex lex-row gap-3 justify-center">
                        <LeadGenCtaBtn
                          pageName="home-landing"
                          ctaName="dream-colleges-apply-now"
                          className=" bg-green border-green text-white py-[14px] px-[34px]  hover:bg-blue hover:text-white rounded-sm"
                        >
                          Apply Now
                        </LeadGenCtaBtn>
                        <Link
                          to={"/applications-admissions-explore-college"}
                          className=" bg-white border-green border text-green py-[14px] px-[34px]  hover:bg-green hover:text-white rounded-sm"
                        >
                          Know More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">
                  No universities match the selected filters.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {showMobileFilter && (
          <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[1111] top-0 h-full">
            <div className="bg-blue w-full max-w-lg p-6 rounded-lg h-full overflow-y-auto">
              <button
                className="absolute top-4 right-6 text-white text-xl"
                onClick={() => setShowMobileFilter(false)}
              >
                ✕
              </button>
              <div className="mt-4 overflow-y-auto">
                <FiltersSidebar
                  filters={filters}
                  setFilters={setFilters}
                  handleCheckboxChange={handleCheckboxChange}
                  universityData={universityData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationToAdmissionFilter;
