import React, { useState, useEffect } from "react";
import MeettopMentorsImage from "../../images/courses/meet-top-mentors.png";
import "./MeetTopMentors.scss";
import { Link } from "react-router-dom";
import QuizWidget, { QuizQuestion } from "./QuizWidget";
import Modal from "../Modal";
import { useNavigate, useLocation } from "react-router-dom";

const quizQuestions: QuizQuestion[] = [
  {
    question: "What subjects did you enjoy most in 12th grade?",
    options: [
      {
        answer: "Mathematics and Physics",
        suggestion:
          "Consider Engineering, Computer Science, or Physical Sciences.",
      },
      {
        answer: "Biology and Chemistry",
        suggestion:
          "Look into Medicine, Biotechnology, or Environmental Science.",
      },
      {
        answer: "Economics and Business Studies",
        suggestion: "Explore Economics, Commerce, or Business Administration.",
      },
      {
        answer: "Literature and History",
        suggestion: "Consider Literature, Arts, Journalism, or History.",
      },
    ],
  },
  {
    question: "How long are you willing to study before starting your career?",
    options: [
      {
        answer: "3-4 years",
        suggestion:
          "Courses like Engineering, Commerce, Computer Science, Arts.",
      },
      {
        answer: "5-6 years",
        suggestion: "Courses like Medicine, Law, Architecture.",
      },
      {
        answer: "Flexible",
        suggestion:
          "Combination programs or dual degrees in Business, Law, or research-focused fields.",
      },
    ],
  },
  {
    question:
      "Are you interested in courses that offer high international mobility?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Business Administration, Engineering, Medicine, IT.",
      },
      {
        answer: "Maybe",
        suggestion: "Explore Environmental Science, Social Work, Education.",
      },
      {
        answer: "Not a priority",
        suggestion:
          "Consider Regional Studies, Public Administration, Indian Law.",
      },
    ],
  },
  {
    question: "What kind of impact do you want your career to have?",
    options: [
      {
        answer: "Innovation and technology advancement",
        suggestion: "Consider Computer Science, Engineering, Data Science.",
      },
      {
        answer: "Health and well-being of others",
        suggestion: "Consider Medicine, Nursing, Public Health.",
      },
      {
        answer: "Social and community development",
        suggestion: "Consider Psychology, Social Work, Public Policy.",
      },
      {
        answer: "Creativity and cultural enrichment",
        suggestion: "Consider Fine Arts, Journalism, Media Studies.",
      },
    ],
  },
  {
    question: "What is your interest level in working with data and analytics?",
    options: [
      {
        answer: "High",
        suggestion: "Consider Data Science, Economics, Actuarial Science.",
      },
      {
        answer: "Moderate",
        suggestion: "Consider Business Analytics, Marketing, Social Sciences.",
      },
      {
        answer: "Low",
        suggestion: "Consider Performing Arts, Humanities, Social Work.",
      },
    ],
  },
  {
    question: "What kind of course duration suits your learning style?",
    options: [
      {
        answer: "Short-term courses",
        suggestion:
          "Consider Diplomas in Web Design, Animation, Culinary Arts.",
      },
      {
        answer: "Bachelor’s degree (3-4 years)",
        suggestion: "Consider B.A., B.Sc., B.Com. in various fields.",
      },
      {
        answer: "Professional degree with extended training",
        suggestion: "Consider Medicine, Architecture, Law.",
      },
    ],
  },
  {
    question:
      "Are you looking for a course with a structured curriculum or a flexible one with many electives?",
    options: [
      {
        answer: "Structured",
        suggestion: "Consider Law, Engineering, Medicine.",
      },
      {
        answer: "Flexible",
        suggestion: "Consider Liberal Arts, Humanities, Business.",
      },
    ],
  },
  {
    question:
      "What type of work-life balance do you envision after your studies?",
    options: [
      {
        answer: "Intense, high-responsibility roles",
        suggestion: "Consider Medicine, Law, Aerospace Engineering.",
      },
      {
        answer: "Balanced, 9-5 jobs",
        suggestion: "Consider Business, Information Technology, Education.",
      },
      {
        answer: "Flexible or freelance work",
        suggestion:
          "Consider Graphic Design, Freelance Writing, Digital Marketing.",
      },
    ],
  },
  {
    question:
      "Are you comfortable with high-pressure, fast-paced environments?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Finance, Media, Healthcare (especially emergency services).",
      },
      {
        answer: "Sometimes",
        suggestion: "Consider Business Management, Marketing, Social Sciences.",
      },
      {
        answer: "No",
        suggestion: "Consider Teaching, Research, Environmental Studies.",
      },
    ],
  },
  {
    question:
      "What’s your interest in courses that involve significant teamwork and collaboration?",
    options: [
      {
        answer: "High",
        suggestion: "Consider Business, Engineering, Social Work.",
      },
      {
        answer: "Moderate",
        suggestion: "Consider Healthcare, Education, Environmental Science.",
      },
      {
        answer: "Low",
        suggestion:
          "Consider Literature, Mathematics, Research-oriented fields.",
      },
    ],
  },
  {
    question:
      "What’s your preference for a career that includes travel or fieldwork?",
    options: [
      {
        answer: "Regular travel",
        suggestion:
          "Consider Geology, Environmental Science, Hospitality, International Business.",
      },
      {
        answer: "Occasional travel",
        suggestion: "Consider Marketing, Journalism, Consultancy.",
      },
      {
        answer: "Stationary",
        suggestion: "Consider Computer Science, Finance, Local Governance.",
      },
    ],
  },
  {
    question: "How important is creativity in your future career?",
    options: [
      {
        answer: "Very important",
        suggestion:
          "Consider Design, Media Studies, Architecture, Performing Arts.",
      },
      {
        answer: "Somewhat important",
        suggestion: "Consider Marketing, Business Development, Engineering.",
      },
      {
        answer: "Not a priority",
        suggestion: "Consider Accounting, Data Analysis, Technical Writing.",
      },
    ],
  },
  {
    question:
      "Are you interested in a career that combines your passion for sports with business skills?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Bachelor’s in Sports Management, combining sports, business, and marketing.",
      },
      {
        answer: "Somewhat",
        suggestion: "Consider broader business-focused programs.",
      },
      {
        answer: "No",
        suggestion: "Consider other fields outside sports management.",
      },
    ],
  },
  {
    question:
      "Do you enjoy creative storytelling and the idea of working in television, film, or digital media?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Bachelor’s in Media Studies or Mass Communication.",
      },
      {
        answer: "Somewhat",
        suggestion: "Explore other creative fields with a broader focus.",
      },
      {
        answer: "No",
        suggestion: "Consider analytical or structured work fields.",
      },
    ],
  },
  {
    question:
      "Are you inclined towards fashion, styling, and creating innovative designs?",
    options: [
      {
        answer: "Absolutely",
        suggestion:
          "Consider Bachelor’s in Fashion Design or Fashion Technology.",
      },
      { answer: "Somewhat", suggestion: "Consider broader creative fields." },
      {
        answer: "Not really",
        suggestion: "Explore other arts-related fields.",
      },
    ],
  },
  {
    question: "How interested are you in advocating for others?",
    options: [
      {
        answer: "Very interested",
        suggestion: "Consider Bachelor of Laws (LL.B.) or BA in Law.",
      },
      { answer: "Interested", suggestion: "Explore other social sciences." },
      { answer: "Not at all", suggestion: "Consider fields outside of law." },
    ],
  },
  {
    question:
      "Do you have a strong interest in aviation, physics, and the technicalities of flying aircraft?",
    options: [
      {
        answer: "Yes",
        suggestion: "Consider Aviation or Pilot Training Program.",
      },
      { answer: "Somewhat", suggestion: "Consider broader technical fields." },
      { answer: "No", suggestion: "Consider grounded professions." },
    ],
  },
  {
    question:
      "Are you interested in working on nuclear energy, technology, and safety?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Bachelor’s in Nuclear Engineering or Nuclear Physics.",
      },
      { answer: "Somewhat", suggestion: "Explore broader engineering fields." },
      {
        answer: "Not really",
        suggestion: "Consider fields outside nuclear science.",
      },
    ],
  },
  {
    question:
      "How interested are you in understanding human behavior, emotions, and mental health?",
    options: [
      {
        answer: "Very interested",
        suggestion: "Consider Bachelor’s in Psychology.",
      },
      { answer: "Somewhat", suggestion: "Explore social sciences." },
      { answer: "Not at all", suggestion: "Consider other fields." },
    ],
  },
  {
    question:
      "Do you enjoy studying multiple disciplines and want a broad-based education with flexibility?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Liberal Arts." },
      { answer: "Somewhat", suggestion: "Consider career-focused programs." },
      { answer: "No", suggestion: "Consider specialized fields." },
    ],
  },
  {
    question:
      "Are you interested in visual arts, creating artwork, and expressing yourself through fine arts?",
    options: [
      {
        answer: "Absolutely",
        suggestion: "Consider Bachelor’s in Fine Arts (BFA).",
      },
      { answer: "Somewhat", suggestion: "Explore broader creative fields." },
      { answer: "Not really", suggestion: "Explore structured careers." },
    ],
  },
  {
    question:
      "Do you enjoy working with numbers, analyzing financial trends, and managing business accounts?",
    options: [
      {
        answer: "Yes",
        suggestion:
          "Consider Bachelor’s in Commerce (B.Com.) or Bachelor’s in Finance.",
      },
      { answer: "Somewhat", suggestion: "Explore broader business education." },
      {
        answer: "Not really",
        suggestion: "Consider creative or analytical fields.",
      },
    ],
  },
];
const MeetTopMentorsContent = [
  {
    title: "Meet Top Mentors",
    highlight: "College Mentor",
    Description:
      " helps students pick the best courses and universities globally while also teaching important skills for today’s world. Receive personalized counselling from our College Mentors, who offer tailored guidance to students and parents alike, assisting with courses selection and providing invaluable direction.",
    videoLink: "https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1",
    button1: "Map My Mentors",
    button2: "Map My Course",
  },
];

const MeetTopMentors: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  const [quizPopupIsOpen, setQuizPopupIsOpen] = useState(false);
  const { pathname } = useLocation();
  const user = localStorage.getItem("user");
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  const handleQuiz = () => {
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      setQuizPopupIsOpen(true);
    }
  };

  return (
    <>
      <div className="bg-[#DFF4FF] flex py-20 max-md:py-5">
        <div className="container mx-auto">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-col lg:flex-row gap-10 lg:gap-16 items-center">
              {MeetTopMentorsContent.map((content) => (
                <>
                  <div className="flex flex-col gap-4 lg:gap-16 w-full lg:w-1/2">
                    <div className="top-mentors flex flex-col gap-3">
                      <h3 className="text-2xl lg:text-[40px] font-bold lg:leading-[58px] text-black uppercase">
                        {" "}
                        {content.title}
                      </h3>
                      <p className="text-lg font-light text-[--paragraph-color]">
                        <strong className="font-bold text-[--primary-color]">
                          {content.highlight}
                        </strong>{" "}
                        {content.Description}
                      </p>
                    </div>
                    <div className="flex gap-4">
                      <Link
                        to={"/mentors"}
                        className="white-color map-mentor arrow-button  button-styles flex flex-row items-center justify-center gap-2"
                      >
                        {content.button1}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="currentColor"
                          className="arrow-icon"
                        >
                          <path
                            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Link>
                      <button
                        className="white-color button-styles map-mentor arrow-button   flex flex-row items-center justify-center gap-2"
                        onClick={handleQuiz}
                      >
                        {content.button2}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="currentColor"
                          className="arrow-icon"
                        >
                          <path
                            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center w-full lg:w-1/2 relative">
                    {isPlaying ? (
                      <div
                        className="relative w-full h-0"
                        style={{ paddingBottom: "56.25%" }}
                      >
                        <iframe
                          width="100%"
                          height="100%"
                          src="https://cdncollegementor.blob.core.windows.net/mentors/Mentor%20Video.mp4"
                          title="YouTube Video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="absolute inset-0 w-full h-full rounded-lg"
                        ></iframe>
                      </div>
                    ) : (
                      <>
                        <button
                          className="watch-button"
                          onClick={handlePlayVideo}
                        ></button>
                        <img
                          src={MeettopMentorsImage}
                          alt="Meet-top-mentors"
                          className="w-full h-full object-cover rounded-lg cursor-pointer"
                          onClick={handlePlayVideo}
                        />
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MapMyCoursePopup
        isOpen={quizPopupIsOpen}
        onClose={() => {
          setQuizPopupIsOpen(false);
        }}
      />
    </>
  );
};

export default MeetTopMentors;

const MapMyCoursePopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget questions={quizQuestions} destinationLink="" />{" "}
    </Modal>
  );
};
