import { Outlet } from "react-router-dom";
import {
  faBars,
  faChartLine,
  faCalendar,
  faMessage,
  faBell,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapsibleSideBar from "./components/CollapsibleSideBar";
import MobileHeader from "./components/MobileHeader";
import Header from "./components/Header";
import companyLogo from "../images/cm-logo-white.png";
import UserAvatar from "../components/UserAvatar";
import UserIcon from "../images/authentication/avatar.jpg";

const isLargeDevice = window.innerWidth >= 1024;

const NAV_LINKS = [
  {
    name: "Profile",
    icon: <FontAwesomeIcon icon={faBars} />,
    link: "my-profile",
  },
  {
    name: "Admissions",
    icon: <FontAwesomeIcon icon={faChartLine} />,
    link: "college-admission",
  },
  {
    name: "Mentors",
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    link: "mentors",
  },
];

const DashboardLayout = () => {
  return (
    <>
      {isLargeDevice && (
        <div className="flex flex-col items-center justify-between pt-4 border-t border-gray-500 sm:flex-row bg-[#050038] text-white">
          <div className="container">
            <div className="flex flex-row items-center">
              <div className="mb-4 space-x-4 sm:mb-0">
                <a href="/">
                  <img src={companyLogo} alt="Logo" className="w-auto h-10" />
                </a>
              </div>
              <div className="text-center sm:text-right grow">
                <nav>
                  <ul className="flex items-end justify-end gap-12 list-none">
                    <li>
                      <a href="/colleges" className="">
                        Colleges
                      </a>
                    </li>
                    <li>
                      <a href="/courses" className="">
                        Courses
                      </a>
                    </li>
                    <li>
                      <a href="/exams" className="">
                        Exams
                      </a>
                    </li>
                    <li>
                      <a href="/careers" className="">
                        Careers
                      </a>
                    </li>
                    <li>
                      <UserAvatar link="#" logoutUrl={"/secure/login"} />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="">
              <div className="container mobileContainerNone">
                <div className="flex flex-row items-center my-6 text-white flex-nowrap">
                  <div className="w-24 h-24 rounded-full border-2 border-[#d9d9e5] p-[3px] mr-6 bg-gray-200 relative">
                    <div className="flex items-center justify-center rounded-full w-full h-full  ">
                      <img
                        src={UserIcon}
                        className="text-gray-700 w-full h-full rounded-full"
                      />
                    </div>
                  </div>
                  <div>
                    <h1 className="text-3xl font-semibold">
                      Welcome, <span></span>
                    </h1>
                    <div className="">
                      {/* <a href="/dashboard/my-profile">Manage your Profile </a> */}
                      <span>Manage your Profile</span>
                      {/* <span className="userProfileHeader_pipeSymbol___LbkP">
                      |{" "}
                    </span>
                    <a>Track your Admission Journey</a> */}
                    </div>
                  </div>
                </div>
                <div className="">
                  <section></section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-[#eef2f5] flex max-lg:flex-col h-screen">
        {isLargeDevice && <CollapsibleSideBar navLinks={NAV_LINKS} />}
        {!isLargeDevice && <MobileHeader navLinks={NAV_LINKS} />}

        <div className="flex-1 bg-[#eef2f5] h-full overflow-y-auto">
          {/* {isLargeDevice && <Header />} */}

          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
