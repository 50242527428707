import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { Banner } from "../../components/careers-comp/banner/Banner";
import FeaturedCareers from "../../components/careers-comp/featuredCareers/FeaturedCareers";
import HighPaidCareer from "../../components/careers-comp/highPaidCareer/HighPaidCareer";
import ConnectToMentor from "../../components/careers-comp/connectMentor/ConnectToMentor";
import KnowledgeHub from "../../components/careers-comp/knowledgeHub/KnowledgeHub";
import SubscribeNow from "../../components/courses/SubscribeNow";
import DreamCareerJourney from "../../components/careers-comp/dreamCareerJourney/DreamCareerJourney";
import RequestApplyNow from "../../components/careers-comp/requestApplyNow/RequestApplyNow";
import DreamCareers from "../../components/careers-comp/dreamCareers/DreamCareers";
import type { SVGProps } from "react";
import "animate.css";
import config from "../../util/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

export function IcBaselineError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3em"
      height="3em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m1 15h-2v-2h2zm0-4h-2V7h2z"
      ></path>
    </svg>
  );
}

// Define the RequestApplyNowData object here
const RequestApplyNowData = {
  requestInfo: {
    title: "Request Info",
    description: "Interested in knowing more about Career Scholarships?",
    buttontext: "Submit Request",
    buttonLink: "/career-intermediate",
  },
  applyNow: {
    title: "Apply Now",
    description: "Ready to start the Application to Admission Process?",
    buttontext: "Start Application",
    buttonLink: "/career-intermediate",
  },
};

const Careers = () => {
  const [careerData, setCareerData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [courseCareersData, setcourseCareersData] = useState<any>([]);
  const [blogsAndArticlesData, setBlogsAndArticlesData] = useState<any>([]);

  useEffect(() => {
    axios
      .get(config.apiUrl + `/api/career/landing-page`)
      .then((response) => {
        setCareerData(response.data);
        getBlogsandNews();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    document.title =
      "College Mentor | Find Best Careers for the Future & Top Paying Careers";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
      );
  }, []);

  const getBlogsandNews = () => {
    axios
      .get(config.apiUrl + `/api/blogs/blogsandnews`)
      .then((response) => {
        setcourseCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        prepareCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const prepareCareersData = (data: any) => {
    const filteredData = data.filter((item: any) =>
      item.subTopic.toLowerCase().includes("".toLowerCase())
    );
    setBlogsAndArticlesData(filteredData);
  };

  if (loading) {
    return <Loader />;
  }
  if (!careerData) {
    return (
      <div className="py-10 lg:py-16">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center">
            <IcBaselineError className="text-6xl text-red-500" />
            <h3 className="text-xl text-black animate-pulse">
              Error loading data...
            </h3>
          </div>
        </div>
      </div>
    );
  }

  const { Explore, Learn, Experience } = careerData.dreamCareerJourney || {};
  const {
    careerInterest,
    careerbulleyes,
    careerVisualStories,
    careerspotlights,
    blogsAndArticles,
  } = careerData.knowledgeHub || {};

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Find Best Careers for the Future & Top Paying
            Careers
          </title>
          <meta
            name="description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta
            name="keywords"
            content="Top paying careers, engineering, ai careers, up to date salary, compatability, college mentor, career list in 2 min, featured careers, dream career, careers repository, career listing, high paid career"
          />
          <link rel="canonical" href={`${config.apiUrl}/careers`} />

          <meta
            property="og:title"
            content="College Mentor | Find Best Careers for the Future & Top Paying Careers"
          />
          <meta
            property="og:description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Find Best Careers for the Future & Top Paying Careers"
          />
          <meta
            name="twitter:description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner bannerInfo={[]} {...careerData} />
        <FeaturedCareers featuredCareers={careerData.featuredCareers} />
        <DreamCareers dreamCareers={careerData.dreamCareers} />
        <SubscribeNow />
        <DreamCareerJourney
          Explore={Explore || []}
          Learn={Learn || []}
          Experience={Experience || []}
        />
        <HighPaidCareer highPaidCareer={careerData.highPaidCareer} />
        <ConnectToMentor connectToMentor={careerData.connectToMentor} />
        <KnowledgeHub
          careerInterest={careerInterest || []}
          careerbullseye={careerbulleyes || []}
          careerVisualStories={careerVisualStories || []}
          careerspotlights={careerspotlights || []}
          blogsAndArticles={blogsAndArticlesData || []}
        />
        <RequestApplyNow
          requestInfo={RequestApplyNowData.requestInfo}
          applyNow={RequestApplyNowData.applyNow}
        />
      </div>
    </>
  );
};

export default Careers;
