import React, { useState } from "react";
import alert1 from "../Images/alert1.png";
import alert2 from "../Images/alert2.png";
import alert3 from "../Images/alert3.png";
import alert4 from "../Images/alert4.png";
import alert5 from "../Images/alert5.png";
import alert6 from "../Images/alert6.png";
interface DataItem {
  id: number;
  name: string;
  title: string;
  img: string;
}
const Examalerts: React.FC = () => {
  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState<string>("ExamAlerts");
  // Function to handle tab change
  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };
  // Content for each tab
  const data: DataItem[] = [];
  const Admissiondata: DataItem[] = [];
  const Collegedata: DataItem[] = [];
  const q_a_data: DataItem[] = [];
  const [expandedCardId, setExpandedCardId] = useState<number | null>(null);
  const toggleExpand = (cardId: number) => {
    setExpandedCardId((prevId) => (prevId === cardId ? null : cardId));
  };
  const tabContent: { [key: string]: JSX.Element } = {
    ExamAlerts: (
      <>
        <div className="grid grid-cols-2 gap-4 my-5">
          {data.map((p, i) => {
            return (
              <>
                <div
                  className="flex flex-col  max-md:ml-0 max-md:w-full"
                  key={i}
                >
                  <div className="flex flex-col grow justify-center p-6 w-full text-sm font-semibold bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <img
                        loading="lazy"
                        alt="alert"
                        src={p.img}
                        className="shrink-0 aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-zinc-800 max-md:max-w-full">
                          {p.title}
                        </div>
                        <div className="mt-1 text-neutral-600 max-md:max-w-full">
                          {expandedCardId === i ? p.name : p.name.slice(0, 50)}
                          <br />
                          {p.name.length > 30 && (
                            <div className="flex gap-1.5 self-start mt-1 text-blue-800">
                              <button
                                className="arrow-button"
                                onClick={() => toggleExpand(i)}
                              >
                                {expandedCardId === i
                                  ? "Learn less"
                                  : "Learn More"}

                                <img
                                  loading="lazy"
                                  alt="alert"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc98361e0c5b6fd32d0d7fc44f4d6d9697cab55ca5b010ed71881b8acfb06822?"
                                  className="shrink-0 my-auto w-3 aspect-[1.09] fill-blue-800 arrow-icon ml-1"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    ),
    Admissions: (
      <>
        <div className="grid grid-cols-2 gap-4 my-5">
          {Admissiondata.map((p, i) => {
            return (
              <>
                <div
                  className="flex flex-col  max-md:ml-0 max-md:w-full"
                  key={i}
                >
                  <div className="flex flex-col grow justify-center p-6 w-full text-sm font-semibold bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <img
                        loading="lazy"
                        alt="alert"
                        src={p.img}
                        className="shrink-0 aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-zinc-800 max-md:max-w-full">
                          {p.title}
                        </div>
                        <div className="mt-1 text-neutral-600 max-md:max-w-full">
                          {expandedCardId === i ? p.name : p.name.slice(0, 50)}
                          <br />
                          {p.name.length > 30 && (
                            <div className="flex gap-1.5 self-start mt-1 text-blue-800">
                              <button
                                className="arrow-button"
                                onClick={() => toggleExpand(i)}
                              >
                                {expandedCardId === i
                                  ? "Learn less"
                                  : "Learn More"}

                                <img
                                  loading="lazy"
                                  alt="alert"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc98361e0c5b6fd32d0d7fc44f4d6d9697cab55ca5b010ed71881b8acfb06822?"
                                  className="shrink-0 my-auto w-3 aspect-[1.09] fill-blue-800 arrow-icon ml-1"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    ),
    CollegeNews: (
      <>
        <div className="grid grid-cols-2 gap-4 my-5">
          {data.map((p, i) => {
            return (
              <>
                <div
                  className="flex flex-col  max-md:ml-0 max-md:w-full"
                  key={i}
                >
                  <div className="flex flex-col grow justify-center p-6 w-full text-sm font-semibold bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <img
                        loading="lazy"
                        alt="alert"
                        src={p.img}
                        className="shrink-0 aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-zinc-800 max-md:max-w-full">
                          {p.title}
                        </div>
                        <div className="mt-1 text-neutral-600 max-md:max-w-full">
                          {expandedCardId === i ? p.name : p.name.slice(0, 50)}
                          <br />
                          {p.name.length > 30 && (
                            <div className="flex gap-1.5 self-start mt-1 text-blue-800">
                              <button
                                className="arrow-button"
                                onClick={() => toggleExpand(i)}
                              >
                                {expandedCardId === i
                                  ? "Learn less"
                                  : "Learn More"}

                                <img
                                  loading="lazy"
                                  alt="alert"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc98361e0c5b6fd32d0d7fc44f4d6d9697cab55ca5b010ed71881b8acfb06822?"
                                  className="shrink-0 my-auto w-3 aspect-[1.09] fill-blue-800 arrow-icon ml-1"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    ),
    Q_A: (
      <>
        <div className="grid grid-cols-2 gap-4 my-5">
          {data.map((p, i) => {
            return (
              <>
                <div
                  className="flex flex-col  max-md:ml-0 max-md:w-full"
                  key={i}
                >
                  <div className="flex flex-col grow justify-center p-6 w-full text-sm font-semibold bg-white rounded-3xl shadow-2xl max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <img
                        loading="lazy"
                        alt="alert"
                        src={p.img}
                        className="shrink-0 aspect-square w-[92px]"
                      />
                      <div className="flex flex-col flex-1 max-md:max-w-full">
                        <div className="text-zinc-800 max-md:max-w-full">
                          {p.title}
                        </div>
                        <div className="mt-1 text-neutral-600 max-md:max-w-full">
                          {expandedCardId === i ? p.name : p.name.slice(0, 50)}
                          <br />
                          {p.name.length > 30 && (
                            <div className="flex gap-1.5 self-start mt-1 text-blue-800">
                              <button
                                className="arrow-button"
                                onClick={() => toggleExpand(i)}
                              >
                                {expandedCardId === i
                                  ? "Learn less"
                                  : "Learn More"}

                                <img
                                  loading="lazy"
                                  alt="alert"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc98361e0c5b6fd32d0d7fc44f4d6d9697cab55ca5b010ed71881b8acfb06822?"
                                  className="shrink-0 my-auto w-3 aspect-[1.09] fill-blue-800 arrow-icon ml-1"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    ),
  };
  return (
    <div className="exam-journeys">
      <div className="flex flex-col items-center px-5 w-[1360px] mx-auto">
        <div className="flex gap-4 mt-8 text-sm font-semibold text-center text-zinc-800 max-md:flex-wrap">
          <button
            className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${
              activeTab === "ExamAlerts" ? "active_tab_exam" : ""
            }`}
            onClick={() => handleTabChange("ExamAlerts")}
          >
            Exam Alerts
          </button>
          <button
            className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${
              activeTab === "Admissions" ? "active_tab_exam" : ""
            }`}
            onClick={() => handleTabChange("Admissions")}
          >
            Admissions
          </button>
          <button
            className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${
              activeTab === "CollegeNews" ? "active_tab_exam" : ""
            }`}
            onClick={() => handleTabChange("CollegeNews")}
          >
            College News
          </button>
          <button
            className={`justify-center px-6 py-3 bg-white border border-solid border-neutral-200 rounded-[50px] max-md:px-5 shadow-md ${
              activeTab === "Q_A" ? "active_tab_exam" : ""
            }`}
            onClick={() => handleTabChange("Q_A")}
          >
            Q&A
          </button>
        </div>
        <div className="self-stretch mt-8 w-full max-md:max-w-full">
          {tabContent[activeTab]}
        </div>
      </div>
    </div>
  );
};
export default Examalerts;
