import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Banner from "./components/Banner";
import FeaturedPredictors from "./components/FeaturedPredictors";
import PredictorTools from "./components/PredictorTools";
import KnowledgeHub from "./components/KnowledgeHub";
import { update } from "../../../store/slices/predictorLanding"; // Correct import path
import News from "./components/News";
import FeaturedColleges from "../../colleges-new/landing/components/FeaturedColleges";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import EntranceExams from "./components/EntranceExams";
import TestimonialPredictor from "./components/TestimonialPredictor";
import Blogs from "./components/Blogs";
import Footer from "./components/Footer";
import config from "../../../util/config";
import collegeLandingMock from "../../exams/mock/mockData";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";

const CollegePredictorLanding: React.FC = () => {
  const { featuredColleges } = collegeLandingMock;
  const dispatch = useDispatch();
  const [newsAlertsData, setNewsAlertsData] = useState<any[]>([]);
  const [blogsArticleData, setArticles] = useState<any[]>([]);

  // const applyToDirectAdmissions = useSelector(
  //   (state: RootState) => state.predictorLanding.directAdmissions
  // );

  useEffect(() => {
    // Fetching Predictor Landing page data
    axios
      .get(config.apiUrl + "/api/college/predictorLandingPage")
      .then((response) => {
        dispatch(update(response.data.data));
      })
      .catch((error) => {
        console.error("Failed to fetch data", error);
      });

    // Fetching blogs and news data
    axios
      .get(`${config.apiUrl}/api/blogs/blogsandnews`)
      .then((response) => {
        const sections = response?.data?.sections?.[0];

        // Extracting latest news
        const latestNews =
          sections?.["Exams And Admissions"]?.subcategories?.["Latest News"]
            ?.results || [];
        setNewsAlertsData(latestNews);

        // Extracting Articles & Blogs
        const data = sections?.["College And Scholarship"]?.subcategories;
        const fetchedArticles = data?.["Articles & Blogs"]?.results || [];
        setArticles(fetchedArticles);
      })
      .catch((error) => {
        console.error("Error fetching blogs and news data", error);
      });

    document.title =
      "College Mentor | Predict Your College & Rank with a click";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Discover your dream college with College Mentor’s College Predictor tool. Predict admissions for JEE Mains, NEET, EAPCET, and more. Plan smarter and succeed with expert guidance."
      );
  }, [dispatch]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Predict Your College & Rank with a click
          </title>
          <meta
            name="description"
            content="Discover your dream college with College Mentor’s College Predictor tool. Predict admissions for JEE Mains, NEET, EAPCET, and more. Plan smarter and succeed with expert guidance."
          />
          <meta
            name="keywords"
            content="college predictor JEE Mains, college predictor JEE Advanced, NEET college predictor, EAPCETcollege predictor, college predictor NEET, JEE admission predictor, college acceptance calculator, college predictor tools, predict college admission, plan college and course, discover ideal college, rank prediction for admissions."
          />
          <link rel="canonical" href={`${config.apiUrl}/college-predictor`} />

          <meta
            property="og:title"
            content="College Mentor | Predict Your College & Rank with a click"
          />
          <meta
            property="og:description"
            content="Discover your dream college with College Mentor’s College Predictor tool. Predict admissions for JEE Mains, NEET, EAPCET, and more. Plan smarter and succeed with expert guidance."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Predict Your College & Rank with a click"
          />
          <meta
            name="twitter:description"
            content="Discover your dream college with College Mentor’s College Predictor tool. Predict admissions for JEE Mains, NEET, EAPCET, and more. Plan smarter and succeed with expert guidance."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="font-inter">
        <Banner />
        <FeaturedPredictors />
        <PredictorTools />
        <FeaturedColleges
          title="Apply To Direct Admissions"
          featuredColleges={featuredColleges}
        />
        <News newsAlertsData={newsAlertsData} />
        <EntranceExams />
        <TestimonialPredictor />
        <Blogs blogsArticleData={blogsArticleData} />
        <KnowledgeHub />
        <Footer />
      </div>
    </>
  );
};

export default CollegePredictorLanding;
