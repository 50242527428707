import React, { useState } from "react";
import FAQV2 from "../../../../components/FAQV2";

const scholarshipFaqMock = [
  {
    question:
      "What are the eligibility criteria for Indian government scholarships?",
    answer:
      "Eligibility criteria vary by scholarship but generally include Indian citizenship, enrollment in a recognized institution, and meeting academic and financial requirements.",
  },
  {
    question:
      "How do Indian students apply for the National Scholarship Portal (NSP)?",
    answer:
      "Students can apply online through the NSP portal by registering and submitting the required documents.",
  },
  {
    question:
      "What types of scholarships are available for Indian students pursuing higher education?",
    answer:
      "There are merit-based, need-based, and specific category scholarships available for Indian students.",
  },
  {
    question:
      "What documents are required to apply for the Post-Matric Scholarship Scheme?",
    answer:
      "Applicants need to provide proof of identity, income certificate, academic records, and bank details.",
  },
  {
    question: "Can Indian students receive scholarships for studying abroad?",
    answer:
      "Yes, there are several scholarships like Chevening, Fulbright, and DAAD for Indian students wanting to study abroad.",
  },
  {
    question:
      "How can Indian students apply for the Chevening Scholarships to study in the UK?",
    answer:
      "Students must apply through the official Chevening website by submitting an application with references and essays.",
  },
  {
    question:
      "What is the application process for Erasmus Mundus Scholarships for studying in Europe?",
    answer:
      "Students must apply directly through the Erasmus Mundus program website and submit necessary documents.",
  },
  {
    question:
      "What are the eligibility criteria for DAAD scholarships for Indian students in Germany?",
    answer:
      "The DAAD scholarship requires a strong academic record, a well-defined study plan, and proof of German/English proficiency.",
  },
  {
    question:
      "How can Indian students apply for Australia Awards Scholarships?",
    answer:
      "Students need to apply through the Australian Government’s Department of Foreign Affairs and Trade (DFAT) website.",
  },
  {
    question:
      "What scholarships are available for Indian students pursuing higher education in Canada?",
    answer:
      "Options include the Vanier Canada Graduate Scholarships and the Shastri Indo-Canadian Institute Scholarships.",
  },
  {
    question:
      "Can Indian students receive financial aid for studying in Australia?",
    answer:
      "Yes, financial aid options include university-based scholarships and Australian Government funding programs.",
  },
  {
    question:
      "What are the application requirements for Indian students applying for scholarships in Singapore?",
    answer:
      "Requirements typically include academic transcripts, recommendation letters, and a statement of purpose.",
  },
  {
    question:
      "How can Indian students find scholarships for studying in New Zealand?",
    answer:
      "They can explore options through Education New Zealand’s official website and university portals.",
  },
  {
    question:
      "Are there specific scholarships for Indian students pursuing engineering degrees abroad?",
    answer:
      "Yes, scholarships like the Inlaks Shivdasani Foundation Scholarship and Narotam Sekhsaria Scholarships support engineering students.",
  },
];

const educationLoanFaqMock = [
  {
    question: "What is the interest rate for education loans in India?",
    answer:
      "Interest rates vary between banks and financial institutions, typically ranging from 8% to 12% per annum.",
  },
  {
    question: "What documents are required to apply for an education loan?",
    answer:
      "Common documents include admission letters, fee structure, KYC documents, income proof, and collateral documents if required.",
  },
  {
    question: "Are there any government schemes for education loans?",
    answer:
      "Yes, schemes like the Vidya Lakshmi Portal and CSIS (Central Sector Interest Subsidy) are available for eligible students.",
  },
  {
    question: "How can students apply for education loans for studying abroad?",
    answer:
      "Students can apply through banks and NBFCs by submitting admission proof, academic records, and collateral details if required.",
  },
  {
    question: "What is the repayment period for education loans in India?",
    answer:
      "The repayment period usually ranges from 5 to 15 years, depending on the loan amount and lender policies.",
  },
  {
    question: "Do education loans cover living expenses?",
    answer:
      "Yes, most education loans cover tuition fees, accommodation, books, and travel expenses for international studies.",
  },
  {
    question: "Can students get an education loan without collateral?",
    answer:
      "Yes, loans up to ₹7.5 lakhs are usually available without collateral, while higher amounts may require security.",
  },
  {
    question: "What is the process for getting an education loan subsidy?",
    answer:
      "Students from economically weaker sections can apply for government interest subsidies through the Vidya Lakshmi portal.",
  },
  {
    question: "Which banks provide the best education loans in India?",
    answer:
      "Banks like SBI, HDFC, Axis, and ICICI offer competitive education loan schemes with flexible repayment options.",
  },
  {
    question: "What happens if I fail to repay my education loan on time?",
    answer:
      "Failure to repay on time can impact your credit score and may result in legal action or loan restructuring options.",
  },
  {
    question: "Is there a moratorium period for education loans?",
    answer:
      "Yes, most loans have a moratorium period during the course duration plus 6-12 months after completion.",
  },
  {
    question: "Are NRIs eligible for education loans in India?",
    answer:
      "Yes, NRIs can avail education loans for studies in India, subject to additional documentation requirements.",
  },
  {
    question: "Can education loans be transferred to another bank?",
    answer:
      "Yes, education loans can be transferred to another bank under better interest rates through loan refinancing.",
  },
  {
    question: "How much loan can I get for studying in the USA?",
    answer:
      "Loan amounts vary, but most banks provide up to ₹1.5 crore for studying abroad, depending on university ranking and collateral.",
  },
];

const KnowledgeHub = () => {
  const [activeTab, setActiveTab] = useState("scholarship");

  return (
    <div className="bg-[#EEF2FF] py-12 max-sm:py-2">
      <div className="container">
        <div className="text-gray-800 font-bold text-[40px] leading-[60px] font-sans">
          Knowledge Hub (FAQ's)
        </div>
        <div className="pt-4">
          <div className="flex space-x-12 mb-4 max-sm:mb-0">
            <button
              onClick={() => setActiveTab("scholarship")}
              className={`py-2 px-4 rounded-2xl transition-colors duration-200 border border-[#173CBA] ${
                activeTab === "scholarship" ? "bg-[#173CBA] text-white" : ""
              }`}
            >
              Scholarships
            </button>
            <button
              onClick={() => setActiveTab("education")}
              className={`py-2 px-4 rounded-2xl transition-colors duration-200 border border-[#173CBA] ${
                activeTab === "education" ? "bg-[#173CBA] text-white" : ""
              }`}
            >
              Education Loans
            </button>
          </div>

          <div className="border rounded-lg pt-6">
            {activeTab === "scholarship" && (
              <FAQV2 questionsAndAnswers={scholarshipFaqMock} />
            )}
            {activeTab === "education" && (
              <FAQV2 questionsAndAnswers={educationLoanFaqMock} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
