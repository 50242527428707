import React from "react";
import CMLogo from "../../../../../images/navbar/cm-logo.png";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

const AuthHeader = () => {
  return (
    <>
      <header className="bg-white">
        <div className="flex items-center justify-between px-4 py-4 mx-auto sm:px-6 lg:px-8">
          <div className="text-xl font-bold text-blue-500">
            <a href="/">
              <img src={CMLogo} alt="logo" width={250} />
            </a>
          </div>
          <div className="flex">
            <Link
              to="/secure/login"
              className="bg-blue px-6 py-2 text-base rounded-full text-white hover:bg-green font-medium"
            >
              Login
            </Link>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default AuthHeader;
