import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faHeart,
  faComment,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import "./Posts.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import SwiperCore from "swiper";

const PostsData = [
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/why-clg-mentor/gallery02.jpeg",
    likes: 0,
    comments: 0,
  },
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/center-image.png",
    likes: 0,
    comments: 0,
  },
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/careers/cvs01.jpg",
    likes: 0,
    comments: 0,
  },
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/careers/cvs02.jpg",
    likes: 0,
    comments: 0,
  },
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/careers/cvs03.jpg",
    likes: 0,
    comments: 0,
  },
  {
    hashtag: "@collegementor",
    description: "",
    image: "/images/careers/cvs04.jpg",
    likes: 0,
    comments: 0,
  },
];

const Posts = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const swiperRef = useRef<SwiperCore | null>(null); // Correctly typing useRef for Swiper

  return (
    <section className="mt-24 md:mt-0 posts-section container max-w-screen-2xl py-12 hidden">
      <h3 className="text-3xl font-extrabold text-center text-[#231F40]">
        Posts @CollegeMentor
      </h3>
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        pagination={isMobile ? false : { clickable: true }}
        navigation={isMobile ? true : false}
        modules={[Autoplay, Pagination, Navigation]}
        className="h-full w-full max-md:w-[85%] flex flex-row justify-center items-center mt-6"
        breakpoints={{
          640: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        }}
      >
        {PostsData.map((postData, index) => (
          <SwiperSlide key={index}>
            <PostItem
              hashtag={postData.hashtag}
              description={postData.description}
              image={postData.image}
              likes={postData.likes}
              comments={postData.comments}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {isMobile && (
        <div className="flex justify-center mt-4">
          <button
            className="p-2"
            onClick={() => swiperRef.current?.slidePrev()} // Add null check before accessing swiperRef.current
          >
            <LeftArrow />
          </button>
          <button
            className="p-2"
            onClick={() => swiperRef.current?.slideNext()} // Add null check before accessing swiperRef.current
          >
            <RightArrow />
          </button>
        </div>
      )}
    </section>
  );
};

const PostItem = ({
  hashtag,
  description,
  image,
  likes,
  comments,
}: {
  hashtag: string;
  description: string;
  image: string;
  likes: number;
  comments: number;
}) => {
  return (
    <div className="relative aspect-square group">
      <img
        loading="lazy"
        src={image}
        alt="post-images"
        className="h-full w-full object-cover"
      />
      <div className="absolute top-0 left-0 h-full w-full flex flex-col gap-4 p-6 bg-[#000000BB] opacity-0 group-hover:opacity-100 transition duration-300">
        <p className="text-white">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="mr-2" />
          {hashtag}
        </p>
        <div className="flex-1 overflow-hidden">
          <p className="text-white">{description}</p>
        </div>
        <div className="text-white flex">
          <div>
            <FontAwesomeIcon icon={faHeart} size="1x" className="mr-2" />
            <span className="mr-4">{likes}</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faComment} size="1x" className="mr-2" />
            {comments}
          </div>
          <a href="#" className="block ml-auto">
            <FontAwesomeIcon icon={faExternalLink} size="1x" />
          </a>
        </div>
      </div>
    </div>
  );
};

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black  rounded-full bg-white ">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11045 11.8311 7.11021 11.6934C7.11021 11.556 7.08396 11.4202 7.03203 11.2934C6.9801 11.1667 6.90373 11.0515 6.80729 10.9546L3.375 7.52751H13.7917C14.0562 7.52751 14.309 7.41772 14.4935 7.23317C14.6781 7.04862 14.7879 6.79584 14.7879 6.53134C14.7879 6.26684 14.6781 6.01406 14.4935 5.82951C14.309 5.64496 14.0562 5.53518 13.7917 5.53518V5.45842Z"
          fill="#000000"
        />
      </svg>
    </div>
  );
};

const RightArrow = () => {
  return (
    <div className="p-4 border border-black  rounded-full bg-white ">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M0.958334 5.45842H11.375L7.94792 2.03134C7.85028 1.9345 7.77279 1.8193 7.7199 1.69236C7.66702 1.56542 7.63979 1.42927 7.63979 1.29176C7.63979 1.15425 7.66702 1.01809 7.7199 0.891158C7.77279 0.764222 7.85028 0.649012 7.94792 0.552176C8.14309 0.358165 8.4071 0.249268 8.68229 0.249268C8.95748 0.249268 9.2215 0.358165 9.41667 0.552176L13.8854 5.03134C14.2767 5.42024 14.4977 5.94844 14.5 6.50008V6.50008C14.4949 7.04811 14.2741 7.57207 13.8854 7.95842L9.41667 12.4376C9.31954 12.534 9.20438 12.6104 9.07774 12.6623C8.9511 12.7142 8.81548 12.7407 8.67861 12.7402C8.54174 12.7398 8.4063 12.7123 8.28004 12.6595C8.15377 12.6067 8.03915 12.5295 7.94271 12.4324C7.84627 12.3352 7.7699 12.2201 7.71797 12.0934C7.66604 11.9668 7.63955 11.8311 7.63979 11.6934C7.63979 11.556 7.66604 11.4202 7.71797 11.2934C7.7699 11.1667 7.84627 11.0515 7.94271 10.9546L11.375 7.52751H0.958334C0.693834 7.52751 0.441051 7.41772 0.256502 7.23317C0.0719528 7.04862 -0.0378418 6.79584 -0.0378418 6.53134C-0.0378418 6.26684 0.0719528 6.01406 0.256502 5.82951C0.441051 5.64496 0.693834 5.53518 0.958334 5.53518V5.45842Z"
          fill="#000000"
        />
      </svg>
    </div>
  );
};

export default Posts;
