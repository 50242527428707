import React, { useEffect, useState } from "react";
import BlogsImage from "../../../images/blog-article/blogsandarticles.jpeg";
import BlogsImage1 from "../../../images/blog-article/exam-section-image2.jpeg";
import { useNavigate, Link } from "react-router-dom";
import Image from "../../../components/Image";
import { getBlogOrNewsUrl } from "../utils";
import moment from "moment";
import DefaultThumbNail from "../../../images/blog-article/default.png";

interface BlogHoverContainerProps {
  blogInfo: any;
  hoveredContainerItem: any;
}
const BlogHoverContainer: React.FC<BlogHoverContainerProps> = ({
  blogInfo,
  hoveredContainerItem,
}) => {
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState<any>({});

  // useEffect(() => {
  //   setHoveredItem(blogInfo);
  // }, []);

  useEffect(() => {
    setHoveredItem(hoveredContainerItem);
  }, [hoveredContainerItem]);
  const formattedDate = (date: any) => moment(date).format("MMMM D, YYYY");
  return (
    <Link
      // to={`/blogs/${hoveredItem.category?.toLocaleLowerCase()}/${hoveredItem?.title?.toLocaleLowerCase()}`}
      to={getBlogOrNewsUrl(hoveredItem)}
      state={{ from: "BlogHoverContainer", blogDetail: hoveredItem }}
    >
      <div className="h-full group">
        {/* <div className="h-[50%]">
          <img
            className=""
            src={hoveredItem?.imageUrl}
            // src={BlogsImage1}
            alt=""
            width="100%"
          />
        </div> */}
        <img
          src={hoveredItem?.imageUrl || DefaultThumbNail}
          className="md:w-[360px] md:h-[240px] lg:w-[678px] lg:h-[508px]"
          alt={hoveredItem?.title}
        />

        <div className="py-2 min-h-40">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2 group-hover:text-[#173CBA]">
            {hoveredItem?.title}
          </h1>
          <p className="">
            {hoveredItem?.topic || hoveredItem?.category} |{" "}
            {formattedDate(hoveredItem?.publishedDate)}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 line-clamp-2">
            {hoveredItem?.description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogHoverContainer;
