import React, { useCallback, useEffect, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
interface MentorArticlesProps {
  mentorArticles?: any;
}

const articles = [
  {
    imageUrl: "/images/mentors/abm01.png",
    title: "Convert your GPA to a 4.0 scale",
    description:
      "Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.",
  },
  {
    imageUrl: "/images/mentors/abm02.png",
    title: "Types of Colleges",
    description:
      "Colleges offer various programs to serve students with different needs and goals. Learning about the types of colleges will help you find the best choices for you.",
  },
  {
    imageUrl: "/images/mentors/abm03.png",
    title: "Why college is Important",
    description:
      "Considering the pros and cons of going to college? Learn reasons why college is worth it for career growth and future financial opportunities.",
  },
  {
    imageUrl: "/images/mentors/abm04.png",
    title: "Convert your GPA to a 4.0 scale",
    description:
      "Colleges report GPA (grade point average) on a 4.0 scale. See how to calculate your GPA and convert your grades to the 4.0 scale.",
  },
];

const ArticlesByMentor: React.FC<MentorArticlesProps> = ({
  mentorArticles,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  return (
    <div className="bg-[#fff] py-5">
      <div className="container mx-auto">
        <h2 className="text-2xl font-semibold text-[#173CBA] mb-6 max-md:text-center">
          Articles by Mentor
        </h2>
        <div className="w-full">
          <Swiper
            onSwiper={setSwiperRef}
            onSlideChange={handleSlideChange}
            spaceBetween={30}
            className="w-full"
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {mentorArticles.map(
              (
                article: {
                  slug: any;
                  image: string | undefined;
                  title:
                    | any
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | Iterable<React.ReactNode>
                    | null
                    | undefined;
                  description:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                index: React.Key | null | undefined
              ) => (
                <SwiperSlide key={index}>
                  <div
                    key={index}
                    className="bg-white rounded-lg overflow-hidden shadow-lg h-[330px] max-md:w-[305px] mx-auto"
                  >
                    <a href={`/blogs/career-courses/${article.slug}`}>
                      <img
                        src={article.image}
                        alt={article.title}
                        className="w-full h-40 object-cover"
                      />
                    </a>
                    <div className="p-4 h-full bg-[#173CBA] text-white">
                      <a href={`/blogs/career-courses/${article.slug}`}>
                        <h3 className="text-lg font-semibold mb-2 line-clamp-2">
                          {article.title}
                        </h3>
                      </a>
                      <p className="text-sm line-clamp-4">
                        {article.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ArticlesByMentor;

const LeftArrow = () => {
  return (
    <div className="p-4 border border-black hover:border-[#173CBA] rounded-full bg-white hover:bg-[#173CBA] group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-current text-black group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
        />
      </svg>
    </div>
  );
};

const RightArrow = ({ isPrevHovered }: { isPrevHovered: boolean }) => {
  return (
    <div
      className={`p-4 border rounded-full ${
        isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
      }`}
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${
            isPrevHovered
              ? "fill-current text-black"
              : "fill-current text-white"
          }`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
        />
      </svg>
    </div>
  );
};
