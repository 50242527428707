import React from "react";

interface Scholarship {
  id: number;
  logo: string;
  title: string;
  deadline: string;
}

const scholarships: Scholarship[] = [
  {
    id: 1,
    logo: "/images/adani.webp",
    title: "Adani Gyan Jyoti Scholarship 2024-25",
    deadline: "07-Oct-2024",
  },
  {
    id: 2,
    logo: "/images/hdfc.webp",
    title: "HDFC Bank Parivartan's ECSS Programme 2024-25",
    deadline: "30-Oct-2024",
  },
  {
    id: 3,
    logo: "/images/reliance.webp",
    title: "Reliance Foundation Scholarships 2024-25",
    deadline: "06-Oct-2024",
  },
  {
    id: 4,
    logo: "/images/sbi.webp",
    title: "SBIF Asha Scholarship Program 2024",
    deadline: "01-Oct-2024",
  },
  {
    id: 5,
    logo: "/images/legrand.webp",
    title: "Legrand Empowering Scholarship Program 2024-25",
    deadline: "10-Sep-2024",
  },
  {
    id: 6,
    logo: "/images/u-go.webp",
    title: "U-Go Scholarship Program 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 7,
    logo: "/images/ksb.webp",
    title: "KSB Scholarship and Mentorship Program 2024-25",
    deadline: "16-Sep-2024",
  },
  {
    id: 8,
    logo: "/images/infosys.webp",
    title: "Infosys Foundation STEM Stars Scholarship Program 2024-25",
    deadline: "15-Sep-2024",
  },
  {
    id: 9,
    logo: "/images/infosys.webp",
    title: "Infosys Foundation STEM Stars Scholarship Program 2024-25",
    deadline: "15-Sep-2024",
  },
  {
    id: 10,
    logo: "/images/nikon.webp",
    title: "KSB Scholarship and Mentorship Program 2024-25",
    deadline: "16-Sep-2024",
  },
  {
    id: 11,
    logo: "/images/corteva.webp",
    title: "Corteva Agriscience Scholarship Program 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 12,
    logo: "/images/swavam.webp",
    title: "Corteva Agriscience Scholarship Program 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 13,
    logo: "/images/lifesgood.webp",
    title: "Corteva Agriscience Scholarship Program 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 14,
    logo: "/images/kotak.webp",
    title: "Kotak Kanya Scholarship 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 15,
    logo: "/images/appinventiv.webp",
    title: "Appinventiv 'Edu Boost' Scholarship Program 2024-25",
    deadline: "30-Sep-2024",
  },
  {
    id: 16,
    logo: "/images/buddystudy.webp",
    title: "Buddy4Study’s Online Degree Scholarship Support Program",
    deadline: "30-Sep-2024",
  },
  {
    id: 17,
    logo: "/images/tata.webp",
    title: "Tata Capital Pankh Scholarship Program 2024-25",
    deadline: "15-Sep-2024",
  },
  {
    id: 18,
    logo: "/images/college.webp",
    title: "College Board 90% Fee Waiver Program",
    deadline: "18-Sep-2024",
  },
];

const FeaturedScholarshipsAd = () => {
  return (
    <div className="w-full max-w-xl mx-auto bg-white   border-[1px] border-[#747474] sticky lg:top-0 max-md:w-full">
      <h3 className="text-xl font-bold text-center text-[#FFF] bg-[#173CBA] py-5 ">
        Featured Scholarships
      </h3>

      <div className="mt-4 space-y-4  flex flex-col px-4">
        {scholarships.map((scholarship) => (
          <div
            key={scholarship.id}
            className="flex text-left gap-3 border-b  border-[#A3A2AC] pb-3 flex-col"
          >
            <img
              src={scholarship.logo}
              alt={scholarship.title}
              className="w-[75px] h-[37px] flex-shrink-0 object-contain"
            />
            <div className="text-sm font-medium text-black">
              <p>
                {scholarship.title} {scholarship.deadline}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedScholarshipsAd;
