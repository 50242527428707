import Banner from "./components/Banner";
import FeaturedScholarships from "./components/FeaturedScholarships";
import DreamstoReality from "./components/DreamstoReality";
import OneStopSolution from "./components/OneStopSolution";

import { useEffect } from "react";
import { fetchLandingData } from "./scholarshipThunk";
import RightScholarship from "./components/RightScholarship";
import TypeOfScholarships from "./components/TypeOfScholarships";
import InspiringStories from "./components/InspiringStories";
import Mentors from "./components/Mentors";

import WebinarAndArticles from "../../colleges-new/landing/components/WebinarAndArticles";
import Tabs from "./components/Tabs";
import KnowledgeHub from "./components/KnowledgeHub";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../../util/config";

const ScholarshipLanding = () => {
  const dispatch = useDispatch<AppDispatch>();

  const blogsData = useSelector(
    (state: RootState) => state.collegeLanding.blogsAndArticles
  );

  useEffect(() => {
    dispatch(fetchLandingData());
    document.title = "Find the list of Scholarships with College Mentor";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Explore scholarships with College Mentor. check scholarship status, eligibility, and deadlines. minority & girl scholarships, PM Scholarships, get scholarship help"
      );
  }, [dispatch]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>Find the list of Scholarships with College Mentor</title>
          <meta
            name="description"
            content="Explore scholarships with College Mentor. check scholarship status, eligibility, and deadlines. minority & girl scholarships, PM Scholarships, get scholarship help"
          />
          <meta
            name="keywords"
            content="scholarships, scholarship eligibility, minority scholarships, school scholarships, tuition discounts, cash prizes for top ranks, scholarship opportunities, scholarship test, scholarship for students, study scholarships, college scholarships, graduate scholarships, financial aid, top scholarships, tuition fee waivers, academic scholarships."
          />
          <link rel="canonical" href={`${config.apiUrl}/scholarships`} />

          <meta
            property="og:title"
            content="Find the list of scholarships with College Mentor"
          />
          <meta
            property="og:description"
            content="Explore scholarships with College Mentor. check scholarship status, eligibility, and deadlines. minority & girl scholarships, PM Scholarships, get scholarship help"
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Find the list of scholarships with College Mentor"
          />
          <meta
            name="twitter:description"
            content="With College Mentor, get admissions help, campus tours, and exclusive discounts. Secure admissions to top universities in India & abroad."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner />
        {/* <FeaturedScholarships /> */}
        <DreamstoReality />
        <RightScholarship />
        <TypeOfScholarships />
        <OneStopSolution />
        <div>
          <Tabs />
        </div>
        <Mentors />
        <InspiringStories />
        <WebinarAndArticles />
        <KnowledgeHub />
        {/* <CreditScore /> */}
      </div>
    </>
  );
};

export default ScholarshipLanding;
