import React from "react";

const contactOptions = [
  {
    id: "call",
    icon: "/images/call-icon.webp",
    alt: "Call",
    title: "Call Us we're here to answer all Doubts",
    contact: "+91 89 5959 1818",
  },
  {
    id: "whatsapp",
    icon: "/images/watsapp-icon.webp",
    alt: "WhatsApp",
    title: "Chat with us on WhatsApp",
    contact: "+91 89 5959 1818",
  },
  {
    id: "email",
    icon: "/images/email-icon.webp",
    alt: "Email",
    title: "Drop us an email",
    contact: "admin@collegementor.com",
  },
];

const Guides: React.FC = () => {
  return (
    <section className="py-10 bg-[#FFF9E5] max-sm:py-6 max-md:w-full">
      <div className="container flex flex-col gap-8">
        <div className="text-center mx-auto max-md:w-full">
          <h3 className="text-[#173CBA] text-center font-inter text-[40px] font-normal leading-[40px]">
            Need Any Help ?
          </h3>
          <p className="text-[#383838] font-inter text-[24px] font-light leading-[40px]">
            Connect with our Mentor experts today!
          </p>
        </div>

        <div className="flex  gap-10  flex-row max-lg:flex-col max-md:gap-10 justify-between xl:px-10 max-md:w-full ">
          {contactOptions.map((option) => (
            <div
              key={option.id}
              className="flex flex-col items-center max-md:w-full min-max-md:mx-auto"
            >
              <img
                src={option.icon}
                alt={option.alt}
                width={40}
                height={40}
                className="w-10 h-10"
              />
              <p className="text-[#383838] text-center font-inter text-[24px] font-light leading-[40px]">
                {option.title}
              </p>
              <p className="text-[#383838] font-inter text-[24px] font-semibold leading-[40px]">
                {option.contact}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Guides;
