import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

interface Mentor {
  id: number;
  name: string;
  role: string;
  location: string;
  imageUrl: string | null;
}

const mentors: Mentor[] = [
  {
    id: 19,
    name: "Rajashekar",
    role: "Student Buddy Mentor",
    location: "Hyderabad, Telangana",
    imageUrl: "/images/rajshekhar.webp",
  },
  {
    id: 20,
    name: "Monica",
    role: "Career Mentor",
    location: "Hyderabad",
    imageUrl: "/images/mentor-four.webp",
  },
  {
    id: 21,
    name: "Pasupuleti Manjusha",
    role: "Loan Mentor",
    location: "Hyderabad",
    imageUrl: "/images/mentor-two.webp",
  },
  {
    id: 22,
    name: "Archana",
    role: "Mental Health Mentor",
    location: "Hyderabad",
    imageUrl: "/images/mentor-three.webp",
  },
];

export default function MentorBanner() {
  const [currentMentor, setCurrentMentor] = useState<Mentor | undefined>(
    mentors[0]
  );

  const handleSlideChange = (swiper: any) => {
    const currentIndex = swiper.realIndex;
    setCurrentMentor(mentors[currentIndex]);
  };

  return (
    <div className="lg:h-[398px] md:h-auto bg-gradient-to-b from-[#edfcff] to-[#89c9ed] min-max-xss:pb-7 min-max-xs:pb-7 max-md:pt-4">
      <div className="container flex min-max-xss:flex-col w-full min-max-xs:flex-col h-full">
        <div className="lg:w-3/6 md:w-full flex flex-col justify-center lg:pl-10 pr-5 min-max-xss:mb-10 min-max-xs:mb-10">
          <h1 className="text-4xl font-bold text-[#383838] mb-7 max-md:text-[28px]">
            Every Student Deserves an <br /> Inspiring Mentor
          </h1>

          <p className="text-[#747474] text-xl mb-7">
            Through mentoring, students become the architects of their own
            careers, with guidance serving as the blueprint to shape their
            journey towards excellence.
          </p>

          <LeadGenCtaBtn
            pageName="mentors"
            ctaName="explore-membership"
            className="w-60 px-3 py-[5px] text-white bg-[#0C9] text-base rounded-full hover:bg-green-600 transition duration-300"
          >
            Explore Membership →
          </LeadGenCtaBtn>
        </div>

        <div className="lg:w-3/6 md:w-full flex lg:flex-row md:flex-col min-max-xss:flex-col max-sm:gap-0 max-sm:flex-col items-center lg:justify-end min-max-md:py-5">
          <div className="w-[387px] min-max-md:flex min-max-xss:flex min-max-md:justify-center min-max-xss:justify-center min-max-md:mb-5 min-max-xss:mb-0">
            <div className="max-w-xs w-full rounded-lg overflow-hidden transition duration-300 p-3 h-[360px] max-sm:h-[307px]">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                navigation
                pagination
                onSlideChange={handleSlideChange}
              >
                {mentors.map((mentor) => (
                  <SwiperSlide key={mentor.id}>
                    <img
                      src={mentor.imageUrl || "/images/rajshekhar.webp"}
                      alt={mentor.name}
                      className="w-full object-cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="w-[274px] h-[274px] flex flex-col bg-white p-4 max-sm:py-0 max-sm:px-4 rounded-lg shadow-md">
            <div className="justify-center w-full">
              <img
                src="/images/mentors/india.png"
                alt="India Image"
                className="mb-4 w-28 mx-auto"
              />
            </div>

            {currentMentor && (
              <div className="text-left mb-2">
                <h2 className="text-base font-bold text-[#173CBA]  mb-2">
                  {currentMentor?.name}
                </h2>
                <p className="text-xs text-[#383838] ">{currentMentor?.role}</p>
                {/* <span className="text-xs text-[#383838]">
                  {currentMentor?.role}
                </span> */}

                <span className="text-xs text-[#383838]">
                  {currentMentor?.location}
                </span>
              </div>
            )}
            <div>
              <img src={logo} alt="Logo" className="w-[158px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
