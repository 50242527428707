import ArticlesBlogs from "./components/ArticlesBlogs";
import GetAdmissionAdvise from "./components/GetAdmissionAdvise";
import WhatWeOffer from "./components/WhatWeOffer";
import CollegeAdmissionProcess from "./components/CollegeAdmissionProcess";
import Testimonials from "./components/Testimonials";
import ApplicatonToAdmission from "./components/ApplicationToAdmission";
import TopColleges from "./components/TopColleges";
import ExploreCollegeLife from "./components/ExploreCollegeLife";

import ExploreYourDreamCollege from "./components/ExploreYourDreamCollege";
import MentorSection from "./components/MentorSection";
import { Banner } from "./components/Banner";
import ConnectWithTopUniversities from "./components/ConnectWithTopUniversities";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";
import { useEffect } from "react";

const ApplicationsDirectAdmissions = () => {
  useEffect(() => {
    document.title =
      "College Mentor | Apply for Admissions at Top Universities";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "With College Mentor, get admissions help, campus tours, and exclusive discounts. Secure admissions to top universities in India & abroad."
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Apply for Admissions at Top Universities
          </title>
          <meta
            name="description"
            content="With College Mentor, get admissions help, campus tours, and exclusive discounts. Secure admissions to top universities in India & abroad."
          />
          <meta
            name="keywords"
            content="great discounts on application process, admissions, college applications, personalized support, application help, Top universities, campus tours, applications to admissions, expert assistance, explore campus before joining, part of campus tour, partner with top colleges India/abroad, special dedicated mentors for you"
          />
          <link
            rel="canonical"
            href={`${config.apiUrl}/applications-admissions`}
          />

          <meta
            property="og:title"
            content="College Mentor | Apply for Admissions at Top Universities"
          />
          <meta
            property="og:description"
            content="With College Mentor, get admissions help, campus tours, and exclusive discounts. Secure admissions to top universities in India & abroad."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Apply for Admissions at Top Universities"
          />
          <meta
            name="twitter:description"
            content="With College Mentor, get admissions help, campus tours, and exclusive discounts. Secure admissions to top universities in India & abroad."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner />
        <TopColleges />
        <CollegeAdmissionProcess />
        <WhatWeOffer />
        <GetAdmissionAdvise />
        <ExploreCollegeLife />
        <ApplicatonToAdmission />
        <MentorSection />
        <Testimonials />
        <ArticlesBlogs />
        <ExploreYourDreamCollege />
        <ConnectWithTopUniversities />
      </div>
    </>
  );
};

export default ApplicationsDirectAdmissions;
