import React from "react";

const AIUDetailedInfo = () => {
  return (
    <div className="w-full max-lg:gap-5 my-3 px-20 py-16  max-md:px-5 bg-[#FFF1FA] text-black flex flex-col-reverse lg:flex-row lg:items-start lg:space-x-8 max-md:py-6 max-md:my-0 ">
      {/* Text Content */}
      <div className="lg:w-[70%] leading-8">
        <h2 className="text-2xl font-bold text-[#173CBA]">
          Association of Indian Universities (AIU)
        </h2>
        <p>
          The Association of Indian Universities (AIU) plays a crucial role in
          the development of higher education in India. Established as the
          Inter-University Board in 1967 and renamed in 1973, AIU serves as a
          pivotal body for various types of universities, including
          conventional, open, deemed, state, central, private universities, and
          institutes of national importance.
        </p>
        <h3 className="font-semibold">Key Functions of UGC</h3>
        <ul className="leading-9 list-none">
          <li>
            <strong className="text-[#173CBA]">Legal Status </strong> Registered
            as a society under the Societies Registration Act of 1860
          </li>
          <li>
            <strong className="text-[#173CBA]">Membership</strong> Comprises a
            diverse range of institutions, reflecting the multifaceted nature of
            higher education in India
          </li>
          <li>
            <strong className="text-[#173CBA]">
              International Collaboration
            </strong>{" "}
            Includes associate members from several countries, enhancing
            cross-border academic ties
          </li>
          <p className="text-[#173CBA] font-semibold">
            Focus Areas
            <ul className="list-disc pl-6 space-y-2 text-black font-normal">
              <li>Promoting quality in higher education</li>
              <li>Facilitating cooperation among universities</li>
              <li>Conducting research and sharing best practices</li>
              <li>Organizing seminars, workshops, and conferences</li>
            </ul>
          </p>
        </ul>
        <p>
          AIU’s efforts are vital for the holistic development of higher
          education in India and fostering international academic
          collaborations. If you have any specific questions about AIU or higher
          education you can visit the official website:
          <a
            href="https://aiu.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            https://aiu.ac.in/
          </a>
        </p>
      </div>

      {/* Image Section */}
      <div className="lg:w-[30%] flex justify-center  my-auto mx-auto">
        <img
          src="/images/regulatory-professionals/AIU.png"
          alt="UGC Logo"
          className=""
        />
      </div>
    </div>
  );
};

export default AIUDetailedInfo;
