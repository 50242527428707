import ApplySmarter from "./components/ApplySmarter";
import BuildingBridges from "./components/BuildingBridges";
import FeaturedColleges from "./components/FeaturedColleges";
import CollegeBanner from "./components/CollegeBanner";
import StateWise from "./components/StateWise";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import About from "./components/About";
import ChooseCollegeMentor from "./components/ChooseCollegeMentor";
import WorldClassUni from "./components/WorldClassUni";
import Preference from "./components/Preference";
import Dreams from "./components/Dreams";
import FindPerfectCollege from "./components/FindPerfectCollege";
import axios from "axios";
import collegeLanding, { update } from "../../../store/slices/collegeLanding";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import config from "../../../util/config";
import WebinarAndArticles from "./components/WebinarAndArticles";
import collegeLandingMock from "../../exams/mock/mockData";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";

interface Mentor {
  id?: number;
  name: string;
  role?: string;
  experience?: string;
  worksOn?: string;
  imageUrl?: string;
  location?: string;
  videoUrl?: string | null;
  country?: {
    countryId: number;
    countryName: string;
  };
  featured?: boolean;
  phoneNumber?: string;
  email?: string;
  active?: boolean;
  respondTime?: string;
}

const CollegeLanding = () => {
  const {
    featuredColleges,
    statewiseColleges,
    worldClass,
    dreamsHappen,
    cardsDreams,
  } = collegeLandingMock;
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          config.apiUrl + "/api/auth/mentors/mentorList"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch mentor data");
        }
        const responseData = await response.json();
        setMentors(responseData.data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);
  console.log("#mentors", mentors);
  useEffect(() => {
    document.title =
      "College Mentors| discover top colleges & engineering universities";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Partner with College Mentor & know more about top colleges, universities, admission help ,expert mentorship, admissions help, compare colleges, admissions"
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentors| discover top colleges & engineering universities
          </title>
          <meta
            name="description"
            content="Partner with College Mentor & know more about top colleges, universities, admission help ,expert mentorship, admissions help, compare colleges, admissions"
          />
          <meta
            name="keywords"
            content="featured college, study journey, engineering college, top colleges, top universities, partner colleges, college admissions help, direct admissions, college mentorship, scholarships, college comparison, admission guidance"
          />
          <link rel="canonical" href={`${config.apiUrl}/colleges`} />

          <meta
            property="og:title"
            content="College Mentors| discover top colleges & engineering universities"
          />
          <meta
            property="og:description"
            content="Partner with College Mentor & know more about top colleges, universities, admission help ,expert mentorship, admissions help, compare colleges, admissions"
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentors| discover top colleges & engineering universities"
          />
          <meta
            name="twitter:description"
            content="Partner with College Mentor & know more about top colleges, universities, admission help ,expert mentorship, admissions help, compare colleges, admissions"
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="font-inter">
        <CollegeBanner />
        <FeaturedColleges
          title={"Featured Colleges"}
          featuredColleges={featuredColleges}
        />
        <BuildingBridges />
        <FindPerfectCollege />
        <ApplySmarter />
        <Preference />
        <ChooseCollegeMentor />
        <Dreams dreamsHappen={dreamsHappen} cardsDreams={mentors} />
        <WorldClassUni worldClass={worldClass} />
        <StateWise stateWiseColleges={statewiseColleges} />
        <WebinarAndArticles />
        <About />
      </div>
    </>
  );
};

export default CollegeLanding;
