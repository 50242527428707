import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../../util/config";
import PopupInfo from "../../../../components/popupInfo/popup";

interface Exam {
  name: string;
  categoryName: string;
  title: string;
  isDataAvailable: boolean;
}

const SearchBox = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [examsList, setExamsList] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<Exam[]>([]);
  const [selectedExamUrl, setSelectedExamUrl] = useState<string>("");

  useEffect(() => {
    axios({
      url: config.apiUrl + "/indexes/exams/search",
      method: "POST",
      headers: {
        Authorization: `Bearer ` + config.searchApiKey,
        "Content-Type": "application/json",
      },
      data: {
        q: searchTerm || " ",
      },
    })
      .then((response) => {
        const fetchedExams = response.data.hits.map((exam: Exam) => ({
          name: exam.name,
          categoryName: exam.categoryName,
          title: exam.title,
          isDataAvailable: exam.isDataAvailable,
        }));

        setExamsList(fetchedExams);

        if (searchTerm.trim()) {
          setSuggestions(fetchedExams);
        } else {
          setSuggestions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching exams data:", error);
      });
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = examsList.filter((suggestion: any) =>
        suggestion?.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSuggestionClick = (suggestion: Exam) => {
    setSearchTerm(suggestion.title);
    if (suggestion.isDataAvailable) {
      setSelectedExamUrl(`/exam-details/${suggestion.name}`);
      window.location.href = `/exam-details/${suggestion.name}`;
    } else {
      setShowPopup(true);
      setSelectedExamUrl("");
    }
    setSuggestions([]);
  };

  const handleClear = () => {
    setSearchTerm("");
    setIsDropdownOpen(false);
  };
  const handleSearchExam = () => {
    if (selectedExamUrl) {
      window.location.href = selectedExamUrl;
    }
  };

  return (
    <div className="w-full lg:w-[468px] flex gap-5 relative text-[--primary-color] mt-5 lg:mt-10">
      <form className="bg-white pl-3 py-1 pr-1 md:pl-4 md:pr-2 md:py-2 rounded-full flex flex-row w-full items-center justify-between">
        <div className="flex flex-row gap-2 items-center w-full">
          <div className="flex flex-col w-5 h-5">
            <svg
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 md:w-5 md:h-5"
            >
              <path
                d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
                fill="#113CC0"
              />
            </svg>
          </div>
          <div className="relative w-full">
            <input
              type="text"
              id="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search your dream exam"
              className="block w-full px-2 pr-4 text-xs md:text-base text-[#A3A2AC] outline-none leading-10"
            />
            {searchTerm.length > 0 && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute right-[150px] top-1/2 transform animate__animated"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="#717171"
                  width={14}
                  height={14}
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            )}
            {suggestions.length > 0 && (
              <div
                className={`absolute -left-3.5 w-full py-6 top-12 z-20 ${
                  suggestions.length > 0
                    ? "animate__animated animate__fadeIn bg-white searchSuggestion"
                    : ""
                }`}
              >
                <hr className="border-gray-200 py-2" />
                <div className="min-h-[200px] max-h-[300px] overflow-y-auto scrollbar-custom">
                  <ul>
                    {suggestions.map((exam, index) => (
                      <div key={index}>
                        <li
                          className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                          onClick={() => handleSuggestionClick(exam)}
                        >
                          <span>{exam.title}</span>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <button
        onClick={handleSearchExam}
        type="button"
        className=" absolute top-1/2 transform -translate-y-1/2 bg-[--primary-color] text-white md:py-3 px-3 rounded-full right-1"
        style={{
          background: "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
        }}
      >
        <span className="min-max-xss:hidden min-max-xs:hidden min-max-sm:hidden min-max-md:hidden">
          Search Exam
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
          className="arrow-icon m-2"
        >
          <path
            d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
            fill="currentColor"
          />
        </svg>
      </button>
      {showPopup && (
        <PopupInfo isOpen={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};

export default SearchBox;
