import React, { useEffect } from "react";
import ScholorshipBanner from "./components/ScholorshipBanner";
import ScholarshipFAQ from "./components/ScholorshipFAQ";
import SclorshipObjective from "./components/SclorshipObjective";
import ScholarshipDisbursement from "./components/ScholarshipDisbursement";
import CollegeMentorServices from "./components/CollegeMentorServices";
import ScholorshipApply from "./components/ScholorshipApply";
import ScholorshipEligibility from "./components/ScholorshipEligibility";
import IndiasTopInstitutions from "./components/IndiasTopInstitutions";
import ScholorshipCategoryAward from "./components/ScholorshipCategoryAwards";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../util/config";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

const ScholarshipTest = () => {
  const projectName = "College Mentors";

  useEffect(() => {
    document.title = "College Mentor | Scholarship Test for 10+2 students";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Apply for College Mentor scholarship test. Check eligibility, minority scholarships, school scholarships, education tour, tuition discounts, cash prizes for top ranks"
      );
  }, []);

  return (
    <div className="text-black">
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>College Mentor Scholarship Test 2025 for Plus 12 Students | Online Registration</title>
          <meta name="description" content="The College Mentor Scholarship Test 2025 is a nationwide FREE examination designed to recognize and reward academic excellence among students in Class 12."></meta>

          <meta
            name="keywords"
            content="Scholarship status, Scholarship check, Scholarship eligibility, Scholarship last date, National scholarship portal, Scholarship portal, College Mentor Scholarship Test, Merit-based scholarships, Europe education tour scholarship, Tuition discounts at IIT, NIT, AIIMS, Cash prizes for students, Academic excellence rewards, Scholarship opportunities for students, National scholarship eligibility, Top scholarships for students, College Mentor"
          />
          <link
            rel="canonical"
            href={`${config.apiUrl}/college-mentor-scholarship`}
          />

          <meta property="og:title" content="Scholarship Test 2025 | College Mentor | Free Register Online" />
          <meta property="og:description" content="Register for the College Mentor Scholarship Test and stand a chance to win scholarships worth ₹1 crore. Apply now before the deadline!" />
          <meta property="og:image" content="https://cdncollegementor.blob.core.windows.net/scholarship/scholarshipTest.jpg" />
          <meta property="og:url" content="https://collegementor.com/college-mentor-scholarship" />
          <meta property="og:type" content="website" />


          <meta name="twitter:card" content="summary_large_image"/>
          <meta property="twitter:domain" content="collegementor.com"/>
          <meta property="twitter:url" content="https://www.collegementor.com/college-mentor-scholarship"/>
          <meta name="twitter:title" content="College Mentor Scholarship Test 2025 for Plus 12 Students | Online Registration"/>
          <meta name="twitter:description" content="The College Mentor Scholarship Test 2025 is a nationwide FREE examination designed to recognize and reward academic excellence among students in Class 12."/>
          <meta name="twitter:image" content="https://cdncollegementor.blob.core.windows.net/scholarship/scholarshipTest.jpg"/>

          <meta name="robots" content="index, follow" />
        </Helmet>
        <ScholorshipBanner />
        <IndiasTopInstitutions />
        <SclorshipObjective />
        <ScholorshipEligibility />
        <ScholorshipCategoryAward />
        <ScholarshipDisbursement />
        <CollegeMentorServices />
        <ScholorshipApply />
        <ScholarshipFAQ />
      </HelmetProvider>
    </div>
  );
};

export default ScholarshipTest;
