import React from "react";
import { Link } from "react-router-dom";

const rankingSections = [
  {
    id: 1,
    className: "banner-img5",
    rankings: [
      {
        title: "Overall",
        url: "/overall",
        img: "/images/overall.webp",
      },
      {
        title: "University",
        url: "/university",
        img: "/images/universities.webp",
      },
      {
        title: "College",
        url: "/college",
        img: "/images/colleges.webp",
      },
      {
        title: "Research",
        url: "/research",
        img: "/images/research.webp",
      },
      {
        title: "Engineering",
        url: "/engineering",
        img: "/images/engineering.webp",
      },
      {
        title: "Management",
        url: "/management",
        img: "/images/management.webp",
      },
    ],
  },
  {
    id: 2,
    className: "banner-img4",
    rankings: [
      {
        title: "Pharmacy",
        url: "/pharmacy",
        img: "/images/pharmacy.webp",
      },
      {
        title: "Medical",
        url: "/medical",
        img: "/images/medical.webp",
      },
      {
        title: "Dental",
        url: "/dental",
        img: "/images/dental.webp",
      },
      {
        title: "Law",
        url: "/law",
        img: "/images/law.webp",
      },
      {
        title: "Architecture",
        url: "/architecture",
        img: "/images/architecture.webp",
      },
      {
        title: "Agriculture",
        url: "/agriculture",
        img: "/images/agriculture.webp",
      },
      {
        title: "Innovation",
        url: "/innovation",
        img: "/images/innovation.webp",
      },
    ],
  },
  {
    id: 3,
    className: "banner-img7",
    rankings: [
      {
        title: "Open University",
        url: "/open-university",
        img: "/images/open-university.webp",
      },
      {
        title: "Skill University",
        url: "/skill-university",
        img: "/images/skill-university.webp",
      },
      {
        title: "State Public University",
        url: "/state-public-university",
        img: "/images/state-public-university.webp",
      },
    ],
  },
];
const RankingTabs: React.FC = () => {
  return (
    <div
      id="tabs"
      className="flex flex-col items-center gap-12 py-12 bg-gray-100"
    >
      {rankingSections.map((section) => (
        <div key={section.id} className="w-full max-w-6xl">
          <div
            className={`${section.className} flex justify-center flex-wrap gap-10`}
          >
            {section.rankings.map((rank) => (
              <Link
                key={rank.title}
                to={`/rankings${rank.url}`}
                className="flex flex-col items-center text-center group"
              >
                <img
                  src={rank.img}
                  alt={rank.title}
                  className="w-32 h-32 object-contain transition-transform duration-300 ease-in-out group-hover:scale-110"
                />
                <span className="mt-2 text-lg font-medium transition-opacity duration-300 ease-in-out group-hover:opacity-80">
                  {rank.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RankingTabs;
