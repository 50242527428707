import React from "react";
import bannerImg from "../images/banner-img.png";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";

const Banner = () => {
  const isMdDevice = window.innerWidth >= 768;
  return (
    <section
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)`,
      }}
    >
      <div className="container max-w-screen-2xl text-white py-6">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="relative w-full md:w-1/2 flex flex-col justify-center pt-12 md:pt-0">
            <div className="mb-auto">
              <Breadcrumbs
                links={[
                  { name: "Home", path: "/" },
                  {
                    name: "List of loans in India",
                    path: "/education-loan-details",
                  },
                ]}
              />
            </div>
            <h1 className="text-4xl mt-12 md:mt-0 font-bold">
              College Mentor Education Loan Partner
            </h1>
            <p className="text-lg md:text-2xl mt-4 mb-auto font-light">
              Education Loans for courses such as{" "}
              <span className="font-bold">
                Diploma, Bachelors, Masters, Ph.D{" "}
              </span>
              and <span className="font-bold">Vocational</span> and{" "}
              <span className="font-bold">Excutive Education</span> courses
              based on the student's eligibility in{" "}
              <span className="font-bold">Inida and Abroad</span>{" "}
            </p>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <img
              src="https://cdncollegementor.blob.core.windows.net/education/Education-loan-banner.webp"
              className="w-full md:w-2/3 aspect-square object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
