import React from "react";
import { useCourseDetails } from "../../../screens/course-details/CourseDetailsContext";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import save from "../../../images/courses/course-details/save.svg";
import reHeart from "../../../images/courses/course-details/red-heart.svg";

import ask from "../../../images/courses/course-details/ask.svg";
import rightarrow from "../../../images/courses/course-details/rightarrow.svg";
import banner from "../../../images/courses/course-details/banner.png";
import bannermob from "../../../images/courses/course-details/banner-mob.png";
import { useEffect, useState } from "react";
import axios from "axios";
import type { SVGProps } from "react";
import LeadGeneration from "../../LeadGeneration";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../../util/config";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";
import { Link } from "react-router-dom";

interface Shortlist {
  image: string;
  title: string;
  category: string;
  collegeId?: number;
  location: string;
  courseId?: number;
}
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}

const Banner: React.FC = () => {
  const { data } = useCourseDetails();
  console.log("data", data);
  const { courseTitle = "", bannerSection, descriptions } = data || {};
  const sanitizeUrl = (url: string | undefined) =>
    url ? encodeURI(url.trim()) : "";

  const dynamicBanner = sanitizeUrl(bannerSection?.courseBannerImage) || banner;
  const dynamicMobileBanner =
    sanitizeUrl(bannerSection?.mobileBannerImage) || banner;
  const [bgImage, setBgImage] = useState(dynamicBanner);
  const [bgMobImage, setBgMobImage] = useState(dynamicMobileBanner);
  const [smalldevice, setSmalldevice] = useState(false);
  const description = "Certificate, Diploma, Degree, Degree (Honours)";
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isRed, setIsRed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isWishlisted, setIsWishlisted] = useState(false);

  const [userId, setUserId] = useState<number | null>(null);
  const [shortListData, setShortListData] = useState<Shortlist[]>([]);
  useEffect(() => {
    setIsRed(isWishlisted);
  }, [isWishlisted]);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.userId);
    }
  }, []);
  useEffect(() => {
    if (userId && data?.courseId) {
      axios({
        url: config.apiUrl + `/api/wishlist/category/${userId}?category=course`,
        method: "GET",
      })
        .then((response) => {
          setShortListData(response.data.data);
          const isCollegeWishlisted = response.data.data.some(
            (item: Shortlist) => item.courseId === data.courseId
          );
          setIsWishlisted(isCollegeWishlisted);
        })
        .catch((error) => {
          console.error("Error fetching mentor data:", error);
        });
    } else {
      console.log("userId or courseId is null, skipping API call");
    }
  }, [userId, data?.courseId]);

  const handleLeadGenerationSave = () => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      const toggleWishlist = async () => {
        try {
          if (userId) {
            console.log("im inside");
            const apiUrl = isWishlisted
              ? config.apiUrl + "/api/wishlist/remove"
              : config.apiUrl + "/api/wishlist/add";
            console.log("data ve dhan", data, userId);
            const response = await axios.post(
              apiUrl,
              {
                categoryName: "course",
                categoryId: data.courseId,
                userId: userId,
              },
              {
                headers: {
                  accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("response", response);
            if (response.data) {
              setIsWishlisted(!isWishlisted);
            } else {
              console.error("Error toggling wishlist:", response.statusText);
            }
          }
        } catch (error) {
          console.error("Error toggling wishlist:", error);
        }
      };
      toggleWishlist();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBgImage(dynamicMobileBanner);
        setSmalldevice(true);
      } else {
        setBgImage(dynamicBanner);
        setSmalldevice(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [bannerSection?.courseBannerImage, bannerSection?.mobileBannerImage]);

  const handleShareOnWhatsApp = () => {
    const text =
      "Check out this brochure: https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;

    window.open(whatsappUrl, "_blank");
  };
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "List of courses in India",
      path: "/courses/list-of-courses-in-india",
    },
    {
      name: courseTitle,
      path: `/course-details/${courseTitle}`,
    },
  ];

  return (
    <div
      className={`bg-[#0A0A60] bg-no-repeat ${
        smalldevice ? "bg-full bg-center" : "bg-cover bg-full bg-bottom"
      }`}
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="relative container mx-auto py-8 lg:py-28">
        <div className="w-full lg:w-[650px]">
          <div className="flex flex-col max-lg:gap-12">
            <div className="relative lg:absolute lg:top-16 text-[#00CC99] font-open-sans text-sm">
              <Breadcrumbs links={breadcrumbLinks} />
            </div>
            <div className="w-full flex flex-row gap-4 items-start max-md:mt-2">
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-4">
                  <div className="flex">
                    <h1 className="text-2xl lg:text-[40px] font-inter font-bold lg:leading-[60px] text-white m-0">
                      {courseTitle}
                    </h1>
                    {/* <img
                  src={bannerSection?.courseLogo}
                  alt="icon"
                  className="w-14 h-14 md:w-[75px] md:h-[75px]"
                /> */}
                    {/* <svg
                      width="76"
                      height="71"
                      viewBox="0 0 76 71"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="relative -top-6"
                    >
                      <path
                        d="M34.2039 39.7508C35.7216 38.8025 37.0962 37.6459 38.2873 36.32L42.5815 38.7999L46.4915 32.0271L42.2059 29.5525C42.7508 27.8889 43.0713 26.1243 43.1314 24.2951L48.0069 23.4354L46.6484 15.7338L41.7738 16.5935C41.1002 14.9237 40.1964 13.3711 39.1004 11.9759L42.2868 8.17866L36.2963 3.15209L33.1125 6.94605C31.5575 6.11272 29.8735 5.48978 28.0988 5.11597V0.167969H20.2788V5.11597C18.5039 5.48978 16.8199 6.11272 15.2647 6.94605L12.0811 3.15209L6.09061 8.17884L9.2767 11.976C8.18083 13.3713 7.27705 14.9241 6.60339 16.5936L1.72883 15.7339L0.371094 23.4356L5.24584 24.2953C5.30592 26.1246 5.62661 27.8889 6.1715 29.5531L1.88558 32.0274L5.79574 38.8001L10.0901 36.3209C11.2808 37.6463 12.6554 38.803 14.1733 39.7513L12.4782 44.4082L19.8268 47.0825L21.5196 42.4319C22.392 42.5553 23.2829 42.6208 24.1888 42.6208C25.0947 42.6208 25.9858 42.5557 26.8582 42.4319L28.5508 47.0829L35.899 44.4078L34.2039 39.7508ZM24.1884 33.6521C18.6818 33.6521 14.2018 29.1721 14.2018 23.665C14.2018 18.1583 18.6818 13.6779 24.1884 13.6779C29.6951 13.6779 34.175 18.1583 34.175 23.665C34.175 29.1721 29.6952 33.6521 24.1884 33.6521Z"
                        fill="#00CC99"
                      />
                      <path
                        d="M67.8166 45.6233L64.4456 46.7138C63.8028 45.5965 63.0082 44.5849 62.0933 43.7042L63.9773 40.6973L59.2338 37.7252L57.351 40.7296C56.1665 40.2923 54.9113 40.017 53.6157 39.9277L53.1269 36.4194L47.5826 37.1923L48.0714 40.7006C46.8501 41.1405 45.7173 41.7493 44.6973 42.4935L42.0658 40.118L38.3151 44.2735L40.9488 46.6518C40.3099 47.7491 39.8229 48.939 39.5103 50.1899L35.9691 50.0619L35.7666 55.656L39.3076 55.7844C39.5311 57.0755 39.9329 58.2939 40.4839 59.4203L37.6898 61.5984L41.1308 66.0135L43.9304 63.8317C44.9057 64.6535 45.995 65.3376 47.1644 65.8605L46.4222 69.3301L51.8965 70.4996L52.6378 67.0353C53.2684 67.0371 53.9062 66.9943 54.5483 66.9049C55.1908 66.8156 55.8164 66.6807 56.4221 66.5076L58.0822 69.6378L63.0278 67.0149L61.3659 63.8812C62.3487 63.0586 63.208 62.1022 63.9221 61.045L67.2111 62.3788L69.3144 57.1908L66.0316 55.8595C66.253 54.6264 66.3059 53.3436 66.1687 52.0403L69.5404 50.949L67.8166 45.6233ZM53.6632 60.5464C49.7584 61.09 46.1394 58.3565 45.5957 54.452C45.051 50.5474 47.7851 46.9278 51.6897 46.3843C55.594 45.8398 59.2121 48.5739 59.7572 52.4788C60.3005 56.3831 57.5673 60.0017 53.6632 60.5464Z"
                        fill="#00CC99"
                      />
                      <path
                        d="M75.3683 26.2232L75.4996 21.8056L72.7041 21.7226C72.5289 20.749 72.2158 19.7849 71.7562 18.8579L73.9516 17.1234L71.2126 13.6563L69.0176 15.39C68.2347 14.7395 67.3688 14.2091 66.4496 13.8087L67.0171 11.066L62.6905 10.1699L62.1219 12.9108C61.1249 12.9123 60.121 13.0538 59.1372 13.3435L57.8116 10.8813L53.9204 12.977L55.2461 15.4395C54.4635 16.1007 53.792 16.8626 53.2416 17.6935L50.6416 16.6585L49.0075 20.7647L51.6096 21.8013C51.4383 22.7893 51.405 23.8027 51.5174 24.8145L48.8613 25.693L50.2481 29.8893L52.9042 29.0111C53.4244 29.9054 54.0568 30.6965 54.7742 31.3789L53.3051 33.7588L57.0649 36.0809L58.5373 33.6967C59.4852 34.0368 60.4794 34.244 61.4882 34.3091L61.8921 37.0808L66.2654 36.4421L65.8626 33.6738C66.3293 33.5021 66.7898 33.2955 67.2407 33.0526C67.692 32.8101 68.118 32.5384 68.5183 32.2437L70.6075 34.1039L73.5466 30.8039L71.456 28.9415C71.9573 28.0626 72.3304 27.1184 72.5686 26.14L75.3683 26.2232ZM64.8383 28.5903C62.0975 30.066 58.6677 29.0373 57.1928 26.2975C55.7167 23.5573 56.7455 20.1266 59.4863 18.6516C62.2256 17.1756 65.6552 18.205 67.1313 20.9452C68.606 23.6855 67.5779 27.1145 64.8383 28.5903Z"
                        fill="#00CC99"
                      />
                    </svg> */}
                  </div>
                  <p className="text-inter text-lg lg:text-xl leading-9 text-white">
                    <span className="font-bold">Degrees offered: </span>
                    {descriptions}
                  </p>
                </div>
                <div className="flex flex-row  gap-2 lg:gap-10 items-center">
                  <button
                    className="flex flex-row text-white text-sm lg:text-base font-medium leading-8 rounded-lg whitespace-nowrap items-center"
                    onClick={handleLeadGenerationSave}
                  >
                    {!isRed ? (
                      <img src={save} className="pr-2" alt="Save Icon" />
                    ) : (
                      <img src={reHeart} className="pr-2" alt="Save Icon" />
                    )}
                    Save
                  </button>
                  <a
                    href={`https://api.whatsapp.com/send?text=hi&amp;phone=91${config.mobileNumber}`}
                    target="_blank"
                    className="flex flex-row text-white text-sm lg:text-base font-medium leading-8  rounded-lg items-center"
                  >
                    <img src={ask} className="pr-2" alt="askIcon" />
                    Ask a Question
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-9 flex lg:mt-24 gap-10 p-1 sm:p-2 bg-[#00CC99] rounded-full max-xs:rounded-xl w-2/3">
            <div className="flex relative w-full gap-2.5 lg:gap-8">
              <LeadGenCtaBtn
                pageName="course-detail"
                ctaName="apply-now"
                className="flex flex-row w-full font-inter text-white max-xss:text-[9px] text-[10px] sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-1 sm:p-2"
              >
                <span>Apply Now</span>
                <img
                  src={rightarrow}
                  alt="rightarrow"
                  className="w-5 h-5 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                />
              </LeadGenCtaBtn>
              {/* <a
                href="/course-compare"
                className="flex flex-row w-full font-inter text-white max-xss:text-[9px] text-[10px] sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-1 sm:p-2"
              >
                <span>Compare Course</span>
                <img
                  src={rightarrow}
                  className="w-5 h-5 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                />
              </a> */}
              <Link
                to={"/mentors"}
                className="flex flex-row w-full font-inter text-white max-xss:text-[9px] text-[10px] sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-1 sm:p-2"
              >
                <span>Talk to Mentor</span>
                <img
                  src={rightarrow}
                  alt="rightarrow"
                  className="w-5 h-5 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
