import React, { useRef, useState } from "react";
import "./touch.scss";

import Modal from "../../components/Modal";
import socialIcons from "../../assets/Social-icon.png";
import youtubeIcon from "../../assets/contactUs/youtube.svg";
import facebookIcon from "../../assets/contactUs/facebook.svg";
import twitterIcon from "../../assets/contactUs/twitter.svg";
import linkedinIcon from "../../assets/contactUs/linkedin.svg";
import pinterestIcon from "../../assets/contactUs/pinterest.svg";
import instagramIcon from "../../assets/contactUs/instagram.svg";

import HeroSection, {
  HeroSectionProps,
} from "../../components/careersDD/assets/HeroSection";
import RevealFromBottom from "../../components/careersDD/assets/RevealFromBottom";

interface CityOption {
  label: string;
  value: string;
}

const cityOptions: CityOption[] = [
  { label: "Mumbai", value: "mumbai" },
  { label: "Delhi", value: "delhi" },
  { label: "Bengaluru", value: "bengaluru" },
  { label: "Hyderabad", value: "hyderabad" },
  { label: "Ahmedabad", value: "ahmedabad" },
  { label: "Chennai", value: "chennai" },
  { label: "Kolkata", value: "kolkata" },
  { label: "Pune", value: "pune" },
  { label: "Surat", value: "surat" },
  { label: "Jaipur", value: "jaipur" },
];

const heroSectionData: HeroSectionProps = {
  headings: [<>Get in Touch with US</>],
  paragraphs: [
    <>
      We're here to help! Whether you have a question, need assistance, or just
      want to say hello, feel free to reach out to us. Our team is ready to
      assist you with any inquiries you may have.
    </>,
  ],
  image: "/images/contact-banner.jpg",
  linkText: "Contact us",
  anchorLink: "#contact",
};

const ContactInfo: React.FC = () => (
  <div className="flex flex-col gap-8 items-start">
    <div className="flex flex-col">
      <div className="flex items-center">
        <img src="/images/location.webp" alt="Location" />
        <span
          className="ml-3 my-3"
          style={{
            fontFamily: "Inter, sans-serif",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: "#000000",
          }}
        >
          Location
        </span>
      </div>

      <span
        className="max-sm:ml-0 ml-10 text-[#6F6F6F]"
        style={{
          fontFamily: "Inter, sans-serif",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "30px",
          color: "000000",
        }}
      >
        Plot 38, F301, Road no-1, Kakatiyahills, Madhapur, Shaikpet, Hyderabad,
        Telangana - 500081
      </span>
    </div>
    <div className="flex flex-col">
      <div className="flex items-center">
        <img src="/images/phone.webp" alt="Location" />
        <span
          className="ml-3 my-3"
          style={{
            fontFamily: "Inter, sans-serif",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: "#000000",
          }}
        >
          Phone
        </span>
      </div>
      <span
        className="max-sm:ml-0 ml-10 text-[#6F6F6F]"
        style={{
          fontFamily: "iner, sans-serif",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "30px",
          color: "000000",
          wordBreak: "break-word",
        }}
      >
        +91 7997166666
      </span>
    </div>
    <div className="flex flex-col">
      <div className="flex items-center">
        <img src="/images/mail.webp" alt="Location" />
        <span
          className="ml-3 my-3"
          style={{
            fontFamily: "Inter, sans-serif",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: "#000000",
          }}
        >
          Email
        </span>
      </div>
      <span
        className="max-sm:ml-0 ml-10 text-[#6F6F6F]"
        style={{
          fontFamily: "iner, sans-serif",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "30px",
          color: "000000",
          wordBreak: "break-word",
        }}
      >
        admin@dreamsanddegrees.com
      </span>
    </div>
  </div>
);

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  message: string;
}
interface SocialMediaIconsProps {
  icons: { src: string; alt: string; link: string }[];
}

const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({ icons }) => (
  <div className="social mt-8">
    <ul className="flex space-x-4">
      {icons.map(({ src, alt, link }, index) => (
        <li key={index}>
          <a
            href={link}
            target="_blank"
            className="social-media-icons bg-blue w-10 h-10 rounded-lg flex items-center justify-center hover:rounded-full"
            rel="noreferrer"
          >
            <img
              className="icon-color h-2/3 w-2/3"
              src={src}
              alt={alt}
              loading="lazy"
            />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

// Define icons array properly
const socialMediaIcons: { src: string; alt: string; link: string }[] = [
  {
    src: "/images/youtube.webp",
    alt: "YouTube",
    link: "https://www.youtube.com/@dreamsdegrees",
  },
  {
    src: "/images/pinterest.webp",
    alt: "Pinterest",
    link: "https://in.pinterest.com/dreamsdegrees/",
  },
  {
    src: "/images/instagram.webp",
    alt: "Instagram",
    link: "https://www.instagram.com/DreamsDegrees/",
  },
  {
    src: "/images/facebook.webp",
    alt: "Facebook",
    link: "https://www.facebook.com/people/Dreams-And-Degrees/61560469552824/",
  },
  {
    src: "/images/twitter.webp",
    alt: "Twitter",
    link: "https://twitter.com/DreamsDegrees",
  },
  {
    src: "/images/linkedin.webp",
    alt: "LinkedIn",
    link: "https://www.linkedin.com/company/dreamsanddegrees/",
  },
];

export default function ContactUs() {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<FormValues>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    message: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const [isValidPhone, setValidPhone] = useState(true);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    message: "",
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChangePhoneNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      phoneNumber: value,
    });
    setErrors({
      ...errors,
      phoneNumber: "",
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error message when user starts typing
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      city: "",
      message: "",
    };

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Mobile is required";
    else if (!/^\d{10}$/.test(formData.phoneNumber))
      newErrors.phoneNumber = "Phone number is not valid";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (hasErrors) {
      return; // Do not submit the form if there are any errors
    }
    // const serviceId = "service_tpb45xk";
    // const templateId = "template_5z14vyr";
    // const publicKey = "XaVhl8zO16B6lJLzy";

    // try {
    //   const response = await emailjs.sendForm(
    //     serviceId,
    //     templateId,
    //     formRef.current as HTMLFormElement,
    //     publicKey
    //   );
    //   if (response.status === 200) {
    //     alert('Message sent successfully!');
    //     setFormData({
    //       firstName: "",
    //       lastName: "",
    //       email: "",
    //       phoneNumber: "",
    //       city: "",
    //       message: "",
    //     });
    //     setIsSubmitted(false);
    //   }
    // } catch (error) {
    //   console.error("Error sending email:", error);
    //   alert('Failed to send message, please try again later.');
    // }
    const subject = encodeURIComponent("Contact-us page: Form Submission");
    const body = encodeURIComponent(
      `First name: ${formData.firstName}\n` +
        `Last name: ${formData.lastName}\n` +
        `Email: ${formData.email}\n` +
        `Mobile: ${formData.phoneNumber}\n` +
        `City: ${formData.city}\n\n` +
        `Message:\n${formData.message}`
    );

    window.location.href = `mailto:admin@dreamsanddegrees.com?subject=${subject}&body=${body}`;
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        {successMessage ? (
          <p>Successfully sent a mail to Team.</p>
        ) : (
          <p>something went wrong. Please try after some time.</p>
        )}
      </Modal>

      <div className="contact-us-main pt-12 md:pt-0 lg:pt-12 overflow-x-hidden xl:px-36">
        <HeroSection {...heroSectionData} />
        <div
          id="contact-section"
          className="relative container max-w-screen-xl mt-24"
        >
          <div className="container mx-auto py-10 lg:py-24">
            <div className="w-full mb-10 lg:mb-0">
              <RevealFromBottom>
                <h2 className=" font-bold text-center">
                  <div className="relative text-left flex items-center">
                    <span className="font-epilogue font-semibold text-black text-[2.875rem] leading-[3.75rem]  md:leading-[2.375rem] md:text-darkGray">
                      Get in Touch
                    </span>

                    <div className="flex-grow border border-grey mt-10 ml-4"></div>
                  </div>
                  <div className="relative md:mt-2 -translate-x-12  md:translate-x-0">
                    <img
                      src="/images/Getintouch.webp"
                      className="w-28 md:w-auto h-6 ml-16"
                      alt="Get in touch"
                    />
                  </div>
                </h2>
              </RevealFromBottom>
              <div className="lg:flex flex-row gap-6 relative z-10 lg:mt-10 ">
                <div className="w-full lg:w-1/3 bg-[#f1f1f1] max-sm:p-4 p-8 rounded-md max-lg:mb-8">
                  <div className="mb-8">
                    {/* Add margin bottom to create space */}
                    <ContactInfo />
                  </div>
                  <div className="mt-4  ">
                    {/* Add margin top to create space */}
                    <SocialMediaIcons icons={socialMediaIcons} />
                  </div>
                </div>

                <div className="w-full lg:w-2/3 bg-[#f1f1f1] p-8 rounded-md">
                  <div className="my-9">
                    <form
                      onSubmit={handleSubmit}
                      ref={formRef}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      className="flex flex-col"
                    >
                      <div className="flex flex-col lg:flex-row gap-3 lg:gap-7 mb-6 w-full">
                        <div className="flex flex-col gap-3 w-full lg:w-1/2">
                          <label
                            htmlFor="First name"
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              color: "#000000",
                            }}
                          >
                            {"First name"}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className={`bg-white p-1 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                                errors.firstName ? "border-[#ff0000]" : ""
                              }`}
                              aria-label={"Name"}
                              name={"firstName"}
                              value={formData.firstName}
                              onChange={handleChange}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                          {/* {errors.firstName && (
                            <p className="text-[#ff0000] text-base">{errors.firstName}</p>
                          )} */}
                        </div>
                        <div className="flex flex-col gap-3 w-full lg:w-1/2">
                          <label
                            htmlFor="Last Name"
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              color: "#000000",
                            }}
                          >
                            {"Last name"}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className={`bg-white p-1 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                                errors.lastName ? "border-[#ff0000]" : ""
                              }`}
                              aria-label={"Name"}
                              name={"lastName"}
                              value={formData.lastName}
                              onChange={handleChange}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                          {/* {errors.lastName && (
                            <p className="text-[#ff0000] text-base">{errors.lastName}</p>
                          )} */}
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row gap-3 lg:gap-7 mb-6 w-full">
                        <div className="flex flex-col gap-3 w-full lg:w-1/2">
                          <label
                            htmlFor="email"
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              color: "#000000",
                            }}
                          >
                            {"Email"}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className={`bg-white p-1 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                                errors.email ? "border-[#ff0000]" : ""
                              }`}
                              aria-label={"Email"}
                              name={"email"}
                              value={formData.email}
                              onChange={handleChange}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                          {formData.email && errors.email !== "" && (
                            <p className="text-[#ff0000] text-base">
                              {errors.email}
                            </p>
                          )}
                        </div>

                        <div className="flex flex-col gap-3 w-full lg:w-1/2">
                          <label
                            htmlFor="phoneNumber"
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              color: "#000000",
                            }}
                          >
                            {"Mobile"}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className={`bg-white p-1 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                                errors.phoneNumber ? "border-[#ff0000]" : ""
                              }`}
                              aria-label={"Mobile"}
                              name={"phoneNumber"}
                              value={formData.phoneNumber}
                              onChange={handleChangePhoneNum}
                              style={{ fontSize: "14px" }}
                            />
                          </div>
                          {formData.phoneNumber &&
                            errors.phoneNumber !== "" && (
                              <p className="text-[#ff0000] text-base">
                                {errors.phoneNumber}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row gap-3 lg:gap-7 mb-6 w-full">
                        <div className="w-full lg:w-1/2">
                          <label
                            htmlFor="city"
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              color: "#000000",
                            }}
                          >
                            {"Select city"}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                          <div className="select-wrapper">
                            <select
                              className={`bg-white p-2 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                                errors.city ? "border-[#ff0000]" : ""
                              }`}
                              aria-label={"Select City"}
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                              style={{ fontSize: "14px" }}
                            >
                              <option key={"select"} value={""}></option>
                              {cityOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3 mb-3 w-full">
                        <label
                          htmlFor="message"
                          style={{
                            fontFamily: "Inter, sans-serif",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "30px",
                            color: "#000000",
                          }}
                        >
                          {"Message"}
                          <span className="text-[#ff0000]">*</span>
                        </label>
                        <div className="input-wrapper">
                          <textarea
                            className={`bg-white p-1 border border-[#d8e1ed] rounded-lg text-lg font-medium placeholder-[#7A828A] text-black w-full ${
                              errors.message ? "border-[#ff0000]" : ""
                            }`}
                            aria-label={"Message"}
                            name={"message"}
                            value={formData.message}
                            onChange={handleChange}
                            style={{ fontSize: "14px" }}
                          />
                        </div>
                      </div>
                      {isSubmitted &&
                        Object.values(errors).some((error) => error) && (
                          <p
                            className="text-[#ff0000] text-base mb-1"
                            style={{ fontSize: "14px" }}
                          >
                            Look out for mandatory or invalid fields.
                          </p>
                        )}
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="CTABtn align-center lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex container max-w-screen-xl mb-10 ">
          <iframe
            title="location-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d237.89763676493246!2d78.40126836462254!3d17.442370443119245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1715422918929!5m2!1sen!2sin"
            width="100%"
            height="450px"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}
