import React from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

const RankingBanner = () => {
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "College Rankings ",
      path: "/rankings",
    },
    {
      name: "",
      path: ``,
    },
  ];
  return (
    <div className="w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] px-4 py-8 md:py-10">
      <div className="relative container lg:pl-6   lg:mb-2 text-[#0c9] font-open-sans text-sm max-md:mb-4">
        <Breadcrumbs links={breadcrumbLinks} />
      </div>
      <div className="pl-4 md:pl-20 flex flex-col md:flex-row items-center md:items-start md:justify-between w-full">
        {/* Left Section */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left w-full md:w-[20%] py-6 md:py-16">
          <div className="w-20 sm:w-24 md:w-full max-md:max-w-full">
            <img
              src="/images/ranking-framework/emblem.png"
              alt="Emblem"
              className="object-contain w-full aspect-[2.67]"
            />
          </div>
          <div className="mt-4 text-[#383838] text-lg md:text-2xl">
            <span className="font-semibold block">Ministry of Education</span>
            <span className="mt-2 font-normal block">Government of India</span>
            <span className="mt-2 font-semibold block">NIRF 2024 Ranking</span>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative w-full md:w-[100%] flex justify-center">
          <img
            src="/images/ranking-framework/ranking-banner.webp"
            alt="Ranking Banner"
            className="object-cover w-full md:w-[1432.52px] h-auto md:h-[398px] rounded-lg"
          />
          <div className="absolute top-8 sm:top-12 md:top-14 left-4 sm:left-8 md:left-10 text-center md:text-left">
            <h3 className="text-[#383838] text-lg sm:text-2xl md:text-[40px] font-medium leading-tight md:leading-[50px]">
              National Institutional Ranking Framework
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingBanner;
