import React, { useState, useEffect } from "react";
import rankingsData from "../../../util/rankings.json";
import { useParams } from "react-router-dom";

// Define the type for the structure of rankingsData
interface CollegeRanking {
  Institute_ID: string;
  Name: string;
  City: string;
  State: string;
  Score: string;
  Rank: number;
}

interface RankingsData {
  overall: CollegeRanking[];
  research: CollegeRanking[];
}

const IndiaRankingOverall = () => {
  const [colleges, setColleges] = useState<any[]>([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { overall } = useParams();

  useEffect(() => {
    const fetchColleges = async () => {
      setLoading(true);
      try {
        if (overall && rankingsData[overall as keyof RankingsData]) {
          setColleges(rankingsData[overall as keyof RankingsData]); // Dynamically set based on the overall parameter
        } else {
          setError("Data not found for the given ranking type.");
        }
      } catch (error) {
        setError("Failed to load data.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchColleges();
  }, [overall]);

  const filteredColleges = colleges.filter(
    (college) =>
      college?.Name &&
      college.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredColleges.length / entriesPerPage);
  const startIndex = (currentPage - 1) * entriesPerPage;
  const displayedColleges = filteredColleges.slice(
    startIndex,
    startIndex + entriesPerPage
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on search change
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when entries per page changes
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="bg-[#F7F7F7] w-full">
      <div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8 flex flex-col gap-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex items-center mb-4 sm:mb-0">
            <span className="mr-2 text-sm">Show</span>
            <select
              className="border border-black rounded p-1 text-sm"
              value={entriesPerPage}
              onChange={handleEntriesChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className="ml-2 text-sm">entries</span>
          </div>

          <div className="flex items-center">
            <span className="mr-2 text-sm">Search:</span>
            <input
              type="text"
              className="border border-black rounded p-1 text-sm"
              placeholder="Search by college name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {!loading && !error && (
          <div className="w-full overflow-x-auto">
            <table className="min-w-full bg-white border border-black">
              <thead>
                <tr className="bg-white text-left">
                  <th className="px-4 py-3 border">Rank</th>
                  {colleges.some((college) => college.Score) && (
                    <th className="px-4 py-3 border">Score</th>
                  )}
                  <th className="px-4 py-3 border">Name</th>
                  <th className="px-4 py-3 border">City</th>
                  <th className="px-4 py-3 border">State</th>
                </tr>
              </thead>
              <tbody>
                {displayedColleges.map((college) => (
                  <tr key={college.Institute_ID} className="text-left">
                    <td className="px-4 py-2 border">{college.Rank}</td>
                    {college.Score && (
                      <td className="px-4 py-2 border">{college.Score}</td>
                    )}
                    <td className="px-4 py-2 border">
                      {college?.Name || "Unknown"}
                    </td>
                    <td className="px-4 py-2 border">{college.City}</td>
                    <td className="px-4 py-2 border">{college.State}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!loading && !error && (
          <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
            <div className="text-sm text-black mb-4 sm:mb-0">
              Showing {startIndex + 1} to{" "}
              {Math.min(startIndex + entriesPerPage, filteredColleges.length)}{" "}
              of {filteredColleges.length} entries
            </div>

            <div className="flex items-center">
              <button
                className="text-sm text-black mr-2"
                onClick={handlePreviousPage}
                disabled={currentPage === 1} // Disable button if on the first page
                style={{
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                }}
              >
                Previous
              </button>
              <input
                type="number"
                className="w-12 border border-black rounded p-1 text-sm text-center"
                value={currentPage}
                readOnly
              />
              <button
                className="text-sm text-black ml-2"
                onClick={handleNextPage}
                disabled={
                  currentPage === totalPages || filteredColleges.length === 0
                }
                style={{
                  cursor:
                    currentPage === totalPages || filteredColleges.length === 0
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndiaRankingOverall;
