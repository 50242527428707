import React, { useState } from "react";

const faqs = [
  {
    question: "What is mentoring?",
    answer:
      "Mentoring is a supportive relationship in which an experienced individual guides and advises someone looking to grow in a specific area, such as academics or career development.",
  },
  {
    question: "How do I choose a mentor?",
    answer:
      "Choose a mentor based on their experience, communication style, and how well their expertise aligns with your goals.",
  },
  {
    question: "What should I expect from a mentoring session?",
    answer:
      "Expect to discuss your goals, challenges, and actionable steps. A mentor provides guidance, feedback, and resources to help you progress.",
  },
  {
    question: "How long does a mentoring relationship typically last?",
    answer:
      "The length varies depending on your goals. Some mentoring relationships are short-term, while others last for years.",
  },
  {
    question: "Can I have more than one mentor?",
    answer:
      "Yes, having multiple mentors can provide diverse perspectives and expertise in different areas.",
  },
  {
    question: "How often should I meet with my mentor?",
    answer:
      "Frequency depends on your needs and availability, but regular check-ins, such as bi-weekly or monthly, are common.",
  },
  {
    question: "What if I feel my mentor is not a good fit?",
    answer:
      "It’s okay to reassess the relationship. If you feel it’s not working, discuss your concerns openly or consider finding a new mentor.",
  },
  {
    question: "Are mentoring sessions confidential?",
    answer:
      "Generally, yes. Both mentor and mentee should agree to keep discussions private to build trust.",
  },
  {
    question: "What should I prepare for a mentoring session?",
    answer:
      "Prepare questions, topics, and any progress updates. Being prepared helps maximize the value of the session.",
  },
  {
    question: "How can I provide feedback to my mentor?",
    answer:
      "Provide constructive feedback directly. Share what’s working and suggest any adjustments to improve the mentoring experience.",
  },
];

const KnowledgeHubFAQs: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white pt-10 px-6">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-[#173CBA] mb-3">
          Knowledge Hub (FAQ'S)
        </h2>

        {faqs.map((faq, index) => (
          <div key={index} className="mb-2">
            <button
              onClick={() => toggleFAQ(index)}
              className={`w-full flex items-center text-left font-semibold text-lg py-3  `}
            >
              <span
                className={`bg-[#173CBA] text-white text-lg rounded-md w-6 h-6 flex items-center justify-center mr-4`}
              >
                {openIndex === index ? "−" : "+"}
              </span>
              <span>{faq.question}</span>
            </button>
            {openIndex === index && (
              <div className=" text-[#383838] text-sm px-8 py-2 ">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgeHubFAQs;
