import React, { useState } from "react";
import startYourJourneyImg from "../../images/study-abroad/Student/ScholarshipGuidance-img.png";
import { EpRight } from "../study-abroad-landing/Banner/Banner";
import LeadGeneration from "../LeadGeneration";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";

interface ScholarshipGuidanceProps {
  data: {
    title: string;
    description: string;
    buttonText: string;
  };
}

const ScholarshipGuidance: React.FC<ScholarshipGuidanceProps> = ({ data }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <section className="bg-[#FFF9E5] py-9 max-sm:py-2">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row-reverse items-center gap-10 ">
          <div className="w-full lg:w-1/2 lg:mr-[6%]">
            <div className="flex flex-col gap-7 lg:text-right">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-light max-lg:font-medium text-blue">
                  {data.title}
                </h3>
                <div className="flex flex-col">
                  <p className="text-base text-black">{data.description}</p>
                </div>
              </div>
              <div className="flex flex-row lg:justify-end">
                <LeadGenCtaBtn
                  pageName="study-abroad-student"
                  ctaName="connect-to-study-abroad-mentor"
                  className="bg-green hover:bg-blue button-styles py-3.5 px-3 green-arrow-button flex flex-row items-center justify-center gap-2 text-white"
                >
                  {data.buttonText} <EpRight className="green-arrow-icon" />
                </LeadGenCtaBtn>
                <div className="flex flex-col justify-start text-left"></div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4 xl:w-1/2 hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex w-full h-full">
              <img
                src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Student/study-abroad-student-scholorshipguidence.jpg"
                alt={data.title}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScholarshipGuidance;
