import config from "../../../util/config";

export const ExploreColleges = [
  { text: "BML Munjal", url: config.apiUrl+"/college-details/bml-munjal-university-gurgaon" },
  { text: "Aditya University", url: config.apiUrl+"/college-details/aditya-university-kakinada" },
  { text: "Sreenidi University", url: config.apiUrl+"/college-details/sreenidhi-university-hyderabad" },
  { text: "Parul University", url: config.apiUrl+"/college-details/parul-university-vadodara" },
  { text: "Bennet University", url: config.apiUrl+"/college-details/bennett-university-greater-noida" },
  { text: "Jain University", url: config.apiUrl+"/college-details/jain-deemed-to-be-university-bangalore" },
  { text: "Woxsen University", url: config.apiUrl+"/college-details/woxsen-university-hyderabad" },
];

export const FooterLinks = [
  {
    title: "Explore Careers",
    links: [
      { text: "Data Scientist", url: "/career-details/data-scientist" },
      { text: "Cyber Security Analyst", url: "/career-details/cyber-security-analyst" },
      { text: "Cloud Developer", url: "/career-details/cloud-developer" },
      { text: "Full Stack Developer", url: "/career-details/full-stack-developer" },
      { text: "DevOps Engineer", url: "/career-details/devops-engineer" },
      { text: "Blockchain Developer", url: "/career-details/blockchain-developer" },
      { text: "Aerospace Engineer", url: "/career-details/aerospace-engineer" },
      { text: "Investment Banker", url: "/career-details/investment-banker" },
      { text: "Judge", url: "/career-details/judge" },
      { text: "Surgeon", url: "/career-details/surgeon" },
      { text: "Software Engineer", url: "/career-details/software-engineer" },
      { text: "Business Analyst", url: "/career-details/business-analyst" },
      { text: "Digital Marketing Executive", url: "/career-details/digital-marketing-executive" },
      { text: "Ethical Hacker", url: "/career-details/ethical-hacker" },
    ],
  },
  {
    title: "Explore Exams",
    links: [
      { text: "CLAT 2025 - Law", url: "/exam-details/clat" },
      { text: "AILET 2025 - Law", url: "/exam-details/ailet-pg" },
      { text: "LSAT 2025 - Law", url: "/exam-details/lsat-india" },
      { text: "CUET UG 2025 - Arts", url: "/exam-details/cuet-ug" },
      { text: "CUET PG 2025 - Arts", url: "/exam-details/cuet-pg" },
      { text: "IIT JAM 2025  - Science", url: "/exam-details/IIT-JAM" },
      { text: "UGC NET 2025 - Competition", url: "/exam-details/ugc-net" },
      { text: "NEET UG 2025- Medicine", url: "/exam-details/neet-pg" },
      { text: "VITEEE 2025 - Engineering", url: "/exam-details/viteee" },
      { text: "MAT 2025 - Management", url: "/exam-details/mat" },
      { text: "AP EAPCET 2025 - Engineering", url: "/exam-details/AP-EAPCET" },
      { text: "NATA 2025 - Architecture", url: "/exam-details/nata" },
      { text: "KIITEE 2025 - Engineering", url: "/exam-details/kiitee" },
      { text: "MET 2025- Engineering", url: "/exam-details/MET" },
    ],
  },
  {
    title: "Explore Colleges",
    links: [
      { text: "IIT Madras", url: config.apiUrl+"/college-details/iit-madras-indian-institute-of-technology-madras" },
      { text: "IIT Kanpur", url: config.apiUrl+"/college-details/iit-kanpur-indian-institute-of-technology-kanpur" },
      { text: "IIT Delhi", url: config.apiUrl+"/college-details/iit-delhi-indian-institute-of-technology-delhi" },
      { text: "IIT Bombay", url: config.apiUrl+"/college-details/iit-bombay-indian-institute-of-technology-bombay" },
      { text: "NIT Warangal", url: config.apiUrl+"/college-details/nit-warangal-national-institute-of-technology-warangal" },
      { text: "NIT Trichy", url: config.apiUrl+"/college-details/nit-trichy-national-institute-of-technology-tiruchirappalli" },
      { text: "IIIT Hyderabad", url: config.apiUrl+"/college-details/iiit-hyderabad-international-institute-of-information-technology-hyderabad",},
      { text: "IISC Bangalore",  url: config.apiUrl+"/college-details/iisc-bangalore-indian-institute-of-science-bangalore",},
      { text: "CARE", url: config.apiUrl+"/college-details/care-chettinad-academy-of-research-and-education" },
      { text: "UPES", url: config.apiUrl+"/college-details/upes-university-of-petroleum-and-energy-studies " },
      { text: "GITAM", url: config.apiUrl+"/college-details/gitam-gandhi-institute-of-technology-and-management " },
      { text: "KIIT University", url: config.apiUrl+"/college-details/kiit-university-kalinga-institute-of-industrial-technology-bhubaneswar" },
      { text: "Sathyabama University", url: config.apiUrl+"/college-details/sathyabama-university-sathyabama-institute-of-science-and-technology-chennai" },
      { text: "Amrita Vishwa Vidyapeetham", url: config.apiUrl+"/college-details/amrita-university" },
    ],
  },
  {
    title: "Explore Courses",
    links: [
      { text: "Artificial Intelligence and Machine Learning", url: config.apiUrl+"/course-details/artificial-intelligence-and-machine-learning" },
      { text: "Computer Science Engineering", url: config.apiUrl+"/course-details/computer-science-engineering" },
      { text: "Robotics Engineering", url: config.apiUrl+"/course-details/robotics-engineering" },
      { text: "Mechanical Engineering", url: config.apiUrl+"/course-details/mechanical-engineering" },
      { text: "Electrical and Electronics Engineering", url: config.apiUrl+"/course-details/electrical-and-electronics-engineering" },
      { text: "Electronics and Communications Engineering", url: config.apiUrl+"/course-details/electronics-and-communications-engineering" },
      { text: "Aeronautical Engineering", url: config.apiUrl+"/course-details/aeronautical-engineering" },
      { text: "Automation and Robotics Engineering", url: config.apiUrl+"/course-details/automation-and-robotics-engineering" },
      { text: "Biotechnology Engineering", url: config.apiUrl+"/course-details/biotechnology-engineering" },
      { text: "Petroleum Engineering", url: config.apiUrl+"/course-details/petroleum-engineering" },
      { text: "Financial Management", url: config.apiUrl+"/course-details/financial-management" },
      { text: "Operations Management", url: config.apiUrl+"/course-details/operations-management" },
      { text: "Pharmacy", url: config.apiUrl+"/course-details/neurology" },
      { text: "Neurology", url: config.apiUrl+"/course-details/neurology" },
    ],
  },
  {
    title: "Explore Resources",
    links: [
      { text: "News & Articles", url: config.apiUrl+"/blogs" },
      { text: "Education For All", url: config.apiUrl+"/education-for-all" },
      { text: "Application Admissions", url: config.apiUrl+"/applications-admissions" },
      { text: "Scholarships", url: config.apiUrl+"/scholarships" },
      { text: "Mentors", url: config.apiUrl+"/mentors" },
      { text: "IKIGAI Career Discovery", url: config.apiUrl+"/ikigai-career-discovery" },
      { text: "Refer and Earn", url: config.apiUrl+"/refer-and-earn" },
      { text: "Regulatory Professional Council", url: config.apiUrl+"/regulatory-professional-councils" },
      { text: "Exams", url: config.apiUrl+"/exams" },
      { text: "Colleges", url: config.apiUrl+"/colleges" },
      { text: "Careers", url: config.apiUrl+"/careers" },
      { text: "Courses", url: config.apiUrl+"/courses" },
    ],
  },
  {
    title: "Explore Rankings & Accrediations ",
    links: [
      { text: "NIRF Overall Rankings", url: config.apiUrl+"/rankings/overall" },
      { text: "NIRF Universiteis Rankings", url: config.apiUrl+"/rankings/university"},
      { text: "NIRF College rankings", url: config.apiUrl+"/rankings/college"},
      { text: "NIRF Research Rankings", url:config.apiUrl+"/rankings/research" },
      { text: "NIRF Engineering Rankings", url: config.apiUrl+"/rankings/engineering" },
      { text: "NIRF Management Rankings", url: config.apiUrl+"/rankings/management" },
      { text: "NIRF Pharmacy Rankings", url: config.apiUrl+"/rankings/pharmacy"},
      { text: "NIRF Medical Rankings", url: config.apiUrl+"/rankings/medical" },
      { text: "NIRF Dental Rankings", url:config.apiUrl+"/rankings/dental" },
      { text: "NIRF Law Rankings", url: config.apiUrl+"/rankings/law" },
      { text: "NIRF Architecture Rankings", url: config.apiUrl+"/rankings/architecture" },
      { text: "NIRF Innvoation Rankings", url: config.apiUrl+"/rankings/innovation" }
    ],
  },
  {
    title: "Explore Study Abroad",
    links: [
      { text: "United States", url: config.apiUrl+"/study-abroad?country=USA"},
      { text: "United Kingdom", url:config.apiUrl+"/study-abroad?country=UK" },
      { text: "Canada", url: config.apiUrl+"/study-abroad?country=CANADA" },
      { text: "Australia", url:config.apiUrl+"/study-abroad?country=AUSTRALIA" },
      { text: "France", url: config.apiUrl+"/study-abroad?country=FRANCE" },
      { text: "Germany", url: config.apiUrl+"/study-abroad?country=GERMANY" },
      { text: "Ireland", url: config.apiUrl+"/study-abroad?country=IRELAND" },
      { text: "Italy", url: config.apiUrl+"/study-abroad?country=ITALY" },
      { text: "New Zealand", url: config.apiUrl+"/study-abroad?country=NEWZEALAND"},
      { text: "Dubai", url: config.apiUrl+"/study-abroad?country=DUBAI" },
      { text: "Singapore", url: config.apiUrl+"/study-abroad?country=SINGAPORE"},
      { text: "Sweden", url: config.apiUrl+"/study-abroad?country=SWEDEN" },
    ],
  },
  {
    title: "Explore Tools",
    links: [
      { text: "College Predictor", url: config.apiUrl+"/college-predictor" },
      { text: "College Compare", url: config.apiUrl+"/college-compare" }
    ],
  },
  
];
