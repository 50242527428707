import React from "react";
import { Link } from "react-router-dom";

export const PricingPlans = () => {
  const plans = [
    {
      title: "Academic Success Pack",
      description: (
        <>
          <strong>3 - Sessions each with</strong>
          <ul className="list-disc pl-5">
            <li>Student Buddy Mentor</li>
            <li>Admission Mentor</li>
          </ul>
        </>
      ),
      cost: "Rs: 3,000",
      taxes: "+ Taxes",
    },
    {
      title: "Career & College Prep Pack",
      description: (
        <>
          <strong>2 - sessions each with</strong>
          <ul className="list-disc pl-5">
            <li>Student Buddy Mentor</li>
            <li>Career Mentor</li>
            <li>Admission Mentor</li>
            <li>Scholarship Mentor</li>
          </ul>
        </>
      ),
      cost: "Rs: 6,000",
      taxes: "+ Taxes",
    },
    {
      title: "Premium All-Inclusive Pack",
      description: (
        <>
          <strong> 2 - sessions with all mentors</strong>
          <ul className="list-disc list-outside pl-5">
            <li>Student Buddy Mentor</li>
            <li>Career Mentor</li>
            <li>Admission Mentor</li>
            <li>Student Health Mentor</li>
            <li>Scholarship Mentor</li>
            <li>Loan Mentorship Mentor</li>
          </ul>
        </>
      ),
      cost: "Rs: 9,999",
      taxes: "+ Taxes",
    },
  ];

  return (
    <div className="bg-[#173CBA]  py-10 px-5">
      <div className="container">
        <div className=" mb-8">
          <h2 className="text-5xl font-bold text-[#0C9] mb-2">
            The Mentorship Platform Built to Help You Grow
          </h2>
          <p className="text-xl text-[#F7F7F7]">
            Connect with the right people to clear your doubts and enhance your
            skills with confidence.
          </p>
          <p className="text-xl text-[#F7F7F7]">
            Unlock the full value of mentorship and reach your potential.
          </p>
        </div>

        <div className="flex md:justify-center min-max-md:space-x-2 xl:space-x-6 min-max-xss:flex-col min-max-xs:flex-col xl:gap-6 max-md:gap-7">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="bg-[#FFF] relative w-[413px] h-[560px] max-sm:h-[536px] max-md:[650px] min-max-md:h-[819px] text-[#333] p-6 rounded-tl-[26px] rounded-br-[26px] shadow-md min-max-xss:w-full min-max-xs:w-full"
            >
              <h3
                className="text-2xl max-sm:text-lg font-semibold bg-[#173CBA] text-[#FFF9E5] p-2 mb-4 text-center -ml-6"
                style={{ borderRadius: "0px 50px 50px 0px" }}
              >
                {plan.title}
              </h3>
              <p className="flex text-[21px] max-sm:text-[19px] font-normal gap-[6px] items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M15.1237 5.13011L13.7639 6.48179C13.979 7.11422 14.0958 7.79316 14.0958 8.5C14.0958 11.935 11.3347 14.7205 7.92983 14.7205C4.52496 14.7205 1.76389 11.9365 1.76389 8.5C1.76389 5.06501 4.52496 2.2795 7.92983 2.2795C9.62304 2.2795 11.1565 2.96774 12.2704 4.08225L13.7547 3.07159C12.3057 1.49051 10.233 0.5 7.92983 0.5C3.55083 0.5 0 4.08225 0 8.5C0 12.9177 3.55083 16.5 7.92983 16.5C12.3088 16.5 15.8597 12.9177 15.8597 8.5C15.8597 7.29558 15.5954 6.15317 15.1237 5.13011Z"
                    fill="#173CBA"
                  />
                  <path
                    d="M7.92982 9.4022C10.1977 6.22297 13.0863 4.08695 16.3605 2.59577C16.5249 2.48571 16.6509 2.71358 16.5357 2.80968C13.5841 5.34407 10.8722 8.3683 8.37233 11.8281C8.1142 12.1133 7.66401 12.0932 7.432 11.7862L5.8909 9.74167C4.9183 8.514 3.98719 8.02573 3.10217 8.31404C3.02995 8.34039 2.97925 8.26289 3.04378 8.19778L3.71523 7.39794C5.32547 5.68819 7.15543 8.64266 7.92982 9.4022Z"
                    fill="#00CC99"
                  />
                </svg>
                <span>Stream & College Selection</span>
              </p>
              <p className="flex text-[21px] max-sm:text-[19px] font-normal gap-[6px] items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M15.1237 5.13011L13.7639 6.48179C13.979 7.11422 14.0958 7.79316 14.0958 8.5C14.0958 11.935 11.3347 14.7205 7.92983 14.7205C4.52496 14.7205 1.76389 11.9365 1.76389 8.5C1.76389 5.06501 4.52496 2.2795 7.92983 2.2795C9.62304 2.2795 11.1565 2.96774 12.2704 4.08225L13.7547 3.07159C12.3057 1.49051 10.233 0.5 7.92983 0.5C3.55083 0.5 0 4.08225 0 8.5C0 12.9177 3.55083 16.5 7.92983 16.5C12.3088 16.5 15.8597 12.9177 15.8597 8.5C15.8597 7.29558 15.5954 6.15317 15.1237 5.13011Z"
                    fill="#173CBA"
                  />
                  <path
                    d="M7.92982 9.4022C10.1977 6.22297 13.0863 4.08695 16.3605 2.59577C16.5249 2.48571 16.6509 2.71358 16.5357 2.80968C13.5841 5.34407 10.8722 8.3683 8.37233 11.8281C8.1142 12.1133 7.66401 12.0932 7.432 11.7862L5.8909 9.74167C4.9183 8.514 3.98719 8.02573 3.10217 8.31404C3.02995 8.34039 2.97925 8.26289 3.04378 8.19778L3.71523 7.39794C5.32547 5.68819 7.15543 8.64266 7.92982 9.4022Z"
                    fill="#00CC99"
                  />
                </svg>
                <span>Career Guidance Sessions</span>
              </p>
              <p className="flex text-[21px] max-sm:text-[19px] font-normal gap-[6px] items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M15.1237 5.13011L13.7639 6.48179C13.979 7.11422 14.0958 7.79316 14.0958 8.5C14.0958 11.935 11.3347 14.7205 7.92983 14.7205C4.52496 14.7205 1.76389 11.9365 1.76389 8.5C1.76389 5.06501 4.52496 2.2795 7.92983 2.2795C9.62304 2.2795 11.1565 2.96774 12.2704 4.08225L13.7547 3.07159C12.3057 1.49051 10.233 0.5 7.92983 0.5C3.55083 0.5 0 4.08225 0 8.5C0 12.9177 3.55083 16.5 7.92983 16.5C12.3088 16.5 15.8597 12.9177 15.8597 8.5C15.8597 7.29558 15.5954 6.15317 15.1237 5.13011Z"
                    fill="#173CBA"
                  />
                  <path
                    d="M7.92982 9.4022C10.1977 6.22297 13.0863 4.08695 16.3605 2.59577C16.5249 2.48571 16.6509 2.71358 16.5357 2.80968C13.5841 5.34407 10.8722 8.3683 8.37233 11.8281C8.1142 12.1133 7.66401 12.0932 7.432 11.7862L5.8909 9.74167C4.9183 8.514 3.98719 8.02573 3.10217 8.31404C3.02995 8.34039 2.97925 8.26289 3.04378 8.19778L3.71523 7.39794C5.32547 5.68819 7.15543 8.64266 7.92982 9.4022Z"
                    fill="#00CC99"
                  />
                </svg>
                <span>Parent Guidance Sessions</span>
              </p>
              <p className="text-[21px] max-sm:text-[19px] mb-4 mt-4">
                {plan.description}
              </p>
              <div className="absolute bottom-6 items-end">
                <p className="text-[#173CBA] text-lg ">
                  <span className="">Cost:</span> <br />
                  <span className="text-2xl font-bold ">{plan.cost}</span>
                  {plan.taxes}
                </p>
                <button
                  className="text-[#383838] mt-4 inline-block "
                  title="Mentor Scheduling will be available from January - 2025"
                >
                  Get Started →
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-10 text-sm text-white">
          <p>
            (FAQS) • Pause or cancel your plan at any time. • You have access to
            all mentors on all plans. • Credits are valid for 12 months after
            purchase date. • Refund unused credits within 30 days of purchase.
          </p>
        </div>
      </div>
    </div>
  );
};
