import React, { useState } from "react";

const coursesData = [
  {
    courseName: "Aeronautical Engineering",
    link: "/courses/aeronautical-engineering",
  },
  {
    courseName: "Archaeology",
    link: "/courses/archaeology",
  },
  {
    courseName: "Artificial Intelligence",
    link: "/courses/artificial-intelligence-and-machine-learning",
  },
  {
    courseName: "Automation Engineering",
    link: "/courses/automation-and-robotics-engineering",
  },
  {
    courseName: "Automotive Engineering",
    link: "/courses/automotive-engineering",
  },
  {
    courseName: "Biotechnology Engineering",
    link: "/courses/biotechnology-engineering",
  },
  { courseName: "Cardiology", link: "/courses/cardiology" },
  { courseName: "Chemical Engineering", link: "/courses/chemical-engineering" },
  {
    courseName: "Dermatology",
    link: "/courses/dermatology",
  },
  {
    courseName: "Electrical Engineering",
    link: "/courses/electrical-and-electronics-engineering",
  },
  {
    courseName: "Entrepreneurship",
    link: "/courses/entrepreneurship",
  },
  {
    courseName: "Financial Management",
    link: "/courses/financial-management",
  },
  {
    courseName: "Horticulture",
    link: "/courses/horticulture",
  },
  { courseName: "Hotel Management", link: "/courses/hotel-management" },
  {
    courseName: "International Business",
    link: "/courses/international-business",
  },
  { courseName: "Marine Engineering", link: "/courses/marine-engineering" },
  {
    courseName: "Mechanical Engineering",
    link: "/courses/mechanical-engineering",
  },
  { courseName: "Neurology", link: "/courses/neurology" },
  { courseName: "Nursing", link: "/courses/nursing" },
  {
    courseName: "Operations Management",
    link: "/courses/operations-management",
  },
  { courseName: "Ocean Engineering", link: "/courses/ocean-engineering" },
  {
    courseName: "Pharmaceutical Engineering",
    link: "/courses/pharmaceutical-engineering",
  },
  {
    courseName: "Petroleum Engineering",
    link: "/courses/petroleum-engineering",
  },
  {
    courseName: "Rubber and Plastic Engineering",
    link: "/courses/rubber-plastic-engineering",
  },
  { courseName: "Robotics Engineering", link: "/courses/robotics-engineering" },
  { courseName: "Textile Engineering", link: "/courses/textile-engineering" },
  {
    courseName: "Telecommunication Engineering",
    link: "/courses/telecommunication-engineering",
  },
  { courseName: "Dual Degree BS + MS", link: "/courses/dual-degree-bs-ms" },
  {
    courseName: "Dual Degree B.Tech + M.Tech",
    link: "/courses/dual-degree-btech-mtech",
  },
  {
    courseName: "Dual Degree B.Tech + MBA",
    link: "/courses/dual-degree-btech-mba",
  },
];

const CoursesList = () => {
  const [showAll, setShowAll] = useState(false);

  const displayedCourses = showAll ? coursesData : coursesData.slice(0, 15);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="bg-white shadow-sm">
      <h4 className="w-full bg-blue text-white py-2 mb-4 text-lg text-center font-medium">
        Courses You May Be Interested In
      </h4>
      <div className="px-3 py-2">
        <ul className="list-none">
          {displayedCourses.map((course, index) => (
            <li key={index} className="mb-2 border-b border-[#DAD5D5] pb-2">
              <a
                href={course.link}
                target="blank"
                className="text-[#747474] text-sm"
              >
                {course.courseName}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center bg-green">
        <button
          onClick={toggleShowAll}
          className="bg-blue text-white px-4 py-2 rounded-full text-lg"
        >
          {showAll ? "Show Less" : "View More"}
        </button>
      </div>
    </div>
  );
};

export default CoursesList;
