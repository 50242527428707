import { useEffect, useState } from "react";
import { Mentor } from "../Mentors";
import { Link } from "react-router-dom";
import { convertToKebabCase } from "../../../util/commonUtils";

interface MentorCardsProps {
  mentors: Mentor[];
}

const MentorCards: React.FC<MentorCardsProps> = ({ mentors }) => {
  const [activeTab, setActiveTab] = useState("All");
  const [visibleMentors, setVisibleMentors] = useState(3);
  const [screenSize, setScreenSize] = useState(getScreenSize());

  function getScreenSize() {
    if (window.innerWidth < 768) return "mobile";
    if (window.innerWidth < 1024) return "tablet";
    return "desktop";
  }

  useEffect(() => {
    const handleResize = () => setScreenSize(getScreenSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize === "mobile") setVisibleMentors(1);
    else if (screenSize === "tablet") setVisibleMentors(2);
    else setVisibleMentors(3);
  }, [activeTab, screenSize]);

  const tabs = [
    "All",
    ...Array.from(new Set(mentors.map((mentor) => mentor.role))),
  ];

  const filteredMentors =
    activeTab === "All"
      ? mentors
      : mentors.filter((mentor) => mentor.role === activeTab);

  const handleClick = () => {
    if (
      (screenSize === "mobile" && visibleMentors >= 2) ||
      (screenSize === "tablet" && visibleMentors >= 4) ||
      (screenSize === "desktop" &&
        visibleMentors >= Math.min(6, filteredMentors.length))
    ) {
      if (screenSize === "mobile") setVisibleMentors(1);
      else if (screenSize === "tablet") setVisibleMentors(2);
      else setVisibleMentors(3);
    } else {
      if (screenSize === "mobile")
        setVisibleMentors((prev) => Math.min(prev + 1, 2));
      else if (screenSize === "tablet")
        setVisibleMentors((prev) => Math.min(prev + 2, 4));
      else setVisibleMentors((prev) => Math.min(prev + 3, 6));
    }
  };

  return (
    <div className="bg-[#F7F7F7] py-10 px-5">
      <div className="container">
        <h2 className="text-[36px] text-center font-bold mb-8 max-md:text-4xl">
          Why settle for just one mentor when you can have a whole network of
          them?
        </h2>

        <div className="flex flex-wrap gap-2 justify-center mb-10">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 rounded bg-[#F49FB6] text-white hover:bg-[#D68198] ${
                activeTab === tab ? "!bg-[#D68198] text-white" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="flex justify-center flex-col mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-auto">
            {filteredMentors.slice(0, visibleMentors).map((mentor) => (
              <div
                key={mentor.id}
                className="flex flex-col w-[310px] bg-white border border-gray-300 shadow-md cursor-pointer"
              >
                <div className="bg-[#173CBA] h-[40px] w-full" />
                <div className="flex items-center justify-between px-6 -mt-6">
                  <div className="flex flex-col w-[115px] gap-[10px]">
                    <div className="relative w-[90px] h-[90px] rounded-full border-4 border-white shadow-lg">
                      <img
                        loading="lazy"
                        src={mentor.imageUrl}
                        className="object-cover w-full h-full rounded-full"
                        alt={mentor.name}
                      />
                      <img
                        loading="lazy"
                        src="/images/india-flag-img.webp"
                        className="absolute bottom-0 right-0 w-[24px] h-[16px]"
                        alt="India Flag"
                      />
                    </div>
                    <h2 className="text-sm font-bold text-[#173CBA]">
                      {mentor.name}
                    </h2>
                  </div>
                  <div className="flex flex-col items-end w-[160px] mt-7 gap-2">
                    <img
                      src="/images/collge-mentor-logo.webp"
                      className="w-[120px]"
                      alt="College Mentor Logo"
                    />
                    <p className="mt-1 text-xs text-black text-right">
                      {mentor.experiance}
                    </p>
                  </div>
                </div>
                <hr className="mt-4 border-[#A3A2AC] h-[0.5px] mx-[18px] mb-4" />
                <div className="px-4 py-4 flex flex-grow flex-col">
                  <h3 className="text-sm font-bold text-black">About me</h3>
                  <p className="mt-1 text-sm text-black line-clamp-2">
                    {mentor.worksOn}
                  </p>
                  <Link
                    to={`/mentor/${convertToKebabCase(mentor.name)}-${
                      mentor.id
                    }`}
                    className="mt-3 text-sm text-[#173CBA] hover:text-blue-600 focus:outline-none text-left"
                  >
                    Read more about {mentor.name}
                  </Link>
                </div>
                <div className="flex justify-center p-4 mt-auto">
                  <Link
                    to="https://api.whatsapp.com/send?text=hi&phone=918959591818"
                    target="_blank"
                    className="px-6 py-2 text-sm font-semibold text-[#173CBA] border border-[#173CBA] rounded-full hover:bg-[#FFF9E5] focus:outline-none"
                  >
                    Chat With College Mentor
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-6">
            {filteredMentors.length >
              (screenSize === "mobile"
                ? 1
                : screenSize === "tablet"
                ? 2
                : 3) && (
              <button
                className="px-6 py-2 text-sm font-semibold rounded-full bg-[#173CBA] text-white hover:bg-[#0c9] focus:outline-none"
                onClick={handleClick}
              >
                {visibleMentors >=
                (screenSize === "mobile" ? 2 : screenSize === "tablet" ? 4 : 6)
                  ? "See Less"
                  : "See More"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorCards;
