import React, { useState } from "react";

export const MentorMenteeMatch: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"mentees" | "mentors">("mentees");

  return (
    <div className="bg-[#FFF9E5] md:pt-6 md:pb-3 w-full md:mx-auto rounded-lg max-md:w-full max-md:pt-5">
      <div className="container flex flex-col max-md:w-full max-md:gap-7">
        <h2 className="text-5xl font-bold text-[#383838]  min-max-md:px-3 min-max-lg:px-4 max-md:text-4xl">
          Reach Your Goals Faster with Expert Mentors
        </h2>

        <div className=" flex justify-between items-start gap-6 min-max-md:gap-1 max-md:w-full max-md:flex-col  min-max-md:w-full">
          <div className="w-[57.7%] text-[#333] max-md:w-full">
            <div className="flex  flex-row gap-14 lg:mb-3 max-md:mb-5">
              <button
                className={`pr-2 py-2 text-lg font-semibold w-[106px] max-md:text-2xl ${
                  activeTab === "mentees"
                    ? "text-[#173CBA] border-b-2 border-[#0C9]"
                    : "text-[#747474]"
                }`}
                onClick={() => setActiveTab("mentees")}
              >
                Mentees
              </button>
              <button
                className={`px-2 py-2 text-lg font-semibold max-md:text-2xl ${
                  activeTab === "mentors"
                    ? "text-[#173CBA] border-b-2 border-[#0c9]"
                    : "text-[#747474]"
                }`}
                onClick={() => setActiveTab("mentors")}
              >
                Mentors
              </button>
            </div>

            {activeTab === "mentees" ? (
              <div className="max-md:flex max-md:gap-3 max-md:flex-col">
                {[
                  {
                    title: "Concept",
                    text: "Tailored Support – Mentoring is tailored to meet each student’s individual needs and aspirations, helping them transform their passion into meaningful conversations, lasting friendships, and a global network.",
                  },
                  {
                    title: "Smart Matching",
                    text: "One mentee, six mentors – first-of-its-kind mentoring for overall development. Each student receives tailored support from a dedicated team of six mentors.",
                  },
                  {
                    title: "Benefits",
                    text: "Every student possesses a unique perspective, and our 1:1 mentoring process is thoughtfully designed to provide personalized guidance that aligns with their individual thinking and aspirations.",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="max-md:bg-white max-md:rounded-lg max-md:shadow-md  flex max-md:justify-center  gap-[5.846px]  flex-col max max-md:h-auto max-md:py-4 max-md:px-6"
                  >
                    <h2 className="text-[#173CBA]  text-2xl  mt-4 max-md:text-[12.277px] font-bold max-md:leading-[19.293px] leading-[38px]">
                      {item.title}:
                    </h2>
                    <p className="text-[#747474] text-lg  max-md:text-[12.277px] font-normal max-md:leading-[19.293px] leading-[38px]">
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="max-md:flex max-md:gap-3 max-md:flex-col">
                {[
                  {
                    title: "Concept",
                    text: "Every student possesses a unique perspective, and our 1:1 mentoring process is thoughtfully designed to provide personalized guidance that aligns with their individual thinking and aspirations.",
                  },
                  {
                    title: "Smart Matching",
                    text: "Mentors will be paired with mentees to provide valuable insights into their goals and craft a clear roadmap to help them achieve their aspirations.",
                  },
                  {
                    title: "Benefits",
                    text: "Mentors gain the opportunity to share their expertise, enhance their leadership and communication skills, and build meaningful connections. Mentoring also provides personal fulfillment by positively impacting a student’s journey and growth.",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="max-md:bg-white max-md:rounded-lg max-md:shadow-md  flex max-md:justify-center  md:gap-[5.846px]  flex-col max max-md:h-auto max-md:py-4 max-md:px-6"
                  >
                    <h2 className="text-[#173CBA]  text-2xl  md:mt-4 max-md:text-[12.277px] font-bold max-md:leading-[19.293px] leading-[38px]">
                      {item.title}:
                    </h2>
                    <p className="text-[#747474] text-lg  max-md:text-[12.277px] font-normal max-md:leading-[19.293px] leading-[38px]">
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="relative w-[29.7%] h-auto flex items-center justify-center max-md:w-full min-max-md:my-auto">
            <div className="relative w-[324px] h-[565px] min-max-md:w-[324px] min-max-md:h-[461px] bg-[#F49FB6] rounded-xl p-4 max-md:mx-auto xl:right-[49px] overflow-auto max-md:w-[177px] max-md:h-[305px]"></div>
            <div className="absolute bottom-0 w-[524px] max-md:w-[236px] min-max-md:w-[296px]">
              <img
                src="/images/mentors/mentee.png"
                alt="Mentee"
                className="w-[429px] h-[537px] object-cover rounded-lg  max-md:w-[235px] max-md:object-fit max-md:h-[294px] min-max-md:w-[295px] min-max-md:h-[374px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
