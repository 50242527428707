import configData from "./configData.json";

const config = {
  apiUrl: configData.environments.url,
  searchApiKey: configData.environments.searchApiKey,
  GA_TRACKING_ID: configData.environments.GA_TRACKING_ID,
  mobileNumber: 8959591818,
};

export default config;
