import React, { useEffect } from "react";
import RankingBanner from "./components/RankingBanner";
import RankingInfo from "./components/RankingInfo";
import IndiasTopInstitutions from "../scholarship-test/components/IndiasTopInstitutions";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";

const RankingFramework = () => {
  useEffect(() => {
    document.title = "College Mentor | Top Universities & NIRF Rankings 2025";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Discover the best universities and colleges with College Mentor. Explore NIRF rankings, UGC- approved colleges, stream-wise rankings, and top global universities to shape your future."
      );
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>College Mentor | Top Universities & NIRF Rankings 2025</title>
          <meta
            name="description"
            content="Discover the best universities and colleges with College Mentor. Explore NIRF rankings, UGC- approved colleges, stream-wise rankings, and top global universities to shape your future."
          />
          <meta
            name="keywords"
            content="Top ranks, NIRF, Stream wise, college rankings, compare top universities and colleges, compare best colleges, UGC Ranking, compare colleges as per their rank, Top 100 colleges, NIRF in Pharmacy, Top NIRF colleges in engineering, NIRF in Medical, Top NRIF colleges of India."
          />
          <link rel="canonical" href={`${config.apiUrl}/scholarships`} />

          <meta
            property="og:title"
            content="College Mentor | Top Universities & NIRF Rankings 2025"
          />
          <meta
            property="og:description"
            content="Discover the best universities and colleges with College Mentor. Explore NIRF rankings, UGC- approved colleges, stream-wise rankings, and top global universities to shape your future."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Top Universities & NIRF Rankings 2025"
          />
          <meta
            name="twitter:description"
            content="Discover the best universities and colleges with College Mentor. Explore NIRF rankings, UGC- approved colleges, stream-wise rankings, and top global universities to shape your future."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <RankingBanner />
        <IndiasTopInstitutions />
        <RankingInfo />
      </div>
    </>
  );
};

export default RankingFramework;
