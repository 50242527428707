import React from "react";
import { Link } from "react-router-dom";

const OverviewTabRightSide = () => {
  return (
    <div className="max-lg:h-auto bg-white mx-auto flex flex-col items-center max-md:w-full">
      <div className="w-full">
        <h3 className="text-[16px] font-light py-3 px-6">College Predictor</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="2"
          viewBox="0 0 100% 2"
          fill="none"
          className="max-lg:w-full"
        >
          <path
            d="M1 1H521"
            stroke="#BCBCBC"
            strokeWidth="1.49819"
            strokeLinecap="round"
          />
        </svg>
      </div>

      <div className="w-full flex flex-col items-center p-4 px-6">
        <h2 className="text-[#173CBA] text-center font-roboto text-[18px] font-light leading-[32px]">
          Top MBBS & BDS Colleges Predict Colleges
        </h2>

        <div className="w-full mt-[16px]">
          <img
            src="https://cdncollegementor.blob.core.windows.net/exams/examDetails/exam-details-ad.webp"
            alt="Students"
            className="w-full h-full rounded-lg object-cover"
          />
        </div>

        <p className="text-[#173CBA] text-center font-roboto text-[18px] font-light leading-[32px] my-4">
          Predict Colleges Based on your Rank
        </p>

        <div className="gap-[19px] w-full flex flex-col">
          <Link
            to={"/college-predictor"}
            className="w-full bg-[#173CBA] text-white font-medium text-[16px] leading-[24px] rounded-lg py-2 flex items-center flex-col"
          >
            Predict My College
          </Link>
          <button className="w-full bg-[#0c9] text-white font-medium text-[16px] leading-[24px] rounded-lg py-2 text-left px-[18px]">
            NOTIFICATIONS
          </button>
        </div>

        <div className="flex flex-col w-full gap-[9px] mt-4">
          {[
            "NEET UG 2024 Counselling: Registration for round 2 begins today",
            "Punjab NEET UG 2024 Counselling: Round 1 provisional seat allotment result out",
            "Kerala NEET UG 2024: Phase 1 provisional seat allotment out at cee.kerala.gov.in",
            "Kerala NEET UG 2024: Phase 1 provisional seat allotment out at cee.kerala.gov.in",
          ].map((notification, index) => (
            <span
              key={index}
              className="w-full text-[#383838] bg-[#F4F4F4] font-roboto text-[12px] font-normal leading-[24px] rounded-lg py-1 px-[18px]"
            >
              {notification}
            </span>
          ))}
          <Link
            to={"/blogs"}
            className="flex my-4 w-[129px] mx-auto py-2 px-[12px] justify-center text-white items-center gap-[4px] flex-shrink-0 rounded-[4px] bg-[#0C9]"
          >
            View More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
            >
              <path
                d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                fill="white"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OverviewTabRightSide;
