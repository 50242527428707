import React, { useState } from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import "./FeaturedPredictors.scss";
import PopupInfo from "../../../../components/popupInfo/popup";

const data = [
  {
    collegeLogo:
      "https://mentee-mentor-dev.vercel.app/images/careers/dreamscareer/enginnering.jpg",
    image: "/images/college-predictor/featured-predictors.webp",
    isDataAvailable: false,
    url: "SRM",
  },
  {
    collegeLogo:
      "https://mentee-mentor-dev.vercel.app/images/careers/dreamscareer/enginnering.jpg",
    isDataAvailable: false,
    image: "/images/college-predictor/featured-predictors.webp",
    url: "SRM University Chennai",
  },
  {
    collegeLogo:
      "https://mentee-mentor-dev.vercel.app/images/careers/dreamscareer/enginnering.jpg",
    isDataAvailable: false,
    image: "/images/college-predictor/featured-predictors.webp",
    url: "SRM University Chennai",
  },
];

const CollegeCard = ({
  image,
  collegeLogo,
  url,
  onClick,
}: {
  image?: string;
  collegeLogo: string;
  url: string;
  onClick: () => void;
}) => (
  <div className="w-[304px]" onClick={onClick}>
    <div className="flex flex-col gap-5 overflow-hidden cursor-pointer">
      <img
        src={image}
        className="rounded-xl object-contain w-[304px] h-[314px]"
        alt="Featured Predictors"
      />
    </div>
    <div className="flex px-12">
      <div className="relative w-full h-[140px] flex flex-col bg-white border pb-2 -translate-y-6 z-2 rounded-2xl justify-center items-center">
        <div className="pt-4 w-[60px] h-[60px]">
          <img src={collegeLogo} className="" alt="college Logo" />
        </div>
        <div className="flex name-featured mt-2 pt-4 pb-2">
          <span className="line-clamp-2 px-1">{url}</span>
        </div>
      </div>
    </div>
  </div>
);

const FeaturedPredictors = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState<string | null>(null);

  const handleCardClick = (collegeName: string) => {
    setSelectedCollege(collegeName);
    setShowPopup(true);
  };

  return (
    <>
      <div className="bg-[#FFF9E5] text-black my-7">
        <div className="container py-12">
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            Featured Predictors
          </h2>
          <div className="flex justify-center mt-6">
            <CustomSwiper
              className="!px-2"
              items={data.map((college, index) => (
                <CollegeCard
                  key={index}
                  {...college}
                  onClick={() => handleCardClick(college.url)}
                />
              ))}
              navigation={"bottom"}
              autoplay={!showPopup}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>

      {showPopup && (
        <PopupInfo isOpen={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </>
  );
};

export default FeaturedPredictors;
