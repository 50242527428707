import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../../../util/config";

interface TabProps {
  label: string;
  onClick: () => void;
  active: boolean;
}

const Tab = ({ label, onClick, active }: TabProps) => (
  <button
    className={`p-3 text-white rounded-tl-full rounded-bl-full w-full ${
      active ? "bg-[#2196f3] text-white font-bold" : "bg-[#678CFF]"
    }`}
    onClick={onClick}
  >
    {label}
  </button>
);

interface CardProps {
  image: string;
  title: string;
  category?: string;
  description: string;
  userName: string;
  time: string;
  slug: string;
}

const Card = ({
  image,
  title,
  description,
  userName,
  time,
  slug,
  category,
}: CardProps) => {
  const linkPath =
    category === "News" ? `/news/${slug}` : `/blogs/Exams/${slug}`;

  return (
    <div className="flex p-2 border-b border-[#DAD5D5] mb-4">
      <div>
        <span className="relative">
          <img src={image} alt="User" className="w-24 h-24 mr-4" />
        </span>
      </div>
      <div className="flex-1 gap-1 flex flex-col">
        <div className="flex flex-col gap-1">
          <h5 className="text-[#747474] text-sm font-medium line-clamp-2">
            {title}
          </h5>
          <p className="text-xs font-normal line-clamp-3">{description}</p>
        </div>
        <div className="flex flex-col">
          <ul className="text-xs list-none flex flex-wrap gap-1 font-medium">
            <li>{category}</li>
            <li>|</li>
            <li>{time}</li>
            <li>|</li>
            <li>{userName}</li>
          </ul>
        </div>

        <div className="flex flex-row gap-2 justify-end">
          <Link
            to={linkPath}
            className="text-[#113CC0] font-medium text-xs ml-1"
          >
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

const AlertTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"exam" | "news">("exam");
  const [alertsData, setAlertsData] = useState<{
    exam: CardProps[];
    news: CardProps[];
  }>({
    exam: [],
    news: [],
  });

  const [visibleItems, setVisibleItems] = useState(4);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/api/blogs/blogsandnews`
        );

        const sections = response.data.sections;
        const examsAndAdmissions = sections.find(
          (section: any) => section["Exams And Admissions"]
        );

        if (!examsAndAdmissions)
          throw new Error("Exams And Admissions section not found");

        const subcategories =
          examsAndAdmissions["Exams And Admissions"].subcategories;

        setAlertsData({
          exam: [
            ...subcategories["Articles & Blogs"].results.map((item: any) => ({
              image: item.imageUrl || "/images/default.png",
              title: item.title,
              category: item.category,
              description: item.description,
              userName: "Admin",
              slug: item.slug,
              time: item.publishedDate || "Unknown",
            })),
          ],
          news: [
            ...subcategories["Latest News"].results.map((item: any) => ({
              image: item.imageUrl || "/images/default.png",
              title: item.title,
              category: item.category,
              description: item.description,
              userName: "Admin",
              slug: item.slug,
              time: item.publishedDate || "Unknown",
            })),
          ],
        });
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };
    fetchAlerts();
  }, []);

  return (
    <div className="bg-white mx-auto rounded-tl-[20px] rounded-tr-xl">
      <div className="flex border-b mb-4 relative">
        {[
          { label: "Exam Alerts", key: "exam" },
          { label: "News Alerts", key: "news" },
        ].map((tab) => (
          <Tab
            key={tab.key}
            label={tab.label}
            onClick={() => setActiveTab(tab.key as "exam" | "news")}
            active={activeTab === tab.key}
          />
        ))}
      </div>

      {alertsData[activeTab].length > 0 ? (
        <>
          {alertsData[activeTab].slice(0, visibleItems).map((alert, index) => (
            <Card key={index} {...alert} />
          ))}

          {alertsData[activeTab].length > 4 && (
            <div className="text-center py-2">
              {visibleItems < alertsData[activeTab].length ? (
                <button
                  onClick={() => setVisibleItems(visibleItems + 4)}
                  className="text-[#113CC0] font-medium text-base"
                >
                  Show More
                </button>
              ) : (
                <button
                  onClick={() => setVisibleItems(4)}
                  className="text-[#113CC0] font-medium text-base"
                >
                  Show Less
                </button>
              )}
            </div>
          )}
        </>
      ) : (
        <p className="text-center p-4 text-gray-500">No alerts available.</p>
      )}
    </div>
  );
};

export default AlertTabs;
