import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

import logo from "../../images/navbar/newLogo.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "../../components/Modal";
import config from "../../util/config";

const EducationForAllIntermediate = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <div className="font-roboto text-grey flex flex-col gap-4 lg:gap-1">
      <section className="text-white flex max-md:flex-col bg-[#0A0A60]">
        <div className="py-20 lg:py-36 px-6 lg:px-12 lg:w-1/2 self-center">
          <Breadcrumbs
            links={[
              { name: "Home", path: "/" },
              { name: "Education for All Intermediate", path: "/" },
            ]}
          />
          <h1 className="mt-6 text-4xl lg:text-5xl font-bold">
            Are you a College Mentor student?
          </h1>
          <p className="mt-4 text-lg lg:text-xl">
            If yes, then you can track the progress of the child whose education
            you have helped sponsor.
          </p>
          <form>
            <div className="mt-6 flex gap-4 flex-wrap text-black">
              <input
                type="email"
                required
                placeholder="Enter the email you are enrolled with CM"
                className="p-2 rounded text-base w-72 text-base placeholder:text-sm placeholder:text-grey"
              />
              <input
                type="text"
                required
                placeholder="Your Enrolment number eg. cm1666"
                className="p-2 rounded text-base w-64 text-base placeholder:text-sm placeholder:text-grey"
              />
            </div>
            <button
              type="button"
              className="mt-6 bg-green px-4 py-2 rounded"
              onClick={() => {
                setPopupVisible(true);
              }}
            >
              Track <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
        </div>
      </section>

      <Modal isOpen={popupVisible} onClose={() => setPopupVisible(false)}>
        <div className="p-4 bg-white max-md:w-[80vw]">
          <h3 className="text-lg font-bold text-blue">
            Education for All Initiative
          </h3>
          <p>Powered by College Mentor</p>

          <p className="mt-2">
            Track the 2025 Registered University Admission exclusively with us!
          </p>
          <p>For more details, reach out to us at {config.mobileNumber}.</p>
        </div>
      </Modal>
    </div>
  );
};

export default EducationForAllIntermediate;
