import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import "./Banner.scss";

import happyStudent from "../../images/banner/happy-student.webp";
import womenIcon from "../../images/banner/women.jpg";
import designIcon from "../../images/banner/design-icon.svg";
import graduateIcon from "../../images/banner/graduate-icon.svg";
import medicineIcon from "../../images/banner/medicine-icon.svg";
import engineeringIcon from "../../images/banner/engineering-icon.svg";
import managementIcon from "../../images/banner/management-icon.svg";
import lawIcon from "../../images/banner/law-icon.svg";
import fashionDesignIcon from "../../images/banner/fashion-design-icon.svg";
import { MotionValue, motion } from "framer-motion";
import config from "../../util/config";
import PopupInfo from "../popupInfo/popup";

const careerPathData = [
  {
    path: "Engineering",
    avatar: null,
    icon: engineeringIcon,
  },
  {
    path: "Alum & Mentor",
    avatar: null,
    icon: graduateIcon,
  },
  {
    path: "Medicine",
    avatar: null,
    icon: medicineIcon,
  },
  {
    path: "Law",
    avatar: null,
    icon: lawIcon,
  },
  {
    path: "Fashion design",
    avatar: null,
    icon: fashionDesignIcon,
  },
  {
    path: "Management",
    avatar: null,
    icon: managementIcon,
  },
  {
    path: "Design",
    avatar: null,
    icon: designIcon,
  },
];

interface props {
  animationCheckPointRef: (el: HTMLDivElement | null) => void;
  animationItemRef: React.RefObject<HTMLDivElement>;
  animatedItemProps: {
    x: MotionValue<number>;
    y: MotionValue<number>;
    scale: MotionValue<number>;
  };
  isPulse: boolean;
}

const pulseVariant1 = {
  pulse: { scale: [1, 2], opacity: [1, 0], transition: { duration: 2 } },
  stop: { scale: [1], opacity: [1] },
};

const pulseVariant2 = {
  pulse: {
    scale: [1, 2],
    opacity: [1, 0],
    transition: { duration: 1.6, delay: 0.4, ease: "easeOut" },
  },
  stop: { scale: [1], opacity: [1] },
};
interface College {
  title: string;
  name: string;
  categoryName: string;
  isDataAvailable: string;
}

export default function Banner({
  animationCheckPointRef,
  animationItemRef,
  animatedItemProps,
  isPulse,
}: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isGif, setIsGif] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allSearchData, setAllSearchData] = useState<College[]>([]);
  const [suggestions, setSuggestions] = useState<College[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  useEffect(() => {
    axios({
      url: config.apiUrl + "/indexes/colleges/search",
      method: "POST",
      headers: {
        Authorization: `Bearer ` + config.searchApiKey,
        "Content-Type": "application/json",
      },
      data: {
        q: searchTerm || " ",
      },
    })
      .then((response) => {
        const fetchedData = response.data.hits || [];
        setAllSearchData(fetchedData);
        if (searchTerm.trim()) {
          setSuggestions(fetchedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching search data:", error);
      });
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = allSearchData.filter((suggestion: any) =>
        suggestion?.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };
  const handleSuggestionClick = (suggestion: College) => {
    setSearchTerm(suggestion.title);
    if (suggestion.isDataAvailable) {
      window.location.href = `/college-details/${suggestion.name}`;
    } else {
      setShowPopup(true);
      setSearchTerm("");
      setSuggestions([]);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setSuggestions([]);
  };

  return (
    <div className="banner-section bg-gradient-to-b from-[#c9e4f5] via-[#89C9ED] to-[#89C9ED] py-12">
      <div className="container max-w-[1200px] flex flex-row mx-auto justify-between">
        <div className="text-left md:w-[50%] flex items-center banner-left max-md:w-full  text-base font-semibold text-neutral-600">
          <div className="text-blue md:text-blue">
            <div className="w-full max-md:max-w-full max-md:!text-blue">
              Nurturing Your Pathway to Success
            </div>
            <div className="w-full text-5xl leading-[66px] text-blue max-md:max-w-full max-md:text-4xl max-md:!text-blue">
              Dreams to Degrees
            </div>
            <div className="mt-3.5 w-full text-sm leading-7 max-md:max-w-full md:text-black max-md:!text-black">
              College Mentor connects you with Mentors who share their
              experiences, provide insights, educational content and inspire you
              to reach for the stars. It's Mentorship in the Digital Age.
            </div>
            <div className="flex gap-3.5 mt-10 text-white max-md:flex-wrap">
              <div className="w-[80%] relative">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "100%" }}
                  className="bg-white pl-3 pr-1 md:pl-4 md:pr-2 rounded-full flex flex-row w-full items-center justify-between"
                >
                  <div className="flex flex-row gap-2 items-center w-full">
                    <div className="flex flex-col w-5 h-5">
                      <svg
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 md:w-5 md:h-5"
                      >
                        <path
                          d="M16.6425 14.9422C17.7163 13.4758 18.3586 11.6747 18.3586 9.72205C18.3586 4.83413 14.3817 0.857178 9.49374 0.857178C4.60582 0.857178 0.628906 4.83413 0.628906 9.72205C0.628906 14.61 4.60586 18.5869 9.49379 18.5869C11.4464 18.5869 13.2477 17.9445 14.7141 16.8707L20.5217 22.6783L22.4502 20.7498C22.4502 20.7498 16.6425 14.9422 16.6425 14.9422ZM9.49379 15.8593C6.10952 15.8593 3.35658 13.1063 3.35658 9.72205C3.35658 6.33778 6.10952 3.58485 9.49379 3.58485C12.8781 3.58485 15.631 6.33778 15.631 9.72205C15.631 13.1063 12.878 15.8593 9.49379 15.8593Z"
                          fill="#113CC0"
                        />
                      </svg>
                    </div>
                    <div className="relative w-full">
                      <input
                        type="text"
                        id="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search College"
                        className="w-full py-[14px]  pr-16  rounded-full focus:outline-none focus:border-[#00A3FF] text-black text-xs md:text-base"
                      />
                      {searchTerm.length > 0 && (
                        <button
                          type="button"
                          onClick={handleClear}
                          className="absolute min-max-xss:right-14 right-[100px] top-1/2 transform text-xl -translate-y-1/2 text-blue hover:text-gray-600"
                        >
                          <FontAwesomeIcon icon={faClose} size="sm" />
                        </button>
                      )}

                      {suggestions.length > 0 && (
                        <div
                          className={`absolute -left-3.5 w-full  z-50 ${
                            suggestions.length > 0
                              ? "animate__animated animate__fadeIn bg-white searchSuggestion"
                              : ""
                          }`}
                        >
                          <hr className="border-gray-200 py-2" />
                          <div className="min-h-[200px] overflow-y-auto scrollbar-custom">
                            <ul>
                              {suggestions.map((college, index) => (
                                <div key={index}>
                                  <li
                                    className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                                    onClick={() =>
                                      handleSuggestionClick(college)
                                    }
                                  >
                                    <span>{college.title}</span>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    className="arw-button absolute top-1/2 transform -translate-y-1/2 bg-[--primary-color] text-white md:py-3 px-3 rounded-full right-1"
                    style={{
                      background:
                        "linear-gradient(90deg, #173CBA 0%, #06AFA9 100%)",
                    }}
                  >
                    <span className="min-max-xss:hidden min-max-xs:hidden min-max-sm:hidden min-max-md:hidden">
                      Search
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="currentColor"
                      className="arrow-icon m-2"
                    >
                      <path
                        d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </form>
                {showPopup && (
                  <PopupInfo
                    isOpen={showPopup}
                    onClose={() => setShowPopup(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="banner-right max-md:hidden">
          <div className="relative flex flex-col justify-center px-16 py-16 rounded-full border-2 border-solid border-stone-300 aspect-square max-w-full max-md:px-5">
            {careerPathData.map((data, index) => {
              const angle = (index * 360) / 7 - 50;
              const angleInRadians = (angle * Math.PI) / 180;
              const x = Math.cos(angleInRadians);
              const y = Math.sin(angleInRadians);

              return (
                <>
                  {data.avatar && (
                    <div
                      className="absolute w-12 h-12 z-50 -translate-x-1/2 -translate-y-1/2"
                      style={{
                        top: `calc(50% + ${x} * 50%)`,
                        left: `calc(50% + ${y} * 50%)`,
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 1, scale: 1 }}
                        animate={{
                          opacity: [0, 1, 1, 1, 1, 1, 0, 0],
                          scale: [0, 1, 1, 1, 1, 1, 0, 0],
                        }}
                        transition={{
                          duration: 5,
                          repeatDelay: 30,
                          repeat: Infinity,
                          delay: index * 5,
                        }}
                        className="h-full w-full flex items-center justify-center"
                      >
                        {" "}
                        <img
                          loading="lazy"
                          src={data.avatar}
                          alt="women icon"
                          className="w-full h-full object-cover rounded-full z-50"
                        />
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.2 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                        <motion.div
                          initial={{ opacity: 1, scale: 1 }}
                          animate={{
                            opacity: [1, 1, 0, 0, 0, 0],
                            scale: [1, 1, 2, 2, 2, 2],
                          }}
                          transition={{
                            duration: 5,
                            repeatDelay: 30,
                            repeat: Infinity,
                            delay: 0.4 + index * 5,
                          }}
                          className="absolute w-12 h-12 rounded-full bg-[#00c798]"
                        ></motion.div>
                      </motion.div>
                    </div>
                  )}
                  <div
                    className="absolute z-50 -translate-x-1/2 -translate-y-1/2"
                    style={{
                      top: `calc(50% + ${x} * 50%)`,
                      left: `calc(50% + ${y} * 50%)`,
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{
                        opacity: [0, 0, 1, 1, 0, 0],
                        scale: [0, 0, 1, 1, 0, 0],
                      }}
                      transition={{
                        duration: 6,
                        repeatDelay: 36,
                        repeat: Infinity,
                        delay: 1 + index * 6,
                      }}
                    >
                      <div
                        className={`relative py-1.5 px-4 w-max shadow-xl bg-white border-2 border-[#00c798] rounded-full flex gap-2 items-center`}
                      >
                        <img
                          loading="lazy"
                          src={data.icon}
                          alt={data.path + " icon"}
                          className={`h-6 aspect-square object-cover`}
                        />
                        <span className="text-base text-[#0e2059]">
                          {data.path}
                        </span>
                        {data.avatar && (
                          <svg
                            className="absolute top-[100%] left-4"
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polygon points="0,0 16,0 0,16" fill="#00c798" />
                          </svg>
                        )}
                        {data.avatar == null && (
                          <motion.div
                            initial={{ opacity: 1, scale: 1 }}
                            animate={{
                              opacity: [0, 0, 1, 1, 0, 0],
                              scale: [0, 0, 1, 1, 0, 0],
                            }}
                            transition={{
                              duration: 3,
                              repeatDelay: 39,
                              repeat: Infinity,
                              delay: 2.5 + index * 6,
                            }}
                            className={`aspect-square bg-[#0e2059] rounded-full py-1 px-2 flex items-center absolute bottom-[80%] -right-2`}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="1x"
                              style={{ color: "#00c798" }}
                            />
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  </div>
                </>
              );
            })}
            <div className="small-circle shrink-0 w-full rounded-full border-2 border-solid border-stone-300 aspect-square max-md:mr-0.5">
              <div className="flex flex-col pt-10">
                <img
                  src={happyStudent}
                  alt="happy student"
                  className="absolute md:bottom-[-7.1rem]"
                />
                <div
                  ref={animationCheckPointRef}
                  className="flex flex-col items-start w-full max-md:pl-5 max-md:max-w-full absolute -left-20 top-1/2 z-50"
                >
                  <motion.div
                    className="w-16 h-16 flex items-center justify-center"
                    ref={animationItemRef}
                    style={{ ...animatedItemProps }}
                  >
                    <div className="absolute w-16 h-16 rounded-full overflow-hidden z-10">
                      <img
                        loading="lazy"
                        src={womenIcon}
                        alt="women icon"
                        className="h-full w-full object-cover women-logo"
                      />
                    </div>
                    <motion.div
                      variants={pulseVariant1}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                    <motion.div
                      variants={pulseVariant2}
                      animate={isPulse ? "pulse" : "stop"}
                      className="absolute w-16 h-16 rounded-full bg-[#00c798]"
                    ></motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
