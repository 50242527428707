import React, { useState } from "react";
import parse from "html-react-parser";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col mt-5 w-full leading-7 gap-5">
      <button
        className="flex flex-row text-[14px] gap-3.5 items-center w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {/* Toggle Icon */}
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path d="M5 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path d="M5 12H17" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M11 18L11 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        )}
        
        {/* Question Text */}
        <span className="text-lg font-medium text-left text-black">{question}</span>
      </button>

      {/* Answer Section */}
      {isOpen && (
        <div className="flex-auto leading-6 font-normal">
          {parse(answer)}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
