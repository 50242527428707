import React, { useEffect, useRef, useState } from "react";
import Banner from "../../components/courses/course-details/Banner";
import TabNavigations from "../../components/courses/course-details/TabNavigations";
import { useCourseDetails } from "./CourseDetailsContext";
import Loader from "../../components/Loader/Loader";
import instituteIcon from "../../images/courses/course-details/institute-icon-for-popup.svg";
import CourseOverview from "../../components/courses/course-details/Overview";
import CourseEligibility from "../../components/courses/course-details/Eligibility";
import CourseCareers from "../../components/courses/course-details/Careers";
import CourseJobs from "../../components/courses/course-details/Jobs";
import CourseResources from "../../components/courses/course-details/Resources";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";

const CourseDetails: React.FC = () => {
  const { data, loading } = useCourseDetails();
  const {
    metaTitle = "",
    metaKeywords = "",
    metaDescription = "",
    courseName = "",
  } = data || {};
  const containerRef = useRef<HTMLDivElement>(null);

  const [showEnquirePopup, setShowEnquirePopup] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef && containerRef.current) {
        const { top, bottom } = containerRef.current.getBoundingClientRect();
        const screenBottom = window.innerHeight;
        if (top < 0 && bottom > screenBottom) setShowEnquirePopup(true);
        else setShowEnquirePopup(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          <link
            rel="canonical"
            href={`${config.apiUrl}/course-details/${courseName}`}
          />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner />
        <div ref={containerRef}>
          <TabNavigations />
        </div>
        <CourseOverview />
        <CourseEligibility />
        <CourseCareers />
        <CourseJobs />
        <CourseResources />

        <EnquirePopup show={showEnquirePopup} />
      </div>
    </>
  );
};

export default CourseDetails;

const EnquirePopup = ({ show }: { show: boolean }) => {
  return (
    <div
      className={`fixed z-[100] bottom-0 left-0 w-full bg-blue px-2 md:px-4 py-2 md:py-4 flex flex-col md:flex-row gap-2 items-center justify-between transition ${
        show ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <div className="hidden md:flex items-center justify-center gap-4 md:gap-8">
        <img src={instituteIcon} alt="institute icon" className="w-10" />
        <p className="text-lg text-white">
          Interested in Human Resource Management? Discover more free
          information on eligibility, career scope, jobs, colleges, and exams.
        </p>
      </div>
      <div className="flex gap-4">
        <button className="px-4 py-1 bg-white text-blue text-sm md:text-base font-bold rounded-md w-max">
          Download Guide
        </button>
        <button className="px-4 py-1 bg-white text-blue text-sm md:text-base font-bold rounded-md w-max">
          Ask Question
        </button>
      </div>
    </div>
  );
};
