import React, { useRef } from "react";
import {
  ISelectedFilter,
  IUpdateSelectedFilter,
  IClearFilter,
} from "../customHooks/useSelectedFilter";
import { IFilterDataItem } from "./DesktopFilterMenu";
import { FilterActions } from "../FilterEnum";
import { EpRight } from "../../college-details/components/NavigationTabs";

interface IProps {
  selectedFilter: ISelectedFilter;
  updateSelectedFilter: IUpdateSelectedFilter;
  filterData: IFilterDataItem;
  clearFilter: IClearFilter;
  color: string;
}

const TabSelector: React.FC<IProps> = ({
  filterData,
  selectedFilter,
  updateSelectedFilter,
  clearFilter,
  color,
}) => {
  const selectedOption =
    selectedFilter.singleSelectFilters[filterData.categoryKey];
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const { current } = scrollContainerRef;
      const scrollAmount = 200;
      if (direction === "left") {
        current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  return (
    <div className="w-full flex items-center justify-center">
      <button
        className="p-3 bg-white border border-solid border-black rounded-full cursor-pointer text-center"
        onClick={() => scroll("left")}
      >
        <EpRight className="rotate-180" />
      </button>
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto whitespace-nowrap scrollbar-hide px-2"
        style={{ scrollBehavior: "smooth" }}
      >
        {filterData.options.map((option, index) => {
          const handleClick = (value: string) => {
            clearFilter();
            if (selectedOption !== value)
              updateSelectedFilter(
                filterData.categoryKey,
                FilterActions.ChangeSingleSelect,
                value
              );
          };
          return (
            <button
              key={index}
              className={`px-6 py-2 text-base rounded-md mx-1 whitespace-nowrap ${
                option.key === selectedOption
                  ? `bg-${color} text-white`
                  : "bg-white text-gray-600"
              }`}
              onClick={() => handleClick(option.key)}
            >
              {option.name}
            </button>
          );
        })}
      </div>
      <button
        className="p-3 bg-white border border-solid border-black rounded-full cursor-pointer text-center"
        onClick={() => scroll("right")}
      >
        <EpRight />
      </button>
    </div>
  );
};

export default TabSelector;
