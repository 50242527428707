import React, { useState } from "react";
import MentorReviews from "./MentorReviews";

interface MentorDetailsTabsProps {
  infoResponse: Array<{
    id: number;
    mentorId: number;
    name: string;
    title: string;
    description: string;
    orderIndex: string;
  }>;
}

const MentorDetailsTabs: React.FC<MentorDetailsTabsProps> = ({
  infoResponse,
}) => {
  const [activeTab, setActiveTab] = useState("aboutMentor");

  const aboutMentor = infoResponse.find(
    (item) => item.title === "About Mentor"
  );
  const skillsAndAttributes = infoResponse.find(
    (item) => item.title === "Skills and Attributes"
  );

  const personalPhilosophy = infoResponse.find(
    (item) => item.title === "Personal Philosophy"
  );
  const mentorshipStyle = infoResponse.find(
    (item) => item.title === "Mentorship Style"
  );

  const formatDescription = (description: string | null | undefined) => {
    if (!description) return ""; // Return empty string if description is null or undefined
    return description.replace(/<\/strong>:/g, "</strong><br />");
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    const tabElement = document.getElementById(tab);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="bg-[#FFF9E5]">
      <div className="container p-6 min-max-xss:p-3">
        <div className="flex space-x-4 flex-wrap max-sm:gap-3 max-sm:justify-center min-max-xss:space-x-2 mb-6">
          <button
            className={`md:px-[10px] md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded ${
              activeTab === "aboutMentor"
                ? "bg-[#173CBA] text-white"
                : "bg-white text-[#173CBA]"
            }`}
            onClick={() => handleTabClick("aboutMentor")}
          >
            About Mentor
          </button>
          <button
            className={`md:px-[10px] md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded ${
              activeTab === "skillsAndAttributes"
                ? "bg-[#173CBA] text-white"
                : "bg-white text-[#173CBA]"
            }`}
            onClick={() => handleTabClick("skillsAndAttributes")}
          >
            Skills & Experience
          </button>
          {/* Corrected button click event */}
          <button
            className={`md:px-[10px] md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded ${
              activeTab === "personalPhilosophy"
                ? "bg-[#173CBA] text-white"
                : "bg-white text-[#173CBA]"
            }`}
            onClick={() => handleTabClick("personalPhilosophy")}
          >
            Personal Philosophy
          </button>
          <button
            className={`md:px-[10px] md:py-2 md:text-xl md:font-semibold max-md:font-normal max-md:px-2 rounded ${
              activeTab === "mentorshipStyle"
                ? "bg-[#173CBA] text-white"
                : "bg-white text-[#173CBA]"
            }`}
            onClick={() => handleTabClick("mentorshipStyle")}
          >
            Mentorship Style
          </button>
        </div>

        {/* About Mentor Tab*/}

        {activeTab === "aboutMentor" && (
          <div className="text-gray-800">
            {aboutMentor && aboutMentor.description ? (
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 blog-content"
                dangerouslySetInnerHTML={{
                  __html: formatDescription(aboutMentor.description),
                }}
              />
            ) : (
              <div className="text-gray-700 text-base">
                <p className="mt-2">
                  Dedicated to helping students achieve their dreams and excel
                  in their careers. With over six years of experience as a
                  career mentor, has guided thousands of students from various
                  countries, including India, Oman, Bahrain, UAE, Saudi Arabia,
                  Andaman & Nicobar Islands, Sri Lanka, and beyond.
                </p>
                <p className="mt-4">
                  Specializes in securing admissions to prestigious institutes
                  and renowned medical universities abroad, including in
                  Malaysia, Georgia, Kazakhstan, and more.
                </p>
                <p className="mt-4">
                  Focusing on MBBS and PG Admissions, believes in a one-on-one,
                  personalized counseling approach, ensuring that both students
                  and parents receive tailored guidance that addresses their
                  unique needs and aspirations. Commitment to individual
                  attention has contributed to over 3000 success stories, making
                  a trusted advisor in medical admissions.
                </p>
                <p className="mt-4 font-semibold">
                  Professional Background:
                  <span className="font-normal">
                    {" "}
                    Brings a wealth of experience and dedication to the role as
                    a career mentor and educational consultant. Extensive
                    background includes serving in the Ministry of External
                    Affairs, Government of India, gaining invaluable insights
                    into international relations and education.
                  </span>
                </p>
              </div>
            )}
          </div>
        )}
        {activeTab === "skillsAndAttributes" && (
          <div id="skillsAndAttributes" className="text-gray-800 mt-2">
            <h2 className="text-2xl font-semibold text-[#173CBA]">
              Skills & Experience
            </h2>
            {skillsAndAttributes?.description ? (
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 pb-5"
                dangerouslySetInnerHTML={{
                  __html: formatDescription(skillsAndAttributes.description),
                }}
              />
            ) : (
              <div className="text-black text-base  pb-5 leading-7">
                <p className="mt-2">
                  <strong>Professional Background:</strong> Brings a wealth of
                  experience and dedication to their role as a career mentor and
                  educational consultant. Extensive background includes serving
                  in the Ministry of External Affairs, Government of India,
                  gaining invaluable insights into international relations and
                  education.
                </p>

                <p className="mt-2">
                  <strong>Medical Admissions Counselling:</strong> Expert
                  guidance for securing admissions in MBBS, BDS, and PG medical
                  courses.
                </p>

                <p className="mt-2">
                  <strong>NEET UG & NEET PG Counselling:</strong> Comprehensive
                  support for both undergraduate and postgraduate medical
                  admission counselling processes, including AIQ/Deemed
                  Universities and state-level counselling.
                </p>

                <p className="mt-2">
                  <strong>College Admissions:</strong> Assistance in securing
                  placements in prestigious medical colleges across India and
                  abroad (Malaysia, Georgia, Kazakhstan, etc.).
                </p>

                <p className="mt-2">
                  <strong>Scholarship Guidance:</strong> Advising students on
                  scholarship opportunities and financial aid for medical
                  education. International Medical Admissions – Specialized
                  mentorship for students seeking admission to medical
                  institutes overseas.
                </p>

                <p className="mt-2">
                  <strong>Career Path Planning:</strong> Tailored strategies to
                  align educational choices with long-term career goals in the
                  medical field.
                </p>

                <p className="mt-2">
                  <strong>Networking:</strong> Has built relationships with
                  admissions officers and educational institutions, providing
                  students with insider insights.
                </p>

                <p className="mt-2">
                  <strong>Cultural Sensitivity:</strong> Understands the diverse
                  backgrounds of students and the specific challenges they face
                  in the admissions process.
                </p>

                <p className="mt-2">
                  <strong>Workshops and Seminars:</strong> Regularly conducts
                  workshops on college admissions, preparing students for
                  interviews and application submissions.
                </p>

                <p className="mt-2">
                  <strong>Impact:</strong> Mentorship has not only guided
                  students toward successful admissions but has also inspired
                  many to pursue their academic and career goals with
                  confidence. Commitment to supporting students through this
                  critical phase of their education is evident in the success
                  stories of those mentored.
                </p>
              </div>
            )}

            <div className="mt-6 flex flex-wrap gap-2">
              {[
                "Skills",
                "Communication",
                "Personalized Guidance",
                "Admissions Expertise",
                "Exam Preparation",
                "Application Assistance",
                "Scholarship Advising",
                "Study Abroad Knowledge",
                "Analytical Skills",
                "Motivational Coaching",
                "Networking",
              ].map((skill) => (
                <span
                  key={skill}
                  className="px-3 py-1 bg-white text-[#383838] rounded-full text-xs"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="mt-6 p-4 bg-white border border-[#A3A2AC] rounded-md flex max-md:flex-wrap items-center justify-between min-max-md:w-[700px] lg:w-[850px] max-md:w-full">
          <div className="flex items-center">
            <span className="text-blue-700 text-xl mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="33"
                viewBox="0 0 42 33"
                fill="none"
              >
                <path
                  d="M41.1056 9.0348C41.1056 9.0348 41.1056 9.34107 41.1056 9.4942C41.1056 9.4942 41.1056 9.64733 41.1056 9.80046C41.1056 10.413 40.9528 10.8724 40.8 11.4849C40.1887 13.1694 39.1191 14.5476 37.4382 15.7726H37.2854C37.2854 15.9258 38.0494 23.2761 38.0494 23.2761L31.9371 18.3759H31.7842H31.6314C31.3258 18.3759 29.4921 18.529 29.0337 18.529C28.2696 18.529 27.3528 18.529 26.5887 18.3759C26.5887 18.0696 26.5887 17.7633 26.5887 17.4571C26.5887 16.0789 26.2831 14.7007 25.5191 13.4756C24.9078 12.2506 23.8382 11.1787 22.6157 10.2599C20.9348 9.0348 18.9483 8.11601 16.809 7.65661C17.2674 5.81902 18.337 4.13457 20.1708 2.75638C22.4629 0.918794 25.6719 0 28.8809 0C34.8404 0 40.0359 3.21578 41.1056 7.80974C41.1056 8.26914 41.2584 8.88167 41.2584 9.34107L41.1056 9.0348Z"
                  fill="#42BB93"
                />
                <path
                  d="M21.0876 11.4849C19.5595 10.2599 17.7258 9.4942 15.7393 9.0348C15.5865 9.0348 15.4337 9.0348 15.2809 9.0348C15.1281 9.0348 14.9753 9.0348 14.8225 9.0348C14.0584 9.0348 13.1416 8.88167 12.3775 8.88167C6.41798 8.88167 1.22247 12.0975 0.152808 16.6914C0.152808 17.1508 0 17.7633 0 18.2227C0 18.6821 0 18.529 0 18.6821C0 18.6821 0 18.8353 0 18.9884C0 19.6009 0.152809 20.0603 0.305618 20.6729C0.916854 22.3573 1.98652 23.7355 3.66742 24.9606H3.82022C3.82022 25.1137 3.05618 32.464 3.05618 32.464L9.32135 27.4107H9.62697C9.93258 27.4107 11.7663 27.7169 12.2247 27.7169C15.5865 27.7169 18.6427 26.7981 20.9348 24.9606C22.7685 23.5824 23.8382 21.8979 24.2966 20.0603C24.2966 19.9072 24.2966 19.7541 24.2966 19.6009C24.2966 19.4478 24.2966 19.2947 24.2966 19.1415C24.2966 18.8353 24.2966 18.6821 24.2966 18.3759C24.2966 15.9258 23.0742 13.6288 20.782 11.7912L21.0876 11.4849ZM6.11236 20.0603C4.88989 20.0603 3.97303 19.1415 3.97303 17.9165C3.97303 16.6914 4.88989 15.7726 6.11236 15.7726C7.33483 15.7726 8.25169 16.6914 8.25169 17.9165C8.25169 19.1415 7.33483 20.0603 6.11236 20.0603ZM12.2247 20.0603C11.0022 20.0603 10.0854 19.1415 10.0854 17.9165C10.0854 16.6914 11.0022 15.7726 12.2247 15.7726C13.4472 15.7726 14.364 16.6914 14.364 17.9165C14.364 19.1415 13.4472 20.0603 12.2247 20.0603ZM18.4899 20.0603C17.2674 20.0603 16.3506 19.1415 16.3506 17.9165C16.3506 16.6914 17.2674 15.7726 18.4899 15.7726C19.7124 15.7726 20.6292 16.6914 20.6292 17.9165C20.6292 19.1415 19.7124 20.0603 18.4899 20.0603Z"
                  fill="#324BA0"
                />
              </svg>
            </span>
            <p className="text-sm text-[#383838]">
              <span className="font-semibold">Open to inquiries</span>
              <br />
              You can message College Mentor to ask questions before booking
              their services
            </p>
          </div>
          <button className="bg-[#D9F6EF] text-[#383838] px-4 py-2 rounded font-semibold max-md:mt-4 max-md:ml-10 ">
            Get in touch
          </button>
        </div>

        {/* Skills and Attributes Tab */}

        {/* added static content from figma as fall back if no data from API for this personalPhilosophy */}
        {activeTab === "personalPhilosophy" && (
          <div id="personalPhilosophy" className="text-gray-800 mt-8 leading-7">
            <h2 className="text-2xl font-semibold text-[#173CBA]">
              Personal Philosophy
            </h2>

            {personalPhilosophy ? (
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 blog-content"
                dangerouslySetInnerHTML={{
                  __html: formatDescription(personalPhilosophy.description),
                }}
              />
            ) : (
              <div className="text-gray-700 text-base">
                <p className="mt-2">
                  My mission as a mentor is simple: to empower students to
                  unlock their full potential and achieve their dreams in the
                  world of medical education. Having spent years guiding
                  students through the complexities of medical admissions, I
                  deeply understand the challenges families face and the
                  significance of making informed, well-supported decisions.
                </p>
                <p className="mt-4">
                  I mentor because I believe that every student deserves
                  personalized, thoughtful advice to help them make the best
                  choices for their future. With a focus on providing clear,
                  step-by-step guidance, I strive to make the admission process
                  as seamless and stress-free as possible.
                </p>
                <p className="mt-4">
                  By offering tailored support and a deep commitment to my
                  students’ success, I aim to help them not only secure their
                  place in medical schools but also thrive in their chosen
                  careers. My goal is to see each student succeed.
                </p>
              </div>
            )}
          </div>
        )}

        {/* added static content from figma as fall back if no data from API for this mentorshipStyle  */}
        {activeTab === "mentorshipStyle" && (
          <div id="mentorshipStyle" className="text-gray-800 mt-8 leading-7">
            <h2 className="text-2xl font-semibold text-[#173CBA]">
              Mentorship Style
            </h2>

            {mentorshipStyle ? (
              <div
                className="text-black text-base leading-7 flex flex-col gap-5 py-5 px-8 blog-content"
                dangerouslySetInnerHTML={{
                  __html: formatDescription(mentorshipStyle.description),
                }}
              />
            ) : (
              <div className="text-black text-base">
                <p className="mt-2">
                  <span className="">Mentorship Approach:</span> (Description of
                  their style, e.g., hands-on, supportive, goal-oriented)
                </p>
                <p className="mt-2">
                  The mentorship style is individualized, strategic, and
                  comprehensive, ensuring that students receive continuous,
                  step-by-step guidance throughout the admission process.
                </p>
                <p className="mt-2">
                  This approach is rooted in personal engagement with each
                  student, providing one-on-one counseling sessions at key
                  stages to ensure clarity and confidence at every decision
                  point.
                </p>
                <p className="mt-2">
                  Mentorship goes beyond advice – The dedicated team handles
                  everything from application submissions and choice filling to
                  document formalities and reporting. Every step is completed
                  with the full consent of both the student and their parents,
                  ensuring a seamless and stress-free process.
                </p>
                <p className="mt-2">
                  By offering tailored solutions and extensive support, the
                  mentorship guarantees that each student is provided with the
                  best possible options and clear direction, aligning academic
                  goals with personal aspirations for a successful admission
                  journey.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MentorDetailsTabs;
