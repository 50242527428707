import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import "./NavBar.scss";
import SearchBox from "../searchbox/SearchBox";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UserAvatar from "../UserAvatar";
import OurServiceDropdownDesktop from "../megamenu/OurServiceDropdownDesktop";
import { IconParkOutlineRight } from "../study-abroad-landing/UniversitiesWorldwideMentors";
import { categories } from "../megamenu/NavCategories";
import { serviceItems } from "../megamenu/serviceItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const menuItems = [
  { name: "Home", link: "/" },
  { name: "Colleges", link: "/colleges" },
  { name: "Courses", link: "/courses" },
  { name: "Exams", link: "/exams" },
  { name: "Careers", link: "/careers" },
  { name: "Study Abroad", link: "/study-abroad" },
  { name: "Blogs", link: "/blogs" },
  { name: "Our Services", link: "" },
];
export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [expandedSubmenu, setExpandedSubmenu] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const menuItemRef = useRef<HTMLLIElement | null>(null);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  const handleMenuLinkClick = () => {
    setIsMenuOpen(false);
    setDropdownOpen(false);
    document.body.classList.remove("overflow-hidden");
  };
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    if (isMenuOpen) {
      setActiveMenu(null);
      setActiveSubmenu(null);
    }
  };
  const toggleSubmenu = (menuItem: string) => {
    if (expandedSubmenu === menuItem) {
      setExpandedSubmenu(null);
    } else {
      setExpandedSubmenu(menuItem);
      setExpandedCategory(null);
    }
  };
  const handleDropdownClick = () => {
    setDropdownOpen((prev) => !prev);
    setIsMenuOpen(false);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        menuItemRef.current &&
        !menuItemRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const { otpSent, error, isAuthenticated, token, user } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  let currentUser = localStorage.getItem("user");

  const toggleCategory = (categoryTitle: string) => {
    if (expandedCategory === categoryTitle) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryTitle);
      setExpandedSubmenu(null);
    }
  };

  return (
    <div className="main-nav">
      <div
        className={`nav-bar-section py-[25px] max-md:py-3 ${
          isMenuOpen ? "fixed inset-0 z-20 bg-white" : ""
        }`}
      >
        <div className="container flex items-center justify-between mx-auto">
          <button
            className="block translate-y-1 md:hidden mt-3"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>

          <div className="logo-section">
            <Link to="/" className="main-logo">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="flex md:justify-between md:items-center md:gap-[16px] max-md:mt-6">
            <SearchBox />
          </div>

          <div className="menu-and-button-section flex md:gap-[25px] md:justify-between items-center max-md:mt-5">
            <div
              className={`hidden md:block ${
                isMenuOpen ? "block" : "hidden"
              } md:flex md:gap-[25px] md:justify-between`}
            >
              <nav className="items-center navbar relative">
                <ul className="nav-list flex gap-1 px-4">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className="nav-item"
                      ref={item.name === "Our Services" ? menuItemRef : null}
                    >
                      {item.name === "Our Services" ? (
                        <>
                          <button
                            onClick={handleDropdownClick}
                            className="nav-link flex items-center justify-center hover:bg-[#173CBA] hover:text-white  p-2"
                          >
                            {item.name}
                            <IconParkOutlineRight className="ml-2 rotate-90" />
                          </button>
                          {dropdownOpen && (
                            <div
                              ref={dropdownRef}
                              className="absolute left-0 w-full top-full z-50 bg-white shadow-lg border-t border-gray-200"
                            >
                              <OurServiceDropdownDesktop
                                isVisible={dropdownOpen}
                                closeDropdown={closeDropdown}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <Link
                          to={item.link}
                          onClick={handleMenuLinkClick}
                          className="nav-link flex items-center justify-center hover:bg-[#173CBA] hover:text-white px-4 py-2"
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                  <li className="hidden lg:flex items-center justify-center gap-1 px-4">
                    <a
                      href="https://api.whatsapp.com/send?text=hi&phone=918959591818"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className="text-green-500 scale-[120%]"
                        />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="flex md:justify-between md:items-center md:gap-[16px]">
              {!currentUser ? (
                <Link to="/secure/login">
                  <button
                    className="py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200"
                    // onClick={(e) => navigate("/secure/login")}
                  >
                    Login
                  </button>
                </Link>
              ) : (
                ""
              )}
            </div>

            {currentUser && (
              <UserAvatar
                link={"dashboard/my-profile"}
                logoutUrl={"secure/login"}
              />
            )}
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          ref={menuRef}
          className={`mobilemenu fixed inset-0 z-10000 overflow-auto w-full mt-6 mb-4 pb-6 z-20pl-4 bg-[#ffffff] ${
            isMenuOpen ? "block" : "hidden"
          } md:hidden`}
        >
          <div className="flex justify-between p-4">
            <Link to="/" className="logo-section main-logo">
              <img src={logo} alt="logo" />
            </Link>
            <button className="block" onClick={toggleMenu}>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.743563 1.04983C0.255401 1.55426 0.255401 2.37209 0.743563 2.87653L5.22499 7.50731L0.743563 12.1382C0.255401 12.6426 0.255401 13.4605 0.743563 13.9649C1.23171 14.4693 2.02318 14.4693 2.51133 13.9649L6.99274 9.33398L11.4742 13.9649C11.9624 14.4693 12.7539 14.4693 13.242 13.9649C13.7301 13.4605 13.7301 12.6426 13.242 12.1382L8.76049 7.50731L13.242 2.87654C13.7301 2.37212 13.7301 1.55427 13.242 1.04985C12.7537 0.545416 11.9624 0.545416 11.4742 1.04985L6.99274 5.68063L2.51133 1.04983C2.02318 0.545403 1.23171 0.545403 0.743563 1.04983Z"
                  fill="#0F0F0F"
                />
              </svg>
            </button>
          </div>
          <nav>
            <ul className="flex flex-col justify-start gap-4 p-4 list-none">
              <li key={"home"} className="">
                <div className="whitespace-nowrap">
                  <h4
                    className="superheader font-bold py-4 cursor-pointer whitespace-nowrap flex items-center justify-between border-b border-b-[#C2F1E6]"
                    onClick={() => toggleSubmenu("home")}
                  >
                    Main Menu
                    <span
                      className={`inline transition-transform duration-200 ${
                        expandedSubmenu === "home"
                          ? "transform rotate-180 text-white"
                          : ""
                      }`}
                    >
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                          fill="#201F1F"
                        />
                      </svg>
                    </span>
                  </h4>
                </div>

                {/* Toggle the submenu based on the active state */}
                {expandedSubmenu === "home" && (
                  <ul className="ml-4 list-none">
                    {menuItems.map((item, index) => (
                      <li key={index} className="py-1">
                        {item.name === "Our Services" ? (
                          <>
                            <button
                              className="w-full font-bold superheadermobile text-blue py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2"
                              onClick={() => setDropdownOpen((prev) => !prev)}
                            >
                              {item.name}
                              <span
                                className={`inline transition-transform duration-200 ${
                                  dropdownOpen ? "rotate-180 text-white" : ""
                                }`}
                              >
                                <svg
                                  width="18"
                                  height="12"
                                  viewBox="0 0 18 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                                    fill="#201F1F"
                                  />
                                </svg>
                              </span>
                            </button>

                            {/* Dropdown Content */}
                            {dropdownOpen && (
                              <div
                                ref={dropdownRef}
                                className="pl-4 relative top-full left-0 w-full rounded-md bg-white shadow-lg border p-4 transition-opacity duration-200"
                              >
                                <ul className="flex flex-col gap-4">
                                  {serviceItems.map((item, index) => (
                                    <li
                                      key={index}
                                      className="flex items-center gap-2 text-gray-800 hover:text-blue-600 cursor-pointer transition"
                                      onClick={() => {
                                        setDropdownOpen(false);
                                        setIsMenuOpen(false);
                                      }}
                                    >
                                      <Link
                                        to={item.url}
                                        className="flex gap-2 w-full p-2 text-left"
                                      >
                                        <img
                                          src={item.icon}
                                          alt={item.text}
                                          className="w-4 h-4 object-contain"
                                        />
                                        <span className="text-sm">
                                          {item.text}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={
                              item.name.toLowerCase() === "home"
                                ? "/"
                                : item.name.toLowerCase() === "study abroad"
                                ? "/study-abroad"
                                : `/${item.name.toLowerCase()}`
                            }
                            className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${
                              activeMenu === "home"
                                ? "bg-[#173CBA] text-[#ffffff]"
                                : "text-[#173CBA]"
                            }`}
                            onClick={closeMenu}
                          >
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}

                    {/* College Mentor Scholarship Test */}
                    <li className="py-2">
                      <Link
                        to={`/college-mentor-scholarship`}
                        className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${
                          activeMenu === "home"
                            ? "bg-[#173CBA] text-[#ffffff]"
                            : "text-[#173CBA]"
                        }`}
                      >
                        College Mentor Scholarship Test - 1Cr
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              {categories.map((category, index) => (
                <li key={index}>
                  <h4
                    className="font-bold py-4 cursor-pointer flex items-center justify-between border-b border-b-[#C2F1E6]"
                    onClick={() => toggleCategory(category.title)}
                  >
                    {category.title}
                    <span
                      className={`inline transition-transform duration-200 ${
                        expandedCategory === category.title
                          ? "transform rotate-180 text-white"
                          : ""
                      }`}
                    >
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 2.85748L15.8823 0.739746L9.45269 7.16932L3.02312 0.739746L0.905384 2.85748L9.45269 11.4048L18 2.85748Z"
                          fill="#201F1F"
                        />
                      </svg>
                    </span>
                  </h4>
                  {expandedCategory === category.title && (
                    <ul className="ml-4 list-none">
                      {category.items.map((item, i) => (
                        <li key={i} className="py-1">
                          <Link
                            to={item.url}
                            className={`font-bold superheadermobile py-4 cursor-pointer border-b border-b-[#C2F1E6] whitespace-nowrap flex items-center justify-between px-2 ${
                              activeMenu === "home"
                                ? "bg-[#173CBA] text-[#ffffff]"
                                : "text-[#173CBA]"
                            }`}
                            onClick={closeMenu}
                          >
                            {item.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 z-10 bg-black opacity-50"></div>
      )}
    </div>
  );
}
