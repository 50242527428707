import { FC, useState, useEffect } from "react";
import Question from "./Question";
import { useNavigate, useLocation } from "react-router-dom";
import type { SVGProps } from "react";

export function CharmCircleTick(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25"></path>
        <path d="m5.75 7.75l2.5 2.5l6-6.5"></path>
      </g>
    </svg>
  );
}
export interface Option {
  answer: string;
  suggestion?: string;
}

export interface QuizQuestion {
  question: string;
  options: Option[];
}

interface QuizWidgetProps {
  questions: QuizQuestion[];
  destinationLink: string;
}

const QuizWidget: FC<QuizWidgetProps> = ({ questions, destinationLink }) => {
  const numberOfQuestions = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState<
    { question: string; answer: string; suggestion?: string }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showThanks, setShowThanks] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const savedResponse = responses[currentQuestion];
    if (savedResponse) {
      setSelectedOption(savedResponse.answer);
    } else {
      setSelectedOption(null);
    }
  }, [currentQuestion, responses]);
  const handleResponse = (answer: string, suggestion?: string) => {
    const user = localStorage.getItem("user");
    if (currentQuestion === 1 && !user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    }
    setSelectedOption(answer);
    const updatedResponses = [...responses];
    updatedResponses[currentQuestion] = {
      question: questions[currentQuestion].question,
      answer,
      suggestion,
    };
    setResponses(updatedResponses);
  };

  const goToNextQuestion = () => {
    if (currentQuestion + 1 < numberOfQuestions) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedOption(null);
    } else {
      if (destinationLink) {
        navigate(destinationLink);
      } else {
        setShowThanks(true);
      }
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
      setSelectedOption(null);
    }
  };
  const handleMapMyCollege = () => {};
  return (
    <div className="bg-white p-8 w-80 md:w-[640px]">
      {showThanks ? (
        <div className="text-center text-black">
          <p className="text-center">
            <CharmCircleTick className="w-9 h-9 text-green mx-auto" />
          </p>
          <h2 className="text-3xl  font-bold">Thank you for the response!</h2>
          <p>We appreciate your time and answers. Have a great day!</p>
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-bold text-blue">
            Map Your College Journey!
          </h1>
          <span className="text-sm text-grey">{`Qn: ${
            currentQuestion + 1
          }/${numberOfQuestions}`}</span>
          <Question
            questionData={questions[currentQuestion]}
            handleResponse={handleResponse}
            selectedOption={selectedOption}
          />
          {selectedOption && responses[currentQuestion]?.suggestion && (
            <div className="mt-4 p-4 bg-blue-50 rounded-md text-blue-900">
              <h3 className="font-semibold text-green-900">Suggested Path:</h3>
              <p>{responses[currentQuestion].suggestion}</p>
            </div>
          )}
          <div className="mt-6 flex justify-between">
            <button
              onClick={goToPreviousQuestion}
              disabled={currentQuestion === 0}
              className="px-4 py-2 bg-gray-300 rounded-lg"
            >
              Previous
            </button>
            <button
              onClick={goToNextQuestion}
              disabled={selectedOption === null}
              className="px-4 py-2 bg-blue text-white rounded-lg"
            >
              {currentQuestion + 1 < numberOfQuestions ? "Next" : "Finish"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizWidget;
