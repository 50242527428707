import React from "react";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";

interface ClubItem {
  title: string;
  description: string;
}

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface SectionProps {
  title: string;
  description: string;
  array?: ClubItem[];
  orderIndex?: string;
}

interface CollegeLifeTabProps {
  collegeLifeData: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const CollegeLifeSection: React.FC<SectionProps> = ({ title, description }) => {
  return (
    <section className="college-life-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const CollegeLifeTab: React.FC<CollegeLifeTabProps> = ({ collegeLifeData }) => {
  const similarColleges = collegeLifeData.similarColleges as College[];
  const keys = Object.keys(collegeLifeData)
    .filter((key) => key.startsWith("key_") && collegeLifeData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (collegeLifeData[a] as SectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (collegeLifeData[b] as SectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-9/12">
            <div className="flex flex-col gap-5">
              {keys.map((key) => {
                const section = collegeLifeData[key] as SectionProps;
                if (!section?.title || !section?.description) {
                  return null;
                }

                return (
                  <CollegeLifeSection
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-1/4">
            <div className="flex flex-col gap-10">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
      </div>
      {similarColleges && similarColleges.length > 0 && (
        <div className="container mx-auto">
          <SimilarEngineeringColleges colleges={similarColleges} />
        </div>
      )}
    </div>
  );
};

export default CollegeLifeTab;
