import { FC } from "react";
import { Option } from "./QuizWidget";

interface QuestionProps {
  questionData: {
    question: string;
    options: Option[];
  };
  handleResponse: (answer: string, suggestion?: string) => void;
  selectedOption: string | null;
}

const Question: FC<QuestionProps> = ({
  questionData,
  handleResponse,
  selectedOption,
}) => {
  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold">{questionData.question}</h2>
      <ul className="mt-4 flex flex-col gap-2 text-base text-grey">
        {questionData.options.map((option) => (
          <li key={option.answer} className="list-none">
            <button
              onClick={() => handleResponse(option.answer, option.suggestion)}
              className={`w-full border px-4 py-2 rounded-lg ${
                selectedOption === option.answer
                  ? "bg-blue text-white font-semibold"
                  : ""
              }`}
            >
              {option.answer}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Question;
