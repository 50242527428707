import React from "react";
import CMRLatestUpdates from "../cmresource/CMResourceLatestUpdates";
import ChatWithAmbassadors from "../cmresource/ChatWithAmbassadors";
import NewsAndArticles from "../cmresource/NewsAndArticles";
import ResearchInitiatives from "../cmresource/ResearchInitiatives";
import InternationalPartnerships from "../cmresource/InternationalPartnerships";
import VisualStories from "../cmresource/VisualStories";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import TopPreferences from "./courseFees/TopPreferences";

interface SectionProps {
  title: string;
  description: string;
  orderIndex?: string;
}

interface College {
  image: string;
  name: string;
  logo: string;
  location: string;
  title: string;
}

interface CMResourcesTabProps {
  resourcesData: {
    [key: string]: SectionProps | College[] | undefined;
  };
}

const ResourceSection: React.FC<SectionProps> = ({ title, description }) => {
  return (
    <section className="resource-section">
      <h2 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
        {title}
      </h2>
      <div
        className="text-base leading-relaxed bg-white p-3 flex flex-col gap-4 collegeHighlights"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </section>
  );
};

const CMResourcesTab: React.FC<CMResourcesTabProps> = ({ resourcesData }) => {
  const similarColleges = resourcesData.similarColleges as College[];
  const keys = Object.keys(resourcesData)
    .filter((key) => key.startsWith("key_") && resourcesData[key])
    .sort((a, b) => {
      const aOrder = parseInt(
        (resourcesData[a] as SectionProps)?.orderIndex || "0",
        10
      );
      const bOrder = parseInt(
        (resourcesData[b] as SectionProps)?.orderIndex || "0",
        10
      );
      return aOrder - bOrder;
    });

  return (
    <div className="py-10 bg-[#f4f4f4]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full">
            <div className="flex flex-col gap-5">
              {keys.map((key) => {
                const section = resourcesData[key] as SectionProps;
                if (!section?.title || !section?.description) {
                  return null;
                }

                return (
                  <ResourceSection
                    key={key}
                    title={section.title}
                    description={section.description}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-full mt-6">
          <div className="flex flex-col gap-10">
            <TopPreferences />
            {resourcesData.chatIntro && resourcesData.key_4 && (
              <ChatWithAmbassadors
                title={(resourcesData.chatIntro as SectionProps).title}
                description={
                  (resourcesData.chatIntro as SectionProps).description
                }
                ambassadors={resourcesData.key_4 as any}
              />
            )}
          </div>
        </div>
        {similarColleges && similarColleges.length > 0 && (
          <div className="container mx-auto mt-8">
            <SimilarEngineeringColleges colleges={similarColleges} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CMResourcesTab;
