import React, { useState } from "react";
import startYourJourneyImg from "../../images/study-abroad/University/RecruitDiverse-img.png";

interface RecruitDiverseProps {
  data: {
    title: string;
    description: string;
  };
}

const RecruitDiverse: React.FC<RecruitDiverseProps> = ({ data }) => {
  return (
    <section className="bg-[#FFF9E5] py-9 max-sm:py-2">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row-reverse items-center gap-10 ">
          <div className="w-full lg:w-1/2 lg:mr-[6%]">
            <div className="flex flex-col gap-7 lg:text-right">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-medium lg:font-light text-blue">
                  {data.title}
                </h3>
                <div className="flex flex-col">
                  <p className="text-base text-black">{data.description}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/4 xl:w-1/2 hidden lg:block"></div>
          <div className="w-full lg:w-1/2">
            <div className="flex w-full h-full">
              <img
                src="https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/University/study-abroad-university-reqruitdiverstudent.jpg"
                alt={data.title}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecruitDiverse;
