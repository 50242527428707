import React, { useState, useEffect } from "react";
import "./Accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export interface Option {
  name: string;
  count: number;
  key: string;
  categoryKey?: string;
  isSelected?: boolean;
}

interface AccordionProps {
  filters: {
    label: string;
    options: Option[];
    categoryKey?: string;
  };
  cities?: Option[];
  onFilterChange: (key: string, categoryKey: string, checked: boolean) => void;
  title: string;
  isOpen: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  filters,
  cities,
  onFilterChange,
  title,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const selectedOption = filters.options.find((option) => option.isSelected);
    setSelectedState(selectedOption ? selectedOption.key : null);
  }, [filters]);

  const filteredOptions = filters?.options?.filter((option) => {
    const optionName = option?.name?.toLowerCase() || "";
    const searchLower = searchTerm?.toLowerCase() || "";
    return optionName.includes(searchLower);
  });

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleStateChange = (stateKey: string, checked: boolean) => {
    setSelectedState(checked ? stateKey : null);
    onFilterChange(stateKey, filters.categoryKey || "state", checked);
  };

  const handleCityChange = (cityKey: string, checked: boolean) => {
    onFilterChange(cityKey, "city", checked);
  };

  const filteredCities = cities?.filter(
    (city) =>
      selectedState &&
      city.key.trim().toLowerCase() === selectedState.trim().toLowerCase()
  );

  return (
    <>
      {filters?.options?.length > 0 && (
        <div className="flex flex-col border-b border-[#E0E0E0] last:border-b-0">
          <div
            className="accordion-header text-[#fff] flex justify-between items-center"
            onClick={toggleAccordion}
          >
            <h4 className="text-base w-full flex flex-row justify-between items-center py-4 min-max-md:px-2 font-bold ">
              {title}
            </h4>
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              size="sm"
            />
          </div>

          {isOpen && (
            <div className="accordion-content !pt-0 !bg-[#061958]">
              {filters?.options?.length >= 6 && (
                <input
                  type="text"
                  placeholder={`Search by ${filters.label}`}
                  className="w-full mb-4 p-2 rounded-md bg-white text-[#ABA9A9] placeholder-[#ABA9A9]"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              )}

              <div className="max-h-48 overflow-y-auto">
                {filteredOptions.map((option) => (
                  <div key={option.key} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={option.key}
                      name={filters.label}
                      checked={option.isSelected || false}
                      onChange={(e) =>
                        handleStateChange(option.key, e.target.checked)
                      }
                      className="cursor-pointer"
                    />
                    <label htmlFor={option.key} className="ml-2 text-white">
                      {option.name}
                      {filters.categoryKey !== "sortBy" && option.count
                        ? ` (${option.count})`
                        : ""}
                    </label>
                  </div>
                ))}
              </div>

              {filteredCities && filteredCities.length > 0 && (
                <div className="mt-4">
                  <h5 className="text-white font-bold">Cities</h5>
                  <div className="max-h-48 overflow-y-auto">
                    {filteredCities.map((city) => (
                      <div key={city.key} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          id={city.key}
                          name="cities"
                          checked={city.isSelected || false}
                          onChange={(e) =>
                            handleCityChange(city.key, e.target.checked)
                          }
                        />
                        <label htmlFor={city.key} className="ml-2 text-white">
                          {city.name} {city.count ? `(${city.count})` : ""}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
