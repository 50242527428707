import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Rankings = () => {
  return (
    <section className="py-12 bg-[#FFF1FA] text-black">
      <div className="container flex max-md:flex-col gap-8 items-center">
        <div className="w-full lg:w-1/2">
          <img
            src="/images/college-predictor/intermediate/rankings.jpg"
            className="rounded-xl"
          />
        </div>
        <div className="w-full lg:w-1/2">
          <h2 className="text-4xl font-bold text-blue">
            Discover the Top 100 Universities & Engineering Rankings 2024
          </h2>
          <p className="text-base text-grey mt-4">
            NIRF Rankings are based on key factors that students value the most:
            Teaching Learning & Resources , Research and Professional Practices,
            Graduation Outcomes Outreach and Inclusivity and Perception.
          </p>
          <div className="mt-12 flex flex-wrap gap-4">
            <Link
              className="flex-1 min-w-fit arrow-button button-styles green-color flex items-center px-3 py-2 "
              to={"/rankings"}
            >
              View University Rankings
              <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
            </Link>
            <Link
              className="flex-1 min-w-fit arrow-button button-styles green-color flex items-center px-3 py-2 "
              to={"/rankings"}
            >
              View Engineering Rankings
              <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Rankings;
