import { useParams, useNavigate, useLocation } from "react-router-dom";
import Banner from "./components/Banner";
import { useState, useEffect, useCallback } from "react";
import TabNavigation from "../../exam-details-new/components/TabNavigation";
import axios from "axios";
import config from "../../../util/config";
import Loader from "../../../components/Loader/Loader";
import HtmlContent from "../../../components/HtmlContent";
import FAQItem from "../../../components/FAQItem";
import FeaturedScholarshipsAd from "./components/FeaturedScholarshipsAd";

const tabsWithIds = [
  { name: "About Scholarship", sectionId: "about" },
  { name: "Scholarship Eligibility", sectionId: "eligibility" },
  { name: "Benefits", sectionId: "benefits" },
  { name: "How to Apply", sectionId: "how-to-apply" },
  { name: "Required documents", sectionId: "required-documents" },
  { name: "Special Instructions", sectionId: "special-instruction" },
  { name: "FAQ", sectionId: "faq" },
  { name: "Contact", sectionId: "contact" },
];

const tabs = tabsWithIds.map((item) => item.name);

// Debounce function
const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const ScholarshipDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/api/scholarship-loan/scholarship-detail/${slug}`
        );
        setData(response.data?.data);
      } catch (e) {
        console.error("Error fetching scholarship detail data:", e);
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    const tabIndex = tabsWithIds.findIndex((tab) => tab.sectionId === hash);
    if (tabIndex !== -1) {
      setActiveTab(tabIndex);
      setTimeout(() => {
        const section = document.getElementById(hash);
        if (section) {
          const offset = 50;
          window.scrollTo({
            top: section.offsetTop - offset,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }, [location.hash, data]);

  useEffect(() => {
    if (isManualScroll) {
      const section = document.getElementById(tabsWithIds[activeTab].sectionId);
      if (section) {
        const offset = 50;
        window.scrollTo({
          top: section.offsetTop - offset,
          behavior: "smooth",
        });
      }
      setIsManualScroll(false);
    }
  }, [activeTab, isManualScroll]);

  const handleScroll = useCallback(
    debounce(() => {
      if (isManualScroll) return;

      const scrollPosition = window.scrollY + 150;
      let currentIndex = 0;

      tabsWithIds.forEach((tab, index) => {
        const section = document.getElementById(tab.sectionId);
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            currentIndex = index;
          }
        }
      });

      if (currentIndex !== activeTab) {
        setActiveTab(currentIndex);
        navigate(`#${tabsWithIds[currentIndex].sectionId}`, { replace: true }); //dynamically to chnage url while scrolling
      }
    }, 70),
    [activeTab, isManualScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setIsManualScroll(true);
    navigate(`#${tabsWithIds[index].sectionId}`, { replace: true }); // Update URL hash
  };

  if (!data) return <Loader />;

  return (
    <div className="font-roboto text-black">
      <Banner title={data?.scholarshipName} logo={data?.logo} />
      <TabNavigation
        tabs={tabs}
        activeTabIndex={activeTab}
        changeActiveTabIndex={handleTabChange}
      />
      <section className="container flex max-lg:flex-col gap-8 py-12">
        <div className="flex flex-col gap-4 xl:w-[70%]">
          <div id="about" className="bg-pink-light p-8">
            <h2 className="text-4xl font-medium text-blue">
              About the Scholarship Program
            </h2>
            <HtmlContent htmlString={data?.aboutProgram} />
          </div>
          <div id="eligibility" className="p-8">
            <h2 className="text-4xl font-medium text-blue">
              Scholarship Eligibility
            </h2>
            <HtmlContent htmlString={data?.eligibility} />
          </div>
          <div id="benefits" className="bg-pink-light p-8">
            <h2 className="text-4xl font-medium text-blue">Benefits</h2>
            <HtmlContent htmlString={data?.benefits} />
          </div>
          <div id="how-to-apply" className="p-8">
            <h2 className="text-4xl font-medium text-blue">How to Apply</h2>
            <HtmlContent htmlString={data?.howToApply} />
          </div>
          <div id="required-documents" className="bg-pink-light p-8">
            <h2 className="text-4xl font-medium text-blue">
              Required documents
            </h2>
            <HtmlContent htmlString={data?.requiredDocuments} />
          </div>
          <div id="special-instruction" className="p-8">
            <h2 className="text-4xl font-medium text-blue">
              Special Instructions
            </h2>
            <HtmlContent htmlString={data?.specialInstruction} />
          </div>
          <div id="faq" className="bg-pink-light p-8">
            <h2 className="text-4xl font-medium text-blue">
              Knowledge Hub (FAQ’S)
            </h2>
            {data?.faqs.map((faq: any, index: any) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
          <div id="contact" className="p-8">
            <h2 className="text-4xl font-medium text-blue">Contact</h2>
            <HtmlContent htmlString={data?.contact} />
          </div>
        </div>
        <div className="xl:w-[30%]">
          <FeaturedScholarshipsAd />
        </div>
      </section>
    </div>
  );
};

export default ScholarshipDetail;
