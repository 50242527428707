import classNames from "classnames";
import React, { useState } from "react";

interface Reward {
  rank: string;
  options: string[];
}

interface CardContent {
  title: string;
  description: string;
  additionalDescription: string;
  secondaryTitle: string;
  rewards: Reward[];
}

interface CardData {
  id: number;
  title: string;
  content: CardContent;
}

const cardsData: CardData[] = [
  {
    id: 1,
    title: "Merit Category Rewards",
    content: {
      title: "Merit Category Rewards. Dr. APJ Abdul Kalam Scholarship Awards",
      description:
        "The Merit Category rewards outstanding students with exciting opportunities, allowing them to choose one reward: A World Education Tour to Europe, Significant tution fee discounts at top institutes, or a cash prize.",
      additionalDescription:
        "This initiative is a fantastic way to recognize academic excellence and support students in achieving their dreams!",
      secondaryTitle: "",
      rewards: [
        {
          rank: "Ranks 1 - 10",
          options: [
            "World Education Tour to Europe (7 Days) or",
            "25% off first-year tuition fees at prestigious institutes (IIT, NIT, AIIMS, NIFT, NID) or",
            "Cash Prize of ₹ 75,000",
          ],
        },
        { rank: "Ranks 11 - 15", options: ["Cash Prize of ₹ 50,000"] },
        { rank: "Ranks 16 - 20", options: ["Cash Prize of ₹ 25,000"] },
      ],
    },
  },
  {
    id: 2,
    title: "Championing Gender Equality",
    content: {
      title: "Championing Gender Equality!",
      description:
        "Championing Gender Equality means ensuring everyone has equal rights and opportunities. By fostering an inclusive environment, we empower individuals to thrive and create a brighter, more equitable future for all",
      additionalDescription:
        "We’re excited to award a total of 1000 scholarships—500 each for boys and girls",
      secondaryTitle: " Champion Awards Breakdown for Boys & Girls",
      rewards: [
        {
          rank: "Ranks 1 - 50",
          options: [
            "Cash Prize of ₹ 10,000 + ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 51 - 300",
          options: [
            "Cash Prize of ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 301 - 500",
          options: [
            "Cash Prize of ₹ 5,000 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
      ],
    },
  },
  {
    id: 3,
    title: "Government School Students",
    content: {
      title: "Government School Students!",
      description:
        "This category awards are specially created to reward the students studying in the Government schools and to provide them with the opportunity and equal footing with other students. <br/> <p><strong>Note:</strong> To claim under this category, the student has to produce a certificate from the college Principal that he/she is studying in the government school.  </p>",

      additionalDescription:
        "This initiative is a fantastic way to recognize academic excellence and support students in achieving their dreams!",
      secondaryTitle: "All-Star Awards Breakdown",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: [
            "Cash Prize of ₹ 10,000 + ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 16 - 60",
          options: [
            "Cash Prize of ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 61 - 100",
          options: [
            "Cash Prize of ₹ 5,000 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
      ],
    },
  },
  {
    id: 4,
    title: "Empowering LGBTQ+ Students!",
    content: {
      title: "Empowering LGBTQ+ Students!",
      description:
        "Empowering LGBTQ+ students means creating a supportive environment where they can express their identities freely and pursue their dreams. By championing inclusivity and acceptance, we help foster confidence and resilience for a brighter future.",
      additionalDescription:
        "Committed to supporting and celebrating the achievements of LGBTQ+ students with scholarships for rankers 1-100.",
      secondaryTitle: "Diversity Excellence Awards Breakdown",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: [
            "Cash Prize of ₹ 10,000 + ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 16 - 60",
          options: [
            "Cash Prize of ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 61 - 100",
          options: [
            "Cash Prize of ₹ 5,000 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
      ],
    },
  },
  {
    id: 5,
    title: "Students with Disabilities",
    content: {
      title: "Students with Disabilities!",
      description:
        "Supporting students with disabilities involves providing the necessary resources and opportunities to ensure they can thrive in their education. By promoting inclusivity and understanding, we empower these students to achieve their full potential and lead fulfilling lives.",
      additionalDescription:
        "Recognizing the potential of students with disabilities, scholarship is designed to support them. Scholarships are provided for ranks 1-100 under this category",
      secondaryTitle: "Scholarship Rewards for a Brighter Future",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: [
            "Cash Prize of ₹ 10,000 + ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 16 - 60",
          options: [
            "Cash Prize of ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 61 - 100",
          options: [
            "Cash Prize of ₹ 5,000 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
      ],
    },
  },

  {
    id: 6,
    title: "Talent Category - Sports and Arts",
    content: {
      title: "Talent Category - Sports and Arts",
      description:
        "Talent Category recognizes unique skill of each student. This scholarship is designed to encourage and support Talent in each of the following - Sports, Painting and Sketching, Photography and Community Services. Top 100 performers in each section will be provided various scholarships as mentioned. ",
      additionalDescription:
        "<strong>Note: To claim scholarship under this category candidate need to submit the following documents.</strong>",
      secondaryTitle: "Scholarship Rewards for a Brighter Future",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: [
            "Cash Prize of ₹ 10,000 + ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 16 - 60",
          options: [
            "Cash Prize of ₹ 7,500 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
        {
          rank: "Ranks 61 - 100",
          options: [
            "Cash Prize of ₹ 5,000 Scholarship at Partner Universities + College Mentor Services worth ₹ 5,000",
          ],
        },
      ],
    },
  },
];

const ScholorshipCategoryAward = () => {
  const [expandedCardId, setExpandedCardId] = useState<number>(cardsData[0].id);

  const handleCardClick = (id: number) => {
    if (id !== expandedCardId) {
      setExpandedCardId(id);
    }
  };

  return (
    <div className="scholarship-category-award-section">
      <div className="mx-auto">
        <div className="bg-[#fff] pb-8 px-4">
          <h3 className="text-center text-[#173CBA] font-light text-3xl lg:text-[48px]">
            Scholarship Category Awards Overview
          </h3>
          <p className="text-center text-[#173cba] font-bold text-3xl lg:text-[48px]">
            Total Scholarships Worth: ₹1 Crore <br />
            <span className="text-black text-xl lg:text-[36px] font-light">
              Merit list will be created based on marks from the scholarship
              test.
            </span>
          </p>
        </div>

        <div className="flex flex-col lg:flex-row w-full lg:container h-full lg:h-[563px] bg-[#173cba]">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.id}>
              <div
                onClick={() => handleCardClick(card.id)}
                className={classNames(
                  "relative cursor-pointer transform transition-all duration-500 ease-in-out overflow-hidden",
                  expandedCardId === card.id
                    ? "h-auto w-full lg:w-full"
                    : "h-[100px] w-full lg:h-[563px] lg:w-[111px]"
                )}
              >
                <div className="bg-[#173cba] text-white h-full flex flex-col relative">
                  <div
                    className={classNames(
                      "p-4 flex items-center justify-center",
                      expandedCardId === card.id
                        ? "absolute top-0 left-0 w-full h-full"
                        : "h-full"
                    )}
                  >
                    {expandedCardId !== card.id && (
                      <h2 className="text-[24px] lg:text-[30px] font-light lg:-rotate-90 whitespace-nowrap">
                        {card.title}
                      </h2>
                    )}
                  </div>

                  {expandedCardId === card.id && (
                    <div className="my-auto px-8 leading-7 space-y-4 max-lg:py-8">
                      <h3 className="font-light text-[20px] lg:text-[30px] text-[#FFD540] mb-2 lg:p-1">
                        {card.content.title}
                      </h3>
                      <div
                        className="text-sm lg:text-base font-normal lg:p-1"
                        dangerouslySetInnerHTML={{
                          __html: card.content.description,
                        }}
                      ></div>
                      {expandedCardId === 3 && (
                        <a
                          href="/pdf/Certifications_required.pdf"
                          download
                          target="_blank"
                          className="text-green hover:underline cursor-pointer relative z-10 mt-2"
                        >
                          Download Certificate Format
                        </a>
                      )}
                      <p className="text-sm lg:text-base font-normal lg:p-1">
                        <div
                          className="text-sm lg:text-base font-normal lg:p-1"
                          dangerouslySetInnerHTML={{
                            __html: card.content.additionalDescription,
                          }}
                        ></div>{" "}
                      </p>
                      <p className="text-sm lg:text-[24px] font-semibold lg:p-1">
                        {card.content.secondaryTitle}
                      </p>
                      <div className="space-y-4 mt-4">
                        {card.content.rewards.map((reward, idx) => (
                          <div key={idx}>
                            <h4 className="font-semibold text-[24px] mb-2">
                              {reward.rank}:
                            </h4>
                            <ul className="list-disc ml-4 text-[#FFD540] text-base">
                              {reward.options.map((option, optionIdx) => (
                                <li key={optionIdx}>{option}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {index < cardsData.length - 1 && (
                <>
                  <div className="block lg:hidden h-[2px] bg-[#0c9] w-full my-2"></div>

                  <div className="hidden lg:block w-[2px] bg-[#0c9] h-[563px]"></div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScholorshipCategoryAward;
