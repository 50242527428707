import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import "./CareerDetailsStyles.scss";
interface Responsibilities {
  title: string;
  description: string;
}
const sanitizeHTML = (html: string): string => {
  if (!html) return "";

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Remove <p> tags that contain only &nbsp; or are empty
  tempDiv.querySelectorAll("p").forEach((p) => {
    if (!p.textContent?.trim() || p.innerHTML.trim() === "&nbsp;") {
      p.remove();
    }
  });

  // Remove empty <td> elements
  tempDiv.querySelectorAll("td").forEach((td) => {
    if (!td.textContent?.trim() || td.innerHTML.trim() === "&nbsp;") {
      td.remove();
    }
  });

  // Remove empty <tr> elements
  tempDiv.querySelectorAll("tr").forEach((tr) => {
    const allTdsEmpty = Array.from(tr.children).every(
      (td) => (td as HTMLElement).innerHTML.trim() === ""
    );
    if (allTdsEmpty) {
      tr.remove();
    }
  });

  // Remove tables that no longer have any rows
  tempDiv.querySelectorAll("table").forEach((table) => {
    const allRowsHidden = Array.from(table.rows).length === 0;
    if (allRowsHidden) {
      table.remove();
    }
  });

  return tempDiv.innerHTML;
};

const RolesAndResponsibilities: React.FC<{
  responsibilities: Responsibilities;
}> = ({ responsibilities }) => {
  const [cleanedDescription, setCleanedDescription] = useState("");

  useEffect(() => {
    setCleanedDescription(sanitizeHTML(responsibilities.description));
  }, [responsibilities.description]);

  if (
    !responsibilities ||
    !responsibilities.title ||
    !responsibilities.description.trim()
  ) {
    return (
      <section>
        <div className="container mx-auto">
          <div className="bg-white relative text-center">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h2 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  Roles and Responsibilities
                </h2>
              </div>
              <div className="flex flex-col lg:flex-row gap-5 p-8 md:p-4 lg:p-10">
                <p className="text-red-600 font-medium mt-4">
                  No Roles and Responsibilities data available at the moment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="container mx-auto">
        <div className="bg-white relative">
          <div className="flex flex-col lg:flex-row justify-between gap-6">
            <div className="flex flex-col w-full lg:w-4/5">
              <div className="flex max-md:flex-col max-md:w-full">
                <h2 className="text-xl max-md:text-center lg:text-2xl font-semibold text-white bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0">
                  {responsibilities.title}
                </h2>
              </div>
              <div
                className="text-black text-base leading-7 flex flex-col gap-2 py-5 px-4 md:px-8 salaryDetailsDescription"
                dangerouslySetInnerHTML={{ __html: cleanedDescription }}
              ></div>
            </div>
            <div className="flex flex-col items-center justify-between w-full lg:w-1/5 relative max-xl:hidden">
              <div className="review-and-earn w-[275px] bg-[#FFEFB5] sticky">
                <img
                  src="/images/course-details/logo.png"
                  alt="Ad"
                  className="mb-4"
                />
                <img
                  src="/images/course-details/review-your-college.png"
                  alt="Ad"
                  className="mx-auto"
                />
                <img
                  src="/images/course-details/UNIVERSITY.png"
                  alt="Ad"
                  className="mx-auto"
                />
                <img
                  src="/images/course-details/group.png"
                  alt="Ad"
                  className="mx-auto"
                />
                <img
                  src="/images/course-details/help-students.png"
                  alt="help-students"
                  className="mx-auto"
                />
                <div className="mt-2">
                  <Link
                    to={"/refer-and-earn"}
                    className="flex mx-auto  text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[150px]"
                  >
                    <span className="mr-2">Review & earn</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <img src="/images/course-details/buy-now.png" alt="buy-now" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RolesAndResponsibilities;
