import React, { useEffect, useState } from "react";

const mentors = [
  {
    id: 1,
    name: "Student Buddy Mentor",
    img: "/images/mentors/m7.png",
    top: "50px",
    left: "200px",
  },
  {
    id: 2,
    name: "Career Mentor",
    img: "/images/mentors/m2.png",
    top: "50px",
    left: "500px",
  },
  {
    id: 3,
    name: "Loan Mentorship Mentor",
    img: "/images/mentors/m3.png",
    top: "300px",
    left: "100px",
  },
  {
    id: 4,
    name: "Application to Admission Mentor",
    img: "/images/mentors/m4.png",
    top: "300px",
    left: "600px",
  },
  {
    id: 5,
    name: "Scholarship Mentor",
    img: "/images/mentors/m5.png",
    top: "500px",
    left: "200px",
  },
  {
    id: 6,
    name: "Student Health Mentor",
    img: "/images/mentors/m6.png",
    top: "500px",
    left: "500px",
  },
  {
    id: 7,
    name: "Main mentor",
    img: "/images/mentors/m1.png",
    top: "300px",
    left: "350px",
  },
];

export const SpeakToMentors = () => {
  const [highlightedMentor, setHighlightedMentor] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setHighlightedMentor((prev) => (prev + 1) % mentors.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full  bg-[#DFF4FF] ">
      <div className="container md:h-[720px] min-max-md:h-auto min-max-md:py-5  flex flex-row justify-between min-max-xss:flex-col min-max-xs:flex-col max-md:py-5">
        <div className="w-[33.3333%] pr-4  text-black flex flex-col gap-4 min-max-xs:pr-0 justify-center min-max-xss:w-full min-max-xs:w-full min-max-md:w-full min-max-xss:pr-0">
          <h2 className="text-4xl font-bold mb-2">
            Speak to Mentors <br /> About All Your Needs
          </h2>
          <p className="text-[21px] max-md:text-base">
            A Revolutionary Approach: Easily Match Mentors with Mentees - Our
            program features a unique model of 1 Mentee to 6 Mentors, making it
            the first of its kind in India.
          </p>
          <p className="text-[21px] max-md:text-base">
            This innovative structure provides comprehensive support across
            various aspects of a student’s journey.
          </p>
          <p className="text-[21px] max-md:text-base">
            Each student will be mentored by six specialized mentors, ensuring a
            360-degree approach.
          </p>
        </div>

        <div className="w-[59%] relative min-max-xss:w-full min-max-xs:hidden min-max-xss:hidden min-max-md:hidden ">
          {mentors.slice(0, 7).map((mentor, index) => (
            <div key={mentor.id} className="relative">
              <div
                className={`absolute text-center transition duration-500 ease-in-out`}
                style={{ top: mentor.top, left: mentor.left }}
              >
                <img
                  src={mentor.img}
                  alt={mentor.name}
                  className={`w-20 h-20 object-cover rounded transition duration-500 ease-in-out 
                  ${
                    index === highlightedMentor
                      ? "rounded-full border-4 border-white"
                      : ""
                  }`}
                />
              </div>

              <div
                className={`absolute transition duration-500 ease-in-out p-2 text-center ${
                  index === highlightedMentor
                    ? "bg-white text-black rounded-md"
                    : "text-[#173CBA]"
                }`}
                style={{
                  top: `calc(${mentor.top} + 90px)`,
                  left: `calc(${mentor.left} + 40px)`,
                  transform: "translateX(-50%)",
                }}
              >
                <p className="text-lg font-semibold">{mentor.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
