import { useState, useEffect } from "react";

interface Alumni {
  id: number;
  name: string;
  university: string;
  title: string;
  testimonial: string;
  image: string;
}

const mockAlumni: Alumni[] = [
  {
    id: 1,
    name: "Priya Sharma",
    university: "VIT (1st Year)",
    title: "Admission Mentor",
    testimonial:
      "I was overwhelmed with the application and admission process for VIT, but College Mentor made it so simple. Their counseling sessions were detailed, and they guided me through every step, from filling out the application to admission. Thanks to their expert advice, I secured my spot with ease.",
    image: "/images/student-alumini.webp",
  },
  {
    id: 2,
    name: "Rahul Menon",
    university: "Sri Ramachandra University (1st Year)",
    title: "Scholarship Mentor",
    testimonial:
      "College Mentor helped me secure a scholarship at Sri Ramachandra University. Their step-by-step guidance on the application process and personalized advice made a big difference. Without their support, I wouldn't have been able to reduce my tuition fees so effectively.",
    image: "/images/student-alumini.webp",
  },
  {
    id: 3,
    name: "Anjali Verma",
    university: "Aditya University (1st Year)",
    title: "Student Mentor",
    testimonial:
      "The student mentor from College Mentor provided incredible insights about placements and research opportunities at Aditya University. They also gave me practical tips on managing my fees and planning my academics. Their mentorship has been invaluable for my first-year journey.",
    image: "/images/student-alumini.webp",
  },
  {
    id: 4,
    name: "Rohan Gupta",
    university: "",
    title: "Career Mentor",
    testimonial:
      "I was overwhelmed with the application and admission process for VIT, but College Mentor made it so simple. Their counseling sessions were detailed, and they guided me through every step, from filling out the application to admission. Thanks to their expert advice, I secured my spot with ease.",
    image: "/images/student-alumini.webp",
  },
];

const StudentAlumni = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % mockAlumni.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-[#FCF8EB] py-20 max-md:py-5 min-max-md:py-16 flex max-md:w-full">
      <div className="flex gap-14 max-md:gap-1 max-md:flex-col container mx-auto justify-center">
        <div className="w-[216px]    rounded-lg border border-white max-md:mx-auto">
          <img
            src={mockAlumni[currentIndex].image}
            alt={mockAlumni[currentIndex].name}
            className="w-[216px] h-[279px] object-cover transition-opacity duration-500 ease-in-out"
          />
          <div className="mt-2 flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="174"
              height="5"
              viewBox="0 0 174 5"
              fill="none"
            >
              <rect
                width="37.5053"
                height="5"
                fill={currentIndex === 0 ? "#00CC99" : "#173CBA"}
              />
              <rect
                x="45.4982"
                width="37.5053"
                height="5"
                fill={currentIndex === 1 ? "#00CC99" : "#173CBA"}
              />
              <rect
                x="90.9965"
                width="37.5053"
                height="5"
                fill={currentIndex === 2 ? "#00CC99" : "#173CBA"}
              />
              <rect
                x="136.495"
                width="37.5053"
                height="5"
                fill={currentIndex === 3 ? "#00CC99" : "#173CBA"}
              />
            </svg>
          </div>
        </div>

        <div className=" gap-3  flex flex-col my-auto md:w-[53%] max-md:w-full max-md:gap-1">
          <div className="flex flex-col gap-2 max-md:justify-center max-md:gap-1">
            <h3 className="text-[50px] font-light text-[#173CBA] max-md:text-[40px]">
              Success Story{" "}
              <span className="text-[#173CBA]  text-[50px] font-medium max-md:text-[40px] ">
                ALUMINI
              </span>
            </h3>
            <p className="font-bold text-[#173CBA] text-2xl">
              {mockAlumni[currentIndex].title}
            </p>
          </div>
          <p className="text-gray-800 text-xl">
            Name:{" "}
            <span className="font-bold text-2xl text-[#173CBA]">
              {mockAlumni[currentIndex].name}
            </span>{" "}
            / University :{" "}
            <span className="text-[#173CBA] text-2xl">
              {mockAlumni[currentIndex].university}
            </span>
          </p>
          <p className="mt-2 text-gray-700">
            {mockAlumni[currentIndex].testimonial}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentAlumni;
