import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useForm, UseFormRegister } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../util/config";
import { useNavigate, useParams } from "react-router-dom";
import BaseURLAuth from "../../../../services/api";

const FilterType: { [key: string]: string } = {
  counselling: "dropdown",
  state: "dropdown",
  caste: "dropdown",
  gender: "radio",
};

interface FormData {
  [field: string]: string | number;
}

const PredictionForm = () => {
  const [filterData, setFilterData] = useState<any[] | null>(null);

  const navigate = useNavigate();

  const { exam } = useParams();

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await BaseURLAuth.post(
          "/api/college/college/predicteColleges",
          { examName: exam, pageSize: 1, pageNo: 0 },
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
            },
          }
        );
        if (response?.status == 200)
          setFilterData(response?.data?.data?.filter);
      } catch (e) {
        console.log("Error fetching predictor filter data");
      }
    };

    fetchFilterData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    console.log(data);
    const queryParams = Object.keys(data)
      .filter((key) => !["rank", "mobileNumber"].includes(key))
      .map((key) => `${key}=${data[key]}`)
      .join("&");
    navigate(`/college-predictor-result/${exam}/${data?.rank}?${queryParams}`);
  };

  return (
    <section className="py-9 bg-[#FFF6F1] text-black">
      <div className="container mx-auto">
        <div className="shadow-xl bg-white px-8 py-12 md:max-w-[630px] mx-auto">
          <div style={{ width: "100%", height: "100%", background: "#00CC99" }}>
            <h5 className="text-xl font-bold text-center">
              <span className="text-black">Enter Exam Details</span>
            </h5>
          </div>

          <form
            className="mt-6 flex flex-col gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label className="text-xs text-grey">Enter Your Rank</label>
              <input
                {...register("rank", {
                  required: "rank is required",
                  pattern: {
                    value: /^\d+$/,
                    message: "Invalid rank",
                  },
                })}
                className="w-full mt-2 py-1 rounded border border-grey-3 text-base"
              />
              <p className="text-red-500">{errors["rank"]?.message}</p>
            </div>
            {filterData?.map((field) => {
              switch (FilterType[field.categoryKey]) {
                case "dropdown":
                  return (
                    <DropDown
                      fieldName={field.categoryName}
                      field={field.categoryKey}
                      options={field.options}
                      register={register}
                      errors={errors[field.categoryKey]?.message || ""}
                    />
                  );
                case "radio":
                  return (
                    <Radio
                      fieldName={field.categoryName}
                      field={field.categoryKey}
                      options={field.options}
                      register={register}
                      errors={errors[field.categoryKey]?.message || ""}
                    />
                  );
                case "input":
              }
            })}
            <span className="text-sm font-semibold text-green">
              College predictor report will be sent to you on:
            </span>
            <div>
              <label className="text-xs text-grey">
                Enter Your Mobile Number
              </label>
              <input
                {...register("mobileNumber", {
                  required: "Mobile number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Invalid mobile number",
                  },
                })}
                className="w-full mt-2 py-1 rounded border border-grey-3"
              />
              <p className="text-red-500">{errors["mobileNumber"]?.message}</p>
            </div>
            <button
              type="submit"
              className="text-base font-semibold text-white px-12 py-2 bg-blue mx-auto rounded"
            >
              Predictor
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PredictionForm;

const DropDown = ({
  options,
  fieldName,
  register,
  field,
  errors,
}: {
  options: { key: string; name: string }[];
  fieldName: string;
  field: string;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  return (
    <div>
      <label className="text-xs text-grey">{fieldName}</label>
      <select
        className="w-full mt-2 py-1 rounded border border-grey-3 text-sm"
        {...register(field, { required: true })}
      >
        {options.map((option) => (
          <option key={option.key} value={option.key} className="text-sm">
            {option.name}
          </option>
        ))}
      </select>
      <p className="text-red-500">{errors}</p>
    </div>
  );
};

const Radio = ({
  options,
  fieldName,
  field,
  register,
  errors,
}: {
  options: { key: string; name: string }[];
  fieldName: string;
  field: string;
  register: UseFormRegister<FormData>;
  errors: string;
}) => {
  return (
    <div>
      <label className="text-xs text-grey">{fieldName}</label>
      <div className="flex gap-4 mt-2">
        {options.map((option) => (
          <label key={option.key} className="flex gap-2 items-center text-sm">
            <input
              type="radio"
              value={option.key}
              className="text-sm"
              {...register(field, { required: `${fieldName} is required` })}
            />
            <span>{option.name}</span>
          </label>
        ))}
      </div>
      <p className="text-red-500">{errors}</p>
    </div>
  );
};
