import React from "react";
import { useParams } from "react-router-dom";

// Function to format the URL parameter into a readable format
const formatTitle = (title: string): string => {
  return title
    .split("-")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const IndiaRanking = () => {
  const { overall } = useParams<{ overall?: string }>(); // Type definition for the URL parameter
  return (
    <div className="px-16 mt-4 max-md:px-8 w-full max-md:w-full text-center bg-gradient-to-b py-7 from-[#173CBA] to-[#06AFA9] text-white">
      <h3 className="text-white text-center text-[40px] font-light leading-[55px]">
        India Rankings 2024 {overall ? formatTitle(overall) : "Overall"}
      </h3>
    </div>
  );
};

export default IndiaRanking;
