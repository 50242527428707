import React, { useState, useRef, useEffect } from "react";
import WhyStudyAbroadImg from "../../images/study-abroad/Why-Study-Abroad-Img.png";
import type { SVGProps } from "react";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}

interface whyTabContent {
  heading: string;
  points: string[];
  imageUrl: string;
}

interface Props {
  title: string;
  description: string;
  tabList: {
    title: string;
    content: whyTabContent;
  }[];
}

const tabList = [
  {
    title: "Top-Ranked Universities",
    content: {
      heading: "Top-Ranked Universities",
      points: [
        "<span class='font-bold'>World-Class Education</span>: High-quality teaching and research.",
        "<span class='font-bold'>Prestigious Institutions</span>: Renowned for excellence.",
        "<span class='font-bold'>Academic Credentials</span>: Boost your resume.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
  {
    title: "High Paying Jobs",
    content: {
      heading: "High Paying Jobs",
      points: [
        "<span class='font-bold'>Career Prospects</span>: Better job opportunities.",
        "<span class='font-bold'>Higher Salaries</span>: Increased earning potential.",
        "<span class='font-bold'>Lucrative Positions</span>: Access top companies and MNCs.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
  {
    title: "Post Study Work Permit (PSW)",
    content: {
      heading: "Post Study Work Permit (PSW)",
      points: [
        "<span class='font-bold'>Work Experience</span>: Gain practical experience abroad.",
        "<span class='font-bold'>Extended Stay</span>: Work for a set period after graduation.",
        "<span class='font-bold'>Job Market</span>: Improve employability globally.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
  {
    title: "Permanent Residency (PR)",
    content: {
      heading: "Permanent Residency (PR)",
      points: [
        "<span class='font-bold'>Streamlined Pathway</span>: Easier transition from student to resident.",
        "<span class='font-bold'>Long-Term Stay</span>: Live and work abroad permanently.",
        "<span class='font-bold'>Ease of Transition</span>: Simplified residency process.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
  {
    title: "Affordable Education",
    content: {
      heading: "Affordable Education",
      points: [
        "<span class='font-bold'>Low Tuition Fees</span>: Study at lower cost.",
        "<span class='font-bold'>Financial Support</span>: Scholarships, loans, and aid.",
        "<span class='font-bold'>Part-Time Work</span>: Manage expenses with jobs and internships.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
  {
    title: "Enhanced Global Network",
    content: {
      heading: "Enhanced Global Network",
      points: [
        "<span class='font-bold'>Diverse Contacts</span>: Connect with international peers and professionals.",
        "<span class='font-bold'>Career Development</span>: Network for job opportunities.",
        "<span class='font-bold'>Collaborations</span>: Future projects and partnerships.",
      ],
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/whystudyabrod.jpg",
    },
  },
];

const WhyStudyAbroad: React.FC<Props> = ({ title, description, tabList }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const tabRef = useRef<HTMLDivElement>(null);

  const checkScroll = () => {
    if (tabRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(
        scrollWidth > clientWidth && scrollLeft + clientWidth < scrollWidth
      );
    }
  };

  useEffect(() => {
    checkScroll();

    const tabContainer = tabRef.current;
    if (tabContainer) {
      tabContainer.addEventListener("scroll", checkScroll);
    }

    return () => {
      if (tabContainer) {
        tabContainer.removeEventListener("scroll", checkScroll);
      }
    };
  }, []);

  const handleNextSlide = () => {
    if (activeTab < tabList.length - 1) {
      setActiveTab((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevSlide = () => {
    if (activeTab > 0) {
      setActiveTab((prevIndex) => prevIndex - 1);
    }
  };

  const handleScrollNext = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({
        left: tabRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScrollPrev = () => {
    if (tabRef.current) {
      tabRef.current.scrollBy({
        left: -tabRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className="bg-[#EEF2FF] pt-8 pb-5">
      <div className="container mx-auto">
        <div className="flex flex-col items-center gap-7 lg:gap-8">
          <div className="flex flex-col xl:px-8">
            <h3 className="text-4xl lg:text-5xl text-black font-thin">
              Why Study <span className="text-blue font-bold">{title}?</span>
            </h3>
            <p className="text-black text-base">{description}</p>
          </div>

          <div className="relative w-full flex items-center">
            {canScrollLeft && (
              <button
                className="absolute max-sm:hidden left-0 z-10 bg-blue text-white px-2 py-1 rounded-full rotate-180"
                onClick={handleScrollPrev}
              >
                <EpRight className="max-sm:w-3 max-sm:h-3" />
              </button>
            )}

            <div
              ref={tabRef}
              className="flex gap-3 overflow-x-auto whitespace-nowrap scrollbar-hide items-center justify-start lg:justify-center px-4"
            >
              {tabList.map((tab, index) => (
                <button
                  key={index}
                  className={`px-4 py-1 rounded-full text-sm border border-blue transition-colors duration-300 ${
                    activeTab === index
                      ? "bg-blue text-white"
                      : "bg-transparent text-blue hover:bg-blue hover:text-white"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.title}
                </button>
              ))}
            </div>

            {canScrollRight && (
              <button
                className="absolute max-sm:hidden top-0 right-0 z-10 bg-blue text-white px-2 py-1 rounded-full"
                onClick={handleScrollNext}
              >
                <EpRight className="max-sm:w-3 max-sm:h-3" />
              </button>
            )}
          </div>

          <div
            className="p-5 bg-white rounded-2xl transition-all duration-500 transform ease-in-out w-full lg:w-10/12"
            style={{ boxShadow: "0px 0px 15px 5px #173CBA26" }}
          >
            <div className="flex flex-col items-center lg:flex-row gap-7 opacity-100 transition-opacity duration-500">
              <div className="w-full lg:w-2/3">
                <div className="flex flex-col gap-5">
                  <h3 className="text-4xl font-light text-blue">
                    {tabList[activeTab].content.heading}
                  </h3>
                  <ul className="list-none text-gray-700 p-0 flex flex-col gap-5">
                    {tabList[activeTab].content.points.map((point, index) => (
                      <li
                        key={index}
                        className="flex flex-row items-center gap-4"
                      >
                        <span className="w-4 h-4 rounded-full bg-blue flex items-center p-1">
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.33301 0.333374L9.49613 4.72247L14.333 5.42631L10.8321 8.84361L11.6593 13.6667L7.33301 11.3891L3.00676 13.6667L3.83395 8.84361L0.333008 5.42631L5.16989 4.72247L7.33301 0.333374Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span
                          dangerouslySetInnerHTML={{ __html: point }}
                          className="text-gray-700"
                        ></span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full lg:w-1/3">
                <img
                  src={tabList[activeTab].content.imageUrl}
                  alt={tabList[activeTab].content.heading}
                  className="w-full h-auto rounded-lg transition-transform transform duration-500 ease-in-out"
                />
              </div>
            </div>
          </div>

          <div className="relative w-full flex justify-center items-center gap-8">
            <button
              onClick={handlePrevSlide}
              className={`flex flex-col items-center justify-center bg-blue w-10 h-10 border border-blue text-white px-4 py-2 rounded-full transition-opacity rotate-180 duration-300 ${
                activeTab === 0 ? "opacity-50 pointer-events-none" : ""
              }`}
            >
              <EpRight />
            </button>
            <div className="flex justify-center items-center text-blue text-2xl tracking-widest font-light">
              <span>{activeTab + 1}</span>
              <span>/</span>
              <span>{tabList.length}</span>
            </div>
            <button
              onClick={handleNextSlide}
              className={`flex flex-col items-center justify-center bg-blue w-10 h-10 border border-blue text-white px-4 py-2 rounded-full transition-opacity duration-300 ${
                activeTab === tabList.length - 1
                  ? "opacity-50 pointer-events-none"
                  : "border border-blue bg-blue text-white"
              }`}
            >
              <EpRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhyStudyAbroadSec = () => {
  return (
    <WhyStudyAbroad
      title="Abroad"
      description="Studying abroad offers a transformative experience that lasts a lifetime. You'll explore stunning landscapes, embrace diverse cultures, and gain skills essential for today’s global economy. Building lifelong friendships and enhancing your career prospects, you'll stand out to top companies seeking international experience."
      tabList={tabList}
    />
  );
};

export default WhyStudyAbroadSec;
