import React, { ReactNode, useState } from "react";
import parse from "html-react-parser";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col mt-5 w-full leading-7 gap-5 max-lg:w-full">
      <button
        className="flex flex-row text-[14px] gap-3.5 items-center w-full max-lg:w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <rect width="23" height="23" rx="3" fill="#173CBA" />
            <path
              d="M5 12H17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M11 18L11 6"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        )}
        <span className="font-semibold  items-center text-left">
          {question}
        </span>
      </button>
      {isOpen && (
        <div className="flex-auto leading-6 font-normal max-lg:w-full">
          {parse(answer)}
        </div>
      )}
    </div>
  );
};

const ScholarshipFAQ: React.FC = () => {
  const faqItems = [
    {
      question: "Who is eligible for the College Mentor Scholarship?",
      answer:
        "The College Mentor Scholarship is open to all +2 students in India",
    },
    {
      question: "How can I apply for the scholarship?",
      answer:
        'To apply for the College Mentor Scholarship, visit our scholarship page at <a href="#banner" className="text-blue">collegementor.com/college-mentor-scholarship</a> and click on the apply now button.. Fill out the application form and submit any required documents before the deadline.',
    },
    {
      question: "What is the total prize pool, and how is it distributed?",
      answer:
        "The total prize pool for the College Mentor Scholarship is ₹ 1 Crore, distributed among various winners based on their performance in the scholarship test. Specific amounts may vary based on the scholarship tier.",
    },
    {
      question: "What does the scholarship test entail?",
      answer:
        "The scholarship test consists of multiple-choice questions covering subjects relevant to your field of study (MPC & GK). The duration of the test is 1 hour.",
    },
    {
      question: "What are the important dates for the scholarship?",
      answer:
        "Important dates for the scholarship include the application deadline, scholarship test date, and announcement of winners. Registration Start: November 15, 2024, Registration End: March 30, 2025, Test Dates: March 16 – April 10, 2025, Results Announcement: April 15, 2025",
    },
    {
      question: "How will winners be selected?",
      answer:
        "Winners will be selected based on their performance in the scholarship test. A panel of judges will evaluate the results and make the final decisions.",
    },
    {
      question: "How will the awards be disbursed?",
      answer:
        "Awards will be disbursed directly to the winners' bank accounts within 30 days of the announcement of results. Ensure your bank details are accurately provided during the application process.",
    },
    {
      question: "What support do College Mentor services provide?",
      answer:
        "College Mentor provides personalized mentoring, career counseling, and resources to help students succeed in their educational pursuits. We also offer workshops and webinars on various topics.",
    },
    {
      question: "What if I encounter technical issues during the test?",
      answer:
        "If you face any technical issues during the test, please contact our support team immediately via the chat option on the test platform, or reach out to support@collegementor.com.",
    },
    {
      question: "Is there assistance for students from special categories?",
      answer:
        "Yes, we provide additional support for students from special categories, including tailored mentorship and resources. Please specify your needs in the application form.",
    },
  ];

  return (
    <div className="flex flex-col max-lg:px-7 my-6 px-20 max-lg:w-full text-sm font-semibold py-10 text-black w-full max-lg:mt-5 bg-[#FFF1FA]">
      <h2 className="text-3xl font-bold text-[#173CBA] leading-[55px]">
        Knowledge Hub (FAQ'S)
      </h2>
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default ScholarshipFAQ;
