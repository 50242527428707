import React, { useState, useEffect, useRef } from "react";
import "./SearchBox.scss";
import "animate.css";
import config from "../../util/config";
import axios from "axios";
import PopupInfo from "../popupInfo/popup";

interface SearchProps {
  // Optional props for customization (placeholder text, etc.)
}
interface College {
  title: string;
  name: string;
  categoryName: string;
  isDataAvailable: string;
}

const SearchBox: React.FC<SearchProps> = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allSearchData, setAllSearchData] = useState<College[]>([]);
  const [suggestions, setSuggestions] = useState<College[]>([]);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    setSearchTerm(inputValue);
    if (inputValue === "") {
      setSuggestions([]);
    } else {
      const filteredSuggestions: any = allSearchData.filter((suggestion) =>
        suggestion.title.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  const handleSuggestionClick = (suggestion: College) => {
    setSearchTerm(suggestion.title);
    setSuggestions([]);

    if (suggestion.isDataAvailable) {
      window.location.href = `/college-details/${suggestion.name}`;
    } else {
      setShowPopup(true);
      setSearchTerm("");
      setTimeout(() => {
        setIsExpanded(false);
      }, 2000);
    }
  };

  const handleClearInput = () => {
    setSearchTerm("");
    setSuggestions([]);
  };

  useEffect(() => {
    axios({
      url: config.apiUrl + "/indexes/search/search",
      method: "POST",
      headers: {
        Authorization: `Bearer ` + config.searchApiKey,
        "Content-Type": "application/json",
      },
      data: {
        q: searchTerm || " ",
      },
    })
      .then((response) => {
        const fetchedData = response.data.hits || [];
        setAllSearchData(fetchedData);
        if (searchTerm.trim()) {
          setSuggestions(fetchedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching search data:", error);
      });
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target as Node) // Cast event.target to Node type
      ) {
        setSuggestions([]); // Clear the suggestions
        setSearchTerm(""); // Reset the input search box
        setIsExpanded(false); // Close the popup
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-box align-middle w-full" ref={searchBoxRef}>
      {isExpanded && (
        <div
          className={`md:w-[1000px] mx-auto absolute z-[1111] bg-white rounded-lg left-0 right-0 translate-y-24 top-24 p-5 gap-4 ${
            isExpanded
              ? "animate__animated animate__slideInDown"
              : "animate__animated animate__slideInUp"
          }`}
          style={{ boxShadow: "0 5px 4px rgba(0,0,0,.161)" }}
        >
          <div className="flex flex-col gap-2">
            <h3>
              Search for best colleges, Courses, Exams and Education updates
            </h3>
            <div className="flex flex-row relative items-center justify-end">
              <label className="absolute left-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width={16}
                  height={16}
                  fill="#717171"
                >
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                </svg>
              </label>
              <input
                type="text"
                placeholder="Enter what you are looking for: College, Specialization, Course or Exam"
                value={searchTerm}
                onChange={handleSearchChange}
                className={`searchbox w-full border border-gray-200 ${
                  isExpanded ? "expanded" : ""
                }`}
              />
              {searchTerm && (
                <button
                  className={`animate__animated  absolute right-2`}
                  onClick={handleClearInput}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    fill="#717171"
                    width={14}
                    height={14}
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {suggestions.length > 1 ? (
            <div
              className={`py-6 relative ${
                suggestions.length > 0
                  ? "animate__animated animate__fadeIn"
                  : ""
              }`}
            >
              <hr className="border-gray-200 py-2" />
              <div className="min-h-[200px] max-h-[300px] overflow-y-auto">
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <div>
                      <li
                        key={index}
                        className="cursor-pointer text-sm text-[#333] font-medium py-2 px-4 hover:bg-gray-100 flex flex-row justify-between"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        <span>{suggestion.title}</span>
                        <span>{suggestion.categoryName || "N/A"}</span>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div className="relative flex items-center">
        <input
          type="text"
          placeholder="Search"
          className="hidden md:block border rounded-3xl pl-4 pr-10 py-2 w-full cursor-pointer focus:outline-none "
          onFocus={(e) => {
            handleToggleExpand();
            e.target.blur();
          }}
        />
        <button
          type="button"
          className="md:absolute md:right-0 md:top-0 md:bottom-0 md:py-2 rounded-r-md md:pr-3"
          onClick={handleToggleExpand}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.58329 2.79163C5.55622 2.79163 2.29163 6.05622 2.29163 10.0833C2.29163 14.1104 5.55622 17.375 9.58329 17.375C13.6104 17.375 16.875 14.1104 16.875 10.0833C16.875 6.05622 13.6104 2.79163 9.58329 2.79163ZM1.04163 10.0833C1.04163 5.36586 4.86586 1.54163 9.58329 1.54163C14.3007 1.54163 18.125 5.36586 18.125 10.0833C18.125 12.2171 17.3426 14.1681 16.049 15.6651L18.7752 18.3914C19.0193 18.6354 19.0193 19.0312 18.7752 19.2752C18.5312 19.5193 18.1354 19.5193 17.8914 19.2752L15.1651 16.549C13.6681 17.8426 11.7171 18.625 9.58329 18.625C4.86586 18.625 1.04163 14.8007 1.04163 10.0833Z"
              fill="#333333"
            />
          </svg>
        </button>
      </div>
      {showPopup && (
        <PopupInfo isOpen={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};

export default SearchBox;
