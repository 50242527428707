import React, { useState, useEffect } from "react";
import TrendingTopics from "../components/TrendingTopics";
import BlogsBanner from "../components/BlogsAdBanner";
import BlogsBreadcrumb from "../components/BlogsBreadcrumb";
import BlogsListContainer from "./sections/BlogsListContainer";
import BlogsHeader from "../components/BlogsHeader";
import BannerImag1 from "../../../images/blog-article/blogsAdBanner.png";
import AmritaHorizontal from "../../../images/blog-article/Amrita-Horizontal.png";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import BlogShareFloatingMenu from "../components/BlogShareFloatingMenu";
import usePagination from "../../../components/filter/customHooks/usePagination";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setCurrentCategoryPageValue } from "../../../store/slices/blogSlice";
import { url } from "inspector";
import { breadcrumbs } from "@material-tailwind/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../../util/config";
import CollegeMentorScholarshipAd from "../components/CollegeMentorScholarshipAd";
interface Breadcrumb {
  newsTitle: { title: string; slug: string };
  newsSubTopic: any;
  newsTopic: { title: string; slug: string };
  home: { title: string; slug: string };
  [key: string]: any;
}
const BlogsIntermediate = () => {
  const dispatch = useDispatch();
  const hoveredMenu = useSelector((state: RootState) => state.blogSlice);
  //get category from location
  const location = useLocation();

  const { pathname, state } = location;

  const { topicSlug } = useParams<{ topicSlug: string }>();

  const [blogsList, setBlogsList] = useState([]);
  //Page loading
  const [loading, setLoading] = useState<boolean>(true);

  const bannerImage2 = "/images/blogsBanner.png";
  const [breadCrumb, setBreadCrumb] = useState<Breadcrumb>({
    newsTitle: { title: "", slug: "" },
    newsSubTopic: { title: "", slug: "" },
    newsTopic: { title: "exams", slug: "" },
    home: { title: "home", slug: "/blogs" },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(4);
  const [totalNoOfPages, setTotalNoOfPages] = useState(10);

  const [trendingTopic, setTrendingTopic] = useState([]);
  const [blogRelated, setBlogRelated] = useState([]);
  const [otherNews, setOtherNews] = useState([]);

  //Get All the list of Blogs based on category
  const getBlogsListByCategory = async (topicSlug: string) => {
    setLoading(true);
    let payload = {
      topic: topicSlug,
      subtopic: state?.subTopic || "",
      currentPage: currentPage,
      currentPageSize: currentPageSize,
    };
    await axios
      .post(config.apiUrl + `/api/blogs/topic-details`, payload)
      .then((response) => {
        setTrendingTopic(response?.data?.trendingTopics);
        setBlogsList(response?.data?.details);
        setCurrentPage(response?.data?.details?.currentPage);
        setCurrentPageSize(response?.data?.details?.currentPageSize);
        setTotalNoOfPages(response?.data?.details?.totalNoOfPages);
        setBreadCrumb(response?.data?.breadCrumb);
        setLoading(false);
        setBlogRelated(response?.data?.blogRelated);
        setOtherNews(response?.data?.otherNews);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const handleResetPagination = () => {
    if (currentPage != 1) setCurrentPage(1);
  };

  useEffect(() => {
    if (topicSlug) getBlogsListByCategory(topicSlug);
  }, [state, topicSlug, currentPage]);
  document.title =
    "College Mentor | Popular Education News Blogs & Study updates";
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute(
      "content",
      "Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
    );
  if (loading) {
    return <Loader />;
  }

  // updateTotalCount(paginationValues.totalPages * paginationValues.currentPage);
  const handleNextPage = () => {
    setCurrentPage((prev) => {
      if (prev + 1 <= totalNoOfPages) {
        window.scrollTo(0, 0); // Scroll to the top
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => {
      if (prev - 1 > 0) {
        window.scrollTo(0, 0); // Scroll to the top
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const paginationProps = {
    currentPage: currentPage,
    totalPages: totalNoOfPages,
    handleNextPage: handleNextPage,
    handlePrevPage: handlePrevPage,
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Popular Education News Blogs & Study updates
          </title>
          <meta
            name="description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta
            name="keywords"
            content="latest school news, best universities for international students, popular education news blogs, best academic blogs, government education updates, blogs about student life, jee and neet updates
best blogs for students, most popular university courses, blogs on college admissions, exam preparation blogs, exam updates 2025, international study admissions news, latest college admission news, list of top-ranked universities, scholarship updates for students."
          />
          <link rel="canonical" href={`${config.apiUrl}/blogs/${topicSlug}`} />

          <meta
            property="og:title"
            content="College Mentor | Popular Education News Blogs & Study updates"
          />
          <meta
            property="og:description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Popular Education News Blogs & Study updates"
          />
          <meta
            name="twitter:description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div className="container grid grid-cols-1 gap-4 py-8 sm:grid-cols-1 md:grid-cols-1 gap-y-4">
        <TrendingTopics trendingTopics={trendingTopic} />
        <CollegeMentorScholarshipAd />
        {/* <div className="hidden py-4 xs:hidden sm:hidden md:block ">
          <div className="flex justify-center w-full">
            <BlogsBanner
              width=""
              height=""
              image={bannerImage2}
              link="https://sageuniversity.in/"
            />
          </div>
        </div> */}
        <BlogsBreadcrumb
          newsTitle={breadCrumb?.newsTitle}
          newsSubTopic={breadCrumb?.newsSubTopic}
          newsTopic={breadCrumb?.newsTopic}
          home={breadCrumb?.home}
          stream={breadCrumb?.stream}
        />
        <BlogsListContainer
          blogsCategory={topicSlug}
          blogsList={blogsList}
          paginationProps={paginationProps}
          blogRelated={blogRelated}
          otherNews={otherNews}
        />
      </div>
    </>
  );
};

export default BlogsIntermediate;
