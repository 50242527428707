import React from "react";
import ContactImg from "../../../images/college/details/map.png";
interface ContactSectionProps {
  title: string;
  description: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({
  title,
  description,
}) => {
  return (
    <section className="py-3 bg-white max-sm:py-3" id="ContactSection">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-7 items-center">
          <div className="w-full lg:w-1/2">
            <h2 className="text-3xl font-semibold text-[#113cc0] mb-3">
              {title}
            </h2>
            <div
              className="text-lg leading-relaxed"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="w-full lg:w-1/2">
            <div className="text-center flex flex-col items-center justify-center">
              <img src={ContactImg} alt="Contact Info" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
