import React from "react";
import {
  IClearFilter,
  IUpdateSelectedFilter,
  ISelectedFilter,
} from "../customHooks/useSelectedFilter";
import DesktopFilterItem from "./DesktopFilterItem";
import { FilterCategory } from "../FilterEnum";

interface IFilterDataItem {
  categoryName: string;
  categoryKey: string;
  type:
    | FilterCategory.MultiSelect
    | FilterCategory.SingleSelect
    | FilterCategory.Radio;
  options: {
    count?: number;
    key: string;
    name: string;
    isSelected?: boolean;
  }[];
}

interface IProps {
  filterData: IFilterDataItem[];
  selectedFilter: ISelectedFilter;
  updateSelectedFilter: IUpdateSelectedFilter;
  clearFilter: IClearFilter;
}

const DesktopFilterMenu: React.FC<IProps> = ({
  filterData,
  selectedFilter,
  updateSelectedFilter,
  clearFilter,
}) => {
  const isClearBtnDisabled = !(
    selectedFilter &&
    (Object.keys(selectedFilter.multiSelectFilters).length > 0 ||
      Object.keys(selectedFilter.singleSelectFilters).length > 0)
  );

  return (
    <div className="p-6 bg-blue-dark text-white rounded-lg flex flex-col gap-2">
      <div className="flex items-center">
        <span className="text-lg font-medium">Filter by</span>
        <button
          className={`ml-auto text-base ${
            isClearBtnDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isClearBtnDisabled}
          onClick={clearFilter}
        >
          Clear All
        </button>
      </div>
      {filterData.map((data) => {
        let particularCategorySelectedFilter = null;
        if (data.type == FilterCategory.MultiSelect)
          particularCategorySelectedFilter =
            selectedFilter.multiSelectFilters[data.categoryKey];
        else
          particularCategorySelectedFilter =
            selectedFilter.singleSelectFilters[data.categoryKey];
        return (
          <DesktopFilterItem
            data={data}
            selectedFilter={particularCategorySelectedFilter}
            updateSelectedFilter={updateSelectedFilter}
          />
        );
      })}
    </div>
  );
};

export default DesktopFilterMenu;
export type { IFilterDataItem };
