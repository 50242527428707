import React from "react";
import "./ImageCard.scss";

export const ImageCard = ({ image, city }: { image: string; city: string }) => {
  return (
    <div className="image-card-main">
      <div className="imgZoom">
        <img src={image} alt={city} />
      </div>
      <div className="city-btn-section flex justify-between">
        <span className="font-semibold text-sm text-[#363940]">{city}</span>
        <a href={`/list-of-colleges-in-india?city=${city}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
          >
            <path
              d="M16.4837 20.8839L21.4833 15.8843C21.7169 15.6493 21.8479 15.3314 21.8479 15.0001C21.8479 14.6688 21.7169 14.351 21.4833 14.1159L16.4837 9.11641C16.2486 8.88552 15.9318 8.75682 15.6023 8.75831C15.2728 8.75981 14.9572 8.89137 14.7242 9.12438C14.4912 9.35738 14.3596 9.67298 14.3581 10.0025C14.3566 10.332 14.4853 10.6488 14.7162 10.8839L17.5824 13.7502H10.6C10.2685 13.7502 9.95051 13.8819 9.71609 14.1163C9.48167 14.3507 9.34998 14.6686 9.34998 15.0002C9.34998 15.3317 9.48167 15.6496 9.71609 15.884C9.95051 16.1185 10.2685 16.2502 10.6 16.2502H17.5824L14.7162 19.1164C14.4842 19.3513 14.3546 19.6684 14.3556 19.9986C14.3567 20.3287 14.4883 20.645 14.7217 20.8784C14.9552 21.1118 15.2715 21.2434 15.6016 21.2445C15.9317 21.2455 16.2488 21.1159 16.4837 20.8839Z"
              fill="white"
            />
            <path
              d="M28.1 15C28.1 12.5277 27.3669 10.111 25.9933 8.05538C24.6198 5.99976 22.6676 4.39761 20.3835 3.45151C18.0994 2.50542 15.5861 2.25787 13.1614 2.74019C10.7366 3.2225 8.5093 4.41301 6.76115 6.16117C5.01299 7.90933 3.82248 10.1366 3.34017 12.5614C2.85785 14.9861 3.10539 17.4995 4.05149 19.7836C4.99758 22.0676 6.59974 24.0199 8.65535 25.3934C10.711 26.7669 13.1277 27.5 15.6 27.5C18.9141 27.4963 22.0913 26.1782 24.4347 23.8348C26.7782 21.4914 28.0963 18.3141 28.1 15ZM14.7162 20.8838C14.6001 20.7677 14.508 20.6299 14.4452 20.4783C14.3824 20.3267 14.35 20.1641 14.35 20C14.35 19.8358 14.3824 19.6733 14.4452 19.5216C14.508 19.37 14.6001 19.2322 14.7162 19.1162L17.5824 16.25H10.6C10.2685 16.25 9.95052 16.1183 9.7161 15.8839C9.48168 15.6495 9.34998 15.3315 9.34998 15C9.34998 14.6685 9.48168 14.3505 9.7161 14.1161C9.95052 13.8817 10.2685 13.75 10.6 13.75H17.5824L14.7162 10.8838C14.4853 10.6486 14.3566 10.3319 14.3581 10.0024C14.3596 9.67283 14.4912 9.35723 14.7242 9.12422C14.9572 8.89122 15.2728 8.75965 15.6023 8.75816C15.9318 8.75667 16.2486 8.88537 16.4837 9.11626L21.4834 14.1159C21.7169 14.3509 21.8479 14.6687 21.8479 15C21.8479 15.3313 21.7169 15.6492 21.4834 15.8842L16.4837 20.8838C16.3677 20.9998 16.2299 21.0919 16.0783 21.1548C15.9266 21.2176 15.7641 21.2499 15.6 21.2499C15.4358 21.2499 15.2733 21.2176 15.1217 21.1548C14.97 21.0919 14.8323 20.9998 14.7162 20.8838Z"
              fill="#00C798"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};
