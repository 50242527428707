import axios from "axios";
import config from '../util/config';
const BaseURLAuth = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
const BaseURL = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor: Attach Token Only If Already Present
BaseURLAuth.interceptors.request.use(
  async (configu) => {
    let accessToken = localStorage.getItem("accessToken");

    // If Authorization header is already set, validate token before sending the request
    if (configu.headers.Authorization) {
      if (!accessToken) {
        console.error("Missing access token. Redirecting to login.");
        window.location.href = "/secure/login";
        return Promise.reject("No access token found.");
      }

      // Optional: Validate token expiry before sending request
      const isTokenExpired = checkTokenExpiry(accessToken);
      if (isTokenExpired) {
        try {
          // Refresh token before proceeding
          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) throw new Error("No refresh token found");

          const refreshResponse = await axios.get(
            `${config.apiUrl}/api/auth/accessToken/` + refreshToken,
          );

          accessToken = refreshResponse.data.accessToken;
          localStorage.setItem("accessToken", refreshResponse.data.accessToken);
          localStorage.setItem("refreshToken", refreshResponse.data.refreshToken);

          // Update request headers with the new token
          configu.headers.Authorization = refreshResponse.data.accessToken;
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/secure/login"; // Redirect to login
          return Promise.reject(refreshError);
        }
      }
    }
    return configu;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle Unauthorized Errors
BaseURLAuth.interceptors.response.use(
  (response) => response, // Pass successful responses
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
          throw new Error("No refresh token found");
        }

        // Refresh the token
        const refreshResponse = await axios.post(
          `${config.apiUrl}/api/auth/accessToken`,
          { refreshToken }
        );

        const newAccessToken = refreshResponse.data.accessToken;
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("refreshToken", refreshResponse.data.refreshToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/secure/login"; // Redirect to login
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// Function to Check Token Expiry (Optional)
const checkTokenExpiry = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    const expiry = payload.exp * 1000; // Convert expiry time to milliseconds
    return Date.now() >= expiry;
  } catch (error) {
    return true; // Treat as expired if there's an issue decoding
  }
};


export default BaseURL;
