import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavigationBanner from "../images/navbar/megamenu/NavigationBanner.webp";
import { EpRight } from "./study-abroad-landing/AboutEducationMentorsGlobally";

const ScholarshipPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("scholarshipPopupClosed");

    if (!hasSeenPopup) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        document.body.classList.add("overflow-hidden");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closePopup = () => {
    localStorage.setItem("scholarshipPopupClosed", "true");
    setShowPopup(false);
    document.body.classList.remove("overflow-hidden");
  };
  useEffect(() => {
    if (showPopup) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showPopup]);

  if (!showPopup) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-md z-[1111] animate-fadeIn">
      <div className="bg-white p-4 rounded-lg shadow-2xl w-[90%] md:w-[60%] max-w-lg transform transition-all scale-100">
        <div className="relative">
          <button
            className="absolute -top-1 -right-1 bg-blue text-white w-8 h-8 rounded-full hover:bg-gray-400 z-50 flex items-center justify-center"
            onClick={closePopup}
          >
            ✕
          </button>
          <div className="relative">
            <div className="flex">
              <img
                src={NavigationBanner}
                alt="Scholarship"
                className="w-full h-full rounded-lg object-cover"
              />
            </div>
            <div className="flex flex-col gap-2 mt-4 absolute -bottom-5 p-4">
              <h4 className="text-base font-light text-[#F8EC67] text-center">
                Empowering{" "}
                <strong className="font-semibold">
                  +2 Students with <br />
                  <span className="text-base md:text-xl font-bold">
                    ₹1 Crore
                  </span>
                  <sup>*</sup> in Scholarships.
                </strong>
              </h4>
              <p className="text-white text-center text-sm">
                Currently studying in Class 12 or equivalent from a recognized
                board in India.
              </p>
              <div className="flex items-center justify-center">
                <Link
                  className="bg-[#173CBA] text-white py-1.5 px-4 text-sm rounded-full hover:bg-green transition flex items-center justify-center gap-1"
                  to="https://cocubes.in/collegementor"
                  onClick={closePopup}
                  target="_blank"
                >
                  Exam Live <EpRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipPopup;
