import Banner from "../../components/banner-section/Banner";
import Universities from "../../components/universities/Universities";
import WhyCollegeMentor from "../../components/why-college-mentor/WhyCollegeMentor";
import DidYouKnow from "../../components/did-you-know/DidYouKnowData";
import CareerAssessmentTest from "../../components/career-assessment-test/CareerAssessmentTest";
import DreamMentors from "../../components/dream-mentors/DreamMentors";
import DreamCareer from "../../components/dream-careers/DreamCareer";
import ComprehensiveTest from "../../components/comprehensive-test/ComprehensiveTest";
import { DreamColleges } from "./components/dream-colleges/DreamColleges";

import { RankPredictor } from "./components/college-and-rank-predictor/RankPredictor";
import Scholarships from "./components/Scholarships";
import { CollegesByDestination } from "./components/colleges-by-destination/CollegesByDestination";
import Blogs from "../../components/blogs/Blogs";
import useFloatItemAnimation from "./useFloatItemAnimation";
import Posts from "./components/posts/Posts";
import DreamCourses from "./components/DreamCourses";
import StudyAbroadOptions from "./components/StudyAbroadOptions";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";
import { useEffect } from "react";

function LandingPage() {
  const { checkpointRefs, animationItemRef, animatedItemProps, isPulse } =
    useFloatItemAnimation();
  useEffect(() => {
    document.title =
      "College Mentor | Mentorship, Scholarships & College applications";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "From 8th grade to graduates, College Mentor provides expert guidance on career choices, scholarships, exams, and college applications. Compare institutions, predict rankings, and receive expert mentorship for a successful future."
      );
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Mentorship, Scholarships & College applications
          </title>
          <meta
            name="description"
            content="From 8th grade to graduates, College Mentor provides expert guidance on career choices, scholarships, exams, and college applications. Compare institutions, predict rankings, and receive expert mentorship for a successful future."
          />
          <meta
            name="keywords"
            content="Exams, College, Career Guidance, Scholarships, Mentors, College Applications, College Rankings, Compare Colleges, Admission Assistance, Predict Colleges, Free Mentor, Study Guidance."
          />
          <link rel="canonical" href={`${config.apiUrl}`} />

          <meta
            property="og:title"
            content="College Mentor | Mentorship, Scholarships & College applications"
          />
          <meta
            property="og:description"
            content="From 8th grade to graduates, College Mentor provides expert guidance on career choices, scholarships, exams, and college applications. Compare institutions, predict rankings, and receive expert mentorship for a successful future."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Mentorship, Scholarships & College applications "
          />
          <meta
            name="twitter:description"
            content="From 8th grade to graduates, College Mentor provides expert guidance on career choices, scholarships, exams, and college applications. Compare institutions, predict rankings, and receive expert mentorship for a successful future."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />

          {/* Educational Organization Schema */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "EducationalOrganization",
              name: "College Mentor",
              url: "https://www.collegementor.com/",
              description:
                "College Mentor - Your trusted guide for career choices, scholarships, exams, and college applications.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "Madhapur",
                addressLocality: "Hyderabad",
                addressRegion: "TG",
                postalCode: "500081",
                addressCountry: "India",
              },
              telephone: "+91-7997166666",
              email: "info@collegementor.com",
              sameAs: [
                "https://www.facebook.com/people/College-Mentor/61570539353128/",
                "https://www.instagram.com/college.mentor_insta",
                "https://x.com/college_mentor_",
                "https://www.linkedin.com/company/mycollegementor",
                "https://www.youtube.com/@college.mentor",
              ],
              logo: "https://www.collegementor.com/static/media/College%20Mentor_Final_Logo-01.ae0ff9c1ffa9fc9fac3e.png",
              foundingDate: "2010",
              founders: [
                {
                  "@type": "Person",
                  name: "Raja Shekar",
                },
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+91-7997166666",
                contactType: "customer support",
                availableLanguage: ["English", "Hindi", "Telugu"],
              },
              offers: [
                {
                  "@type": "EducationalOccupationalProgram",
                  name: "College Mentor - Career Guidance & Mentorship Program",
                  provider: {
                    "@type": "EducationalOrganization",
                    name: "College Mentor",
                    url: "https://www.collegementor.com/",
                  },
                },
              ],
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <div className="main-section">
        <Banner
          animationCheckPointRef={(el) => {
            checkpointRefs.current[0] = el;
          }}
          animationItemRef={animationItemRef}
          animatedItemProps={animatedItemProps}
          isPulse={isPulse}
        />
        <Universities />
        <WhyCollegeMentor
          animationCheckPointRef={(el) => {
            checkpointRefs.current[1] = el;
          }}
        />
        <DidYouKnow />
        <CareerAssessmentTest
          animationCheckPointRef={(el) => {
            checkpointRefs.current[2] = el;
          }}
        />
        <DreamMentors
          animationCheckPointRef={(el) => {
            checkpointRefs.current[3] = el;
          }}
        />
        <DreamCareer
          animationCheckPointRef={(el) => {
            checkpointRefs.current[4] = el;
          }}
        />
        <DreamCourses
          animationCheckPointRef={(el) => {
            checkpointRefs.current[5] = el;
          }}
        />
        <ComprehensiveTest
          animationCheckPointRef={(el) => {
            checkpointRefs.current[6] = el;
          }}
        />
        <DreamColleges
          animationCheckPointRef={(el) => {
            checkpointRefs.current[7] = el;
          }}
        />
        <CollegesByDestination
          animationCheckPointRef={(el) => {
            checkpointRefs.current[8] = el;
          }}
        />
        <StudyAbroadOptions
          animationCheckPointRef={(el) => {
            checkpointRefs.current[9] = el;
          }}
        />
        <RankPredictor
          animationCheckPointRef={(el) => {
            checkpointRefs.current[10] = el;
          }}
        />
        <Scholarships
          animationCheckPointRef={(el) => {
            checkpointRefs.current[11] = el;
          }}
        />
        {/* <BlogsAndArticles /> */}
        <Blogs />
        <Posts />
        {/* <DownloadApp/> */}
      </div>
    </>
  );
}
export default LandingPage;
