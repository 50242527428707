import { Link } from "react-router-dom";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { SwiperClass } from "swiper/react";
import { RootState } from "../../../../store/store";

export type IStateCardProps = {
  name: string;
  image: string;
  deemedUniversities: number;
  statePrivateUniversities: number;
  publicUniversities: number;
  collegeData: { image: string; name: string; collegeUrl: string }[];
  slideChange: number;
};

const StateCard: React.FC<IStateCardProps> = ({
  name,
  image,
  deemedUniversities,
  statePrivateUniversities,
  publicUniversities,
  collegeData,
  slideChange,
}) => {
  const collegeCount = collegeData?.length;
  const [slidedItems, setSlidedItems] = useState(0);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

  const sliderContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sliderContainerRef?.current)
      setSliderContainerWidth(sliderContainerRef.current.clientWidth);
  }, [sliderContainerRef]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSlidedItems((prev) => (prev + 1) % collegeCount);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [slideChange]);

  if (!collegeData) return;

  return (
    <div className="w-full p-2 bg-[#F4D2D2] flex flex-col items-center rounded-lg">
      <div className="w-full flex gap-2 items-stretch">
        <div className="w-1/2 overflow-hidden" ref={sliderContainerRef}>
          <div
            className={`flex h-full`}
            style={{
              width: `${(collegeData?.length || 0) * 2 * 100}%`,
              transform: `translateX(${
                -1 * slidedItems * sliderContainerWidth
              }px)`,
              transition: "transform 0.5s ease",
            }}
          >
            {[
              ...collegeData?.map((data) => data.image),
              ...collegeData?.map((data) => data.image),
            ].map((img) => (
              <img
                src={img}
                alt={name}
                style={{ width: `${100 / (collegeData?.length * 2)}%` }}
              />
            ))}
          </div>
        </div>
        <div className="w-1/2">
          <img src={image} className="w-full" alt="university-img" />
        </div>
      </div>
      <div className="mt-2 flex flex-col gap-2">
        {[
          { title: "Deemed Universities", value: deemedUniversities },
          {
            title: "State Private Universities",
            value: statePrivateUniversities,
          },
          { title: "Public Universities", value: publicUniversities },
        ].map((item) => (
          <Link
            key={item.title}
            to={`/list-of-colleges-in-india?state=${encodeURIComponent(name)}`}
            className="w-full px-4 py-2 bg-white hover:bg-blue text-sm font-semibold text-grey hover:text-white rounded-full text-center"
          >
            {`${item.title} - ${item.value}`}
          </Link>
        ))}
      </div>
    </div>
  );
};

const StateWise = ({ stateWiseColleges }: { stateWiseColleges: any }) => {
  const [indiaCardWidth, setIndiaCardWidth] = useState(0);
  const swiperContainerRef = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [slideChange, setSlideChange] = useState(0);

  useEffect(() => {
    if (swiperContainerRef?.current) {
      const totalWidth = swiperContainerRef.current.clientWidth;
      setIndiaCardWidth((totalWidth - 3 * 30) / 4);
    }
  }, [swiperContainerRef?.current?.clientWidth]);

  // useEffect(() => {
  //   console.log("swiper ref change change");

  //   const handleSlideChange = () => {
  //     // TODO change this logic
  //     setSlideChange((prev) => (prev + 1) % 10);
  //   };

  //   if (swiper) {
  //     swiper.on("slideChange", handleSlideChange);
  //   }

  //   return () => {
  //     if (swiper) swiper.off("slideChange");
  //   };
  // }, [swiper == null]);

  if (!stateWiseColleges) return;

  const isLargeScreen = window.innerWidth >= 1280;

  const countryCard = (
    <StateCard {...stateWiseColleges?.country} slideChange={slideChange} />
  );
  let allCards: ReactNode[] = [];

  if (!isLargeScreen) allCards.push(countryCard);

  stateWiseColleges?.states?.forEach((state: any) =>
    allCards.push(<StateCard {...state} slideChange={slideChange} />)
  );

  return (
    <div className="bg-[#FFF1FA] text-black">
      <div className="container py-7">
        <h2 className="text-4xl lg:text-5xl font-bold text-center">
          State-wise Colleges
        </h2>
        <div ref={swiperContainerRef} className="relative mt-6">
          {isLargeScreen && (
            <div
              className="absolute left-0 top-0 z-10"
              style={{ width: indiaCardWidth }}
            >
              {countryCard}
            </div>
          )}
          <CustomSwiper
            items={allCards}
            navigation={"bottom"}
            speed={2000}
            delay={2000}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1280: { slidesPerView: 4, spaceBetween: 30 },
            }}
            progressBar={true}
            getSwiper={(swiper) => setSwiper(swiper)}
          />
        </div>
      </div>
    </div>
  );
};

export default StateWise;
