import React from "react";
import "./ExploreStreamCourse.scss";
import FinanceImg from "../../images/courses/finance.svg";
import ArtsImg from "../../images/courses/liberalarts.svg";
// import DataScienceImg from "../../images/courses/data-scince-icon.svg";
import BusinessImg from "../../images/courses/business.svg";
// import ComputerScienceImg from "../../images/courses/computer-science-icon.svg";
// import AgricultureImg from "../../images/courses/agriculture-icon.svg";
// import UIDesignImg from "../../images/courses/uiux-icon.svg";
import ArchitectureImg from "../../images/courses/architecture.svg";
// import LanguagesImg from "../../images/courses/language-icon.svg";
import engineering from "../../images/courses/engineering.svg";
import MedicalImg from "../../images/courses/medicine.svg";
import tourism from "../../images/courses/tourism.svg";
import journalism from "../../images/courses/journalism.svg";
import law from "../../images/courses/law.svg";
import animation from "../../images/courses/animation.svg";
import Arrow from "../../images/Arrow.svg";
import heart from "../../images/courses/heart.svg";

import { link } from "fs";
import Loader from "../Loader/Loader";

interface IStreamItem {
  name: string;
  key: string;
  icon: string;
}

interface IProps {
  data: IStreamItem[];
}
const card = (stream: IStreamItem, additionalClasses: string = "") => {
  return (
    <a
      href={`courses/list-of-${stream.key}-courses-in-india`}
      key={stream.key}
      className={`Cards-stream flex flex-col w-[168px] md:w-[217px] bg-white rounded-lg cursor-pointer relative pb-10 ${additionalClasses}`}
      style={{ boxShadow: "none" }}
    >
      <div className="imagescaling content w-[168px] md:w-[217px] h-[121px] md:h-[156px] overflow-hidden">
        <img
          src={stream.icon}
          alt={stream.name}
          className="w-full h-full object-contain hover:scale-[130%] transition-transform duration-300"
        />
      </div>
      {/* <img src={heart} className="heart absolute top-2 right-2 z-50" /> */}
      <div className="content flex flex-col items-center justify-center py-2 w-[168px] md:w-[217px] px-2">
        <h4 className="text-sm md:text-base text-blue font-open-sans font-bold m-0 text-center line-clamp-2">
          {stream.name}
        </h4>
      </div>
      <a className="" href={`courses/list-of-${stream.key}-courses-in-india`}>
        <div className="pb-4 cta flex items-center justify-center absolute bottom-0 left-20 md:left-24">
          <img src={Arrow} className="arrow-icon" alt="arrow-icon" />
        </div>
      </a>
    </a>
  );
};
const ExploreStreamCourseContent = [
  {
    title: "Explore Stream by Course",
    button: "View All",
    coursecategories: [
      {
        id: 1,
        title: "Engineering",
        image: engineering,
        link: "/course-details",
      },
      {
        id: 2,
        title: "Architecture",
        image: ArchitectureImg,
        link: "/course-details",
      },
      {
        id: 3,
        title: "Medicine and Allied Sciences",
        image: MedicalImg,
        link: "/course-details",
      },
      {
        id: 4,
        title: "Management and Business",
        image: BusinessImg,
        link: "/course-details",
      },
      {
        id: 5,
        title: "Animation and Design",
        image: animation,
        link: "/course-details",
      },
      {
        id: 6,
        title: "Accounts and Finance",
        image: FinanceImg,
        link: "/course-details",
      },
      {
        id: 7,
        title: "Law",
        image: law,
        link: "/course-details",
      },

      {
        id: 8,
        title: "Liberal Arts",
        image: ArtsImg,
        link: "/course-details",
      },
      {
        id: 9,
        title: "Mass Communication and Journalism",
        image: journalism,
        link: "/course-details",
      },
      {
        id: 10,
        title: "Hospitality and Tourism",
        image: tourism,
        link: "/course-details",
      },
    ],
  },
];

const ExploreStreamCourse: React.FC<IProps> = ({ data }) => {
  if (!data) return <Loader />;

  return (
    <div className="flex py-10 md:py-10 bg-[#FFF9E5] max-sm:py-6">
      <div className="container mx-auto parent-container">
        <div className="flex flex-col">
          <div
            key="explore-course-by-stream"
            className="flex flex-col gap-4  lg:gap-1"
          >
            <div className="flex flex-row justify-between">
              <h3 className="text-3xl lg:text-[40px] font-bold lg:leading-[58px] text-black m-0">
                Explore Course By Stream
              </h3>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-6 lg:gap-9 mt-6 max-sm:mt-1 relative">
              {/* For small devices (less than md) */}
              {data
                .slice(0, 4)
                .map((stream) => card(stream, "block md:hidden"))}
              {data
                .slice(0, 9)
                .map((stream) => card(stream, "hidden md:block lg:hidden"))}

              {/* For medium and larger devices (md and above) */}
              {data
                .slice(0, 10)
                .map((stream) => card(stream, "hidden lg:block"))}
            </div>

            <div className="block  flex flex-row items-center justify-center md:justify-end max-sm:mt-2">
              <a
                href="courses/list-of--courses-in-india"
                className="white-color map-mentor button-styles arrow-button"
              >
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="currentColor"
                  className="arrow-icon"
                >
                  <path
                    d="M9.12305 5.24414L5.09766 1.21875L6.15234 0.164062L11.9883 6L6.15234 11.8359L5.09766 10.7812L9.12305 6.75586H0V5.24414H9.12305Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreStreamCourse;
