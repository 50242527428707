import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

type FiltersProps = {
  filters: Record<string, string[]>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  handleCheckboxChange: (category: string, value: string) => void;
  universityData: {
    filters: Record<string, string[]>;
  };
};

const FiltersSidebar: React.FC<FiltersProps> = ({
  filters,
  setFilters,
  handleCheckboxChange,
  universityData,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerms, setSearchTerms] = useState<Record<string, string>>({
    educationStream: "",
    degree: "",
    state: "",
  });

  const defaultFilters: Record<string, string[]> = Object.keys(
    universityData.filters
  ).reduce((acc, key) => {
    acc[key] = filters[key] || [];
    return acc;
  }, {} as Record<string, string[]>);

  const isAnyFilterSelected = Object.values(defaultFilters).some(
    (filter) => filter.length > 0
  );

  const clearFilters = () => {
    setFilters(
      Object.keys(universityData.filters).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {} as Record<string, string[]>)
    );
    setSearchParams({});
    setSearchTerms({ educationStream: "", degree: "", state: "" });
  };

  return (
    <div className="w-full bg-blue text-white rounded-md">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-white">Filters</h3>
        {isAnyFilterSelected && (
          <button
            onClick={clearFilters}
            className="text-sm text-gray-300 hover:underline"
          >
            Clear Filters
          </button>
        )}
      </div>
      <hr className="h-[1px] bg-white w-full my-3" />

      {Object.keys(universityData.filters)
        .filter((category) => universityData.filters[category]?.length > 0)
        .map((category: string) => {
          // Check if the category needs a search box
          const showSearchBox = ["educationStream", "degree", "state"].includes(
            category
          );
          const filteredOptions = universityData.filters[category]?.filter(
            (item: string) =>
              item
                .toLowerCase()
                .includes(searchTerms[category]?.toLowerCase() || "")
          );

          return (
            <div key={category} className="mb-4 border-b border-white pb-4">
              <h4 className="text-white font-semibold mb-2 capitalize text-lg">
                {category.replace(/([A-Z])/g, " $1")}
              </h4>

              {/* Search Box */}
              {showSearchBox && (
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full p-2 rounded-md text-black mb-2"
                  value={searchTerms[category] || ""}
                  onChange={(e) =>
                    setSearchTerms({
                      ...searchTerms,
                      [category]: e.target.value,
                    })
                  }
                />
              )}

              {/* Filtered Checkbox List */}
              <ul className="flex flex-col gap-2 list-none max-h-40 overflow-y-auto pr-2">
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((item: string) => (
                    <li key={item}>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-blue focus:ring-blue rounded"
                          checked={defaultFilters[category]?.includes(item)}
                          onChange={() => handleCheckboxChange(category, item)}
                        />
                        <span className="text-base line-clamp-1 text-white">
                          {item}
                        </span>
                      </label>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-300 italic">No results found</li>
                )}
              </ul>
            </div>
          );
        })}
    </div>
  );
};

export default FiltersSidebar;
