import React from "react";

export const IkigaiCareerPath: React.FC = () => {
  return (
    <div className=" px-20 md:py-14 max-md:py-5 mt-0 w-full bg-[#FFF1FA] max-md:px-8 max-lg:max-w-full my-1">
      <div className="flex max-lg:gap-1 max-lg:flex-col justify-between">
        <div className="flex flex-col w-[52%] max-md:ml-0 max-lg:w-full">
          <div className="flex flex-col grow text-base leading-7 text-black max-lg:mt-1 max-lg:max-w-full">
            <h3 className="text-[32px] font-light leading-10 max-lg:max-w-full">
              <span className="font-medium">
                Ikigai is a key driver of success in any career.
              </span>{" "}
              To support your child in finding a fulfilling path, consider these
              essential factors
            </h3>
            <p className="mt-3.5 max-lg:max-w-full">
              Interests & Personality: Aligning their passions with their unique
              traits. Cognitive Abilities: Understanding their strengths and
              learning styles. Career Choices: Exploring options that resonate
              with them. Educational Path: Planning for college and beyond.
            </p>
            <p className="mt-3.5 max-lg:max-w-full">
              To guide this process, your child will take a test based on
              Holland's Vocational Personality Theory, which suggests that job
              satisfaction is higher when individuals pursue careers that match
              their personal characteristics. The six Holland Codes are:
            </p>
            <ul className="mt-3.5 max-lg:max-w-full list-none">
              <li>Realistic: Hands-on, practical tasks (The "Do-ers")</li>
              <li>
                Investigative: Analytical, problem-solving roles (The
                "Thinkers")
              </li>
              <li>
                Artistic: Creative, self-expressive careers (The "Creators")
              </li>
              <li>Social: Helping, nurturing professions (The "Helpers")</li>
              <li>
                Enterprising: Leadership and persuasive roles (The "Persuaders")
              </li>
              <li>
                Conventional: Organized, detail-oriented positions (The
                "Organizers")
              </li>
            </ul>
            <p className="mt-3.5 max-lg:max-w-full">
              By understanding these elements, you can help your child navigate
              their career journey toward success and fulfillment.
            </p>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[40%] max-md:ml-0 max-lg:w-full py-10 max-md:py-5">
          <img
            loading="lazy"
            src="/images/career-discovery/career-path-ikigai.webp"
            alt="Ikigai Career Path Illustration"
            className="object-contain self-stretch my-auto w-full aspect-[1.15] max-md:mt-10 max-lg:max-w-full min-max-md:h-80"
          />
        </div>
      </div>
    </div>
  );
};
